import React, { memo, useEffect, useRef } from 'react';

import type { TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { TextField as MuiTextField } from '@mui/material';

import { useField } from '@unform/core';

type TextFieldProps = MuiTextFieldProps & {
  name: string;
  label: string;
  style?: React.CSSProperties;
  manualHelperText?: string;
};

const UnTextField: React.FC<TextFieldProps> = ({
  name,
  label,
  helperText,
  defaultValue,
  InputLabelProps,
  manualHelperText,
  ...restProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue: defaultFieldValue, registerField, error, clearError } = useField(name);

  const defaultInputValue = defaultValue ?? defaultFieldValue;

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: restProps?.type === 'file' ? 'files' : 'value',
        clearValue(ref, resetValue: string) {
          const newValue = resetValue ?? defaultInputValue ?? '';
          ref.value = newValue;
        },
        setValue(ref: HTMLInputElement, value: string) {
          if (ref) {
            const newValue = value ?? '';
            ref.value = newValue;
          }
        }
      });
    }
  }, [fieldName, registerField, defaultInputValue]);

  return (
    <MuiTextField
      inputRef={inputRef}
      name={fieldName}
      data-id={fieldName}
      label={error && helperText ? helperText : error ? error : label}
      error={!!error}
      helperText={helperText ?? manualHelperText}
      defaultValue={defaultInputValue}
      InputLabelProps={{
        ...InputLabelProps
      }}
      variant="outlined"
      onFocus={() => clearError()}
      sx={{
        width: '100%',
        display: restProps.type === 'hidden' ? 'none' : ''
      }}
      {...restProps}
    />
  );
};

const TextField = memo(UnTextField);

export { TextField };
