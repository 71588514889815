export default {
  title: 'dark',

  colors: {
    primary: '#008350',
    secondary: '#008350',

    bg100: '#121212',
    text100: '#fff',

    bg90: '#191919',
    text90: '#8e9297',

    bg80: '#212121',
    text80: '#aaa',

    svg100: '#b9bbbe',

    bgActive: '#0083501a',

    hover: 'rgba(255, 255, 255, .1)',

    red: '#c5221f',
    green: '#188038',
    blue: '#1967d2',
    yellow: '#f5a623',
    orange: '#f57b17',
    white: '#ffffff',
    black: '#F8F9FA'
  },

  tags: {
    bgDefault: 'rgba(255, 255, 255, .1)',
    textDefault: '#fff',

    bgRed: '#c5221f',
    textRed: '#fff',

    bgGreen: '#188038',
    textGreen: '#fff',

    bgBlue: '#1967d2',
    textBlue: '#fff',

    bgYellow: '#f5a623',
    textYellow: '#202124',

    bgWhite: '#fff',
    bgBlack: '#202124'
  },

  boxShadow: {
    shadow100: 'rgb(0 0 0 / 10%) 0px 9px 16px, rgb(0 0 0 / 32%) 0px 2px 2px',
    shadow90: 'none',
    shadow80: 'rgb(0 0 0 / 10%) 0px 9px 16px, rgb(0 0 0 / 32%) 0px 2px 2px',
    showCard:
      'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'
  },

  border: {
    border100: '1px solid rgba(255, 255, 255, .1)',
    border90: '1px solid #191919',
    border80: '1px solid #212121',
    borderPrimary: '1px solid #008350',
    defaultBorder: '1px solid #8e9297',
    color: 'rgba(255, 255, 255, .1)'
  },

  mui: {
    label: '#8e9297',
    border: '#8e9297',

    skeleton: 'rgb(255 255 255 / 5%)',
    skeletonAfter: 'linear-gradient(90deg,transparent,rgba(255,255,255,0.04),transparent)'
  }
};
