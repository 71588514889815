import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  .gm-bundled-control,
  .gm-bundled-control-on-bottom {
    display: block !important;

    .gmnoprint {
      display: block !important;
    }
  }

  .gmnoprint,
  .gm-style-cc {
    display: none;
  }

  .infoBox {
    overflow: inherit !important;

    > div:nth-of-type(1) {
      margin-top: 8px;
      margin-left: -12px;
      background-color: ${(props) => props.theme.colors.bg90};
      border-radius: 4px;
      width: max-content;
    }

    div div {
      width: max-content;
      padding: 8px 12px;
      border-radius: 4px;
      background: #fff;
      margin: 0;
      position: relative;

      :before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background-color: #fff;
        position: absolute;
        left: 8px;
        top: -4px;
        transform: rotate(45deg);
        z-index: 1;
      }
    }
  }
`;
