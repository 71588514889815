import styled from 'styled-components';

type ITitleProps = {
  isActive: boolean;
};

type TabProps = {
  full: boolean;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: ${(props) => props.theme.border.border100};
`;

const Tab = styled.button<TabProps>`
  flex: ${(props) => (props.full ? '1' : '0')};
  padding: 16px;
  position: relative;
  cursor: pointer;
  background: none;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActiveTab = styled.div<ITitleProps>`
  width: 100%;
  height: 2px;
  display: ${(props) => (props.isActive ? 'block' : 'none')};
  background-color: ${(props) => props.theme.colors.primary};
  position: absolute;
  bottom: 0;
  left: 0;
`;

const Title = styled.div<ITitleProps>`
  color: ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.text90)};
  font-weight: 500;
  font-size: 14px;
  width: max-content;
`;

export {
  Container, Tab, Title, ActiveTab,
};
