import Lottie from 'react-lottie-player';

import LoadingAnimation from '@assets/lottie/error.json';

import { Container, LottieContent, Text } from './styles';

type Props = {
  text?: string;
  children?: React.ReactNode;
};

function Error({ text, children }: Props) {
  return (
    <Container>
      <LottieContent>
        <Lottie className="lottie" animationData={LoadingAnimation} loop={true} play={true} />
      </LottieContent>

      <Text>{text || 'Um erro inesperado aconteceu, tente novamente em alguns minutos'}</Text>

      {children}
    </Container>
  );
}

export { Error };
