export default {
  title: 'light',

  colors: {
    primary: '#008350',
    secondary: '#008350',

    bg100: '#F8F9FA',
    text100: '#202124',

    bg90: '#fff',
    text90: '#5F6368',

    bg80: '#fff',
    text80: '#767676',

    svg100: '#5f6368',

    bgActive: '#0083501a',

    hover: 'rgba(0,0,0,.04)',

    red: '#c5221f',
    green: '#188038',
    blue: '#1967d2',
    yellow: '#f5a623',
    orange: '#f57b17',
    white: '#ffffff',
    black: '#F8F9FA'
  },

  tags: {
    bgDefault: 'rgba(0,0,0,.1)',
    textDefault: '#202124',

    bgRed: '#c5221f',
    textRed: '#fff',

    bgGreen: '#188038',
    textGreen: '#fff',

    bgBlue: '#1967d2',
    textBlue: '#fff',

    bgYellow: '#f5a623',
    textYellow: '#202124',

    bgWhite: '#fff',
    bgBlack: '#202124'
  },

  boxShadow: {
    shadow100: 'rgb(255 255 255 / 10%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px',
    shadow90: '1px 1px 12px #dadce0',
    shadow80: 'rgb(255 255 255 / 10%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px',
    showCard:
      'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'
  },

  border: {
    border100: '1px solid rgba(0, 0, 0, .1)',
    border90: '1px solid rgba(0, 0, 0, .1)',
    border80: '1px solid rgba(0, 0, 0, .1)',
    borderPrimary: '1px solid #008350',
    defaultBorder: '1px solid #5F6368',
    color: 'rgba(0, 0, 0, .1)'
  },

  mui: {
    label: 'rgba(0, 0, 0, 0.6)',
    border: 'rgba(0, 0, 0, 0.23)',

    skeleton: 'rgba(0, 0, 0, 0.11)',
    skeletonAfter: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent)'
  }
};
