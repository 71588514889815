import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 16px;
`;

const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
`;

const THead = styled.thead`
  width: 100%;
`;

const TBody = styled.tbody`
  width: 100%;
`;

const TR = styled.tr`
  border-bottom: 1px solid ${(props) => props.theme.colors.hover};
`;

const TD = styled.td`
  color: ${(props) => props.theme.colors.text90};
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
`;

const THeadData = styled.td`
  color: ${(props) => props.theme.colors.text100};
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
`;

export {
  Container, TableContainer, THead, TBody, TR, TD, THeadData,
};
