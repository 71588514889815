import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  box-sizing: border-box;
`;

const LottieContent = styled.div`
  width: 150px;
  height: 150px;

  .lottie {
    width: 150px;
    margin-bottom: -44px;
  }
`;

const Img = styled.img`
  width: 300px;
  height: 40px;
`;

const Text = styled.div`
  font-family: 'Roboto', Sans-Serif;
  font-size: 14px;
  font-weight: 500;
  color: '#5F6368';
`;

const Button = styled.a`
  cursor: pointer;
  padding: 12px 16px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
`;

export {
  Container, LottieContent, Text, Img, Button,
};
