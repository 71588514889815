import LoadingAnimation from '@assets/lottie/loading-files.json';
import Lottie from 'react-lottie-player';

import { Stack, Typography } from '@mui/material';

export function Loading() {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0
      }}
    >
      <Lottie
        className="lottie"
        animationData={LoadingAnimation}
        loop={true}
        play={true}
        // speed={4}
        style={{
          width: 300,
          height: 300
        }}
      />

      <Typography variant="body1" color="text.secondary" sx={{ mt: -10 }}>
        Estamos trazendo os dados para você.
      </Typography>
    </Stack>
  );
}
