import styled from 'styled-components';

export enum IStatus {
  pending = 'pending',
  finished = 'finished',
}

export type StatusProps = {
  status: IStatus;
};

const Container = styled.div<StatusProps>`
  width: 100%;
  background-color: ${(props) => props.theme.colors.bg90};
  color: ${(props) => props.theme.colors.text100};
  box-shadow: ${(props) => props.theme.boxShadow.shadow80};
  border-radius: 8px;
  margin-bottom: 16px;
  border-left: 5px solid
    ${(props) => (props.status === IStatus.finished ? props.theme.colors.green : props.theme.colors.yellow)};

  @media print {
    border-color: ${(props) => props.theme.colors.text90};
  }
`;

const Header = styled.div`
  width: 100%;
  /* height: 54px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border-bottom: ${(props) => props.theme.border.border100};
`;

const LeftContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RightContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.text100};
`;

const Status = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 14px;
  font-weight: 500;
  color: ${(props) => (props.status === IStatus.finished ? props.theme.colors.green : props.theme.colors.yellow)};

  @media print {
    display: none;
  }
`;

const DotStatus = styled.div<StatusProps>`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: ${(props) => (props.status === IStatus.finished ? props.theme.colors.green : props.theme.colors.yellow)};
`;

const Body = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 8px;
`;

const Ingredients = styled.div`
  width: 100%;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border-top: ${(props) => props.theme.border.border100};
`;

const ContainerInformation = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 32px;

  @media print {
    div:nth-child(2) {
      display: none;
    }
  }
`;

const SubmitBtn = styled.button`
  cursor: pointer;
  padding: 12px 16px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  min-width: 200px;

  @media print {
    display: none;
  }
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.text90};
`;

export {
  Container,
  Header,
  LeftContent,
  RightContent,
  Title,
  Status,
  DotStatus,
  Footer,
  Body,
  Ingredients,
  SubmitBtn,
  ContainerInformation,
  Description,
};
