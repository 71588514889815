import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const InputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  .MuiFormControl-root,
  .MuiAutocomplete-root {
    flex: 1;
  }
`;

const InputLineGroup = styled.div`
  display: grid;

  grid-template-areas:
    '. .'
    '. .'
    '. .';

  grid-template-columns: auto;
  grid-template-rows: auto;
  gap: 16px;
`;

const DeleteInputGroupContainer = styled.div`
  width: 48px;
  height: 48px;
  background-color: transparent;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.red};
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.colors.hover};
  }
`;

export { Container, DeleteInputGroupContainer, InputGroup, InputLineGroup };
