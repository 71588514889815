import styled from 'styled-components';

export interface IButtonProps {
  variant: 'primary' | 'secondary';
  fullWidth?: boolean;
}

export const ButtonContainer = styled.button<IButtonProps>`
  width: ${(props) => (props.fullWidth ? '100%' : 'fit-content')};
  position: relative;
  cursor: pointer;
  padding: 12px 16px;
  background-color: ${(props) =>
    props.variant === 'primary' ? props.theme.colors.primary : props.theme.tags.bgDefault};
  color: ${(props) => (props.variant === 'primary' ? props.theme.colors.white : props.theme.tags.textDefault)};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  :disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
    cursor: no-drop;

    svg {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;
