import { AUTH_KEY, FARM_DAY_KEY } from '@/constants';

export function logoutMiddleware() {
  localStorage.removeItem(AUTH_KEY);
  localStorage.removeItem(FARM_DAY_KEY);
  localStorage.removeItem('theme');
  localStorage.removeItem('currentSession');
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('FEED:open-menu');
  localStorage.removeItem('FEED:current-session');
  localStorage.removeItem('FEED:current-navigation');
  localStorage.removeItem('bS');
  localStorage.removeItem('cAS1');
  localStorage.removeItem('cAS2');
  localStorage.removeItem('i18nextLng');
}
