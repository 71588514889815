import styled from 'styled-components';

type NightlyReadItemProps = {
  color: 'green' | 'red';
};

type NightlyReadContainerProps = {
  center: boolean;
};

export const NightlyReadItem = styled.div<NightlyReadItemProps>`
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  background: ${({ color, theme }) => (color === 'green' ? theme.colors.green : theme.colors.red)};
  color: ${({ theme }) => theme.colors.white};
`;

export const NightlyReadContainer = styled.div<NightlyReadContainerProps>`
  width: 100%;
  min-height: 25px;
  display: flex;
  gap: 4px;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
`;
