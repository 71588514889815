// Vite route: https://viterouter.dev/guide/features.html
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const File1 = lazy(() => import('./app/index'));
const File2 = lazy(() => import('./app/admin'));
const File3 = lazy(() => import('./app/admin/batches'));
const File4 = lazy(() => import('./app/admin/batches/add'));
const File5 = lazy(() => import('./app/admin/batches/closure'));
const File6 = lazy(() => import('./app/admin/diet/add'));
const File7 = lazy(() => import('./app/admin/diet/edit/[id]'));
const File8 = lazy(() => import('./app/admin/diet/list'));
const File9 = lazy(() => import('./app/admin/diet/pre-mix/add'));
const File10 = lazy(() => import('./app/admin/diet/pre-mix/edit/[id]'));
const File11 = lazy(() => import('./app/admin/diet/pre-mix/list'));
const File12 = lazy(() => import('./app/admin/diet/premix/add'));
const File13 = lazy(() => import('./app/admin/diet/premix/edit/[id]'));
const File14 = lazy(() => import('./app/admin/diet/premix/list'));
const File15 = lazy(() => import('./app/admin/farm'));
const File16 = lazy(() => import('./app/admin/farm/batch/add'));
const File17 = lazy(() => import('./app/admin/farm/batch/edit/[id]'));
const File18 = lazy(() => import('./app/admin/farm/batch/list'));
const File19 = lazy(() => import('./app/admin/farm/data'));
const File20 = lazy(() => import('./app/admin/farm/data/address'));
const File21 = lazy(() => import('./app/admin/farm/data/factories'));
const File22 = lazy(() => import('./app/admin/farm/days'));
const File23 = lazy(() => import('./app/admin/farm/lots/add'));
const File24 = lazy(() => import('./app/admin/farm/lots/edit/[id]'));
const File25 = lazy(() => import('./app/admin/farm/lots/list'));
const File26 = lazy(() => import('./app/admin/farm/owners/add'));
const File27 = lazy(() => import('./app/admin/farm/owners/edit/[id]'));
const File28 = lazy(() => import('./app/admin/farm/owners/list'));
const File29 = lazy(() => import('./app/admin/farm/pastures/add'));
const File30 = lazy(() => import('./app/admin/farm/pastures/list'));
const File31 = lazy(() => import('./app/admin/farm/settings/financial-reports'));
const File32 = lazy(() => import('./app/admin/farm/settings/grades'));
const File33 = lazy(() => import('./app/admin/farm/settings/night-reading'));
const File34 = lazy(() => import('./app/admin/financial'));
const File35 = lazy(() => import('./app/admin/financial/animal/add'));
const File36 = lazy(() => import('./app/admin/financial/animal/edit/[id]'));
const File37 = lazy(() => import('./app/admin/financial/animal/list'));
const File38 = lazy(() => import('./app/admin/financial/earring/add'));
const File39 = lazy(() => import('./app/admin/financial/earring/edit/[id]'));
const File40 = lazy(() => import('./app/admin/financial/earring/list'));
const File41 = lazy(() => import('./app/admin/financial/farm-foods-create'));
const File42 = lazy(() => import('./app/admin/financial/food/add'));
const File43 = lazy(() => import('./app/admin/financial/food/edit/[id]'));
const File44 = lazy(() => import('./app/admin/financial/food/list'));
const File45 = lazy(() => import('./app/admin/financial/gta-create'));
const File46 = lazy(() => import('./app/admin/financial/gta-details/[id]'));
const File47 = lazy(() => import('./app/admin/financial/gta-list'));
const File48 = lazy(() => import('./app/admin/financial/new-order'));
const File49 = lazy(() => import('./app/admin/financial/origins/add'));
const File50 = lazy(() => import('./app/admin/financial/origins/edit/[id]'));
const File51 = lazy(() => import('./app/admin/financial/origins/list'));
const File52 = lazy(() => import('./app/admin/financial/stock-add'));
const File53 = lazy(() => import('./app/admin/financial/stock-list'));
const File54 = lazy(() => import('./app/admin/management'));
const File55 = lazy(() => import('./app/admin/management/batch-active'));
const File56 = lazy(() => import('./app/admin/management/batch-active/[id]'));
const File57 = lazy(() => import('./app/admin/management/batch-closing-edit/[id]'));
const File58 = lazy(() => import('./app/admin/management/batch-closing-list'));
const File59 = lazy(() => import('./app/admin/management/batch-edit/[id]'));
const File60 = lazy(() => import('./app/admin/management/batches'));
const File61 = lazy(() => import('./app/admin/management/closed-list'));
const File62 = lazy(() => import('./app/admin/management/closures/list'));
const File63 = lazy(() => import('./app/admin/management/closures/list/details/[id]'));
const File64 = lazy(() => import('./app/admin/management/closures/new'));
const File65 = lazy(() => import('./app/admin/management/closures/trucks'));
const File66 = lazy(() => import('./app/admin/management/closures/trucks/details/[id]'));
const File67 = lazy(() => import('./app/admin/management/execute'));
const File68 = lazy(() => import('./app/admin/management/execute/entry/[id]'));
const File69 = lazy(() => import('./app/admin/management/execute/identification/[id]'));
const File70 = lazy(() => import('./app/admin/management/execute/sale/[id]'));
const File71 = lazy(() => import('./app/admin/management/execute/weighing/[id]'));
const File72 = lazy(() => import('./app/admin/management/new'));
const File73 = lazy(() => import('./app/admin/medical-services'));
const File74 = lazy(() => import('./app/admin/medical-services/medicines/add'));
const File75 = lazy(() => import('./app/admin/medical-services/medicines/apply'));
const File76 = lazy(() => import('./app/admin/medical-services/medicines/edit/[id]'));
const File77 = lazy(() => import('./app/admin/medical-services/medicines/list'));
const File78 = lazy(() => import('./app/admin/medical-services/mortality/add'));
const File79 = lazy(() => import('./app/admin/medical-services/mortality/edit/[id]'));
const File80 = lazy(() => import('./app/admin/medical-services/mortality/list'));
const File81 = lazy(() => import('./app/admin/medical-services/protocols/add'));
const File82 = lazy(() => import('./app/admin/medical-services/protocols/details/[id]'));
const File83 = lazy(() => import('./app/admin/medical-services/protocols/list'));
const File84 = lazy(() => import('./app/admin/reports'));
const File85 = lazy(() => import('./app/admin/reports/analytical-report'));
const File86 = lazy(() => import('./app/admin/reports/batches-history'));
const File87 = lazy(() => import('./app/admin/reports/batches-reports'));
const File88 = lazy(() => import('./app/admin/reports/diet-preparation-efficiency'));
const File89 = lazy(() => import('./app/admin/reports/economic-batches-reports'));
const File90 = lazy(() => import('./app/admin/reports/individual-cattle-closing'));
const File91 = lazy(() => import('./app/admin/reports/stock-history'));
const File92 = lazy(() => import('./app/admin/reports/stock-reports'));
const File93 = lazy(() => import('./app/admin/reports/tract-efficiency-reports'));
const File94 = lazy(() => import('./app/admin/reports/zootechnical-indices'));
const File95 = lazy(() => import('./app/admin/traceability'));
const File96 = lazy(() => import('./app/admin/traceability/bnd/import'));
const File97 = lazy(() => import('./app/admin/traceability/certifier/import'));
const File98 = lazy(() => import('./app/admin/traceability/communique/change-characteristic'));
const File99 = lazy(() => import('./app/admin/traceability/communique/change-characteristic/edit/[id]'));
const File100 = lazy(() => import('./app/admin/traceability/communique/death'));
const File101 = lazy(() => import('./app/admin/traceability/communique/death/edit/[id]'));
const File102 = lazy(() => import('./app/admin/traceability/communique/entry'));
const File103 = lazy(() => import('./app/admin/traceability/communique/entry/emit/[id]'));
const File104 = lazy(() => import('./app/admin/traceability/communique/exit'));
const File105 = lazy(() => import('./app/admin/traceability/communique/overview'));
const File106 = lazy(() => import('./app/admin/traceability/communique/re-identification'));
const File107 = lazy(() => import('./app/admin/traceability/communique/re-identification/edit/[id]'));
const File108 = lazy(() => import('./app/admin/traceability/communique/unusability'));
const File109 = lazy(() => import('./app/admin/traceability/communique/unusability/edit/[id]'));
const File110 = lazy(() => import('./app/admin/traceability/earring/details/[id]'));
const File111 = lazy(() => import('./app/admin/traceability/earring/list'));
const File112 = lazy(() => import('./app/admin/traceability/ox-search'));
const File113 = lazy(() => import('./app/admin/traceability/spreadsheets/identification'));
const File114 = lazy(() => import('./app/admin/tract'));
const File115 = lazy(() => import('./app/admin/tract/adjust-dry-matter'));
const File116 = lazy(() => import('./app/admin/tract/beat-add'));
const File117 = lazy(() => import('./app/admin/tract/beat-history'));
const File118 = lazy(() => import('./app/admin/tract/beat-of-day'));
const File119 = lazy(() => import('./app/admin/tract/beat-of-day/v2'));
const File120 = lazy(() => import('./app/admin/tract/change-corral-diet'));
const File121 = lazy(() => import('./app/admin/tract/change-diet'));
const File122 = lazy(() => import('./app/admin/tract/diet-transition-add'));
const File123 = lazy(() => import('./app/admin/tract/diet-transition-list'));
const File124 = lazy(() => import('./app/admin/tract/management-trough'));
const File125 = lazy(() => import('./app/admin/tract/management-trough/v2'));
const File126 = lazy(() => import('./app/admin/tract/occurrences-create'));
const File127 = lazy(() => import('./app/admin/tract/occurrences-list'));
const File128 = lazy(() => import('./app/admin/tract/planning-add'));
const File129 = lazy(() => import('./app/admin/tract/planning-list'));
const File130 = lazy(() => import('./app/admin/tract/pre-mix'));
const File131 = lazy(() => import('./app/admin/tract/replace-planning'));
const File132 = lazy(() => import('./app/admin/tract/replicate'));
const File133 = lazy(() => import('./app/admin/tract/tract-of-day'));
const File134 = lazy(() => import('./app/admin/tract/tract-of-day/v2'));
const File135 = lazy(() => import('./app/create-account'));
const File136 = lazy(() => import('./app/login'));

const AdminLayout = lazy(() => import('./app/admin/layout'));

function Error404() {
  return <div>404</div>;
}

function Loading() {
  return <div>Loading...</div>;
}

interface IAppRoutesProps {
  custom404?: React.ReactNode;
  customLoading?: React.ReactNode;
}

export function AppRoutes({ custom404: Custom404, customLoading: CustomLoading }: IAppRoutesProps) {
  return (
    <BrowserRouter>
      <Suspense fallback={CustomLoading || <Loading />}>
        <Routes>
          
          <Route 
            element={<File1 />} 
            path="/" 
            key="/"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File2 />
              </AdminLayout>
            } 
            path="/admin" 
            key="/admin"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File3 />
              </AdminLayout>
            } 
            path="/admin/batches" 
            key="/admin/batches"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File4 />
              </AdminLayout>
            } 
            path="/admin/batches/add" 
            key="/admin/batches/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File5 />
              </AdminLayout>
            } 
            path="/admin/batches/closure" 
            key="/admin/batches/closure"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File6 />
              </AdminLayout>
            } 
            path="/admin/diet/add" 
            key="/admin/diet/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File7 />
              </AdminLayout>
            } 
            path="/admin/diet/edit/:id" 
            key="/admin/diet/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File8 />
              </AdminLayout>
            } 
            path="/admin/diet/list" 
            key="/admin/diet/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File9 />
              </AdminLayout>
            } 
            path="/admin/diet/pre-mix/add" 
            key="/admin/diet/pre-mix/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File10 />
              </AdminLayout>
            } 
            path="/admin/diet/pre-mix/edit/:id" 
            key="/admin/diet/pre-mix/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File11 />
              </AdminLayout>
            } 
            path="/admin/diet/pre-mix/list" 
            key="/admin/diet/pre-mix/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File12 />
              </AdminLayout>
            } 
            path="/admin/diet/premix/add" 
            key="/admin/diet/premix/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File13 />
              </AdminLayout>
            } 
            path="/admin/diet/premix/edit/:id" 
            key="/admin/diet/premix/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File14 />
              </AdminLayout>
            } 
            path="/admin/diet/premix/list" 
            key="/admin/diet/premix/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File15 />
              </AdminLayout>
            } 
            path="/admin/farm" 
            key="/admin/farm"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File16 />
              </AdminLayout>
            } 
            path="/admin/farm/batch/add" 
            key="/admin/farm/batch/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File17 />
              </AdminLayout>
            } 
            path="/admin/farm/batch/edit/:id" 
            key="/admin/farm/batch/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File18 />
              </AdminLayout>
            } 
            path="/admin/farm/batch/list" 
            key="/admin/farm/batch/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File19 />
              </AdminLayout>
            } 
            path="/admin/farm/data" 
            key="/admin/farm/data"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File20 />
              </AdminLayout>
            } 
            path="/admin/farm/data/address" 
            key="/admin/farm/data/address"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File21 />
              </AdminLayout>
            } 
            path="/admin/farm/data/factories" 
            key="/admin/farm/data/factories"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File22 />
              </AdminLayout>
            } 
            path="/admin/farm/days" 
            key="/admin/farm/days"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File23 />
              </AdminLayout>
            } 
            path="/admin/farm/lots/add" 
            key="/admin/farm/lots/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File24 />
              </AdminLayout>
            } 
            path="/admin/farm/lots/edit/:id" 
            key="/admin/farm/lots/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File25 />
              </AdminLayout>
            } 
            path="/admin/farm/lots/list" 
            key="/admin/farm/lots/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File26 />
              </AdminLayout>
            } 
            path="/admin/farm/owners/add" 
            key="/admin/farm/owners/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File27 />
              </AdminLayout>
            } 
            path="/admin/farm/owners/edit/:id" 
            key="/admin/farm/owners/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File28 />
              </AdminLayout>
            } 
            path="/admin/farm/owners/list" 
            key="/admin/farm/owners/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File29 />
              </AdminLayout>
            } 
            path="/admin/farm/pastures/add" 
            key="/admin/farm/pastures/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File30 />
              </AdminLayout>
            } 
            path="/admin/farm/pastures/list" 
            key="/admin/farm/pastures/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File31 />
              </AdminLayout>
            } 
            path="/admin/farm/settings/financial-reports" 
            key="/admin/farm/settings/financial-reports"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File32 />
              </AdminLayout>
            } 
            path="/admin/farm/settings/grades" 
            key="/admin/farm/settings/grades"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File33 />
              </AdminLayout>
            } 
            path="/admin/farm/settings/night-reading" 
            key="/admin/farm/settings/night-reading"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File34 />
              </AdminLayout>
            } 
            path="/admin/financial" 
            key="/admin/financial"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File35 />
              </AdminLayout>
            } 
            path="/admin/financial/animal/add" 
            key="/admin/financial/animal/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File36 />
              </AdminLayout>
            } 
            path="/admin/financial/animal/edit/:id" 
            key="/admin/financial/animal/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File37 />
              </AdminLayout>
            } 
            path="/admin/financial/animal/list" 
            key="/admin/financial/animal/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File38 />
              </AdminLayout>
            } 
            path="/admin/financial/earring/add" 
            key="/admin/financial/earring/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File39 />
              </AdminLayout>
            } 
            path="/admin/financial/earring/edit/:id" 
            key="/admin/financial/earring/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File40 />
              </AdminLayout>
            } 
            path="/admin/financial/earring/list" 
            key="/admin/financial/earring/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File41 />
              </AdminLayout>
            } 
            path="/admin/financial/farm-foods-create" 
            key="/admin/financial/farm-foods-create"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File42 />
              </AdminLayout>
            } 
            path="/admin/financial/food/add" 
            key="/admin/financial/food/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File43 />
              </AdminLayout>
            } 
            path="/admin/financial/food/edit/:id" 
            key="/admin/financial/food/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File44 />
              </AdminLayout>
            } 
            path="/admin/financial/food/list" 
            key="/admin/financial/food/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File45 />
              </AdminLayout>
            } 
            path="/admin/financial/gta-create" 
            key="/admin/financial/gta-create"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File46 />
              </AdminLayout>
            } 
            path="/admin/financial/gta-details/:id" 
            key="/admin/financial/gta-details/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File47 />
              </AdminLayout>
            } 
            path="/admin/financial/gta-list" 
            key="/admin/financial/gta-list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File48 />
              </AdminLayout>
            } 
            path="/admin/financial/new-order" 
            key="/admin/financial/new-order"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File49 />
              </AdminLayout>
            } 
            path="/admin/financial/origins/add" 
            key="/admin/financial/origins/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File50 />
              </AdminLayout>
            } 
            path="/admin/financial/origins/edit/:id" 
            key="/admin/financial/origins/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File51 />
              </AdminLayout>
            } 
            path="/admin/financial/origins/list" 
            key="/admin/financial/origins/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File52 />
              </AdminLayout>
            } 
            path="/admin/financial/stock-add" 
            key="/admin/financial/stock-add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File53 />
              </AdminLayout>
            } 
            path="/admin/financial/stock-list" 
            key="/admin/financial/stock-list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File54 />
              </AdminLayout>
            } 
            path="/admin/management" 
            key="/admin/management"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File55 />
              </AdminLayout>
            } 
            path="/admin/management/batch-active" 
            key="/admin/management/batch-active"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File56 />
              </AdminLayout>
            } 
            path="/admin/management/batch-active/:id" 
            key="/admin/management/batch-active/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File57 />
              </AdminLayout>
            } 
            path="/admin/management/batch-closing-edit/:id" 
            key="/admin/management/batch-closing-edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File58 />
              </AdminLayout>
            } 
            path="/admin/management/batch-closing-list" 
            key="/admin/management/batch-closing-list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File59 />
              </AdminLayout>
            } 
            path="/admin/management/batch-edit/:id" 
            key="/admin/management/batch-edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File60 />
              </AdminLayout>
            } 
            path="/admin/management/batches" 
            key="/admin/management/batches"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File61 />
              </AdminLayout>
            } 
            path="/admin/management/closed-list" 
            key="/admin/management/closed-list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File62 />
              </AdminLayout>
            } 
            path="/admin/management/closures/list" 
            key="/admin/management/closures/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File63 />
              </AdminLayout>
            } 
            path="/admin/management/closures/list/details/:id" 
            key="/admin/management/closures/list/details/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File64 />
              </AdminLayout>
            } 
            path="/admin/management/closures/new" 
            key="/admin/management/closures/new"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File65 />
              </AdminLayout>
            } 
            path="/admin/management/closures/trucks" 
            key="/admin/management/closures/trucks"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File66 />
              </AdminLayout>
            } 
            path="/admin/management/closures/trucks/details/:id" 
            key="/admin/management/closures/trucks/details/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File67 />
              </AdminLayout>
            } 
            path="/admin/management/execute" 
            key="/admin/management/execute"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File68 />
              </AdminLayout>
            } 
            path="/admin/management/execute/entry/:id" 
            key="/admin/management/execute/entry/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File69 />
              </AdminLayout>
            } 
            path="/admin/management/execute/identification/:id" 
            key="/admin/management/execute/identification/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File70 />
              </AdminLayout>
            } 
            path="/admin/management/execute/sale/:id" 
            key="/admin/management/execute/sale/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File71 />
              </AdminLayout>
            } 
            path="/admin/management/execute/weighing/:id" 
            key="/admin/management/execute/weighing/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File72 />
              </AdminLayout>
            } 
            path="/admin/management/new" 
            key="/admin/management/new"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File73 />
              </AdminLayout>
            } 
            path="/admin/medical-services" 
            key="/admin/medical-services"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File74 />
              </AdminLayout>
            } 
            path="/admin/medical-services/medicines/add" 
            key="/admin/medical-services/medicines/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File75 />
              </AdminLayout>
            } 
            path="/admin/medical-services/medicines/apply" 
            key="/admin/medical-services/medicines/apply"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File76 />
              </AdminLayout>
            } 
            path="/admin/medical-services/medicines/edit/:id" 
            key="/admin/medical-services/medicines/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File77 />
              </AdminLayout>
            } 
            path="/admin/medical-services/medicines/list" 
            key="/admin/medical-services/medicines/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File78 />
              </AdminLayout>
            } 
            path="/admin/medical-services/mortality/add" 
            key="/admin/medical-services/mortality/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File79 />
              </AdminLayout>
            } 
            path="/admin/medical-services/mortality/edit/:id" 
            key="/admin/medical-services/mortality/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File80 />
              </AdminLayout>
            } 
            path="/admin/medical-services/mortality/list" 
            key="/admin/medical-services/mortality/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File81 />
              </AdminLayout>
            } 
            path="/admin/medical-services/protocols/add" 
            key="/admin/medical-services/protocols/add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File82 />
              </AdminLayout>
            } 
            path="/admin/medical-services/protocols/details/:id" 
            key="/admin/medical-services/protocols/details/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File83 />
              </AdminLayout>
            } 
            path="/admin/medical-services/protocols/list" 
            key="/admin/medical-services/protocols/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File84 />
              </AdminLayout>
            } 
            path="/admin/reports" 
            key="/admin/reports"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File85 />
              </AdminLayout>
            } 
            path="/admin/reports/analytical-report" 
            key="/admin/reports/analytical-report"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File86 />
              </AdminLayout>
            } 
            path="/admin/reports/batches-history" 
            key="/admin/reports/batches-history"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File87 />
              </AdminLayout>
            } 
            path="/admin/reports/batches-reports" 
            key="/admin/reports/batches-reports"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File88 />
              </AdminLayout>
            } 
            path="/admin/reports/diet-preparation-efficiency" 
            key="/admin/reports/diet-preparation-efficiency"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File89 />
              </AdminLayout>
            } 
            path="/admin/reports/economic-batches-reports" 
            key="/admin/reports/economic-batches-reports"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File90 />
              </AdminLayout>
            } 
            path="/admin/reports/individual-cattle-closing" 
            key="/admin/reports/individual-cattle-closing"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File91 />
              </AdminLayout>
            } 
            path="/admin/reports/stock-history" 
            key="/admin/reports/stock-history"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File92 />
              </AdminLayout>
            } 
            path="/admin/reports/stock-reports" 
            key="/admin/reports/stock-reports"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File93 />
              </AdminLayout>
            } 
            path="/admin/reports/tract-efficiency-reports" 
            key="/admin/reports/tract-efficiency-reports"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File94 />
              </AdminLayout>
            } 
            path="/admin/reports/zootechnical-indices" 
            key="/admin/reports/zootechnical-indices"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File95 />
              </AdminLayout>
            } 
            path="/admin/traceability" 
            key="/admin/traceability"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File96 />
              </AdminLayout>
            } 
            path="/admin/traceability/bnd/import" 
            key="/admin/traceability/bnd/import"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File97 />
              </AdminLayout>
            } 
            path="/admin/traceability/certifier/import" 
            key="/admin/traceability/certifier/import"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File98 />
              </AdminLayout>
            } 
            path="/admin/traceability/communique/change-characteristic" 
            key="/admin/traceability/communique/change-characteristic"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File99 />
              </AdminLayout>
            } 
            path="/admin/traceability/communique/change-characteristic/edit/:id" 
            key="/admin/traceability/communique/change-characteristic/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File100 />
              </AdminLayout>
            } 
            path="/admin/traceability/communique/death" 
            key="/admin/traceability/communique/death"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File101 />
              </AdminLayout>
            } 
            path="/admin/traceability/communique/death/edit/:id" 
            key="/admin/traceability/communique/death/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File102 />
              </AdminLayout>
            } 
            path="/admin/traceability/communique/entry" 
            key="/admin/traceability/communique/entry"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File103 />
              </AdminLayout>
            } 
            path="/admin/traceability/communique/entry/emit/:id" 
            key="/admin/traceability/communique/entry/emit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File104 />
              </AdminLayout>
            } 
            path="/admin/traceability/communique/exit" 
            key="/admin/traceability/communique/exit"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File105 />
              </AdminLayout>
            } 
            path="/admin/traceability/communique/overview" 
            key="/admin/traceability/communique/overview"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File106 />
              </AdminLayout>
            } 
            path="/admin/traceability/communique/re-identification" 
            key="/admin/traceability/communique/re-identification"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File107 />
              </AdminLayout>
            } 
            path="/admin/traceability/communique/re-identification/edit/:id" 
            key="/admin/traceability/communique/re-identification/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File108 />
              </AdminLayout>
            } 
            path="/admin/traceability/communique/unusability" 
            key="/admin/traceability/communique/unusability"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File109 />
              </AdminLayout>
            } 
            path="/admin/traceability/communique/unusability/edit/:id" 
            key="/admin/traceability/communique/unusability/edit/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File110 />
              </AdminLayout>
            } 
            path="/admin/traceability/earring/details/:id" 
            key="/admin/traceability/earring/details/:id"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File111 />
              </AdminLayout>
            } 
            path="/admin/traceability/earring/list" 
            key="/admin/traceability/earring/list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File112 />
              </AdminLayout>
            } 
            path="/admin/traceability/ox-search" 
            key="/admin/traceability/ox-search"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File113 />
              </AdminLayout>
            } 
            path="/admin/traceability/spreadsheets/identification" 
            key="/admin/traceability/spreadsheets/identification"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File114 />
              </AdminLayout>
            } 
            path="/admin/tract" 
            key="/admin/tract"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File115 />
              </AdminLayout>
            } 
            path="/admin/tract/adjust-dry-matter" 
            key="/admin/tract/adjust-dry-matter"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File116 />
              </AdminLayout>
            } 
            path="/admin/tract/beat-add" 
            key="/admin/tract/beat-add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File117 />
              </AdminLayout>
            } 
            path="/admin/tract/beat-history" 
            key="/admin/tract/beat-history"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File118 />
              </AdminLayout>
            } 
            path="/admin/tract/beat-of-day" 
            key="/admin/tract/beat-of-day"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File119 />
              </AdminLayout>
            } 
            path="/admin/tract/beat-of-day/v2" 
            key="/admin/tract/beat-of-day/v2"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File120 />
              </AdminLayout>
            } 
            path="/admin/tract/change-corral-diet" 
            key="/admin/tract/change-corral-diet"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File121 />
              </AdminLayout>
            } 
            path="/admin/tract/change-diet" 
            key="/admin/tract/change-diet"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File122 />
              </AdminLayout>
            } 
            path="/admin/tract/diet-transition-add" 
            key="/admin/tract/diet-transition-add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File123 />
              </AdminLayout>
            } 
            path="/admin/tract/diet-transition-list" 
            key="/admin/tract/diet-transition-list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File124 />
              </AdminLayout>
            } 
            path="/admin/tract/management-trough" 
            key="/admin/tract/management-trough"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File125 />
              </AdminLayout>
            } 
            path="/admin/tract/management-trough/v2" 
            key="/admin/tract/management-trough/v2"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File126 />
              </AdminLayout>
            } 
            path="/admin/tract/occurrences-create" 
            key="/admin/tract/occurrences-create"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File127 />
              </AdminLayout>
            } 
            path="/admin/tract/occurrences-list" 
            key="/admin/tract/occurrences-list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File128 />
              </AdminLayout>
            } 
            path="/admin/tract/planning-add" 
            key="/admin/tract/planning-add"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File129 />
              </AdminLayout>
            } 
            path="/admin/tract/planning-list" 
            key="/admin/tract/planning-list"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File130 />
              </AdminLayout>
            } 
            path="/admin/tract/pre-mix" 
            key="/admin/tract/pre-mix"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File131 />
              </AdminLayout>
            } 
            path="/admin/tract/replace-planning" 
            key="/admin/tract/replace-planning"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File132 />
              </AdminLayout>
            } 
            path="/admin/tract/replicate" 
            key="/admin/tract/replicate"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File133 />
              </AdminLayout>
            } 
            path="/admin/tract/tract-of-day" 
            key="/admin/tract/tract-of-day"  
          />
        
          <Route 
            element={
              <AdminLayout>
                <File134 />
              </AdminLayout>
            } 
            path="/admin/tract/tract-of-day/v2" 
            key="/admin/tract/tract-of-day/v2"  
          />
        
          <Route 
            element={<File135 />} 
            path="/create-account" 
            key="/create-account"  
          />
        
          <Route 
            element={<File136 />} 
            path="/login" 
            key="/login"  
          />
          <Route
            path="*"
            element={Custom404 || <Error404 />}
          />
        </Routes>
      </Suspense> 
    </BrowserRouter>
  );
}
