import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;

  thead tr {
    border-bottom: 1px solid #ccc;
  }

  thead td {
    padding: 8px;
  }

  tbody tr {
    border-bottom: 1px solid #ccc;
  }

  tbody td {
    padding: 8px;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 16px;
`;

const Title = styled.div`
  text-align: center;
  font-weight: bold;
  padding: 8px;
  border: 2px solid ${(props) => props.theme.colors.primary};
  border-radius: 8px;
`;

const Line = styled.div`
  height: 5px;
  width: 100%;
  border: 2px solid;
`;

export {
  Table, Container, Title, Line,
};
