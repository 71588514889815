import type { RequesterServiceModel } from '../types';
import { RequesterMethodEnum } from '../types';

interface Services {
  postLogin: RequesterServiceModel;
}

export const services: Services = {
  postLogin: {
    method: RequesterMethodEnum.POST,
    endpoint: 'login',
  },
};
