import { ErrorBoundary, Provider } from '@rollbar/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import GlobalStyle from './_layouts/globalStyles';
import { FallBack } from './components/fallBack';
import { ThemeProvider } from './contexts/theme';
import { AppRoutes } from './routes2';

import 'react-circular-progressbar/dist/styles.css';
import { Loading } from './components';
import { NotFound } from './components/notFound';
import './i18n';
import './sentry';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ErrorBoundary fallbackUI={FallBack}>
            <AppRoutes custom404={<NotFound />} customLoading={<Loading />} />

            <GlobalStyle />

            <Toaster
              position="top-center"
              reverseOrder={false}
              toastOptions={{
                duration: 5000,
                style: {
                  background: '#333',
                  color: '#fff',
                  textAlign: 'center'
                }
              }}
            />
          </ErrorBoundary>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
