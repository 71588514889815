import styled from 'styled-components';

type Props = {
  isCurrentDate: boolean;
};

const Container = styled.button<Props>`
  height: 48px;
  padding: 0 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  background: ${({ isCurrentDate, theme }) => (isCurrentDate ? theme.colors.bg100 : theme.colors.red)};
  color: ${({ isCurrentDate, theme }) => (isCurrentDate ? theme.colors.text100 : theme.colors.white)};
  cursor: pointer;
`;

const TextDay = styled.div`
  width: max-content;
  font-size: 16px;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`;

export { Container, TextDay };
