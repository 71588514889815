import styled from 'styled-components';

type Props = {
  isSecondary: boolean;
  ellipsis: boolean;
};

const Container = styled.span<Props>`
  color: ${(props) => (props.isSecondary ? props.theme.colors.text90 : props.theme.colors.text100)};
  font-weight: 500;

  ${(props) => props.ellipsis
    && `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

export { Container };
