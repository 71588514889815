import styled from 'styled-components';

const Header = styled.div`
  width: 100%;
  max-width: 450px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.text100};
`;

const Item = styled.div`
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.hover};
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;

  .title {
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.text100};
    margin-bottom: 8px;
  }

  .description {
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.text90};
  }
`;

export { Header, Item };
