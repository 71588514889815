import styled from 'styled-components';

export const ModalContent = styled.div`
  width: 100%;
  max-width: 900px;
  background-color: #fff;
  box-shadow:
    0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 8px;
`;

const ModalTile = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: center;
  color: ${(props) => props.theme.colors.text100};
  padding: 24px 24px 0;
`;

const ModalBody = styled.div`
  position: relative;
  color: ${(props) => props.theme.colors.text90};
  padding: 0 24px;
  margin-bottom: 16px;
`;

const ModalFooter = styled.div`
  width: 100%;
  padding: 16px;
  border-top: ${(props) => props.theme.border.border100};
  display: flex;
  justify-content: flex-end;
`;

export { ModalBody, ModalFooter, ModalTile };
