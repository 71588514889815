import { logoutMiddleware } from '@/middleware/logout';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../constants';

export const AxiosInstance = Axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export function request<T = any, R = AxiosResponse<T>, D = any>(
  config: AxiosRequestConfig<D>,
  options?: AxiosRequestConfig<D>
): Promise<R> {
  //@ts-expect-error
  return AxiosInstance.request({ ...config, ...options }).catch((error) => {
    if (error.response?.status === 401) {
      logoutMiddleware();
      window.location.reload();
      return;
    }

    if (Axios.isAxiosError(error)) {
      throw error.response?.data || error;
    }

    throw error;
  });
}

/** @deprecated remover no generated */
export function customInstance<T = any, R = AxiosResponse<T>, D = any>(
  config: AxiosRequestConfig<D>,
  options?: AxiosRequestConfig<D>
): Promise<R> {
  return AxiosInstance.request({ ...config, ...options });
}

type State<D> = { loading: boolean; data?: D; error?: any; response?: AxiosResponse<D> };
type ApiCall<D, A extends unknown[]> = (...args: A) => Promise<AxiosResponse<D>>;

/** @deprecated remover no generated */
export function useQuery<D, A extends unknown[]>(apiCall: ApiCall<D, A>, ...args: A) {
  const [state, setState] = useState<State<D>>({ loading: true });

  const configParamIndex = apiCall.length - 1;
  const source = new AbortController();

  function recall() {
    args[configParamIndex] ??= {};
    (args[configParamIndex] as AxiosRequestConfig).signal = source.signal;

    return apiCall(...args)
      .then((response) => {
        setState({ loading: false, data: response.data, response });
      })
      .catch((error) => {
        if (!Axios.isCancel(error)) {
          setState({ loading: false, error });
        }
      });
  }

  useEffect(() => {
    recall();
    return () => source.abort();
  }, []);

  return [state.data, state, recall] as const;
}

/** @deprecated remover no generated */
export function useMutation<D, A extends unknown[]>(apiCall: ApiCall<D, A>) {
  const [state, setState] = useState<State<D>>({ loading: true });

  const configParamIndex = apiCall.length - 1;
  const source = new AbortController();
  useEffect(() => () => source.abort(), []);

  return [
    state,
    (...args: A) => {
      args[configParamIndex] ??= {};
      (args[configParamIndex] as AxiosRequestConfig).signal = source.signal;

      return apiCall(...args)
        .then((response) => {
          setState({ loading: false, data: response.data, response });
        })
        .catch((error) => {
          if (!Axios.isCancel(error)) {
            setState({ loading: false, error });
          }
        });
    }
  ] as const;
}
