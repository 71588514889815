import { EnumsReadType } from '@query';
import styled from 'styled-components';

type TagProps = {
  color: EnumsReadType;
};

type NightlyReadItemProps = {
  color: 'green' | 'red';
};

const PanelInformation = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.bg80};
  border-bottom: ${(props) => props.theme.border.border80};

  display: grid;
  grid-template-areas: '. . . . .';

  grid-template-columns: auto auto auto 350px;
  grid-template-rows: auto;
  overflow-x: auto;
`;

const Content = styled.div`
  min-width: 350px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-right: 1px solid ${(props) => props.theme.colors.hover};
  flex: 1;
  position: relative;
`;

const BoxItem = styled.div`
  background: ${(props) => props.theme.colors.primary};
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 500;
`;

const BoxItemTitle = styled.div`
  color: ${(props) => props.theme.colors.white}cc;
  margin-bottom: 4px;
  font-size: 14px;
  width: max-content;
`;

const BoxItemDescription = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-size: 18px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.text100};
  font-size: 18px;
  font-weight: 500;
`;

const TagItem = styled.div<TagProps>`
  width: fit-content;
  padding: 8px 16px;
  border-radius: 49px;
  background: ${({ color, theme }) =>
    color === EnumsReadType.DRY
      ? theme.colors.orange
      : color === EnumsReadType.INVENTORY
      ? theme.colors.red
      : theme.colors.green};
  font-size: 18px;
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  font-weight: 500;
`;

const TagItemContainer = styled.div`
  width: 100%;
  height: 33px;
`;

const ManagementConfirmed = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const NightlyReadContainer = styled.div`
  width: 100%;
  min-height: 25px;
  display: flex;
  gap: 4px;
`;

const NightlyReadItem = styled.div<NightlyReadItemProps>`
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  background: ${({ color, theme }) => (color === 'green' ? theme.colors.green : theme.colors.red)};
  color: ${({ theme }) => theme.colors.white};
`;

const Graph = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`;

const OccurrencesContent = styled.div`
  padding: 16px;
`;

export const Description = styled.div`
  color: ${(props) => props.theme.colors.red};
  font-size: 14px;
  font-weight: 500;
`;

export {
  BoxItem,
  BoxItemDescription,
  BoxItemTitle,
  Content,
  Graph,
  ManagementConfirmed,
  NightlyReadContainer,
  NightlyReadItem,
  OccurrencesContent,
  PanelInformation,
  TagItem,
  TagItemContainer,
  Title
};
