//@ts-nocheck
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { request } from './api/axios';
export type TroughManagementGraphViewParams = {
  since?: string;
};

export type ViewTreatParams = {
  batchIds?: number[];
};

export type TransferViewParams = {
  corralDestinyId: number;
  corralOriginId: number;
};

export type SideBarViewSpan = (typeof SideBarViewSpan)[keyof typeof SideBarViewSpan];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SideBarViewSpan = {
  NUMBER_7: '7',
  NUMBER_15: '15',
  NUMBER_30: '30',
  all: 'all'
} as const;

export type SideBarViewParams = {
  batchId: number;
  span: SideBarViewSpan;
};

export type SideBarFactoryViewSpan = (typeof SideBarFactoryViewSpan)[keyof typeof SideBarFactoryViewSpan];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SideBarFactoryViewSpan = {
  NUMBER_7: '7',
  NUMBER_15: '15',
  NUMBER_30: '30',
  all: 'all'
} as const;

export type SideBarFactoryViewParams = {
  factoryId: number;
  span: SideBarFactoryViewSpan;
};

export type RecentTreatsViewParams = {
  limit?: number;
  page?: number;
};

export type RecentBeatsViewParams = {
  limit?: number;
  page?: number;
};

export type FindOxParams = {
  electronic?: string;
  identifier?: string;
  oxId?: number;
  sisbov?: number;
};

export type ExitOxenViewParams = {
  date: string;
};

export type DietsViewParams = {
  category?: EnumsDietCategory;
  limit?: number;
  page?: number;
};

export type BatchDetailsParams = {
  batchId: number;
};

export type SubmitCertifierBody = {
  certifier: Blob;
};

export type SubmitBndBody = {
  bnd: Blob;
};

export type RestoreFeedDataBody = {
  backup: Blob;
};

export type RestoreFeedDataParams = {
  tk1: string;
};

export type ExportFeedDataParams = {
  title?: string;
  tk1: string;
};

export type CreateTreatmentBody = {
  divisions: number[];
  name: string;
};

export type ListTreatmentsParams = {
  limit?: number;
  page?: number;
};

export type ListStructuresParams = {
  limit?: number;
  page?: number;
};

export type RemoveStockParams = {
  amount: number;
  date: string;
};

export type ReceivePremixBody = {
  amount: number;
};

export type ReceiveStockParams = {
  amount: number;
  date: string;
  kiloPrice?: number;
  structureIds?: number[];
};

export type ReceiveStockPurchaseParams = {
  date: string;
  purchaseId: number;
  structureIds?: number[];
};

export type GetStockAverageInfoBody = {
  ingredients: Ingredient[];
};

export type ListStocksNaturalMatter = (typeof ListStocksNaturalMatter)[keyof typeof ListStocksNaturalMatter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListStocksNaturalMatter = {
  empty: 'empty',
  not_empty: 'not empty'
} as const;

export type ListStocksParams = {
  isPremix?: boolean;
  naturalMatter?: ListStocksNaturalMatter;
};

export type CloseSaleManagementBody = {
  carcassExcessive?: number;
  carcassMedium?: number;
  carcassMissing?: number;
  carcassPrice: number;
  carcassThin?: number;
  carcassType?: EnumsCarcassType;
  carcassWeight: number;
  invoice?: string;
};

export type ListSaleManagementsParams = {
  closed?: boolean;
  ownerId?: number;
};

export type GetIndividualCattleClosingReportXlsxParams = {
  batchId?: number;
  end?: string;
  start?: string;
  tk1: string;
};

export type GetHistoryReportXlsxParams = {
  batchId: number;
  end?: string;
  start: string;
  tk1: string;
};

export type GetFoodHistoryReportXlsxParams = {
  end?: string;
  start: string;
  stockId: number;
  tk1: string;
};

export type GetFinancialReportXlsxParams = {
  end?: string;
  start: string;
  tk1: string;
};

export type GetEfficiencyReportXlsxParams = {
  end?: string;
  start: string;
  tk1: string;
};

export type GetEconomicEvaluationReportXlsxParams = {
  end?: string;
  start: string;
  tk1: string;
};

export type GetAnalyticalBatchesReportXlsxParams = {
  batchId: number;
  tk1: string;
};

export type IndividualCattleClosingReportParams = {
  batchId?: number;
  end?: string;
  start?: string;
};

export type BatchHistoryReportParams = {
  batchId: number;
  end?: string;
  start: string;
};

export type GetFoodStockHistoryParams = {
  end?: string;
  start: string;
  stockId: number;
};

export type TreatEfficiencyParams = {
  end?: string;
  start: string;
};

export type BatchEconomicEvaluationParams = {
  end?: string;
  start: string;
};

export type AnalyticalBatchReportParams = {
  batchId: number;
};

export type DeletePurchaseCategory = (typeof DeletePurchaseCategory)[keyof typeof DeletePurchaseCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeletePurchaseCategory = {
  FOODS: 'FOODS',
  ANIMALS: 'ANIMALS',
  EARRINGS: 'EARRINGS'
} as const;

export type DeletePurchaseParams = {
  category: DeletePurchaseCategory;
};

export type PurchaseNameExistsParams = {
  name: string;
};

export type SisbovExistsParams = {
  sisbov: number;
};

export type ListPurchasesParams = {
  category?: EnumsPurchaseCategory;
  limit?: number;
  orderByStatus?: EnumsPurchaseStatus;
  page?: number;
  status?: EnumsPurchaseStatus;
};

export type MoveOxenBody = {
  corralOriginId?: number;
  corralTargetId: number;
  oxenIds?: number[];
};

export type OxIdentificationParams = {
  batchId: number;
};

export type CheckOxIdentificationParams = {
  /** Dados do brinco eletrônico */
  electronic?: string;
  sisbov?: number;
};

export type ListOxenHealthParams = {
  limit?: number;
  page?: number;
};

export type OxClosureByDateBody = {
  carcassExcessive?: number;
  carcassMedium?: number;
  carcassMissing?: number;
  carcassPrice: number;
  carcassThin?: number;
  carcassType?: EnumsCarcassType;
  carcassWeight: number;
  date: string;
  invoice?: string;
};

export type OxClosureBody = {
  oxClosure: Blob;
};

export type OxClosureParams = {
  carcassPrice: number;
  invoice?: string;
};

export type ListAvailableOxenParams = {
  batchId?: number;
  limit?: number;
  page?: number;
};

export type ListOccurrencesParams = {
  limit?: number;
  page?: number;
};

export type GetMedicinesParams = {
  limit?: number;
  page?: number;
};

export type GetHealthProtocolParams = {
  name?: string;
};

export type ListHealthProtocolsParams = {
  limit?: number;
  page?: number;
};

export type ListGtasParams = {
  completed?: boolean;
  limit?: number;
  page?: number;
  withoutPurchases?: boolean;
};

export type LoadFinancialReportParams = {
  end: string;
  start: string;
};

export type ListExitTrucksParams = {
  date?: string;
  destinyId?: number;
  sale?: boolean;
  status?: EnumsExitTruckStatus;
};

export type ListEstablishmentsParams = {
  destiny?: boolean;
  limit?: number;
  origin?: boolean;
  page?: number;
};

export type RegisterEarringsParams = {
  purchaseId: number;
};

export type SucceedDietParams = {
  newDietId: number;
};

export type ChangeBatchesDietBody = {
  batchesId: number[];
  newDietId: number;
};

export type ListDietsParams = {
  category?: EnumsDietCategory;
  limit?: number;
  page?: number;
};

export type ListDietTransitionsParams = {
  divisions?: number;
  limit?: number;
  page?: number;
};

export type MoveBatchParams = {
  targetId: number;
};

export type ListCorralParams = {
  /** Não tem lotes no momento */
  empty?: boolean;
  type?: EnumsCorralType;
};

export type TransitionBatchDietParams = {
  dietId: number;
  transitionId: number;
};

export type ConfirmTreatBody = {
  feederIds: number[];
  order: number;
  totalKgNm: number;
};

export type ActivateBatchParams = {
  dietId: number;
  liveWeight: number;
  treatmentId: number;
};

export type EnsureBatchStatusesBodyCheckType =
  (typeof EnsureBatchStatusesBodyCheckType)[keyof typeof EnsureBatchStatusesBodyCheckType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnsureBatchStatusesBodyCheckType = {
  none_managed: 'none managed',
  all_managed: 'all managed',
  none_managed_or_all_managed: 'none managed or all managed'
} as const;

export type EnsureBatchStatusesBody = {
  batchIds: number[];
  checkType: EnsureBatchStatusesBodyCheckType;
};

export type BatchesOxenParams = {
  corralId: number;
};

export type ListFinalizedBatchesParams = {
  limit?: number;
  page?: number;
};

export type ListBatchesParams = {
  limit?: number;
  page?: number;
};

export type RefreshTokenBody = {
  oldRefresh: string;
};

export type EmmitEntryAnnouncementBody = {
  gtas: number[];
};

export type GenerateUnusabilityBody = {
  date: string;
};

export type GenerateReIdentificationAnnouncementBody = {
  date: string;
};

export type EmitEntryParams = {
  announcementId: number;
};

export type GenerateChangeOfCharacteristicsBody = {
  changeOfCharacteristicDate: string;
};

export type GetExitAnnouncementByDateParams = {
  date: string;
};

export type CheckGtasBody = {
  gtasId: number[];
};

export type CreateEntryAnnouncementsBody = {
  gtasId: number[];
  purchaseId: number;
};

export type ListAnnouncementsParams = {
  emitted?: boolean;
  limit?: number;
  page?: number;
  type?: EnumsAnnouncementType;
};

export type ListAddressesParams = {
  limit?: number;
  page?: number;
};

/** Um array de todos os sequenciais de que foram usados nesta distribuição de trato */
export interface GlobalPrismaJsonTracerRealizedSequential {
  amountMn: number;
  amountMs: number;
  seq: number;
  treatIndex: number;
}

export interface GlobalPrismaJsonNightlyRead {
  hadFood: boolean;
  hour: string;
}

export interface GlobalPrismaJsonLatLng {
  lat: number;
  lng: number;
}

/** Documentos de uma inscrição estadual de um estabelecimento / fazenda. */
export interface GlobalPrismaJsonGovDocuments {
  /** Número do ERAS */
  ERAS?: string;
  /** Número do registro no Instituto Nacional de Colonização e Reforma Agrária */
  INCRA?: string;
  /** Inscrição Estadual */
  IS?: string;
  /** Número do Imóvel na Receita Federal */
  NIRF?: string;
  [key: string]: string | unknown;
}

/** Um array de boolean para como true -> novo trato e false -> trato antigo (length = quantia de dias). */
export type GlobalPrismaJsonDietTransitionDay = boolean[];

export type GlobalPrismaJsonAnnouncementData =
  | DeathAnnouncement[]
  | EntryAnnouncement
  | SaleAnnouncement
  | ExitAnnouncement[]
  | ReIdentificationAnnouncement[]
  | ChangeOfCharacteristicAnnouncement[]
  | UnusabilityAnnouncement[];

export type GlobalBufferBuffer = {
  byteLength: number;
};

export interface GlobalBuffer {
  buffer: GlobalBufferBuffer;
  byteLength: number;
  byteOffset: number;
  BYTES_PER_ELEMENT: number;
  length: number;
  [key: string]: number;
}

/** Resumo */
export type ZootechnicalReturnResume = {
  /** Ganho de Carcaça (kg/d) */
  carcassGain: number;
  /** Ganho carc */
  carcassGainPerOx: number;
  /** Peso de Carcaça (kg) */
  carcassWeight: number;
  /** Rendimento de Carcaça (%) */
  carcassYield: number;
  /** Dias de Confinamento */
  confinamentDays: number;
  /** Consumo (kgMS / %PV */
  dryMatterConsumeLiveWeight: number;
  /** Consumo MS (kg/cab/d) */
  dryMatterConsumePerOx: number;
  /** Peso Vivo de Entrada (kg) */
  entryLiveWeight: number;
  /** Peso Vivo de Entrada (@) */
  entryLiveWeightAtSign: number;
  /** Peso Vivo de Saída (kg) */
  exitLiveWeight: number;
  /** Peso Vivo de Saída (@) */
  exitLiveWeightAtSign: number;
  /** Conv. Alim (kgMS / kgPV) */
  foodConversion: number;
  /** Efic. Alim (kgMS/@+) */
  foodEfficiency: number;
  /** Índice de Retorno a Pasto (%) */
  indicePastureReturns: number;
  /** Ganho de Peso Vivo (kg/d) */
  liveWeightGain: number;
  /** Mortalidade (%) */
  mortality: number;
  /** Consumo MO (kg/cab/d) */
  naturalMatterConsume: number;
  /** Entrada de Animais (total) */
  oxenEntries: number;
  /** Entrada de Animais (indireta) */
  oxenIndirectEntries: number;
  /** Total de Mortes */
  totalDeaths: number;
  /** Total de Animais de Saída */
  totalOxenExit: number;
  /** Total de Retornos a Pasto */
  totalPastureReturns: number;
  /** Dias de Trato */
  tractDays: number;
};

/** Saida */
export type ZootechnicalReturnHeadersItemExitDate = string | null;

export type ZootechnicalReturnHeadersItem = {
  /** Lote */
  batch: string;
  carcassGainPerOx: number;
  /** Peso Carc (kg/cab) */
  carcassWeight: number;
  /** Rend carcaça real % */
  carcassYield: number;
  /** Categoria */
  category: EnumsOxGender[];
  /** Dias confinado */
  confinamentDays: number;
  /** Curral */
  corral?: string;
  /** CV peso de entrada */
  cov?: number;
  /** GCD */
  dailyCarcassGain: number;
  /** GPD */
  dailyWeightGain: number;
  /** Mortes (cab) */
  deaths: number;
  /** Mortes % */
  deathsPercentage: number;
  /** Cons MS kg/%/PV */
  dryMatterConsumeLiveWeight: number;
  /** Cons MS kg/cab/d */
  dryMatterConsumePerOx: number;
  /** PV entrada kg/cab */
  entryLiveWeight: number;
  /** Peso Entrada (@/cab) */
  entryLiveWeightAtSign: number;
  /** Tipo de entrada */
  entryType?: EnumsOxPurpose;
  /** Saida */
  exitDate: ZootechnicalReturnHeadersItemExitDate;
  /** PV saida kg/cab */
  exitLiveWeight: number;
  /** Peso Saída (@/cab) */
  exitLiveWeightAtSign: number;
  /** Peso Saída (kg/cab) */
  exitLiveWeightKile: number;
  /** Conv. Alim kgMS/kgPV */
  foodConversion: number;
  /** Efic. Alim kgMS/@ */
  foodEfficiency: number;
  /** Ganho de Carcaça (kg/d) */
  gain: number;
  /** Cons MO kg/cab/d */
  naturalMatterConsume: number;
  /** Proprietário */
  owner: string[];
  /** Qt Anm */
  oxenAmount: number;
  /** Ret pasto (cab) */
  pastureReturns: number;
  /** Ret pasto % */
  pastureReturnsPercentage: number;
  /** Total diárias */
  totalDailies: number;
  /** Dias trato */
  tractDays: number;
  /** Raça */
  variety: string[];
};

export interface ZootechnicalReturn {
  headers: ZootechnicalReturnHeadersItem[];
  /** Resumo */
  resume: ZootechnicalReturnResume;
}

export interface WeighingOx {
  electronic?: string;
  extraMedicineIds?: number[];
  healthProtocolId?: number;
  identifier?: string;
  oxId?: number;
  sisbov?: number;
  weight: number;
}

export type WaitForEmissionResponseSaleId = null | number;

export type WaitForEmissionResponsePlate = null | string;

export type WaitForEmissionResponseName = null | string;

export type WaitForEmissionResponseExitGta = null | string;

export type WaitForEmissionResponseDestinyId = null | number;

export type WaitForEmissionResponseAnnouncementId = null | number;

export interface WaitForEmissionResponse {
  announcementId: WaitForEmissionResponseAnnouncementId;
  capacity: number;
  destinyId: WaitForEmissionResponseDestinyId;
  exitAt: string;
  exitGta: WaitForEmissionResponseExitGta;
  farmId: number;
  id: number;
  name: WaitForEmissionResponseName;
  plate: WaitForEmissionResponsePlate;
  reason: EnumsExitReason;
  saleId: WaitForEmissionResponseSaleId;
  status: EnumsExitTruckStatus;
}

export type ViewTreatResponse = ViewTreatResponseItem[];

export type ViewTreatResponseItemBatchesItemTractsItem = {
  adjustedForeseen: boolean;
  foreseen: number;
  order: number;
  originalForeseen: number;
  realized?: number;
};

export type ViewTreatResponseItemBatchesItem = {
  corralName: string;
  corralPosition: number;
  foreseen: number;
  foreseenDeviation: number;
  id: number;
  name: string;
  realized: number;
  tracts: ViewTreatResponseItemBatchesItemTractsItem[];
};

export type ViewTreatResponseItem = {
  batches: ViewTreatResponseItemBatchesItem[];
  highestTractDistribution: number;
  id: number;
  name: string;
};

export interface UpdateTruck {
  capacity?: number;
  destinyId?: number;
  exitGta?: string;
  name?: string;
  plate?: string;
}

export interface UpdateStructureResponse {
  category: EnumsStructureCategory;
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
}

export interface UpdateStructure {
  category?: EnumsStructureCategory;
  name?: string;
}

export type UpdateSaleManagementResponseAnnouncementId = null | number;

export interface UpdateSaleManagementResponse {
  announcementId: UpdateSaleManagementResponseAnnouncementId;
  closed: boolean;
  date: string;
  farmId: number;
  id: number;
  ownerId: number;
}

export interface UpdateSaleManagement {
  ownerId?: number;
  trucksIds?: number[];
}

export type UpdatePersonResponsePhone = null | string;

export type UpdatePersonResponseDocument = null | string;

export type UpdatePersonResponseDescription = null | string;

export interface UpdatePersonResponse {
  createdAt: string;
  description: UpdatePersonResponseDescription;
  document: UpdatePersonResponseDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: UpdatePersonResponsePhone;
  profiles: EnumsProfile[];
}

export type UpdatePersonUserPassword = string | null;

export type UpdatePersonUserEmail = string | null;

export type UpdatePersonUser = {
  email: UpdatePersonUserEmail;
  gov?: GlobalPrismaJsonGovDocuments;
  password: UpdatePersonUserPassword;
};

export interface UpdatePerson {
  description?: string;
  document?: string;
  imageUrl?: string;
  name?: string;
  phone?: string;
  profiles?: EnumsProfile[];
  user?: UpdatePersonUser;
}

export interface UpdateOxenPurchase {
  buyerId?: number;
  commission?: number;
  distance?: number;
  gtaIds?: number[];
  invoice?: string;
  issuedAt?: string;
  name?: string;
  observation?: string;
  ownerId?: number;
  price?: number;
  purpose?: EnumsOxPurpose;
  series?: string;
  shipping?: number;
  traceable?: boolean;
  weightAverage?: number;
}

export type UpdateMedicineResponseKiloDose = null | number;

export interface UpdateMedicineResponse {
  blockingDays: number;
  createdAt: string;
  dosePrice: number;
  farmId: number;
  id: number;
  kiloDose: UpdateMedicineResponseKiloDose;
  manufacturer: string;
  measurement: EnumsMeasurement;
  name: string;
  type: EnumsMedicineType;
}

export interface UpdateMedicine {
  blockingDays?: number;
  dosePrice?: number;
  kiloDose?: number;
  manufacturer?: string;
  measurement?: EnumsMeasurement;
  name?: string;
  type?: EnumsMedicineType;
}

export type UpdateGtaResponsePurchaseId = null | number;

export type UpdateGtaResponseDescription = null | string;

export interface UpdateGtaResponse {
  completed: boolean;
  description: UpdateGtaResponseDescription;
  emission: string;
  entry: string;
  expiration: string;
  farmId: number;
  id: number;
  number: string;
  originId: number;
  purchaseId: UpdateGtaResponsePurchaseId;
  series: string;
}

export type UpdateGtaOxenAgesItem = {
  femaleTotal: number;
  maleTotal: number;
  yearsOld: number;
};

export interface UpdateGta {
  description?: string;
  emission?: string;
  entry?: string;
  expiration?: string;
  number?: string;
  originId?: number;
  oxenAges?: UpdateGtaOxenAgesItem[];
  series?: string;
}

export type UpdateFoodPurchaseResponseReceivedAt = string | null;

export type UpdateFoodPurchaseResponseObservation = null | string;

export type UpdateFoodPurchaseResponseEarringsPerPage = null | number;

export interface UpdateFoodPurchaseResponse {
  category: EnumsPurchaseCategory;
  earringsPerPage: UpdateFoodPurchaseResponseEarringsPerPage;
  farmId: number;
  id: number;
  invoice: string;
  issuedAt: string;
  name: string;
  observation: UpdateFoodPurchaseResponseObservation;
  price: number;
  receivedAt: UpdateFoodPurchaseResponseReceivedAt;
  series: string;
  status: EnumsPurchaseStatus;
}

export interface UpdateFoodPurchase {
  amount?: number;
  invoice?: string;
  issuedAt?: string;
  name?: string;
  observation?: string;
  price?: number;
  series?: string;
  stockId?: number;
}

export type UpdateFarmResponseMatinalReadTime = string | null;

export type UpdateFarmResponseLastCertifierImport = string | null;

export type UpdateFarmResponseLastBndImport = string | null;

export type UpdateFarmResponseAlias = null | string;

export interface UpdateFarmResponse {
  addressId: number;
  alias: UpdateFarmResponseAlias;
  blockedDays: number;
  createdAt: string;
  dailyCost: number;
  day: string;
  divisionThreshold: number;
  factory: GlobalPrismaJsonLatLng[];
  gradeInfo: unknown;
  gradeMethod: EnumsGradeMethod;
  id: number;
  lastBndImport: UpdateFarmResponseLastBndImport;
  lastCertifierImport: UpdateFarmResponseLastCertifierImport;
  managementConfig: unknown;
  matinalReadTime: UpdateFarmResponseMatinalReadTime;
  name: string;
  nightlyReadTimes: string[];
  position: GlobalPrismaJsonLatLng;
}

export type UpdateFarmConfigResponseMatinalReadTime = string | null;

export type UpdateFarmConfigResponseLastCertifierImport = string | null;

export type UpdateFarmConfigResponseLastBndImport = string | null;

export type UpdateFarmConfigResponseAlias = null | string;

export interface UpdateFarmConfigResponse {
  addressId: number;
  alias: UpdateFarmConfigResponseAlias;
  blockedDays: number;
  createdAt: string;
  dailyCost: number;
  day: string;
  divisionThreshold: number;
  factory: GlobalPrismaJsonLatLng[];
  gradeInfo: unknown;
  gradeMethod: EnumsGradeMethod;
  id: number;
  lastBndImport: UpdateFarmConfigResponseLastBndImport;
  lastCertifierImport: UpdateFarmConfigResponseLastCertifierImport;
  managementConfig: unknown;
  matinalReadTime: UpdateFarmConfigResponseMatinalReadTime;
  name: string;
  nightlyReadTimes: string[];
  position: GlobalPrismaJsonLatLng;
}

export interface UpdateFarmConfig {
  blockedUntil?: number;
  dailyCost?: number;
  factory?: GlobalPrismaJsonLatLng[];
  gradeInfo?: unknown;
  gradeMethod?: EnumsGradeMethod;
  managementConfig?: unknown;
  matinalReadTime?: string;
  nightlyReadTimes?: string[];
  position?: GlobalPrismaJsonLatLng;
}

export interface UpdateFarm {
  alias?: string;
  name?: string;
  position?: GlobalPrismaJsonLatLng;
}

export type UpdateExitTruckResponseSaleId = null | number;

export type UpdateExitTruckResponsePlate = null | string;

export type UpdateExitTruckResponseName = null | string;

export type UpdateExitTruckResponseExitGta = null | string;

export type UpdateExitTruckResponseDestinyId = null | number;

export type UpdateExitTruckResponseAnnouncementId = null | number;

export interface UpdateExitTruckResponse {
  announcementId: UpdateExitTruckResponseAnnouncementId;
  capacity: number;
  destinyId: UpdateExitTruckResponseDestinyId;
  exitAt: string;
  exitGta: UpdateExitTruckResponseExitGta;
  farmId: number;
  id: number;
  name: UpdateExitTruckResponseName;
  plate: UpdateExitTruckResponsePlate;
  reason: EnumsExitReason;
  saleId: UpdateExitTruckResponseSaleId;
  status: EnumsExitTruckStatus;
}

export type UpdateEstablishmentResponseCode = null | string;

export interface UpdateEstablishmentResponse {
  addressId: number;
  code: UpdateEstablishmentResponseCode;
  destiny: boolean;
  farmId: number;
  gov: GlobalPrismaJsonGovDocuments;
  id: number;
  name: string;
  origin: boolean;
  responsibleId: number;
}

export type UpdateEstablishmentAddress = {
  city?: string;
  complement?: string;
  country?: string;
  neighborhood?: string;
  number?: string;
  state?: string;
  street?: string;
  zip?: string;
};

export interface UpdateEstablishment {
  address: UpdateEstablishmentAddress;
  /** Se este estabelecimento pode ser usado como destino */
  destiny?: boolean;
  /** [GovDocuments] Documentos deste estabelecimento */
  gov?: GlobalPrismaJsonGovDocuments;
  /** Nome do estabelecimento */
  name?: string;
  /** Se este estabelecimento pode ser usado como origem */
  origin?: boolean;
  /** (EXTERN) Usuário responsável por este estabelecimento. */
  responsible?: number;
}

export type UpdateEarringPurchaseResponseReceivedAt = string | null;

export type UpdateEarringPurchaseResponseObservation = null | string;

export type UpdateEarringPurchaseResponseEarringsPerPage = null | number;

export interface UpdateEarringPurchaseResponse {
  category: EnumsPurchaseCategory;
  earringsPerPage: UpdateEarringPurchaseResponseEarringsPerPage;
  farmId: number;
  id: number;
  invoice: string;
  issuedAt: string;
  name: string;
  observation: UpdateEarringPurchaseResponseObservation;
  price: number;
  receivedAt: UpdateEarringPurchaseResponseReceivedAt;
  series: string;
  status: EnumsPurchaseStatus;
}

export interface UpdateEarringPurchase {
  brand?: string;
  earringsPerPage?: number;
  finalSisbov?: number;
  initialSisbov?: number;
  invoice?: string;
  issuedAt?: string;
  name?: string;
  observation?: string;
  ownerId?: number;
  price?: number;
  series?: string;
}

export interface UpdateCorralResponse {
  area: number;
  capacity: number;
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
  polygon: GlobalPrismaJsonLatLng[];
  position: number;
  type: EnumsCorralType;
}

export type UpdateCorralPositionsResponseItem = {
  area: number;
  capacity: number;
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
  polygon: GlobalPrismaJsonLatLng[];
  position: number;
  type: EnumsCorralType;
};

export type UpdateCorralPositionsResponse = UpdateCorralPositionsResponseItem[];

export interface UpdateCorral {
  area?: number;
  capacity?: number;
  name?: string;
  polygon?: GlobalPrismaJsonLatLng[];
  /** Position auto preenchido, caso não fornecido. */
  position?: number;
  type?: EnumsCorralType;
}

export type UpdateBatchResponseOtherCost = null | number;

export type UpdateBatchResponseOldCorralId = null | number;

export type UpdateBatchResponseForeseenWeight = null | number;

export type UpdateBatchResponseForeseenCarcass = null | number;

export type UpdateBatchResponseFinalizedAt = string | null;

export type UpdateBatchResponseExitPrice = null | number;

export type UpdateBatchResponseCorralId = null | number;

export type UpdateBatchResponseCarcass = null | number;

export type UpdateBatchResponseAdmCost = null | number;

export type UpdateBatchResponseActivatedAt = string | null;

export interface UpdateBatchResponse {
  activatedAt: UpdateBatchResponseActivatedAt;
  admCost: UpdateBatchResponseAdmCost;
  carcass: UpdateBatchResponseCarcass;
  corralId: UpdateBatchResponseCorralId;
  createdAt: string;
  exitPrice: UpdateBatchResponseExitPrice;
  farmId: number;
  finalizedAt: UpdateBatchResponseFinalizedAt;
  foreseenCarcass: UpdateBatchResponseForeseenCarcass;
  foreseenWeight: UpdateBatchResponseForeseenWeight;
  id: number;
  liveWeight: number;
  name: string;
  oldCorralId: UpdateBatchResponseOldCorralId;
  otherCost: UpdateBatchResponseOtherCost;
}

export interface UpdateBatch {
  admCost?: number;
  carcass?: number;
  exitPrice?: number;
  foreseenCarcass?: number;
  foreseenWeight?: number;
  name?: string;
  otherCost?: number;
}

export type UpdateAnnouncementResponseEmittedAt = string | null;

export interface UpdateAnnouncementResponse {
  data: GlobalPrismaJsonAnnouncementData;
  emittedAt: UpdateAnnouncementResponseEmittedAt;
  farmId: number;
  id: number;
  type: EnumsAnnouncementType;
}

export interface UpdateAnnouncement {
  data: GlobalPrismaJsonAnnouncementData;
  type: EnumsAnnouncementType;
}

export type UpdateAnimalPurchaseResponseReceivedAt = string | null;

export type UpdateAnimalPurchaseResponseObservation = null | string;

export type UpdateAnimalPurchaseResponseEarringsPerPage = null | number;

export interface UpdateAnimalPurchaseResponse {
  category: EnumsPurchaseCategory;
  earringsPerPage: UpdateAnimalPurchaseResponseEarringsPerPage;
  farmId: number;
  id: number;
  invoice: string;
  issuedAt: string;
  name: string;
  observation: UpdateAnimalPurchaseResponseObservation;
  price: number;
  receivedAt: UpdateAnimalPurchaseResponseReceivedAt;
  series: string;
  status: EnumsPurchaseStatus;
}

export type UpdateAddressResponseStreet = null | string;

export type UpdateAddressResponseNumber = null | string;

export type UpdateAddressResponseNeighborhood = null | string;

export type UpdateAddressResponseComplement = null | string;

export interface UpdateAddressResponse {
  city: string;
  complement: UpdateAddressResponseComplement;
  country: string;
  id: number;
  neighborhood: UpdateAddressResponseNeighborhood;
  number: UpdateAddressResponseNumber;
  state: string;
  street: UpdateAddressResponseStreet;
  zip: string;
}

export interface UpdateAddress {
  city?: string;
  complement?: string;
  country?: string;
  neighborhood?: string;
  number?: string;
  state?: string;
  street?: string;
  zip?: string;
}

export interface UnusabilityAnnouncement {
  brand: string;
  observation?: string;
  sisbov: number;
}

export type TroughManagementViewResponse = TroughManagementViewResponseItem[];

export type TroughManagementViewResponseItemTreatment = {
  divisions: number[];
  name: string;
};

export type TroughManagementViewResponseItemManagedAt = string | null;

export type TroughManagementViewResponseItemEntry = string | null;

export type TroughManagementViewResponseItem = {
  batchId: number;
  batchName: string;
  breeds: string[];
  corralName: string;
  currentWeight: number;
  d_0: TroughManagementViewResponseItemD0;
  d_1: TroughManagementViewResponseItemD1;
  dietExpiration: number;
  dietId: number;
  dietName: string;
  dietTransition?: string;
  entry: TroughManagementViewResponseItemEntry;
  entryWeight: number;
  genders: EnumsOxGender[];
  managedAt: TroughManagementViewResponseItemManagedAt;
  naturalMatterFactor?: number;
  oxenAmount: number;
  throughDays: number;
  treatment?: TroughManagementViewResponseItemTreatment;
  varieties: string[];
};

export type TroughManagementViewResponseItemD1ReadValue = null | number;

export type TroughManagementViewResponseItemD1ReadType = EnumsReadType | null;

export type TroughManagementViewResponseItemD1 = {
  deviation?: number;
  foreseen?: number;
  foreseenLiveWeight?: number;
  nightlyReads?: GlobalPrismaJsonNightlyRead[];
  readType?: TroughManagementViewResponseItemD1ReadType;
  readValue?: TroughManagementViewResponseItemD1ReadValue;
  realized?: number;
  realizedLiveWeight?: number;
};

export type TroughManagementViewResponseItemD0ReadValue = null | number;

export type TroughManagementViewResponseItemD0ReadType = EnumsReadType | null;

export type TroughManagementViewResponseItemD0 = {
  deviation?: number;
  foreseen: number;
  foreseenLiveWeight: number;
  nightlyReads: GlobalPrismaJsonNightlyRead[];
  readType: TroughManagementViewResponseItemD0ReadType;
  readValue: TroughManagementViewResponseItemD0ReadValue;
  realized: number;
  realizedLiveWeight: number;
};

export type TroughManagementGraphViewResponseItem = {
  batchId: number;
  graph: TroughManagementGraphViewResponseItemGraphItem[];
  occurrences: TroughManagementGraphViewResponseItemOccurrencesItem[];
};

export type TroughManagementGraphViewResponse = TroughManagementGraphViewResponseItem[];

export type TroughManagementGraphViewResponseItemOccurrencesItemOldFactor = null | number;

export type TroughManagementGraphViewResponseItemOccurrencesItemNewFactor = null | number;

export type TroughManagementGraphViewResponseItemOccurrencesItemName = null | string;

export type TroughManagementGraphViewResponseItemOccurrencesItemBatchesItem = {
  id: number;
};

export type TroughManagementGraphViewResponseItemOccurrencesItem = {
  batches: TroughManagementGraphViewResponseItemOccurrencesItemBatchesItem[];
  category: EnumsOccurrenceCategory;
  date: string;
  description: string;
  farmId: number;
  id: number;
  name: TroughManagementGraphViewResponseItemOccurrencesItemName;
  newFactor: TroughManagementGraphViewResponseItemOccurrencesItemNewFactor;
  oldFactor: TroughManagementGraphViewResponseItemOccurrencesItemOldFactor;
  type: EnumsOccurrenceType;
};

export type TroughManagementGraphViewResponseItemGraphItemReadType = EnumsReadType | null;

export type TroughManagementGraphViewResponseItemGraphItem = {
  day: string;
  deviation: number;
  foreseen: number;
  liveWeightConsume: number;
  nightlyReads: GlobalPrismaJsonNightlyRead[];
  oxenAmount: number;
  readType: TroughManagementGraphViewResponseItemGraphItemReadType;
  realized: number;
  throughDays: number;
};

export interface TreatEfficiencyResponse {
  average: TreatEfficiencyResponseAverage;
  history: TreatEfficiencyResponseHistoryItem[];
}

export type TreatEfficiencyResponseHistoryItemFeederItemPhone = null | string;

export type TreatEfficiencyResponseHistoryItemFeederItemDocument = null | string;

export type TreatEfficiencyResponseHistoryItemFeederItemDescription = null | string;

export type TreatEfficiencyResponseHistoryItemFeederItem = {
  createdAt: string;
  description: TreatEfficiencyResponseHistoryItemFeederItemDescription;
  document: TreatEfficiencyResponseHistoryItemFeederItemDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: TreatEfficiencyResponseHistoryItemFeederItemPhone;
  profiles: EnumsProfile[];
};

export type TreatEfficiencyResponseHistoryItem = {
  batch: string;
  corral?: string;
  day: string;
  feeder: TreatEfficiencyResponseHistoryItemFeederItem[];
  foreseenWeight: number;
  realWeight: number;
  weightDifference: number;
  weightDifferencePercentage: number;
};

export type TreatEfficiencyResponseAverage = {
  foreseenWeight: number;
  realWeight: number;
  weightDifference: number;
  weightDifferencePercentage: number;
};

export type TransitionBatchDietResponseFinalizedAt = string | null;

export interface TransitionBatchDietResponse {
  batchId: number;
  createdAt: string;
  finalizedAt: TransitionBatchDietResponseFinalizedAt;
  id: number;
  transitionId: number;
}

export type TransferViewResponseOrigin = {
  batch?: string;
  batchId?: number;
  breeds?: string[];
  corral: string;
  corralId: number;
  diet?: string;
  genders?: EnumsOxGender[];
  oxenAmount?: number;
  varieties?: string[];
};

export type TransferViewResponseDestiny = {
  batch?: string;
  batchId?: number;
  breeds?: string[];
  corral: string;
  corralId: number;
  diet?: string;
  genders?: EnumsOxGender[];
  oxenAmount?: number;
  varieties?: string[];
};

export interface TransferViewResponse {
  destiny: TransferViewResponseDestiny;
  origin: TransferViewResponseOrigin;
}

export interface SubmitTracerTrato {
  BAL_AT_COD: number;
  BAT_BAL_SEQ: number;
  DIETA_AT_COD: number;
  DISTR_AT_COD: number;
  FUNC_AT_NUM: number;
  PIQ_AT_POS: number;
  TRATO_CONC: boolean;
  TRATO_DATA: string;
  TRATO_DISTR_SEQ: number;
  TRATO_HORA: string;
  TRATO_HORAPREV: string;
  TRATO_PROPCONC: number;
  TRATO_QT: number;
  TRATO_QTCONC: number;
  TRATO_QTPREV: number;
  TRATO_QTPREVAJ: number;
}

export interface SubmitTracerBatAlim {
  ALIM_COD: string;
  BAL_AT_COD: number;
  BAT_BAL_SEQ: number;
  BAT_DATA: string;
  BATALIM_QTMOPREV: number;
  BATALIM_QTMOREAL: number;
  PALIM_COD: string;
}

export interface SubmitTracerBat {
  BAL_AT_COD: number;
  BAT_BAL_SEQ: number;
  BAT_DATA: string;
  BAT_HORA: string;
  BAT_MIXTEMPO: string;
  BAT_QTMO: number;
  BAT_QTMOPREV: number;
  DIETA_AT_COD: number;
  FUNC_AT_NUM: number;
  /** Batch history id */
  RCT_AT_COD: number;
}

export interface SubmitCertifierResponse {
  updated: number;
}

export type SubmitBndResponseResultItem = {
  birth: string;
  breed: string;
  difBirth: string;
  difBreed: EnumsOxGender;
  difGender: string;
  gender: EnumsOxGender;
  id: string;
  includedAt: string;
  sisbov: string;
};

export interface SubmitBndResponse {
  breedUpdated: number;
  genderUpdated: number;
  result: SubmitBndResponseResultItem[];
}

export interface StructureFilter {
  limit?: number;
  page?: number;
}

export type StructureCategory = EnumsStructureCategory;

export type StorageOxenPurchaseResponseReceivedAt = string | null;

export type StorageOxenPurchaseResponseObservation = null | string;

export type StorageOxenPurchaseResponseEarringsPerPage = null | number;

export interface StorageOxenPurchaseResponse {
  category: EnumsPurchaseCategory;
  earringsPerPage: StorageOxenPurchaseResponseEarringsPerPage;
  farmId: number;
  id: number;
  invoice: string;
  issuedAt: string;
  name: string;
  observation: StorageOxenPurchaseResponseObservation;
  price: number;
  receivedAt: StorageOxenPurchaseResponseReceivedAt;
  series: string;
  status: EnumsPurchaseStatus;
}

export type StockFilterNaturalMatter = (typeof StockFilterNaturalMatter)[keyof typeof StockFilterNaturalMatter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StockFilterNaturalMatter = {
  empty: 'empty',
  not_empty: 'not empty'
} as const;

export interface StockFilter {
  isPremix?: boolean;
  naturalMatter?: StockFilterNaturalMatter;
}

export interface StaticFood {
  Alimento: string;
  Codigo: string;
  /** Energia liquida de ganho */
  Elg: number;
  /** Energia liquida de manutenção */
  Elm: number;
  MS: number;
}

export type SideBarViewResponseOccurrencesItemOldFactor = null | number;

export type SideBarViewResponseOccurrencesItemNewFactor = null | number;

export type SideBarViewResponseOccurrencesItemName = null | string;

export type SideBarViewResponseOccurrencesItem = {
  category: EnumsOccurrenceCategory;
  date: string;
  description: string;
  farmId: number;
  id: number;
  name: SideBarViewResponseOccurrencesItemName;
  newFactor: SideBarViewResponseOccurrencesItemNewFactor;
  oldFactor: SideBarViewResponseOccurrencesItemOldFactor;
  type: EnumsOccurrenceType;
};

export type SideBarViewResponseManagementInfoReadValue = null | number;

export type SideBarViewResponseManagementInfoReadType = EnumsReadType | null;

export type SideBarViewResponseManagementInfo = {
  deviation?: number;
  foreseen?: number;
  foreseenLiveWeight: number;
  nightlyReads?: GlobalPrismaJsonNightlyRead[];
  readType?: SideBarViewResponseManagementInfoReadType;
  readValue?: SideBarViewResponseManagementInfoReadValue;
  realized: number;
  realizedLiveWeight: number;
};

export type SideBarViewResponseGraphItemReadType = EnumsReadType | null;

export type SideBarViewResponseGraphItem = {
  day: string;
  deviation: number;
  foreseen: number;
  liveWeightConsume: number;
  nightlyReads: GlobalPrismaJsonNightlyRead[];
  oxenAmount: number;
  readType: SideBarViewResponseGraphItemReadType;
  realized: number;
  throughDays: number;
};

export type SideBarViewResponseCorralInfoEntry = string | null;

export type SideBarViewResponseCorralInfo = {
  breeds: string[];
  category: EnumsOxGender[];
  corralName?: string;
  currentWeight?: number;
  dietName?: string;
  entry: SideBarViewResponseCorralInfoEntry;
  entryType: EnumsOxPurpose[];
  entryWeight: number;
  owner: string[];
  oxenAmount: number;
  throughDays: number;
  varieties: string[];
};

export interface SideBarViewResponse {
  corralInfo: SideBarViewResponseCorralInfo;
  graph: SideBarViewResponseGraphItem[];
  managementInfo: SideBarViewResponseManagementInfo;
  occurrences: SideBarViewResponseOccurrencesItem[];
}

export type SideBarFactoryViewResponseGraphItem = {
  dietId: number;
  dietName: string;
  foreseen: number;
};

export type SideBarFactoryViewResponseFoodsItem = {
  expiresIn: number;
  food: string;
  naturalMatter: number;
};

export type SideBarFactoryViewResponseFactory = {
  category: EnumsStructureCategory;
  createdAt: string;
  name: string;
};

export interface SideBarFactoryViewResponse {
  factory: SideBarFactoryViewResponseFactory;
  foods: SideBarFactoryViewResponseFoodsItem[];
  graph: SideBarFactoryViewResponseGraphItem[];
}

export type SendNightlyReadsResponse = SendNightlyReadsResponseItem[];

export type SendNightlyReadsResponseItemReadValue = null | number;

export type SendNightlyReadsResponseItemReadType = EnumsReadType | null;

export type SendNightlyReadsResponseItemManagedAt = string | null;

export type SendNightlyReadsResponseItem = {
  batchId: number;
  day: string;
  dietId: number;
  farmId: number;
  id: number;
  managedAt: SendNightlyReadsResponseItemManagedAt;
  matterVariation: number;
  nightlyReads: GlobalPrismaJsonNightlyRead[];
  oxenAmount: number;
  readType: SendNightlyReadsResponseItemReadType;
  readValue: SendNightlyReadsResponseItemReadValue;
  realizedTreatDistributions: number[];
  realizedTreatMatter: number;
  realizedTreatNaturalMatter: number;
  resultingMatter: number;
  tracerRealizedSequential: GlobalPrismaJsonTracerRealizedSequential[];
  treatmentId: number;
  weightAverage: number;
};

export type SendDailyReadResponseReadValue = null | number;

export type SendDailyReadResponseReadType = EnumsReadType | null;

export type SendDailyReadResponseManagedAt = string | null;

export interface SendDailyReadResponse {
  batchId: number;
  day: string;
  dietId: number;
  farmId: number;
  id: number;
  managedAt: SendDailyReadResponseManagedAt;
  matterVariation: number;
  nightlyReads: GlobalPrismaJsonNightlyRead[];
  oxenAmount: number;
  readType: SendDailyReadResponseReadType;
  readValue: SendDailyReadResponseReadValue;
  realizedTreatDistributions: number[];
  realizedTreatMatter: number;
  realizedTreatNaturalMatter: number;
  resultingMatter: number;
  tracerRealizedSequential: GlobalPrismaJsonTracerRealizedSequential[];
  treatmentId: number;
  weightAverage: number;
}

export interface SearchSaleManagement {
  closed?: boolean;
  ownerId?: number;
}

export interface SearchPurchase {
  category?: EnumsPurchaseCategory;
  limit?: number;
  orderByStatus?: EnumsPurchaseStatus;
  page?: number;
  status?: EnumsPurchaseStatus;
}

export interface SearchPeopleResponse {
  items: SearchPeopleResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type SearchPeopleResponseItemsItemUserAnyOfPassword = null | string;

export type SearchPeopleResponseItemsItemUserAnyOfGov = GlobalPrismaJsonGovDocuments | null;

export type SearchPeopleResponseItemsItemUserAnyOfEmail = null | string;

export type SearchPeopleResponseItemsItemUserAnyOf = {
  email: SearchPeopleResponseItemsItemUserAnyOfEmail;
  farmId: number;
  gov: SearchPeopleResponseItemsItemUserAnyOfGov;
  id: number;
  password: SearchPeopleResponseItemsItemUserAnyOfPassword;
  personId: number;
};

export type SearchPeopleResponseItemsItemUser = SearchPeopleResponseItemsItemUserAnyOf | null;

export type SearchPeopleResponseItemsItemPhone = null | string;

export type SearchPeopleResponseItemsItemDocument = null | string;

export type SearchPeopleResponseItemsItemDescription = null | string;

export type SearchPeopleResponseItemsItem = {
  createdAt: string;
  description: SearchPeopleResponseItemsItemDescription;
  document: SearchPeopleResponseItemsItemDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: SearchPeopleResponseItemsItemPhone;
  profiles: EnumsProfile[];
  user: SearchPeopleResponseItemsItemUser;
};

export interface SearchOx {
  electronic?: string;
  identifier?: string;
  oxId?: number;
  sisbov?: number;
}

export interface SearchExitTruck {
  date?: string;
  destinyId?: number;
  sale?: boolean;
  status?: EnumsExitTruckStatus;
}

export interface SearchEstablishment {
  destiny?: boolean;
  limit?: number;
  origin?: boolean;
  page?: number;
}

export interface SaleAnnouncement {
  oxenIds: number[];
  saleId: number;
}

export interface ReportInterval {
  end?: string;
  start: string;
}

export type ReplicateCurrentForeseenResponseReadValue = null | number;

export type ReplicateCurrentForeseenResponseReadType = EnumsReadType | null;

export type ReplicateCurrentForeseenResponseManagedAt = string | null;

export interface ReplicateCurrentForeseenResponse {
  batchId: number;
  day: string;
  dietId: number;
  farmId: number;
  id: number;
  managedAt: ReplicateCurrentForeseenResponseManagedAt;
  matterVariation: number;
  nightlyReads: GlobalPrismaJsonNightlyRead[];
  oxenAmount: number;
  readType: ReplicateCurrentForeseenResponseReadType;
  readValue: ReplicateCurrentForeseenResponseReadValue;
  realizedTreatDistributions: number[];
  realizedTreatMatter: number;
  realizedTreatNaturalMatter: number;
  resultingMatter: number;
  tracerRealizedSequential: GlobalPrismaJsonTracerRealizedSequential[];
  treatmentId: number;
  weightAverage: number;
}

export type RemoveStockResponsePremix = null | boolean;

export interface RemoveStockResponse {
  code: string;
  elg: number;
  elm: number;
  farmId: number;
  id: number;
  loadMode: EnumsLoadMode;
  name: string;
  premix: RemoveStockResponsePremix;
}

export interface RefreshTokenResponse {
  expiresAt: number;
  refresh: string;
  token: string;
  user: RefreshTokenResponseUser;
}

export type RefreshTokenResponseUserPersonPhone = null | string;

export type RefreshTokenResponseUserPersonDocument = null | string;

export type RefreshTokenResponseUserPersonDescription = null | string;

export type RefreshTokenResponseUserPerson = {
  createdAt: string;
  description: RefreshTokenResponseUserPersonDescription;
  document: RefreshTokenResponseUserPersonDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: RefreshTokenResponseUserPersonPhone;
  profiles: EnumsProfile[];
};

export type RefreshTokenResponseUserPassword = null | string;

export type RefreshTokenResponseUserGov = GlobalPrismaJsonGovDocuments | null;

export type RefreshTokenResponseUser = {
  email: RefreshTokenResponseUserEmail;
  farm: RefreshTokenResponseUserFarm;
  farmId: number;
  gov: RefreshTokenResponseUserGov;
  id: number;
  password: RefreshTokenResponseUserPassword;
  person: RefreshTokenResponseUserPerson;
  personId: number;
};

export type RefreshTokenResponseUserFarmMatinalReadTime = string | null;

export type RefreshTokenResponseUserFarmLastCertifierImport = string | null;

export type RefreshTokenResponseUserFarmLastBndImport = string | null;

export type RefreshTokenResponseUserFarmAlias = null | string;

export type RefreshTokenResponseUserFarm = {
  addressId: number;
  alias: RefreshTokenResponseUserFarmAlias;
  blockedDays: number;
  createdAt: string;
  dailyCost: number;
  day: string;
  divisionThreshold: number;
  factory: GlobalPrismaJsonLatLng[];
  gradeInfo: unknown;
  gradeMethod: EnumsGradeMethod;
  id: number;
  lastBndImport: RefreshTokenResponseUserFarmLastBndImport;
  lastCertifierImport: RefreshTokenResponseUserFarmLastCertifierImport;
  managementConfig: unknown;
  matinalReadTime: RefreshTokenResponseUserFarmMatinalReadTime;
  name: string;
  nightlyReadTimes: string[];
  position: GlobalPrismaJsonLatLng;
};

export type RefreshTokenResponseUserEmail = null | string;

export type RecentTreatsViewResponseItemsItem = {
  batch: string;
  corral?: string;
  day: string;
  foreseen: number;
  realized: number;
  realizedNm: number;
};

export interface RecentTreatsViewResponse {
  items: RecentTreatsViewResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type RecentBeatsViewResponseItemsItem = {
  day: string;
  dietId: number;
  dietName: string;
  foreseen: number;
  foreseenNm: number;
};

export interface RecentBeatsViewResponse {
  items: RecentBeatsViewResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type ReceiveStockResponsePremix = null | boolean;

export interface ReceiveStockResponse {
  code: string;
  elg: number;
  elm: number;
  farmId: number;
  id: number;
  loadMode: EnumsLoadMode;
  name: string;
  naturalMatter: number;
  premix: ReceiveStockResponsePremix;
}

export type ReceiveStockPurchaseResponsePremix = null | boolean;

export type ReceiveStockPurchaseResponseFlowsItem = {
  amount: number;
  date: string;
  id: number;
  stockId: number;
};

export interface ReceiveStockPurchaseResponse {
  code: string;
  elg: number;
  elm: number;
  farmId: number;
  flows: ReceiveStockPurchaseResponseFlowsItem[];
  id: number;
  loadMode: EnumsLoadMode;
  name: string;
  premix: ReceiveStockPurchaseResponsePremix;
}

export type ReceivePurchaseResponseReceivedAt = string | null;

export type ReceivePurchaseResponseObservation = null | string;

export type ReceivePurchaseResponseEarringsPerPage = null | number;

export interface ReceivePurchaseResponse {
  category: EnumsPurchaseCategory;
  earringsPerPage: ReceivePurchaseResponseEarringsPerPage;
  farmId: number;
  id: number;
  invoice: string;
  issuedAt: string;
  name: string;
  observation: ReceivePurchaseResponseObservation;
  price: number;
  receivedAt: ReceivePurchaseResponseReceivedAt;
  series: string;
  status: EnumsPurchaseStatus;
}

export interface ReceivePremixResponse {
  amount: number;
  date: string;
  id: number;
  stockId: number;
}

export type ReadType = EnumsReadType;

export interface ReadTracerInfoResponse {
  AT_CONFIG: ReadTracerInfoResponseATCONFIGItem[];
  AT_PREV_ALIM: ReadTracerInfoResponseATPREVALIMItem[];
  AT_PREV_BAL: ReadTracerInfoResponseATPREVBALItem[];
  AT_PREV_BAT: ReadTracerInfoResponseATPREVBATItem[];
  AT_PREV_BATALIM: ReadTracerInfoResponseATPREVBATALIMItem[];
  AT_PREV_DIETAPALIM: ReadTracerInfoResponseATPREVDIETAPALIMItem[];
  AT_PREV_FUNC: ReadTracerInfoResponseATPREVFUNCItem[];
  AT_PREV_RCT: ReadTracerInfoResponseATPREVRCTItem[];
  AT_PREV_RCTALIM: ReadTracerInfoResponseATPREVRCTALIMItem[];
  AT_PREV_TRATO: ReadTracerInfoResponseATPREVTRATOItem[];
}

export type ReadTracerInfoResponseATPREVTRATOItemTRATOQTPREVAJ = null | number;

export type ReadTracerInfoResponseATPREVTRATOItemTRATOQTPREV = null | number;

export type ReadTracerInfoResponseATPREVTRATOItemTRATOQTCONC = null | number;

export type ReadTracerInfoResponseATPREVTRATOItemTRATOPROPCONC = null | number;

export type ReadTracerInfoResponseATPREVTRATOItemDIETAATCOD = null | number;

export type ReadTracerInfoResponseATPREVTRATOItem = {
  BAL_AT_COD: null;
  BAT_BAL_SEQ: null;
  DIETA_AT_COD: ReadTracerInfoResponseATPREVTRATOItemDIETAATCOD;
  DISTR_AT_COD: null;
  FUNC_AT_NUM: null;
  PIQ_AT_POS: number;
  TRATO_CONC: boolean;
  TRATO_DATA: string;
  TRATO_DISTR_SEQ: null;
  TRATO_HORA: null;
  TRATO_HORAPREV: string;
  TRATO_PROPCONC: ReadTracerInfoResponseATPREVTRATOItemTRATOPROPCONC;
  TRATO_QT: null;
  TRATO_QTCONC: ReadTracerInfoResponseATPREVTRATOItemTRATOQTCONC;
  TRATO_QTPREV: ReadTracerInfoResponseATPREVTRATOItemTRATOQTPREV;
  TRATO_QTPREVAJ: ReadTracerInfoResponseATPREVTRATOItemTRATOQTPREVAJ;
};

export type ReadTracerInfoResponseATPREVRCTALIMItem = {
  ALIM_COD: string;
  AMZAL_AT_COD: null;
  AMZAL_TIPTRANSPALIM: number;
  PALIM_COD: string;
  PALIM_TIPBATCARGA: EnumsLoadMode;
  RCT_AT_COD: number;
  RCTALIM_ACIONAR: boolean;
  RCTALIM_ORDEM: number;
  RCTALIM_QTMO: number;
};

export type ReadTracerInfoResponseATPREVRCTItem = {
  BAL_AT_COD: number;
  BAL_AUTO: boolean;
  DIETA_AT_COD: number;
  RCT_ACTMIX_OP: number;
  RCT_ACTMIX_PESO: number;
  RCT_AT_COD: number;
  RCT_DATA: string;
  RCT_QTMO: number;
  RCT_TIPO: number;
};

export type ReadTracerInfoResponseATPREVFUNCItem = {
  FUNC_AT_NUM: number;
  FUNC_BAT: boolean;
  FUNC_NOME: string;
  FUNC_TRATO: boolean;
};

export type ReadTracerInfoResponseATPREVDIETAPALIMItem = {
  DIETA_AT_COD: number;
  DIETAPALIM_ORDEMROTOR: number;
  DIETAPALIM_ORDEMTOMBA: number;
  DIETAPALIM_PROPMS: number;
  PALIM_ARRED: number;
  PALIM_COD: string;
};

export type ReadTracerInfoResponseATPREVBATALIMItem = {
  ALIM_COD: null;
  BAL_AT_COD: null;
  BAT_BAL_SEQ: null;
  BAT_DATA: null;
  BATALIM_QTMOPREV: null;
  BATALIM_QTMOREAL: null;
  PALIM_COD: null;
};

export type ReadTracerInfoResponseATPREVBATItem = {
  BAL_AT_COD: null;
  BAT_BAL_SEQ: null;
  BAT_DATA: null;
  BAT_HORA: null;
  BAT_MIXTEMPO: null;
  BAT_QTMO: null;
  BAT_QTMOPREV: null;
  DIETA_AT_COD: null;
  FUNC_AT_NUM: null;
  RCT_AT_COD: null;
};

export type ReadTracerInfoResponseATPREVBALItem = {
  BAL_ARRED: number;
  BAL_AT_COD: number;
  BAL_CAPAC: number;
  BAL_DISTR: boolean;
  BAL_MIX: boolean;
  BAL_MIXTEMPO: string;
  BAL_MIXTIPO: number;
  BAL_NOME: string;
  BAL_VOL: number;
};

export type ReadTracerInfoResponseATPREVALIMItem = {
  ALIM_COD: string;
  ALIM_MS: number;
  ALIM_QTMO: number;
  ALIM_QTMS: number;
  PALIM_COD: string;
  PALIM_NOME: string;
  PALIM_TIPBATCARGA: EnumsLoadMode;
};

export type ReadTracerInfoResponseATCONFIGItem = {
  PARAM: string;
  VALOR: number;
};

export interface ReIdentificationAnnouncement {
  newSisbov: number;
  oldSisbov: number;
  oxId: number;
}

export type PurchasesAvailableEarringsResponseItem = {
  available: number[];
  cancelled: number[];
  name: string;
  owner: string;
  purchaseId: number;
  total: number;
  used: number[];
};

export type PurchasesAvailableEarringsResponse = PurchasesAvailableEarringsResponseItem[];

export type PurchaseStatus = EnumsPurchaseStatus;

export type PurchaseCategory = EnumsPurchaseCategory;

export type Profile = EnumsProfile;

export interface PersonFilter {
  limit?: number;
  page?: number;
  profiles?: EnumsProfile[];
}

export interface Paginate {
  limit?: number;
  page?: number;
}

export interface OxenHealthFilter {
  limit?: number;
  page?: number;
}

export type OxPurpose = EnumsOxPurpose;

export type OxIdentificationResponseSisbov = null | number;

export type OxIdentificationResponsePurchaseId = null | number;

export type OxIdentificationResponsePriceAtSign = null | number;

export type OxIdentificationResponseInvoice = null | string;

export type OxIdentificationResponseIdentifier = null | string;

export type OxIdentificationResponseGtaId = null | number;

export type OxIdentificationResponseExitTruckId = null | number;

export type OxIdentificationResponseElectronic = null | string;

export type OxIdentificationResponseDiedAt = string | null;

export type OxIdentificationResponseDeathNecropsy = EnumsDeathNecropsy | null;

export type OxIdentificationResponseDeathDetails = null | string;

export type OxIdentificationResponseDeathCause = EnumsDeathCause | null;

export type OxIdentificationResponseComments = null | string;

export type OxIdentificationResponseCarcassWeight = null | number;

export type OxIdentificationResponseCarcassType = EnumsCarcassType | null;

export type OxIdentificationResponseCarcassThin = null | number;

export type OxIdentificationResponseCarcassPrice = null | number;

export type OxIdentificationResponseCarcassMissing = null | number;

export type OxIdentificationResponseCarcassMedium = null | number;

export type OxIdentificationResponseCarcassExcessive = null | number;

export type OxIdentificationResponseCarcass = null | number;

export type OxIdentificationResponseBatchesItemFinalizedAt = string | null;

export type OxIdentificationResponseBatchesItem = {
  batchId: number;
  createdAt: string;
  finalizedAt: OxIdentificationResponseBatchesItemFinalizedAt;
  id: number;
  oxId: number;
};

export interface OxIdentificationResponse {
  batches: OxIdentificationResponseBatchesItem[];
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: OxIdentificationResponseCarcass;
  carcassExcessive: OxIdentificationResponseCarcassExcessive;
  carcassMedium: OxIdentificationResponseCarcassMedium;
  carcassMissing: OxIdentificationResponseCarcassMissing;
  carcassPrice: OxIdentificationResponseCarcassPrice;
  carcassThin: OxIdentificationResponseCarcassThin;
  carcassType: OxIdentificationResponseCarcassType;
  carcassWeight: OxIdentificationResponseCarcassWeight;
  comments: OxIdentificationResponseComments;
  deathCause: OxIdentificationResponseDeathCause;
  deathDetails: OxIdentificationResponseDeathDetails;
  deathNecropsy: OxIdentificationResponseDeathNecropsy;
  diedAt: OxIdentificationResponseDiedAt;
  electronic: OxIdentificationResponseElectronic;
  exitTruckId: OxIdentificationResponseExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: OxIdentificationResponseGtaId;
  id: number;
  identifier: OxIdentificationResponseIdentifier;
  includedAt: string;
  invoice: OxIdentificationResponseInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: OxIdentificationResponsePriceAtSign;
  purchaseId: OxIdentificationResponsePurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: OxIdentificationResponseSisbov;
  sisbovHistory: number[];
  variety: string;
}

export type OxGmdResponseLastWeight = {
  date: string;
  id: number;
  oxId: number;
  source: EnumsWeightSource;
  weight: number;
};

export interface OxGmdResponse {
  gmd: number;
  interval: number;
  lastWeight: OxGmdResponseLastWeight;
}

export type OxGender = EnumsOxGender;

export interface OxFilter {
  batchId?: number;
  limit?: number;
  page?: number;
}

export interface OxClosureResponse {
  carcassWeightAverage: number;
  carcassWeightAverageAtSign: number;
  carcassYieldAverage: number;
  notFoundOxen: number[];
  oxenUpdated: OxClosureResponseOxenUpdatedItem[];
  priceAtSignAverage: number;
}

export type OxClosureResponseOxenUpdatedItemSisbov = null | number;

export type OxClosureResponseOxenUpdatedItemPurchaseId = null | number;

export type OxClosureResponseOxenUpdatedItemPriceAtSign = null | number;

export type OxClosureResponseOxenUpdatedItemInvoice = null | string;

export type OxClosureResponseOxenUpdatedItemIdentifier = null | string;

export type OxClosureResponseOxenUpdatedItemGtaId = null | number;

export type OxClosureResponseOxenUpdatedItemExitTruckId = null | number;

export type OxClosureResponseOxenUpdatedItemElectronic = null | string;

export type OxClosureResponseOxenUpdatedItemDiedAt = string | null;

export type OxClosureResponseOxenUpdatedItemDeathNecropsy = EnumsDeathNecropsy | null;

export type OxClosureResponseOxenUpdatedItemDeathDetails = null | string;

export type OxClosureResponseOxenUpdatedItemDeathCause = EnumsDeathCause | null;

export type OxClosureResponseOxenUpdatedItemComments = null | string;

export type OxClosureResponseOxenUpdatedItemCarcassWeight = null | number;

export type OxClosureResponseOxenUpdatedItemCarcassType = EnumsCarcassType | null;

export type OxClosureResponseOxenUpdatedItemCarcassThin = null | number;

export type OxClosureResponseOxenUpdatedItemCarcassPrice = null | number;

export type OxClosureResponseOxenUpdatedItemCarcassMissing = null | number;

export type OxClosureResponseOxenUpdatedItemCarcassMedium = null | number;

export type OxClosureResponseOxenUpdatedItemCarcassExcessive = null | number;

export type OxClosureResponseOxenUpdatedItemCarcass = null | number;

export type OxClosureResponseOxenUpdatedItem = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: OxClosureResponseOxenUpdatedItemCarcass;
  carcassExcessive: OxClosureResponseOxenUpdatedItemCarcassExcessive;
  carcassMedium: OxClosureResponseOxenUpdatedItemCarcassMedium;
  carcassMissing: OxClosureResponseOxenUpdatedItemCarcassMissing;
  carcassPrice: OxClosureResponseOxenUpdatedItemCarcassPrice;
  carcassThin: OxClosureResponseOxenUpdatedItemCarcassThin;
  carcassType: OxClosureResponseOxenUpdatedItemCarcassType;
  carcassWeight: OxClosureResponseOxenUpdatedItemCarcassWeight;
  comments: OxClosureResponseOxenUpdatedItemComments;
  deathCause: OxClosureResponseOxenUpdatedItemDeathCause;
  deathDetails: OxClosureResponseOxenUpdatedItemDeathDetails;
  deathNecropsy: OxClosureResponseOxenUpdatedItemDeathNecropsy;
  diedAt: OxClosureResponseOxenUpdatedItemDiedAt;
  electronic: OxClosureResponseOxenUpdatedItemElectronic;
  exitTruckId: OxClosureResponseOxenUpdatedItemExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: OxClosureResponseOxenUpdatedItemGtaId;
  id: number;
  identifier: OxClosureResponseOxenUpdatedItemIdentifier;
  includedAt: string;
  invoice: OxClosureResponseOxenUpdatedItemInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: OxClosureResponseOxenUpdatedItemPriceAtSign;
  purchaseId: OxClosureResponseOxenUpdatedItemPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: OxClosureResponseOxenUpdatedItemSisbov;
  sisbovHistory: number[];
  variety: string;
};

export interface OxClosureByDateResponse {
  carcassWeightAverage: number;
  carcassYieldAverage: number;
  oxenUpdated: OxClosureByDateResponseOxenUpdatedItem[];
  priceAtSignAverage: number;
}

export type OxClosureByDateResponseOxenUpdatedItemSisbov = null | number;

export type OxClosureByDateResponseOxenUpdatedItemPurchaseId = null | number;

export type OxClosureByDateResponseOxenUpdatedItemPriceAtSign = null | number;

export type OxClosureByDateResponseOxenUpdatedItemInvoice = null | string;

export type OxClosureByDateResponseOxenUpdatedItemIdentifier = null | string;

export type OxClosureByDateResponseOxenUpdatedItemGtaId = null | number;

export type OxClosureByDateResponseOxenUpdatedItemExitTruckId = null | number;

export type OxClosureByDateResponseOxenUpdatedItemElectronic = null | string;

export type OxClosureByDateResponseOxenUpdatedItemDiedAt = string | null;

export type OxClosureByDateResponseOxenUpdatedItemDeathNecropsy = EnumsDeathNecropsy | null;

export type OxClosureByDateResponseOxenUpdatedItemDeathDetails = null | string;

export type OxClosureByDateResponseOxenUpdatedItemDeathCause = EnumsDeathCause | null;

export type OxClosureByDateResponseOxenUpdatedItemComments = null | string;

export type OxClosureByDateResponseOxenUpdatedItemCarcassWeight = null | number;

export type OxClosureByDateResponseOxenUpdatedItemCarcassType = EnumsCarcassType | null;

export type OxClosureByDateResponseOxenUpdatedItemCarcassThin = null | number;

export type OxClosureByDateResponseOxenUpdatedItemCarcassPrice = null | number;

export type OxClosureByDateResponseOxenUpdatedItemCarcassMissing = null | number;

export type OxClosureByDateResponseOxenUpdatedItemCarcassMedium = null | number;

export type OxClosureByDateResponseOxenUpdatedItemCarcassExcessive = null | number;

export type OxClosureByDateResponseOxenUpdatedItemCarcass = null | number;

export type OxClosureByDateResponseOxenUpdatedItem = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: OxClosureByDateResponseOxenUpdatedItemCarcass;
  carcassExcessive: OxClosureByDateResponseOxenUpdatedItemCarcassExcessive;
  carcassMedium: OxClosureByDateResponseOxenUpdatedItemCarcassMedium;
  carcassMissing: OxClosureByDateResponseOxenUpdatedItemCarcassMissing;
  carcassPrice: OxClosureByDateResponseOxenUpdatedItemCarcassPrice;
  carcassThin: OxClosureByDateResponseOxenUpdatedItemCarcassThin;
  carcassType: OxClosureByDateResponseOxenUpdatedItemCarcassType;
  carcassWeight: OxClosureByDateResponseOxenUpdatedItemCarcassWeight;
  comments: OxClosureByDateResponseOxenUpdatedItemComments;
  deathCause: OxClosureByDateResponseOxenUpdatedItemDeathCause;
  deathDetails: OxClosureByDateResponseOxenUpdatedItemDeathDetails;
  deathNecropsy: OxClosureByDateResponseOxenUpdatedItemDeathNecropsy;
  diedAt: OxClosureByDateResponseOxenUpdatedItemDiedAt;
  electronic: OxClosureByDateResponseOxenUpdatedItemElectronic;
  exitTruckId: OxClosureByDateResponseOxenUpdatedItemExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: OxClosureByDateResponseOxenUpdatedItemGtaId;
  id: number;
  identifier: OxClosureByDateResponseOxenUpdatedItemIdentifier;
  includedAt: string;
  invoice: OxClosureByDateResponseOxenUpdatedItemInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: OxClosureByDateResponseOxenUpdatedItemPriceAtSign;
  purchaseId: OxClosureByDateResponseOxenUpdatedItemPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: OxClosureByDateResponseOxenUpdatedItemSisbov;
  sisbovHistory: number[];
  variety: string;
};

export type OxCheckupResponseSisbov = null | number;

export type OxCheckupResponsePurchaseId = null | number;

export type OxCheckupResponsePriceAtSign = null | number;

export type OxCheckupResponseInvoice = null | string;

export type OxCheckupResponseIdentifier = null | string;

export type OxCheckupResponseGtaId = null | number;

export type OxCheckupResponseExitTruckId = null | number;

export type OxCheckupResponseElectronic = null | string;

export type OxCheckupResponseDiedAt = string | null;

export type OxCheckupResponseDeathNecropsy = EnumsDeathNecropsy | null;

export type OxCheckupResponseDeathDetails = null | string;

export type OxCheckupResponseDeathCause = EnumsDeathCause | null;

export type OxCheckupResponseComments = null | string;

export type OxCheckupResponseCarcassWeight = null | number;

export type OxCheckupResponseCarcassType = EnumsCarcassType | null;

export type OxCheckupResponseCarcassThin = null | number;

export type OxCheckupResponseCarcassPrice = null | number;

export type OxCheckupResponseCarcassMissing = null | number;

export type OxCheckupResponseCarcassMedium = null | number;

export type OxCheckupResponseCarcassExcessive = null | number;

export type OxCheckupResponseCarcass = null | number;

export interface OxCheckupResponse {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: OxCheckupResponseCarcass;
  carcassExcessive: OxCheckupResponseCarcassExcessive;
  carcassMedium: OxCheckupResponseCarcassMedium;
  carcassMissing: OxCheckupResponseCarcassMissing;
  carcassPrice: OxCheckupResponseCarcassPrice;
  carcassThin: OxCheckupResponseCarcassThin;
  carcassType: OxCheckupResponseCarcassType;
  carcassWeight: OxCheckupResponseCarcassWeight;
  comments: OxCheckupResponseComments;
  deathCause: OxCheckupResponseDeathCause;
  deathDetails: OxCheckupResponseDeathDetails;
  deathNecropsy: OxCheckupResponseDeathNecropsy;
  diedAt: OxCheckupResponseDiedAt;
  electronic: OxCheckupResponseElectronic;
  exitTruckId: OxCheckupResponseExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: OxCheckupResponseGtaId;
  id: number;
  identifier: OxCheckupResponseIdentifier;
  includedAt: string;
  invoice: OxCheckupResponseInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: OxCheckupResponsePriceAtSign;
  purchaseId: OxCheckupResponsePurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: OxCheckupResponseSisbov;
  sisbovHistory: number[];
  variety: string;
}

export type OxCheckupId = {
  electronic?: string;
  identifier?: string;
  sisbov?: number;
};

export interface OxCheckup {
  ageInMonths?: number;
  breed?: string;
  electronic?: string;
  extraMedicines?: number[];
  gender?: EnumsOxGender;
  healthProtocolId?: number;
  id: OxCheckupId;
  identifier?: string;
  ownerId?: number;
  sisbov?: number;
  variety?: string;
  weight?: number;
}

export type OccurrenceType = EnumsOccurrenceType;

export type OccurrenceCategory = EnumsOccurrenceCategory;

export type NmUpdatesHistoryResponse = NmUpdatesHistoryResponseItem[];

export type NmUpdatesHistoryResponseItemOldFactor = null | number;

export type NmUpdatesHistoryResponseItemNewFactor = null | number;

export type NmUpdatesHistoryResponseItemName = null | string;

export type NmUpdatesHistoryResponseItem = {
  date: string;
  name: NmUpdatesHistoryResponseItemName;
  newFactor: NmUpdatesHistoryResponseItemNewFactor;
  oldFactor: NmUpdatesHistoryResponseItemOldFactor;
  percentageChange: number;
};

export type MoveBatchResponseOtherCost = null | number;

export type MoveBatchResponseOldCorralId = null | number;

export type MoveBatchResponseForeseenWeight = null | number;

export type MoveBatchResponseForeseenCarcass = null | number;

export type MoveBatchResponseFinalizedAt = string | null;

export type MoveBatchResponseExitPrice = null | number;

export type MoveBatchResponseCorralId = null | number;

export type MoveBatchResponseCarcass = null | number;

export type MoveBatchResponseAdmCost = null | number;

export type MoveBatchResponseActivatedAt = string | null;

export interface MoveBatchResponse {
  activatedAt: MoveBatchResponseActivatedAt;
  admCost: MoveBatchResponseAdmCost;
  carcass: MoveBatchResponseCarcass;
  corralId: MoveBatchResponseCorralId;
  createdAt: string;
  exitPrice: MoveBatchResponseExitPrice;
  farmId: number;
  finalizedAt: MoveBatchResponseFinalizedAt;
  foreseenCarcass: MoveBatchResponseForeseenCarcass;
  foreseenWeight: MoveBatchResponseForeseenWeight;
  id: number;
  liveWeight: number;
  name: string;
  oldCorralId: MoveBatchResponseOldCorralId;
  otherCost: MoveBatchResponseOtherCost;
}

export type MedicineType = EnumsMedicineType;

export interface MedicateRandomOx {
  batchId: number;
  customDate?: string;
  disease: EnumsDisease;
  extraMedicineIds?: number[];
  healthProtocolId?: number;
}

export interface MedicateOx {
  customDate?: string;
  disease: EnumsDisease;
  electronic?: string;
  extraMedicineIds?: number[];
  healthProtocolId?: number;
  identifier?: string;
  sisbov?: number;
}

export type Measurement = EnumsMeasurement;

export type LoginResponseUserPersonPhone = null | string;

export type LoginResponseUserPersonDocument = null | string;

export type LoginResponseUserPersonDescription = null | string;

export type LoginResponseUserPerson = {
  createdAt: string;
  description: LoginResponseUserPersonDescription;
  document: LoginResponseUserPersonDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: LoginResponseUserPersonPhone;
  profiles: EnumsProfile[];
};

export type LoginResponseUserPassword = null | string;

export type LoginResponseUserGov = GlobalPrismaJsonGovDocuments | null;

export type LoginResponseUserFarmMatinalReadTime = string | null;

export type LoginResponseUserFarmLastCertifierImport = string | null;

export type LoginResponseUserFarmLastBndImport = string | null;

export type LoginResponseUserFarmAlias = null | string;

export type LoginResponseUserFarm = {
  addressId: number;
  alias: LoginResponseUserFarmAlias;
  blockedDays: number;
  createdAt: string;
  dailyCost: number;
  day: string;
  divisionThreshold: number;
  factory: GlobalPrismaJsonLatLng[];
  gradeInfo: unknown;
  gradeMethod: EnumsGradeMethod;
  id: number;
  lastBndImport: LoginResponseUserFarmLastBndImport;
  lastCertifierImport: LoginResponseUserFarmLastCertifierImport;
  managementConfig: unknown;
  matinalReadTime: LoginResponseUserFarmMatinalReadTime;
  name: string;
  nightlyReadTimes: string[];
  position: GlobalPrismaJsonLatLng;
};

export type LoginResponseUserEmail = null | string;

export type LoginResponseUser = {
  email: LoginResponseUserEmail;
  farm: LoginResponseUserFarm;
  farmId: number;
  gov: LoginResponseUserGov;
  id: number;
  password: LoginResponseUserPassword;
  person: LoginResponseUserPerson;
  personId: number;
};

export interface LoginResponse {
  expiresAt: number;
  refresh: string;
  token: string;
  user: LoginResponseUser;
}

export interface Login {
  email: string;
  password: string;
}

export type LoadFinancialReportResponseItem = {
  animalsPurchases: number;
  beatPrice: number;
  day: string;
  earringsPurchases: number;
  foodsPurchases: number;
};

export type LoadFinancialReportResponse = LoadFinancialReportResponseItem[];

export type ListTreatmentsResponseItemsItem = {
  createdAt: string;
  divisions: number[];
  farmId: number;
  hours: string[];
  id: number;
  name: string;
};

export interface ListTreatmentsResponse {
  items: ListTreatmentsResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type ListStructuresResponseItemsItem = {
  category: EnumsStructureCategory;
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
};

export interface ListStructuresResponse {
  items: ListStructuresResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type ListStocksResponseItemPremix = null | boolean;

export type ListStocksResponseItem = {
  code: string;
  elg: number;
  elm: number;
  farmId: number;
  id: number;
  kiloPrice: number;
  loadMode: EnumsLoadMode;
  name: string;
  naturalMatter: number;
  naturalMatterFactor: number;
  premix: ListStocksResponseItemPremix;
};

export type ListStocksResponse = ListStocksResponseItem[];

export type ListSoldBatchesResponse = ListSoldBatchesResponseItem[];

export type ListSoldBatchesResponseItemOtherCost = null | number;

export type ListSoldBatchesResponseItemOldCorralId = null | number;

export type ListSoldBatchesResponseItemForeseenWeight = null | number;

export type ListSoldBatchesResponseItemForeseenCarcass = null | number;

export type ListSoldBatchesResponseItemFinalizedAt = string | null;

export type ListSoldBatchesResponseItemExitPrice = null | number;

export type ListSoldBatchesResponseItemCorralId = null | number;

export type ListSoldBatchesResponseItemCorralAnyOf = {
  area: number;
  capacity: number;
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
  polygon: GlobalPrismaJsonLatLng[];
  position: number;
  type: EnumsCorralType;
};

export type ListSoldBatchesResponseItemCorral = ListSoldBatchesResponseItemCorralAnyOf | null;

export type ListSoldBatchesResponseItemCarcass = null | number;

export type ListSoldBatchesResponseItemAdmCost = null | number;

export type ListSoldBatchesResponseItemActivatedAt = string | null;

export type ListSoldBatchesResponseItem = {
  activatedAt: ListSoldBatchesResponseItemActivatedAt;
  admCost: ListSoldBatchesResponseItemAdmCost;
  carcass: ListSoldBatchesResponseItemCarcass;
  corral: ListSoldBatchesResponseItemCorral;
  corralId: ListSoldBatchesResponseItemCorralId;
  createdAt: string;
  exitPrice: ListSoldBatchesResponseItemExitPrice;
  farmId: number;
  finalizedAt: ListSoldBatchesResponseItemFinalizedAt;
  foreseenCarcass: ListSoldBatchesResponseItemForeseenCarcass;
  foreseenWeight: ListSoldBatchesResponseItemForeseenWeight;
  id: number;
  liveWeight: number;
  name: string;
  oldCorralId: ListSoldBatchesResponseItemOldCorralId;
  otherCost: ListSoldBatchesResponseItemOtherCost;
};

export type ListSimpleAnimalResponseSimpleAnimalsItem = {
  ox: ListSimpleAnimalResponseSimpleAnimalsItemOx;
};

export interface ListSimpleAnimalResponse {
  name: string;
  simpleAnimals: ListSimpleAnimalResponseSimpleAnimalsItem[];
  totalAnimals: number;
  totalSimpleAnimals: number;
}

export type ListSimpleAnimalResponseSimpleAnimalsItemOxSisbov = null | number;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxPurchaseId = null | number;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxPriceAtSign = null | number;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxInvoice = null | string;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxIdentifier = null | string;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxGtaId = null | number;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxExitTruckId = null | number;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxElectronic = null | string;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxDiedAt = string | null;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxDeathNecropsy = EnumsDeathNecropsy | null;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxDeathDetails = null | string;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxDeathCause = EnumsDeathCause | null;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxComments = null | string;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxCarcassWeight = null | number;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxCarcassType = EnumsCarcassType | null;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxCarcassThin = null | number;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxCarcassPrice = null | number;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxCarcassMissing = null | number;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxCarcassMedium = null | number;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxCarcassExcessive = null | number;

export type ListSimpleAnimalResponseSimpleAnimalsItemOxCarcass = null | number;

export type ListSimpleAnimalResponseSimpleAnimalsItemOx = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: ListSimpleAnimalResponseSimpleAnimalsItemOxCarcass;
  carcassExcessive: ListSimpleAnimalResponseSimpleAnimalsItemOxCarcassExcessive;
  carcassMedium: ListSimpleAnimalResponseSimpleAnimalsItemOxCarcassMedium;
  carcassMissing: ListSimpleAnimalResponseSimpleAnimalsItemOxCarcassMissing;
  carcassPrice: ListSimpleAnimalResponseSimpleAnimalsItemOxCarcassPrice;
  carcassThin: ListSimpleAnimalResponseSimpleAnimalsItemOxCarcassThin;
  carcassType: ListSimpleAnimalResponseSimpleAnimalsItemOxCarcassType;
  carcassWeight: ListSimpleAnimalResponseSimpleAnimalsItemOxCarcassWeight;
  comments: ListSimpleAnimalResponseSimpleAnimalsItemOxComments;
  deathCause: ListSimpleAnimalResponseSimpleAnimalsItemOxDeathCause;
  deathDetails: ListSimpleAnimalResponseSimpleAnimalsItemOxDeathDetails;
  deathNecropsy: ListSimpleAnimalResponseSimpleAnimalsItemOxDeathNecropsy;
  diedAt: ListSimpleAnimalResponseSimpleAnimalsItemOxDiedAt;
  electronic: ListSimpleAnimalResponseSimpleAnimalsItemOxElectronic;
  exitTruckId: ListSimpleAnimalResponseSimpleAnimalsItemOxExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: ListSimpleAnimalResponseSimpleAnimalsItemOxGtaId;
  id: number;
  identifier: ListSimpleAnimalResponseSimpleAnimalsItemOxIdentifier;
  includedAt: string;
  invoice: ListSimpleAnimalResponseSimpleAnimalsItemOxInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: ListSimpleAnimalResponseSimpleAnimalsItemOxPriceAtSign;
  purchaseId: ListSimpleAnimalResponseSimpleAnimalsItemOxPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: ListSimpleAnimalResponseSimpleAnimalsItemOxSisbov;
  sisbovHistory: number[];
  variety: string;
};

export type ListSaleManagementsResponse = ListSaleManagementsResponseItem[];

export type ListSaleManagementsResponseItemTrucksItemSaleId = null | number;

export type ListSaleManagementsResponseItemTrucksItemPlate = null | string;

export type ListSaleManagementsResponseItemTrucksItemName = null | string;

export type ListSaleManagementsResponseItemTrucksItemExitGta = null | string;

export type ListSaleManagementsResponseItemTrucksItemDestinyId = null | number;

export type ListSaleManagementsResponseItemTrucksItemBoardingItemSisbov = null | number;

export type ListSaleManagementsResponseItemTrucksItemBoardingItem = {
  id: number;
  sisbov: ListSaleManagementsResponseItemTrucksItemBoardingItemSisbov;
};

export type ListSaleManagementsResponseItemTrucksItemAnnouncementId = null | number;

export type ListSaleManagementsResponseItemTrucksItem = {
  announcementId: ListSaleManagementsResponseItemTrucksItemAnnouncementId;
  boarding: ListSaleManagementsResponseItemTrucksItemBoardingItem[];
  capacity: number;
  destinyId: ListSaleManagementsResponseItemTrucksItemDestinyId;
  exitAt: string;
  exitGta: ListSaleManagementsResponseItemTrucksItemExitGta;
  farmId: number;
  id: number;
  name: ListSaleManagementsResponseItemTrucksItemName;
  plate: ListSaleManagementsResponseItemTrucksItemPlate;
  reason: EnumsExitReason;
  saleId: ListSaleManagementsResponseItemTrucksItemSaleId;
  status: EnumsExitTruckStatus;
};

export type ListSaleManagementsResponseItemOwner = {
  name: string;
};

export type ListSaleManagementsResponseItemAnnouncementId = null | number;

export type ListSaleManagementsResponseItem = {
  announcementId: ListSaleManagementsResponseItemAnnouncementId;
  closed: boolean;
  date: string;
  farmId: number;
  id: number;
  owner: ListSaleManagementsResponseItemOwner;
  ownerId: number;
  trucks: ListSaleManagementsResponseItemTrucksItem[];
};

export interface ListPurchasesResponse {
  items: ListPurchasesResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type ListPurchasesResponseItemsItemStockAnyOf = {
  amount: number;
  id: number;
  purchaseId: number;
  stockId: number;
};

export type ListPurchasesResponseItemsItemStock = ListPurchasesResponseItemsItemStockAnyOf | null;

export type ListPurchasesResponseItemsItemSisbov = ListPurchasesResponseItemsItemSisbovAnyOf | null;

export type ListPurchasesResponseItemsItem = {
  category: EnumsPurchaseCategory;
  earringsPerPage: ListPurchasesResponseItemsItemEarringsPerPage;
  farmId: number;
  id: number;
  invoice: string;
  issuedAt: string;
  name: string;
  observation: ListPurchasesResponseItemsItemObservation;
  oxen: ListPurchasesResponseItemsItemOxen;
  price: number;
  receivedAt: ListPurchasesResponseItemsItemReceivedAt;
  series: string;
  sisbov: ListPurchasesResponseItemsItemSisbov;
  status: EnumsPurchaseStatus;
  stock: ListPurchasesResponseItemsItemStock;
};

export type ListPurchasesResponseItemsItemSisbovAnyOfOwnerPhone = null | string;

export type ListPurchasesResponseItemsItemSisbovAnyOfOwnerDocument = null | string;

export type ListPurchasesResponseItemsItemSisbovAnyOfOwnerDescription = null | string;

export type ListPurchasesResponseItemsItemSisbovAnyOfOwner = {
  createdAt: string;
  description: ListPurchasesResponseItemsItemSisbovAnyOfOwnerDescription;
  document: ListPurchasesResponseItemsItemSisbovAnyOfOwnerDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: ListPurchasesResponseItemsItemSisbovAnyOfOwnerPhone;
  profiles: EnumsProfile[];
};

export type ListPurchasesResponseItemsItemSisbovAnyOf = {
  brand: string;
  cancelled: number[];
  finalSisbov: number;
  id: number;
  initialSisbov: number;
  owner: ListPurchasesResponseItemsItemSisbovAnyOfOwner;
  ownerId: number;
  purchaseId: number;
};

export type ListPurchasesResponseItemsItemReceivedAt = string | null;

export type ListPurchasesResponseItemsItemOxen = ListPurchasesResponseItemsItemOxenAnyOf | null;

export type ListPurchasesResponseItemsItemOxenAnyOfGtasItemOriginResponsible = {
  id: number;
  name: string;
};

export type ListPurchasesResponseItemsItemOxenAnyOfGtasItemOrigin = {
  responsible: ListPurchasesResponseItemsItemOxenAnyOfGtasItemOriginResponsible;
};

export type ListPurchasesResponseItemsItemOxenAnyOfGtasItem = {
  origin: ListPurchasesResponseItemsItemOxenAnyOfGtasItemOrigin;
};

export type ListPurchasesResponseItemsItemOxenAnyOfBuyer = {
  id: number;
  name: string;
};

export type ListPurchasesResponseItemsItemOxenAnyOf = {
  buyer: ListPurchasesResponseItemsItemOxenAnyOfBuyer;
  buyerId: number;
  commission: number;
  distance: number;
  gtas: ListPurchasesResponseItemsItemOxenAnyOfGtasItem[];
  id: number;
  ownerId: number;
  purchaseId: number;
  purpose: EnumsOxPurpose;
  shipping: number;
  traceable: boolean;
  weightAverage: number;
};

export type ListPurchasesResponseItemsItemObservation = null | string;

export type ListPurchasesResponseItemsItemEarringsPerPage = null | number;

export interface ListPurchasesEarringsResponse {
  available: number[];
  cancelled: number[];
  owner: string;
  purchaseId: number;
  total: number;
  used: number[];
}

export type ListOxenHealthResponseItemsItem = {
  appliedAt: string;
  disease: EnumsDisease;
  healthProtocol: ListOxenHealthResponseItemsItemHealthProtocol;
  healthProtocolId: ListOxenHealthResponseItemsItemHealthProtocolId;
  id: number;
  medicines: ListOxenHealthResponseItemsItemMedicinesItem[];
  ox: ListOxenHealthResponseItemsItemOx;
  oxId: number;
};

export interface ListOxenHealthResponse {
  items: ListOxenHealthResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type ListOxenHealthResponseItemsItemOxSisbov = null | number;

export type ListOxenHealthResponseItemsItemOxPurchaseId = null | number;

export type ListOxenHealthResponseItemsItemOxPriceAtSign = null | number;

export type ListOxenHealthResponseItemsItemOxInvoice = null | string;

export type ListOxenHealthResponseItemsItemOxIdentifier = null | string;

export type ListOxenHealthResponseItemsItemOxGtaId = null | number;

export type ListOxenHealthResponseItemsItemOxExitTruckId = null | number;

export type ListOxenHealthResponseItemsItemOxElectronic = null | string;

export type ListOxenHealthResponseItemsItemOxDiedAt = string | null;

export type ListOxenHealthResponseItemsItemOxDeathNecropsy = EnumsDeathNecropsy | null;

export type ListOxenHealthResponseItemsItemOxDeathDetails = null | string;

export type ListOxenHealthResponseItemsItemOxDeathCause = EnumsDeathCause | null;

export type ListOxenHealthResponseItemsItemOxComments = null | string;

export type ListOxenHealthResponseItemsItemOxCarcassWeight = null | number;

export type ListOxenHealthResponseItemsItemOxCarcassType = EnumsCarcassType | null;

export type ListOxenHealthResponseItemsItemOxCarcassThin = null | number;

export type ListOxenHealthResponseItemsItemOxCarcassPrice = null | number;

export type ListOxenHealthResponseItemsItemOxCarcassMissing = null | number;

export type ListOxenHealthResponseItemsItemOxCarcassMedium = null | number;

export type ListOxenHealthResponseItemsItemOxCarcassExcessive = null | number;

export type ListOxenHealthResponseItemsItemOxCarcass = null | number;

export type ListOxenHealthResponseItemsItemOx = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: ListOxenHealthResponseItemsItemOxCarcass;
  carcassExcessive: ListOxenHealthResponseItemsItemOxCarcassExcessive;
  carcassMedium: ListOxenHealthResponseItemsItemOxCarcassMedium;
  carcassMissing: ListOxenHealthResponseItemsItemOxCarcassMissing;
  carcassPrice: ListOxenHealthResponseItemsItemOxCarcassPrice;
  carcassThin: ListOxenHealthResponseItemsItemOxCarcassThin;
  carcassType: ListOxenHealthResponseItemsItemOxCarcassType;
  carcassWeight: ListOxenHealthResponseItemsItemOxCarcassWeight;
  comments: ListOxenHealthResponseItemsItemOxComments;
  deathCause: ListOxenHealthResponseItemsItemOxDeathCause;
  deathDetails: ListOxenHealthResponseItemsItemOxDeathDetails;
  deathNecropsy: ListOxenHealthResponseItemsItemOxDeathNecropsy;
  diedAt: ListOxenHealthResponseItemsItemOxDiedAt;
  electronic: ListOxenHealthResponseItemsItemOxElectronic;
  exitTruckId: ListOxenHealthResponseItemsItemOxExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: ListOxenHealthResponseItemsItemOxGtaId;
  id: number;
  identifier: ListOxenHealthResponseItemsItemOxIdentifier;
  includedAt: string;
  invoice: ListOxenHealthResponseItemsItemOxInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: ListOxenHealthResponseItemsItemOxPriceAtSign;
  purchaseId: ListOxenHealthResponseItemsItemOxPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: ListOxenHealthResponseItemsItemOxSisbov;
  sisbovHistory: number[];
  variety: string;
};

export type ListOxenHealthResponseItemsItemMedicinesItemKiloDose = null | number;

export type ListOxenHealthResponseItemsItemMedicinesItem = {
  blockingDays: number;
  createdAt: string;
  dosePrice: number;
  farmId: number;
  id: number;
  kiloDose: ListOxenHealthResponseItemsItemMedicinesItemKiloDose;
  manufacturer: string;
  measurement: EnumsMeasurement;
  name: string;
  type: EnumsMedicineType;
};

export type ListOxenHealthResponseItemsItemHealthProtocolId = null | number;

export type ListOxenHealthResponseItemsItemHealthProtocolAnyOf = {
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
};

export type ListOxenHealthResponseItemsItemHealthProtocol = ListOxenHealthResponseItemsItemHealthProtocolAnyOf | null;

export type ListOxenByBatchIdResponseTreatmentsItemTreatment = {
  name: string;
};

export type ListOxenByBatchIdResponseTreatmentsItem = {
  treatment: ListOxenByBatchIdResponseTreatmentsItemTreatment;
};

export type ListOxenByBatchIdResponseOxenItem = {
  ox: ListOxenByBatchIdResponseOxenItemOx;
};

export interface ListOxenByBatchIdResponse {
  activatedAt: ListOxenByBatchIdResponseActivatedAt;
  admCost: ListOxenByBatchIdResponseAdmCost;
  carcass: ListOxenByBatchIdResponseCarcass;
  corral: ListOxenByBatchIdResponseCorral;
  corralId: ListOxenByBatchIdResponseCorralId;
  createdAt: string;
  diets: ListOxenByBatchIdResponseDietsItem[];
  exitPrice: ListOxenByBatchIdResponseExitPrice;
  farmId: number;
  finalizedAt: ListOxenByBatchIdResponseFinalizedAt;
  foreseenCarcass: ListOxenByBatchIdResponseForeseenCarcass;
  foreseenWeight: ListOxenByBatchIdResponseForeseenWeight;
  histories: ListOxenByBatchIdResponseHistoriesItem[];
  id: number;
  liveWeight: number;
  name: string;
  oldCorralId: ListOxenByBatchIdResponseOldCorralId;
  otherCost: ListOxenByBatchIdResponseOtherCost;
  oxen: ListOxenByBatchIdResponseOxenItem[];
  treatments: ListOxenByBatchIdResponseTreatmentsItem[];
}

export type ListOxenByBatchIdResponseOxenItemOxWeightsItem = {
  weight: number;
};

export type ListOxenByBatchIdResponseOxenItemOxSisbov = null | number;

export type ListOxenByBatchIdResponseOxenItemOxPurchaseId = null | number;

export type ListOxenByBatchIdResponseOxenItemOxPriceAtSign = null | number;

export type ListOxenByBatchIdResponseOxenItemOxInvoice = null | string;

export type ListOxenByBatchIdResponseOxenItemOxIdentifier = null | string;

export type ListOxenByBatchIdResponseOxenItemOxGtaId = null | number;

export type ListOxenByBatchIdResponseOxenItemOxExitTruckId = null | number;

export type ListOxenByBatchIdResponseOxenItemOxElectronic = null | string;

export type ListOxenByBatchIdResponseOxenItemOxDiedAt = string | null;

export type ListOxenByBatchIdResponseOxenItemOxDeathNecropsy = EnumsDeathNecropsy | null;

export type ListOxenByBatchIdResponseOxenItemOxDeathDetails = null | string;

export type ListOxenByBatchIdResponseOxenItemOxDeathCause = EnumsDeathCause | null;

export type ListOxenByBatchIdResponseOxenItemOxComments = null | string;

export type ListOxenByBatchIdResponseOxenItemOxCarcassWeight = null | number;

export type ListOxenByBatchIdResponseOxenItemOxCarcassType = EnumsCarcassType | null;

export type ListOxenByBatchIdResponseOxenItemOxCarcassThin = null | number;

export type ListOxenByBatchIdResponseOxenItemOxCarcassPrice = null | number;

export type ListOxenByBatchIdResponseOxenItemOxCarcassMissing = null | number;

export type ListOxenByBatchIdResponseOxenItemOxCarcassMedium = null | number;

export type ListOxenByBatchIdResponseOxenItemOxCarcassExcessive = null | number;

export type ListOxenByBatchIdResponseOxenItemOxCarcass = null | number;

export type ListOxenByBatchIdResponseOxenItemOx = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: ListOxenByBatchIdResponseOxenItemOxCarcass;
  carcassExcessive: ListOxenByBatchIdResponseOxenItemOxCarcassExcessive;
  carcassMedium: ListOxenByBatchIdResponseOxenItemOxCarcassMedium;
  carcassMissing: ListOxenByBatchIdResponseOxenItemOxCarcassMissing;
  carcassPrice: ListOxenByBatchIdResponseOxenItemOxCarcassPrice;
  carcassThin: ListOxenByBatchIdResponseOxenItemOxCarcassThin;
  carcassType: ListOxenByBatchIdResponseOxenItemOxCarcassType;
  carcassWeight: ListOxenByBatchIdResponseOxenItemOxCarcassWeight;
  comments: ListOxenByBatchIdResponseOxenItemOxComments;
  deathCause: ListOxenByBatchIdResponseOxenItemOxDeathCause;
  deathDetails: ListOxenByBatchIdResponseOxenItemOxDeathDetails;
  deathNecropsy: ListOxenByBatchIdResponseOxenItemOxDeathNecropsy;
  diedAt: ListOxenByBatchIdResponseOxenItemOxDiedAt;
  electronic: ListOxenByBatchIdResponseOxenItemOxElectronic;
  exitTruckId: ListOxenByBatchIdResponseOxenItemOxExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: ListOxenByBatchIdResponseOxenItemOxGtaId;
  id: number;
  identifier: ListOxenByBatchIdResponseOxenItemOxIdentifier;
  includedAt: string;
  invoice: ListOxenByBatchIdResponseOxenItemOxInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: ListOxenByBatchIdResponseOxenItemOxPriceAtSign;
  purchaseId: ListOxenByBatchIdResponseOxenItemOxPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: ListOxenByBatchIdResponseOxenItemOxSisbov;
  sisbovHistory: number[];
  variety: string;
  weights: ListOxenByBatchIdResponseOxenItemOxWeightsItem[];
};

export type ListOxenByBatchIdResponseOtherCost = null | number;

export type ListOxenByBatchIdResponseOldCorralId = null | number;

export type ListOxenByBatchIdResponseHistoriesItem = {
  weightAverage: number;
};

export type ListOxenByBatchIdResponseForeseenWeight = null | number;

export type ListOxenByBatchIdResponseForeseenCarcass = null | number;

export type ListOxenByBatchIdResponseFinalizedAt = string | null;

export type ListOxenByBatchIdResponseExitPrice = null | number;

export type ListOxenByBatchIdResponseDietsItemDiet = {
  name: string;
};

export type ListOxenByBatchIdResponseDietsItem = {
  diet: ListOxenByBatchIdResponseDietsItemDiet;
};

export type ListOxenByBatchIdResponseCorralId = null | number;

export type ListOxenByBatchIdResponseCorralAnyOf = {
  area: number;
  capacity: number;
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
  polygon: GlobalPrismaJsonLatLng[];
  position: number;
  type: EnumsCorralType;
};

export type ListOxenByBatchIdResponseCorral = ListOxenByBatchIdResponseCorralAnyOf | null;

export type ListOxenByBatchIdResponseCarcass = null | number;

export type ListOxenByBatchIdResponseAdmCost = null | number;

export type ListOxenByBatchIdResponseActivatedAt = string | null;

export interface ListOccurrencesResponse {
  items: ListOccurrencesResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type ListOccurrencesResponseItemsItemOldFactor = null | number;

export type ListOccurrencesResponseItemsItemNewFactor = null | number;

export type ListOccurrencesResponseItemsItemName = null | string;

export type ListOccurrencesResponseItemsItemBatchesItem = {
  id: number;
  name: string;
};

export type ListOccurrencesResponseItemsItem = {
  batches: ListOccurrencesResponseItemsItemBatchesItem[];
  category: EnumsOccurrenceCategory;
  date: string;
  description: string;
  farmId: number;
  id: number;
  name: ListOccurrencesResponseItemsItemName;
  newFactor: ListOccurrencesResponseItemsItemNewFactor;
  oldFactor: ListOccurrencesResponseItemsItemOldFactor;
  type: EnumsOccurrenceType;
};

export type ListHealthProtocolsResponseItemsItemMedicinesItemKiloDose = null | number;

export type ListHealthProtocolsResponseItemsItemMedicinesItem = {
  blockingDays: number;
  createdAt: string;
  dosePrice: number;
  farmId: number;
  id: number;
  kiloDose: ListHealthProtocolsResponseItemsItemMedicinesItemKiloDose;
  manufacturer: string;
  measurement: EnumsMeasurement;
  name: string;
  type: EnumsMedicineType;
};

export type ListHealthProtocolsResponseItemsItem = {
  id: number;
  medicines: ListHealthProtocolsResponseItemsItemMedicinesItem[];
  name: string;
};

export interface ListHealthProtocolsResponse {
  items: ListHealthProtocolsResponseItemsItem[];
  next?: number;
  previous?: number;
}

export interface ListGtasResponse {
  items: ListGtasResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type ListGtasResponseItemsItemPurchaseId = null | number;

export type ListGtasResponseItemsItemOrigin = {
  name: string;
  responsible: ListGtasResponseItemsItemOriginResponsible;
};

export type ListGtasResponseItemsItemOriginResponsiblePhone = null | string;

export type ListGtasResponseItemsItemOriginResponsibleDocument = null | string;

export type ListGtasResponseItemsItemOriginResponsibleDescription = null | string;

export type ListGtasResponseItemsItemOriginResponsible = {
  createdAt: string;
  description: ListGtasResponseItemsItemOriginResponsibleDescription;
  document: ListGtasResponseItemsItemOriginResponsibleDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: ListGtasResponseItemsItemOriginResponsiblePhone;
  profiles: EnumsProfile[];
};

export type ListGtasResponseItemsItemFarm = {
  name: string;
};

export type ListGtasResponseItemsItemDescription = null | string;

export type ListGtasResponseItemsItemAgesItem = {
  femaleManaged: number;
  femaleTotal: number;
  gtaId: number;
  id: number;
  maleManaged: number;
  maleTotal: number;
  yearsOld: number;
};

export type ListGtasResponseItemsItem = {
  ages: ListGtasResponseItemsItemAgesItem[];
  completed: boolean;
  description: ListGtasResponseItemsItemDescription;
  emission: string;
  entry: string;
  expiration: string;
  farm: ListGtasResponseItemsItemFarm;
  farmId: number;
  id: number;
  number: string;
  origin: ListGtasResponseItemsItemOrigin;
  originId: number;
  purchaseId: ListGtasResponseItemsItemPurchaseId;
  series: string;
};

export type ListGtasByPurchaseIdResponseItemPurchaseId = null | number;

export type ListGtasByPurchaseIdResponseItemDescription = null | string;

export type ListGtasByPurchaseIdResponseItemAgesItem = {
  femaleManaged: number;
  femaleTotal: number;
  gtaId: number;
  id: number;
  maleManaged: number;
  maleTotal: number;
  yearsOld: number;
};

export type ListGtasByPurchaseIdResponseItem = {
  ages: ListGtasByPurchaseIdResponseItemAgesItem[];
  completed: boolean;
  description: ListGtasByPurchaseIdResponseItemDescription;
  emission: string;
  entry: string;
  expiration: string;
  farmId: number;
  id: number;
  number: string;
  originId: number;
  purchaseId: ListGtasByPurchaseIdResponseItemPurchaseId;
  series: string;
};

export type ListGtasByPurchaseIdResponse = ListGtasByPurchaseIdResponseItem[];

export type ListFoodsResponse = StaticFood[];

export interface ListFinalizedBatchesResponse {
  items: ListFinalizedBatchesResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type ListFinalizedBatchesResponseItemsItemOtherCost = null | number;

export type ListFinalizedBatchesResponseItemsItemOldCorralId = null | number;

export type ListFinalizedBatchesResponseItemsItemForeseenWeight = null | number;

export type ListFinalizedBatchesResponseItemsItemForeseenCarcass = null | number;

export type ListFinalizedBatchesResponseItemsItemFinalizedAt = string | null;

export type ListFinalizedBatchesResponseItemsItemExitPrice = null | number;

export type ListFinalizedBatchesResponseItemsItemCorralId = null | number;

export type ListFinalizedBatchesResponseItemsItemCarcass = null | number;

export type ListFinalizedBatchesResponseItemsItemAdmCost = null | number;

export type ListFinalizedBatchesResponseItemsItemActivatedAt = string | null;

export type ListFinalizedBatchesResponseItemsItem = {
  activatedAt: ListFinalizedBatchesResponseItemsItemActivatedAt;
  admCost: ListFinalizedBatchesResponseItemsItemAdmCost;
  carcass: ListFinalizedBatchesResponseItemsItemCarcass;
  corralId: ListFinalizedBatchesResponseItemsItemCorralId;
  createdAt: string;
  exitPrice: ListFinalizedBatchesResponseItemsItemExitPrice;
  farmId: number;
  finalizedAt: ListFinalizedBatchesResponseItemsItemFinalizedAt;
  foreseenCarcass: ListFinalizedBatchesResponseItemsItemForeseenCarcass;
  foreseenWeight: ListFinalizedBatchesResponseItemsItemForeseenWeight;
  id: number;
  liveWeight: number;
  name: string;
  oldCorralId: ListFinalizedBatchesResponseItemsItemOldCorralId;
  otherCost: ListFinalizedBatchesResponseItemsItemOtherCost;
};

export type ListExitTrucksResponse = ListExitTrucksResponseItem[];

export type ListExitTrucksResponseItemSaleId = null | number;

export type ListExitTrucksResponseItemPlate = null | string;

export type ListExitTrucksResponseItemName = null | string;

export type ListExitTrucksResponseItemExitGta = null | string;

export type ListExitTrucksResponseItemDestinyId = null | number;

export type ListExitTrucksResponseItemAnnouncementId = null | number;

export type ListExitTrucksResponseItem = {
  _count?: unknown;
  announcementId: ListExitTrucksResponseItemAnnouncementId;
  capacity: number;
  destinyId: ListExitTrucksResponseItemDestinyId;
  exitAt: string;
  exitGta: ListExitTrucksResponseItemExitGta;
  farmId: number;
  id: number;
  name: ListExitTrucksResponseItemName;
  oxenAmount: number;
  plate: ListExitTrucksResponseItemPlate;
  reason: EnumsExitReason;
  saleId: ListExitTrucksResponseItemSaleId;
  status: EnumsExitTruckStatus;
};

export interface ListEstablishmentsResponse {
  items: ListEstablishmentsResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type ListEstablishmentsResponseItemsItemResponsiblePhone = null | string;

export type ListEstablishmentsResponseItemsItemResponsibleDocument = null | string;

export type ListEstablishmentsResponseItemsItemResponsibleDescription = null | string;

export type ListEstablishmentsResponseItemsItemResponsible = {
  createdAt: string;
  description: ListEstablishmentsResponseItemsItemResponsibleDescription;
  document: ListEstablishmentsResponseItemsItemResponsibleDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: ListEstablishmentsResponseItemsItemResponsiblePhone;
  profiles: EnumsProfile[];
};

export type ListEstablishmentsResponseItemsItemCode = null | string;

export type ListEstablishmentsResponseItemsItem = {
  address: ListEstablishmentsResponseItemsItemAddress;
  addressId: number;
  code: ListEstablishmentsResponseItemsItemCode;
  destiny: boolean;
  farmId: number;
  gov: GlobalPrismaJsonGovDocuments;
  id: number;
  name: string;
  origin: boolean;
  responsible: ListEstablishmentsResponseItemsItemResponsible;
  responsibleId: number;
};

export type ListEstablishmentsResponseItemsItemAddressStreet = null | string;

export type ListEstablishmentsResponseItemsItemAddressNumber = null | string;

export type ListEstablishmentsResponseItemsItemAddressNeighborhood = null | string;

export type ListEstablishmentsResponseItemsItemAddressComplement = null | string;

export type ListEstablishmentsResponseItemsItemAddress = {
  city: string;
  complement: ListEstablishmentsResponseItemsItemAddressComplement;
  country: string;
  id: number;
  neighborhood: ListEstablishmentsResponseItemsItemAddressNeighborhood;
  number: ListEstablishmentsResponseItemsItemAddressNumber;
  state: string;
  street: ListEstablishmentsResponseItemsItemAddressStreet;
  zip: string;
};

export interface ListDietsResponse {
  items: ListDietsResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type ListDietsResponseItemsItemObservation = null | string;

export type ListDietsResponseItemsItemIngredientsItemStockPremix = null | boolean;

export type ListDietsResponseItemsItemIngredientsItemStockChangesItem = {
  date: string;
  id: number;
  kiloPrice: number;
  naturalMatterFactor: number;
  stockId: number;
};

export type ListDietsResponseItemsItemIngredientsItemStock = {
  changes: ListDietsResponseItemsItemIngredientsItemStockChangesItem[];
  code: string;
  elg: number;
  elm: number;
  farmId: number;
  id: number;
  loadMode: EnumsLoadMode;
  name: string;
  premix: ListDietsResponseItemsItemIngredientsItemStockPremix;
};

export type ListDietsResponseItemsItemIngredientsItem = {
  dietId: number;
  id: number;
  percentage: number;
  stock: ListDietsResponseItemsItemIngredientsItemStock;
  stockId: number;
};

export type ListDietsResponseItemsItemCategory = EnumsDietCategory | null;

export type ListDietsResponseItemsItem = {
  active: boolean;
  category: ListDietsResponseItemsItemCategory;
  createdAt: string;
  dietId?: number;
  elg?: number;
  elm?: number;
  estimatedWeightGain: number;
  expiry: number;
  farmId: number;
  id: number;
  ingredients: ListDietsResponseItemsItemIngredientsItem[];
  kiloPrice?: number;
  name: string;
  naturalMatterFactor?: number;
  observation: ListDietsResponseItemsItemObservation;
  priceMS?: number;
};

export type ListDietTransitionsResponseItemsItem = {
  days: GlobalPrismaJsonDietTransitionDay[];
  divisions: number;
  farmId: number;
  id: number;
  name: string;
};

export interface ListDietTransitionsResponse {
  items: ListDietTransitionsResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type ListDeadOxenResponse = ListDeadOxenResponseItem[];

export type ListDeadOxenResponseItemSisbov = null | number;

export type ListDeadOxenResponseItemPurchaseId = null | number;

export type ListDeadOxenResponseItemPriceAtSign = null | number;

export type ListDeadOxenResponseItemInvoice = null | string;

export type ListDeadOxenResponseItemIdentifier = null | string;

export type ListDeadOxenResponseItemGtaId = null | number;

export type ListDeadOxenResponseItemExitTruckId = null | number;

export type ListDeadOxenResponseItemElectronic = null | string;

export type ListDeadOxenResponseItemDiedAt = string | null;

export type ListDeadOxenResponseItemDeathNecropsy = EnumsDeathNecropsy | null;

export type ListDeadOxenResponseItemDeathDetails = null | string;

export type ListDeadOxenResponseItemDeathCause = EnumsDeathCause | null;

export type ListDeadOxenResponseItemComments = null | string;

export type ListDeadOxenResponseItemCarcassWeight = null | number;

export type ListDeadOxenResponseItemCarcassType = EnumsCarcassType | null;

export type ListDeadOxenResponseItemCarcassThin = null | number;

export type ListDeadOxenResponseItemCarcassPrice = null | number;

export type ListDeadOxenResponseItemCarcassMissing = null | number;

export type ListDeadOxenResponseItemCarcassMedium = null | number;

export type ListDeadOxenResponseItemCarcassExcessive = null | number;

export type ListDeadOxenResponseItemCarcass = null | number;

export type ListDeadOxenResponseItem = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: ListDeadOxenResponseItemCarcass;
  carcassExcessive: ListDeadOxenResponseItemCarcassExcessive;
  carcassMedium: ListDeadOxenResponseItemCarcassMedium;
  carcassMissing: ListDeadOxenResponseItemCarcassMissing;
  carcassPrice: ListDeadOxenResponseItemCarcassPrice;
  carcassThin: ListDeadOxenResponseItemCarcassThin;
  carcassType: ListDeadOxenResponseItemCarcassType;
  carcassWeight: ListDeadOxenResponseItemCarcassWeight;
  comments: ListDeadOxenResponseItemComments;
  deathCause: ListDeadOxenResponseItemDeathCause;
  deathDetails: ListDeadOxenResponseItemDeathDetails;
  deathNecropsy: ListDeadOxenResponseItemDeathNecropsy;
  diedAt: ListDeadOxenResponseItemDiedAt;
  electronic: ListDeadOxenResponseItemElectronic;
  exitTruckId: ListDeadOxenResponseItemExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: ListDeadOxenResponseItemGtaId;
  id: number;
  identifier: ListDeadOxenResponseItemIdentifier;
  includedAt: string;
  invoice: ListDeadOxenResponseItemInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: ListDeadOxenResponseItemPriceAtSign;
  purchaseId: ListDeadOxenResponseItemPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: ListDeadOxenResponseItemSisbov;
  sisbovHistory: number[];
  variety: string;
};

export type ListCorralsWithoutDietResponseItemDietsItemFinalizedAt = string | null;

export type ListCorralsWithoutDietResponseItemDietsItem = {
  batchId: number;
  createdAt: string;
  dietId: number;
  finalizedAt: ListCorralsWithoutDietResponseItemDietsItemFinalizedAt;
  id: number;
};

export type ListCorralsWithoutDietResponseItem = {
  activatedAt: ListCorralsWithoutDietResponseItemActivatedAt;
  corral: ListCorralsWithoutDietResponseItemCorral;
  createdAt: string;
  diets: ListCorralsWithoutDietResponseItemDietsItem[];
  id: number;
  name: string;
};

export type ListCorralsWithoutDietResponse = ListCorralsWithoutDietResponseItem[];

export type ListCorralsWithoutDietResponseItemCorralAnyOf = {
  id: number;
  name: string;
};

export type ListCorralsWithoutDietResponseItemCorral = ListCorralsWithoutDietResponseItemCorralAnyOf | null;

export type ListCorralsWithoutDietResponseItemActivatedAt = string | null;

export type ListCorralsAndBatchesResponseItemBatchAnyOf = {
  id: number;
  name: string;
};

export type ListCorralsAndBatchesResponseItemBatch = ListCorralsAndBatchesResponseItemBatchAnyOf | null;

export type ListCorralsAndBatchesResponseItem = {
  batch: ListCorralsAndBatchesResponseItemBatch;
  id: number;
  name: string;
};

export type ListCorralsAndBatchesResponse = ListCorralsAndBatchesResponseItem[];

export type ListCorralResponseItemBatch = ListCorralResponseItemBatchAnyOf | null;

export type ListCorralResponseItem = {
  area: number;
  batch: ListCorralResponseItemBatch;
  capacity: number;
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
  polygon: GlobalPrismaJsonLatLng[];
  position: number;
  type: EnumsCorralType;
};

export type ListCorralResponse = ListCorralResponseItem[];

export type ListCorralResponseItemBatchAnyOfOtherCost = null | number;

export type ListCorralResponseItemBatchAnyOfOldCorralId = null | number;

export type ListCorralResponseItemBatchAnyOfForeseenWeight = null | number;

export type ListCorralResponseItemBatchAnyOfForeseenCarcass = null | number;

export type ListCorralResponseItemBatchAnyOfFinalizedAt = string | null;

export type ListCorralResponseItemBatchAnyOfExitPrice = null | number;

export type ListCorralResponseItemBatchAnyOfCorralId = null | number;

export type ListCorralResponseItemBatchAnyOfCarcass = null | number;

export type ListCorralResponseItemBatchAnyOfAdmCost = null | number;

export type ListCorralResponseItemBatchAnyOfActivatedAt = string | null;

export type ListCorralResponseItemBatchAnyOf = {
  activatedAt: ListCorralResponseItemBatchAnyOfActivatedAt;
  admCost: ListCorralResponseItemBatchAnyOfAdmCost;
  carcass: ListCorralResponseItemBatchAnyOfCarcass;
  corralId: ListCorralResponseItemBatchAnyOfCorralId;
  createdAt: string;
  exitPrice: ListCorralResponseItemBatchAnyOfExitPrice;
  farmId: number;
  finalizedAt: ListCorralResponseItemBatchAnyOfFinalizedAt;
  foreseenCarcass: ListCorralResponseItemBatchAnyOfForeseenCarcass;
  foreseenWeight: ListCorralResponseItemBatchAnyOfForeseenWeight;
  id: number;
  liveWeight: number;
  name: string;
  oldCorralId: ListCorralResponseItemBatchAnyOfOldCorralId;
  otherCost: ListCorralResponseItemBatchAnyOfOtherCost;
};

export type ListCorralPositionsResponsePositionsItem = {
  id: number;
  name: string;
  position: number;
};

export interface ListCorralPositionsResponse {
  next: number;
  positions: ListCorralPositionsResponsePositionsItem[];
}

export type ListCorralOxenResponse = ListCorralOxenResponseItem[];

export type ListCorralOxenResponseItemSisbov = null | number;

export type ListCorralOxenResponseItemPurchaseId = null | number;

export type ListCorralOxenResponseItemPriceAtSign = null | number;

export type ListCorralOxenResponseItemInvoice = null | string;

export type ListCorralOxenResponseItemIdentifier = null | string;

export type ListCorralOxenResponseItemGtaId = null | number;

export type ListCorralOxenResponseItemExitTruckId = null | number;

export type ListCorralOxenResponseItemElectronic = null | string;

export type ListCorralOxenResponseItemDiedAt = string | null;

export type ListCorralOxenResponseItemDeathNecropsy = EnumsDeathNecropsy | null;

export type ListCorralOxenResponseItemDeathDetails = null | string;

export type ListCorralOxenResponseItemDeathCause = EnumsDeathCause | null;

export type ListCorralOxenResponseItemComments = null | string;

export type ListCorralOxenResponseItemCarcassWeight = null | number;

export type ListCorralOxenResponseItemCarcassType = EnumsCarcassType | null;

export type ListCorralOxenResponseItemCarcassThin = null | number;

export type ListCorralOxenResponseItemCarcassPrice = null | number;

export type ListCorralOxenResponseItemCarcassMissing = null | number;

export type ListCorralOxenResponseItemCarcassMedium = null | number;

export type ListCorralOxenResponseItemCarcassExcessive = null | number;

export type ListCorralOxenResponseItemCarcass = null | number;

export type ListCorralOxenResponseItem = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: ListCorralOxenResponseItemCarcass;
  carcassExcessive: ListCorralOxenResponseItemCarcassExcessive;
  carcassMedium: ListCorralOxenResponseItemCarcassMedium;
  carcassMissing: ListCorralOxenResponseItemCarcassMissing;
  carcassPrice: ListCorralOxenResponseItemCarcassPrice;
  carcassThin: ListCorralOxenResponseItemCarcassThin;
  carcassType: ListCorralOxenResponseItemCarcassType;
  carcassWeight: ListCorralOxenResponseItemCarcassWeight;
  comments: ListCorralOxenResponseItemComments;
  deathCause: ListCorralOxenResponseItemDeathCause;
  deathDetails: ListCorralOxenResponseItemDeathDetails;
  deathNecropsy: ListCorralOxenResponseItemDeathNecropsy;
  diedAt: ListCorralOxenResponseItemDiedAt;
  electronic: ListCorralOxenResponseItemElectronic;
  exitTruckId: ListCorralOxenResponseItemExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: ListCorralOxenResponseItemGtaId;
  id: number;
  identifier: ListCorralOxenResponseItemIdentifier;
  includedAt: string;
  invoice: ListCorralOxenResponseItemInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: ListCorralOxenResponseItemPriceAtSign;
  purchaseId: ListCorralOxenResponseItemPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: ListCorralOxenResponseItemSisbov;
  sisbovHistory: number[];
  variety: string;
};

export interface ListBatchesResponse {
  items: ListBatchesResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type ListBatchesResponseItemsItemOtherCost = null | number;

export type ListBatchesResponseItemsItemOldCorralId = null | number;

export type ListBatchesResponseItemsItemForeseenWeight = null | number;

export type ListBatchesResponseItemsItemForeseenCarcass = null | number;

export type ListBatchesResponseItemsItemFinalizedAt = string | null;

export type ListBatchesResponseItemsItemExitPrice = null | number;

export type ListBatchesResponseItemsItemCorralId = null | number;

export type ListBatchesResponseItemsItemCorralAnyOf = {
  id: number;
  name: string;
};

export type ListBatchesResponseItemsItemCorral = ListBatchesResponseItemsItemCorralAnyOf | null;

export type ListBatchesResponseItemsItemCarcass = null | number;

export type ListBatchesResponseItemsItemAdmCost = null | number;

export type ListBatchesResponseItemsItemActivatedAt = string | null;

export type ListBatchesResponseItemsItem = {
  activatedAt: ListBatchesResponseItemsItemActivatedAt;
  admCost: ListBatchesResponseItemsItemAdmCost;
  carcass: ListBatchesResponseItemsItemCarcass;
  corral: ListBatchesResponseItemsItemCorral;
  corralId: ListBatchesResponseItemsItemCorralId;
  createdAt: string;
  exitPrice: ListBatchesResponseItemsItemExitPrice;
  farmId: number;
  finalizedAt: ListBatchesResponseItemsItemFinalizedAt;
  foreseenCarcass: ListBatchesResponseItemsItemForeseenCarcass;
  foreseenWeight: ListBatchesResponseItemsItemForeseenWeight;
  id: number;
  liveWeight: number;
  name: string;
  oldCorralId: ListBatchesResponseItemsItemOldCorralId;
  otherCost: ListBatchesResponseItemsItemOtherCost;
};

export type ListBatchesInDietTransitionResponseItemFinalDate = string | null;

export type ListBatchesInDietTransitionResponseItem = {
  corral?: string;
  currentDiet?: string;
  currentStep: number;
  finalDate: ListBatchesInDietTransitionResponseItemFinalDate;
  initialDate: string;
  targetDiet: string;
  transition: string;
};

export type ListBatchesInDietTransitionResponse = ListBatchesInDietTransitionResponseItem[];

export interface ListAvailableOxenResponse {
  items: ListAvailableOxenResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type ListAvailableOxenResponseItemsItemSisbov = null | number;

export type ListAvailableOxenResponseItemsItemPurchaseId = null | number;

export type ListAvailableOxenResponseItemsItemPriceAtSign = null | number;

export type ListAvailableOxenResponseItemsItemInvoice = null | string;

export type ListAvailableOxenResponseItemsItemIdentifier = null | string;

export type ListAvailableOxenResponseItemsItemGtaId = null | number;

export type ListAvailableOxenResponseItemsItemExitTruckId = null | number;

export type ListAvailableOxenResponseItemsItemElectronic = null | string;

export type ListAvailableOxenResponseItemsItemDiedAt = string | null;

export type ListAvailableOxenResponseItemsItemDeathNecropsy = EnumsDeathNecropsy | null;

export type ListAvailableOxenResponseItemsItemDeathDetails = null | string;

export type ListAvailableOxenResponseItemsItemDeathCause = EnumsDeathCause | null;

export type ListAvailableOxenResponseItemsItemComments = null | string;

export type ListAvailableOxenResponseItemsItemCarcassWeight = null | number;

export type ListAvailableOxenResponseItemsItemCarcassType = EnumsCarcassType | null;

export type ListAvailableOxenResponseItemsItemCarcassThin = null | number;

export type ListAvailableOxenResponseItemsItemCarcassPrice = null | number;

export type ListAvailableOxenResponseItemsItemCarcassMissing = null | number;

export type ListAvailableOxenResponseItemsItemCarcassMedium = null | number;

export type ListAvailableOxenResponseItemsItemCarcassExcessive = null | number;

export type ListAvailableOxenResponseItemsItemCarcass = null | number;

export type ListAvailableOxenResponseItemsItem = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: ListAvailableOxenResponseItemsItemCarcass;
  carcassExcessive: ListAvailableOxenResponseItemsItemCarcassExcessive;
  carcassMedium: ListAvailableOxenResponseItemsItemCarcassMedium;
  carcassMissing: ListAvailableOxenResponseItemsItemCarcassMissing;
  carcassPrice: ListAvailableOxenResponseItemsItemCarcassPrice;
  carcassThin: ListAvailableOxenResponseItemsItemCarcassThin;
  carcassType: ListAvailableOxenResponseItemsItemCarcassType;
  carcassWeight: ListAvailableOxenResponseItemsItemCarcassWeight;
  comments: ListAvailableOxenResponseItemsItemComments;
  deathCause: ListAvailableOxenResponseItemsItemDeathCause;
  deathDetails: ListAvailableOxenResponseItemsItemDeathDetails;
  deathNecropsy: ListAvailableOxenResponseItemsItemDeathNecropsy;
  diedAt: ListAvailableOxenResponseItemsItemDiedAt;
  electronic: ListAvailableOxenResponseItemsItemElectronic;
  exitTruckId: ListAvailableOxenResponseItemsItemExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: ListAvailableOxenResponseItemsItemGtaId;
  id: number;
  identifier: ListAvailableOxenResponseItemsItemIdentifier;
  includedAt: string;
  invoice: ListAvailableOxenResponseItemsItemInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: ListAvailableOxenResponseItemsItemPriceAtSign;
  purchaseId: ListAvailableOxenResponseItemsItemPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: ListAvailableOxenResponseItemsItemSisbov;
  sisbovHistory: number[];
  variety: string;
};

export type ListAnnouncementsResponseItemsItemEmittedAt = string | null;

export type ListAnnouncementsResponseItemsItem = {
  data: GlobalPrismaJsonAnnouncementData;
  emittedAt: ListAnnouncementsResponseItemsItemEmittedAt;
  farmId: number;
  id: number;
  type: EnumsAnnouncementType;
};

export interface ListAnnouncementsResponse {
  items: ListAnnouncementsResponseItemsItem[];
  next?: number;
  previous?: number;
}

export interface ListAddressesResponse {
  items: ListAddressesResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type ListAddressesResponseItemsItemStreet = null | string;

export type ListAddressesResponseItemsItemNumber = null | string;

export type ListAddressesResponseItemsItemNeighborhood = null | string;

export type ListAddressesResponseItemsItemComplement = null | string;

export type ListAddressesResponseItemsItem = {
  city: string;
  complement: ListAddressesResponseItemsItemComplement;
  country: string;
  id: number;
  neighborhood: ListAddressesResponseItemsItemNeighborhood;
  number: ListAddressesResponseItemsItemNumber;
  state: string;
  street: ListAddressesResponseItemsItemStreet;
  zip: string;
};

export type ListActiveBatchesResponse = ListActiveBatchesResponseItem[];

export type ListActiveBatchesResponseItemCorralAnyOf = {
  area: number;
  capacity: number;
  id: number;
  name: string;
  polygon: GlobalPrismaJsonLatLng[];
};

export type ListActiveBatchesResponseItemCorral = ListActiveBatchesResponseItemCorralAnyOf | null;

export type ListActiveBatchesResponseItem = {
  corral: ListActiveBatchesResponseItemCorral;
  id: number;
  name: string;
};

export type ListActiveAndFinalizedBatchesResponseItem = {
  id: number;
  name: string;
};

export type ListActiveAndFinalizedBatchesResponse = ListActiveAndFinalizedBatchesResponseItem[];

export type LeftoverViewResponseItem = {
  complete: boolean;
  done: number;
  time: string;
  total: number;
};

export type LeftoverViewResponse = LeftoverViewResponseItem[];

export type KillOxResponseSisbov = null | number;

export type KillOxResponsePurchaseId = null | number;

export type KillOxResponsePriceAtSign = null | number;

export type KillOxResponseInvoice = null | string;

export type KillOxResponseIdentifier = null | string;

export type KillOxResponseGtaId = null | number;

export type KillOxResponseExitTruckId = null | number;

export type KillOxResponseElectronic = null | string;

export type KillOxResponseDiedAt = string | null;

export type KillOxResponseDeathNecropsy = EnumsDeathNecropsy | null;

export type KillOxResponseDeathDetails = null | string;

export type KillOxResponseDeathCause = EnumsDeathCause | null;

export type KillOxResponseComments = null | string;

export type KillOxResponseCarcassWeight = null | number;

export type KillOxResponseCarcassType = EnumsCarcassType | null;

export type KillOxResponseCarcassThin = null | number;

export type KillOxResponseCarcassPrice = null | number;

export type KillOxResponseCarcassMissing = null | number;

export type KillOxResponseCarcassMedium = null | number;

export type KillOxResponseCarcassExcessive = null | number;

export type KillOxResponseCarcass = null | number;

export interface KillOxResponse {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: KillOxResponseCarcass;
  carcassExcessive: KillOxResponseCarcassExcessive;
  carcassMedium: KillOxResponseCarcassMedium;
  carcassMissing: KillOxResponseCarcassMissing;
  carcassPrice: KillOxResponseCarcassPrice;
  carcassThin: KillOxResponseCarcassThin;
  carcassType: KillOxResponseCarcassType;
  carcassWeight: KillOxResponseCarcassWeight;
  comments: KillOxResponseComments;
  deathCause: KillOxResponseDeathCause;
  deathDetails: KillOxResponseDeathDetails;
  deathNecropsy: KillOxResponseDeathNecropsy;
  diedAt: KillOxResponseDiedAt;
  electronic: KillOxResponseElectronic;
  exitTruckId: KillOxResponseExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: KillOxResponseGtaId;
  id: number;
  identifier: KillOxResponseIdentifier;
  includedAt: string;
  invoice: KillOxResponseInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: KillOxResponsePriceAtSign;
  purchaseId: KillOxResponsePurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: KillOxResponseSisbov;
  sisbovHistory: number[];
  variety: string;
}

export interface KillOx {
  cause: EnumsDeathCause;
  diedAt: string;
  necropsy?: EnumsDeathNecropsy;
  observation?: string;
  /** Peso do boi na hora da morte, caso calculado. */
  weight?: number;
}

export type KillBatchResponse = KillBatchResponseItem[];

export type KillBatchResponseItemSisbov = null | number;

export type KillBatchResponseItemPurchaseId = null | number;

export type KillBatchResponseItemPriceAtSign = null | number;

export type KillBatchResponseItemInvoice = null | string;

export type KillBatchResponseItemIdentifier = null | string;

export type KillBatchResponseItemGtaId = null | number;

export type KillBatchResponseItemExitTruckId = null | number;

export type KillBatchResponseItemElectronic = null | string;

export type KillBatchResponseItemDiedAt = string | null;

export type KillBatchResponseItemDeathNecropsy = EnumsDeathNecropsy | null;

export type KillBatchResponseItemDeathDetails = null | string;

export type KillBatchResponseItemDeathCause = EnumsDeathCause | null;

export type KillBatchResponseItemComments = null | string;

export type KillBatchResponseItemCarcassWeight = null | number;

export type KillBatchResponseItemCarcassType = EnumsCarcassType | null;

export type KillBatchResponseItemCarcassThin = null | number;

export type KillBatchResponseItemCarcassPrice = null | number;

export type KillBatchResponseItemCarcassMissing = null | number;

export type KillBatchResponseItemCarcassMedium = null | number;

export type KillBatchResponseItemCarcassExcessive = null | number;

export type KillBatchResponseItemCarcass = null | number;

export type KillBatchResponseItem = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: KillBatchResponseItemCarcass;
  carcassExcessive: KillBatchResponseItemCarcassExcessive;
  carcassMedium: KillBatchResponseItemCarcassMedium;
  carcassMissing: KillBatchResponseItemCarcassMissing;
  carcassPrice: KillBatchResponseItemCarcassPrice;
  carcassThin: KillBatchResponseItemCarcassThin;
  carcassType: KillBatchResponseItemCarcassType;
  carcassWeight: KillBatchResponseItemCarcassWeight;
  comments: KillBatchResponseItemComments;
  deathCause: KillBatchResponseItemDeathCause;
  deathDetails: KillBatchResponseItemDeathDetails;
  deathNecropsy: KillBatchResponseItemDeathNecropsy;
  diedAt: KillBatchResponseItemDiedAt;
  electronic: KillBatchResponseItemElectronic;
  exitTruckId: KillBatchResponseItemExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: KillBatchResponseItemGtaId;
  id: number;
  identifier: KillBatchResponseItemIdentifier;
  includedAt: string;
  invoice: KillBatchResponseItemInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: KillBatchResponseItemPriceAtSign;
  purchaseId: KillBatchResponseItemPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: KillBatchResponseItemSisbov;
  sisbovHistory: number[];
  variety: string;
};

export interface Ingredient {
  percentage: number;
  stockId: number;
}

export type IndividualCattleClosingReturnSisbov = null | number;

/** DATA DE SAÍDA conf */
export type IndividualCattleClosingReturnExitDate = string | null;

export type IndividualCattleClosingReturnDeathCauseAnyOf = { [key: string]: any };

/** Causa da morte conf */
export type IndividualCattleClosingReturnDeathCause = 'SALE' | IndividualCattleClosingReturnDeathCauseAnyOf;

export interface IndividualCattleClosingReturn {
  /** Linha núcleo Adaptação */
  adaptationCoreLine: string;
  /** CUSTO DE AQUISIÇÃO (R$/CAB) */
  animalEntryCost: number;
  /** CUSTO ARROBA COMPRA (R$/CAB) */
  atSignCost: number;
  atSignProduced: number;
  /** CUSTO ARROBA PRODUZIDA (R$/@ prod) conf */
  atSignProducedCost: number;
  /** EFICIÊNCIA BIOLÓGICA (kg de MS/@ Prod) */
  biologicEfficiency: number;
  /** Comprador */
  buyer?: string;
  /** GANHO DIARIO DE CARCAÇA (KG) */
  carcassDailyGain: number;
  /** CATEGORIA */
  category: EnumsOxGender;
  /** Cidade */
  city?: string;
  /** Cliente */
  client: string;
  /** Comissão Compra (R$/CAB) */
  commissionCost: number;
  /** DIAS CONF (CAB) */
  confinementDays: number;
  /** Linha núcleo Terminação */
  coreLineTermination: string;
  /** CONSUMO MN (KG/CAB/DIA) */
  dailyMatterConsume: number;
  /** CONSUMO MS (KG/CAB/DIA) */
  dailyNaturalMatterConsume: number;
  /** Causa da morte conf */
  deathCause: IndividualCattleClosingReturnDeathCause;
  /** FRIGORÍFICO DESTINO */
  destiny?: string;
  /** DIFERENÇA DE DIÁRIAS (CAB) */
  diffDays: number;
  /** Enfermidades */
  diseases: EnumsDisease[];
  /** Km rodado da origem */
  distance?: number;
  /** CONSUMO Elg (mcal/CAB/PERÍODO) */
  elgConsume: number;
  /** CONSUMO Elg (mcal/CAB/DIA) */
  elgConsumePerOx: number;
  /** LOTE Entrada confinamento */
  entryBatch: string;
  /** CV do peso de entrada do lote */
  entryBatchCov: number;
  /** PESO ENTRADA conf (ARROBA) */
  entryCarcassAtSign: number;
  /** PESO ENTRADA conf (CARCAÇA) (kg) */
  entryCarcassWeight: number;
  /** RENDIMENTO CARCAÇA (ENTRADA) conf (%) */
  entryCarcassYield: number;
  /** CUSTO DE ENTRADA ANIMAL (R$/CAB) */
  entryCostPerOx: number;
  /** DATA DE ENTRADA conf */
  entryDate: string;
  /** PESO ENTRADA Confinamento (KG) */
  entryWeight: number;
  /** PESO SAÍDA (ARROBA) */
  exitCarcassAtSign: number;
  /** PESO SAÍDA (CARCAÇA) (kg) */
  exitCarcassWeight: number;
  /** RENDIMENTO CARCAÇA (%) saida conf */
  exitCarcassYield: number;
  /** DATA DE SAÍDA conf */
  exitDate: IndividualCattleClosingReturnExitDate;
  /** PESO SAÍDA conf (KG) */
  exitWeight: number;
  /** CONVERSÃO ALIMENTAR (Kg de MS/kgPV) */
  feedConversion: number;
  /** EFICIÊNCIA ALIMENTAR (GPD/Cons MS dia) */
  feedEfficiency: number;
  /** CUSTO FIXO (R$/CAB/PERÍODO) */
  fixedCost: number;
  /** PERCENTUAL CUSTO FIXO NO CUSTO TOTAL (%) */
  fixedCostPercentageOfTotalCost: number;
  /** CUSTO ALIMENTAR (R$/CAB/DIA) */
  foodCost: number;
  /** PERCENTUAL CUSTO ALIMENTAR NO CUSTO TOTAL (%) */
  foodCostPercentageOfTotalCost: number;
  /** Margem líquida "Dentro da porteira" (R$/cabeça) */
  gateIn: number;
  /** Margem líquida "Fora da porteira" (R$/cabeça) */
  gateOut: number;
  /** PERCENTUAL CUSTO SANIDADE NO CUSTO TOTAL (%) */
  healthCostPercentageOfTotalCost: number;
  /** IMS */
  ims: number;
  /** COD. Manejo */
  managementCode?: string;
  /** Protocolo sanitário */
  medines: string[];
  /** RENTABILIDADE (MÊS) (%) */
  monthlyProfitabilityPercentage: number;
  /** LUCRO LÍQUIDO (R$/CAB/PERÍODO) */
  netProfit: number;
  /** CUSTO OPERACIONAL (R$/CAB/DIA) */
  operacional: number;
  /** OUTROS CUSTOS (R$/CAB/DIA) */
  otherCost: number;
  /** OUTROS CUSTOS (R$/CAB/DIA) conf */
  otherCostConf: number;
  /** PERCENTUAL OUTROS CUSTOS NO CUSTO TOTAL (%) */
  otherCostsPercentageOfTotalCost: number;
  /** Proprietário */
  owner?: string;
  /** PERCENTUAL CUSTO COMPRA ANIM NO CUSTO TOTAL (%) */
  percentageCostAnimalPurchaseInTotalCost: number;
  /** RENTABILIDADE (PERÍODO) (%) */
  periodProfitabilityPercentage: number;
  /** DIAS PRÉ-CONF (CAB) */
  preConfinementDays: number;
  /** CUSTO PRODUÇÃO (R$/CAB/DIA) conf */
  productionCost: number;
  /** Sanidade tratamento aplicado */
  protocols: string[];
  /** Peso médio do pedido de compra */
  purchaseWeightAverage?: number;
  /** Tipo de Cadastro */
  purpose?: EnumsOxPurpose;
  /** RECEITA (R$/CAB) */
  recipe: number;
  /** VALOR DE VENDA (R$/@) */
  saleAtSignCost: number;
  /** CUSTO DE FRETE + COMISSÃO (R$/CAB) */
  shipping: number;
  /** CUSTO FRETE (R$/CAB) */
  shippingCost: number;
  sisbov?: IndividualCattleClosingReturnSisbov;
  /** Estado */
  state?: string;
  /** CUSTO TOTAL DE AQUISIÇÃO (R$/CAB) */
  totalAnimalCost: number;
  /** CUSTO TOTAL (R$/CAB/PERÍODO) conf */
  totalCost: number;
  /** CUSTO TOTAL DO ANIMAL (R$/CAB) */
  totalEntryCost: number;
  /** CUSTO ALIMENTAR (R$/CAB/PERÍODO) */
  totalFoodCost: number;
  /** CONSUMO MN TOTAL (KG/CAB/PERÍODO) */
  totalMatterConsume: number;
  /** CONSUMO MS TOTAL (KG/CAB/PERÍODO) */
  totalNaturalMatterConsume: number;
  /** OUTROS CUSTOS (R$/CAB/PERÍODO) conf */
  totalOtherCostConf: number;
  /** CUSTO PRODUÇÃO (R$/CAB/PERÍODO) conf */
  totalProductionCost: number;
  /** CUSTO SANIDADE (R$/CAB/PERÍODO) conf */
  totalVeterinarySuplies: number;
  /** DIÁRIAS ALIMENTAR (TOTAL) */
  treatmentDays: number;
  /** RAÇA */
  variety: string;
  /** CUSTO SANIDADE (R$/CAB/DIA) conf */
  veterinarySuplies: number;
  /** Quebra de peso (kg) */
  weightBreak: number;
  /** Quebra de peso (%) */
  weightBreakPercentage: number;
  /** GANHO DE PESO DIA (KG) */
  weightDailyGain: number;
}

export type IndividualCattleClosingReportResponse = IndividualCattleClosingReturn[];

export interface IndividualCattleClosingFilter {
  batchId?: number;
  end?: string;
  start?: string;
}

export interface IdentificationOx {
  ageInMonths: number;
  /** O batch que o boi vai começar */
  batchId: number;
  /** Dados do brinco eletrônico */
  electronic?: string;
  /** Id do protocolo de saúde */
  extraMedicineIds?: number[];
  /** Id do protocolo de saúde */
  healthProtocolId?: number;
  identifier?: string;
  sisbov?: number;
  weight: number;
}

export interface HttpError {
  code?: string;
  error?: string;
  message?: string;
  statusCode?: number;
}

export interface HealthCheckResponse {
  database: string;
  environment: string;
  sentry: string;
  status: string;
  uptime: number;
  version: string;
}

export interface GtaFilter {
  completed?: boolean;
  limit?: number;
  page?: number;
  withoutPurchases?: boolean;
}

export type GradeMethod = EnumsGradeMethod;

export type GetZootechnicalIndicesReportXlsxResponse = unknown;

export type GetUnusabilityAnnouncementResponseUnusabilitiesItem = {
  brand: string;
  observation?: string;
  sisbov: number;
};

export interface GetUnusabilityAnnouncementResponse {
  origin: GetUnusabilityAnnouncementResponseOrigin;
  unusabilities: GetUnusabilityAnnouncementResponseUnusabilitiesItem[];
}

export type GetUnusabilityAnnouncementResponseOriginPropertyCodeItem = string | unknown;

export type GetUnusabilityAnnouncementResponseOriginOwnerItemDocument = null | string;

export type GetUnusabilityAnnouncementResponseOriginOwnerItem = {
  document: GetUnusabilityAnnouncementResponseOriginOwnerItemDocument;
  name: string;
};

export type GetUnusabilityAnnouncementResponseOriginAddress = {
  city: string;
  state: string;
};

export type GetUnusabilityAnnouncementResponseOrigin = {
  address: GetUnusabilityAnnouncementResponseOriginAddress;
  id: number;
  name: string;
  owner: GetUnusabilityAnnouncementResponseOriginOwnerItem[];
  propertyCode: GetUnusabilityAnnouncementResponseOriginPropertyCodeItem[];
};

export interface GetTuckDetailsResponse {
  covWeight: number;
  gmd: number;
  totalBoarded: number;
  truck: GetTuckDetailsResponseTruck;
  weightAverage: number;
}

export type GetTuckDetailsResponseTruckSaleId = null | number;

export type GetTuckDetailsResponseTruckPlate = null | string;

export type GetTuckDetailsResponseTruckName = null | string;

export type GetTuckDetailsResponseTruckExitGta = null | string;

export type GetTuckDetailsResponseTruckDestinyId = null | number;

export type GetTuckDetailsResponseTruck = {
  announcementId: GetTuckDetailsResponseTruckAnnouncementId;
  boarding: GetTuckDetailsResponseTruckBoardingItem[];
  capacity: number;
  destinyId: GetTuckDetailsResponseTruckDestinyId;
  exitAt: string;
  exitGta: GetTuckDetailsResponseTruckExitGta;
  farmId: number;
  id: number;
  name: GetTuckDetailsResponseTruckName;
  plate: GetTuckDetailsResponseTruckPlate;
  reason: EnumsExitReason;
  saleId: GetTuckDetailsResponseTruckSaleId;
  status: EnumsExitTruckStatus;
};

export type GetTuckDetailsResponseTruckBoardingItemWeightsItem = {
  date: string;
  id: number;
  oxId: number;
  source: EnumsWeightSource;
  weight: number;
};

export type GetTuckDetailsResponseTruckBoardingItemSisbov = null | number;

export type GetTuckDetailsResponseTruckBoardingItemPurchaseId = null | number;

export type GetTuckDetailsResponseTruckBoardingItemPriceAtSign = null | number;

export type GetTuckDetailsResponseTruckBoardingItemInvoice = null | string;

export type GetTuckDetailsResponseTruckBoardingItemIdentifier = null | string;

export type GetTuckDetailsResponseTruckBoardingItemGtaId = null | number;

export type GetTuckDetailsResponseTruckBoardingItemExitTruckId = null | number;

export type GetTuckDetailsResponseTruckBoardingItemElectronic = null | string;

export type GetTuckDetailsResponseTruckBoardingItemDiedAt = string | null;

export type GetTuckDetailsResponseTruckBoardingItemDeathNecropsy = EnumsDeathNecropsy | null;

export type GetTuckDetailsResponseTruckBoardingItemDeathDetails = null | string;

export type GetTuckDetailsResponseTruckBoardingItemDeathCause = EnumsDeathCause | null;

export type GetTuckDetailsResponseTruckBoardingItemComments = null | string;

export type GetTuckDetailsResponseTruckBoardingItemCarcassWeight = null | number;

export type GetTuckDetailsResponseTruckBoardingItemCarcassType = EnumsCarcassType | null;

export type GetTuckDetailsResponseTruckBoardingItemCarcassThin = null | number;

export type GetTuckDetailsResponseTruckBoardingItemCarcassPrice = null | number;

export type GetTuckDetailsResponseTruckBoardingItemCarcassMissing = null | number;

export type GetTuckDetailsResponseTruckBoardingItemCarcassMedium = null | number;

export type GetTuckDetailsResponseTruckBoardingItemCarcassExcessive = null | number;

export type GetTuckDetailsResponseTruckBoardingItemCarcass = null | number;

export type GetTuckDetailsResponseTruckBoardingItem = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: GetTuckDetailsResponseTruckBoardingItemCarcass;
  carcassExcessive: GetTuckDetailsResponseTruckBoardingItemCarcassExcessive;
  carcassMedium: GetTuckDetailsResponseTruckBoardingItemCarcassMedium;
  carcassMissing: GetTuckDetailsResponseTruckBoardingItemCarcassMissing;
  carcassPrice: GetTuckDetailsResponseTruckBoardingItemCarcassPrice;
  carcassThin: GetTuckDetailsResponseTruckBoardingItemCarcassThin;
  carcassType: GetTuckDetailsResponseTruckBoardingItemCarcassType;
  carcassWeight: GetTuckDetailsResponseTruckBoardingItemCarcassWeight;
  comments: GetTuckDetailsResponseTruckBoardingItemComments;
  deathCause: GetTuckDetailsResponseTruckBoardingItemDeathCause;
  deathDetails: GetTuckDetailsResponseTruckBoardingItemDeathDetails;
  deathNecropsy: GetTuckDetailsResponseTruckBoardingItemDeathNecropsy;
  diedAt: GetTuckDetailsResponseTruckBoardingItemDiedAt;
  electronic: GetTuckDetailsResponseTruckBoardingItemElectronic;
  exitTruckId: GetTuckDetailsResponseTruckBoardingItemExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: GetTuckDetailsResponseTruckBoardingItemGtaId;
  id: number;
  identifier: GetTuckDetailsResponseTruckBoardingItemIdentifier;
  includedAt: string;
  invoice: GetTuckDetailsResponseTruckBoardingItemInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: GetTuckDetailsResponseTruckBoardingItemPriceAtSign;
  purchaseId: GetTuckDetailsResponseTruckBoardingItemPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: GetTuckDetailsResponseTruckBoardingItemSisbov;
  sisbovHistory: number[];
  variety: string;
  weights: GetTuckDetailsResponseTruckBoardingItemWeightsItem[];
};

export type GetTuckDetailsResponseTruckAnnouncementId = null | number;

export type GetStockResponseAnyOf = {
  changes: GetStockResponseAnyOfChangesItem[];
  code: string;
  elg: number;
  elm: number;
  farmId: number;
  id: number;
  kiloPrice: number;
  loadMode: EnumsLoadMode;
  name: string;
  naturalMatter: number;
  naturalMatterFactor: number;
  premix: GetStockResponseAnyOfPremix;
  premixIngredients: GetStockResponseAnyOfPremixIngredientsItem[];
};

export type GetStockResponse = GetStockResponseAnyOf | unknown;

export type GetStockResponseAnyOfPremixIngredientsItemStockPremix = null | boolean;

export type GetStockResponseAnyOfPremixIngredientsItemStock = {
  code: string;
  elg: number;
  elm: number;
  farmId: number;
  id: number;
  loadMode: EnumsLoadMode;
  name: string;
  premix: GetStockResponseAnyOfPremixIngredientsItemStockPremix;
};

export type GetStockResponseAnyOfPremixIngredientsItem = {
  id: number;
  percentage: number;
  premixId: number;
  stock: GetStockResponseAnyOfPremixIngredientsItemStock;
  stockId: number;
};

export type GetStockResponseAnyOfPremix = null | boolean;

export type GetStockResponseAnyOfChangesItem = {
  date: string;
  kiloPrice: number;
  naturalMatterFactor: number;
};

export interface GetStockAverageInfoResponse {
  elg: number;
  elm: number;
  kiloPrice: number;
  naturalMatterFactor: number;
}

export type GetSaleManagementResponseSaleTrucksItemSaleId = null | number;

export type GetSaleManagementResponseSaleTrucksItemPlate = null | string;

export type GetSaleManagementResponseSaleTrucksItemName = null | string;

export type GetSaleManagementResponseSaleTrucksItemExitGta = null | string;

export type GetSaleManagementResponseSaleTrucksItemDestinyId = null | number;

export type GetSaleManagementResponseSaleTrucksItem = {
  announcementId: GetSaleManagementResponseSaleTrucksItemAnnouncementId;
  boarding: GetSaleManagementResponseSaleTrucksItemBoardingItem[];
  capacity: number;
  destiny: GetSaleManagementResponseSaleTrucksItemDestiny;
  destinyId: GetSaleManagementResponseSaleTrucksItemDestinyId;
  exitAt: string;
  exitGta: GetSaleManagementResponseSaleTrucksItemExitGta;
  farmId: number;
  id: number;
  name: GetSaleManagementResponseSaleTrucksItemName;
  plate: GetSaleManagementResponseSaleTrucksItemPlate;
  reason: EnumsExitReason;
  saleId: GetSaleManagementResponseSaleTrucksItemSaleId;
  status: EnumsExitTruckStatus;
};

export type GetSaleManagementResponseSale = {
  announcementId: GetSaleManagementResponseSaleAnnouncementId;
  closed: boolean;
  date: string;
  farmId: number;
  id: number;
  ownerId: number;
  trucks: GetSaleManagementResponseSaleTrucksItem[];
};

export interface GetSaleManagementResponse {
  batches: GetSaleManagementResponseBatchesItem[];
  sale: GetSaleManagementResponseSale;
  weightAverage: number;
}

export type GetSaleManagementResponseSaleTrucksItemDestinyAnyOf = {
  name: string;
};

export type GetSaleManagementResponseSaleTrucksItemDestiny = GetSaleManagementResponseSaleTrucksItemDestinyAnyOf | null;

export type GetSaleManagementResponseSaleTrucksItemBoardingItemWeightsItem = {
  date: string;
  id: number;
  oxId: number;
  source: EnumsWeightSource;
  weight: number;
};

export type GetSaleManagementResponseSaleTrucksItemBoardingItemSisbov = null | number;

export type GetSaleManagementResponseSaleTrucksItemBoardingItem = {
  id: number;
  sisbov: GetSaleManagementResponseSaleTrucksItemBoardingItemSisbov;
  weights: GetSaleManagementResponseSaleTrucksItemBoardingItemWeightsItem[];
};

export type GetSaleManagementResponseSaleTrucksItemAnnouncementId = null | number;

export type GetSaleManagementResponseSaleAnnouncementId = null | number;

export type GetSaleManagementResponseBatchesItemOtherCost = null | number;

export type GetSaleManagementResponseBatchesItemOldCorralId = null | number;

export type GetSaleManagementResponseBatchesItemForeseenWeight = null | number;

export type GetSaleManagementResponseBatchesItemForeseenCarcass = null | number;

export type GetSaleManagementResponseBatchesItemFinalizedAt = string | null;

export type GetSaleManagementResponseBatchesItemExitPrice = null | number;

export type GetSaleManagementResponseBatchesItemCorralId = null | number;

export type GetSaleManagementResponseBatchesItemCorralAnyOf = {
  area: number;
  capacity: number;
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
  polygon: GlobalPrismaJsonLatLng[];
  position: number;
  type: EnumsCorralType;
};

export type GetSaleManagementResponseBatchesItemCorral = GetSaleManagementResponseBatchesItemCorralAnyOf | null;

export type GetSaleManagementResponseBatchesItemCarcass = null | number;

export type GetSaleManagementResponseBatchesItemAdmCost = null | number;

export type GetSaleManagementResponseBatchesItemActivatedAt = string | null;

export type GetSaleManagementResponseBatchesItem = {
  activatedAt: GetSaleManagementResponseBatchesItemActivatedAt;
  admCost: GetSaleManagementResponseBatchesItemAdmCost;
  carcass: GetSaleManagementResponseBatchesItemCarcass;
  corral: GetSaleManagementResponseBatchesItemCorral;
  corralId: GetSaleManagementResponseBatchesItemCorralId;
  createdAt: string;
  exitPrice: GetSaleManagementResponseBatchesItemExitPrice;
  farmId: number;
  finalizedAt: GetSaleManagementResponseBatchesItemFinalizedAt;
  foreseenCarcass: GetSaleManagementResponseBatchesItemForeseenCarcass;
  foreseenWeight: GetSaleManagementResponseBatchesItemForeseenWeight;
  id: number;
  liveWeight: number;
  name: string;
  oldCorralId: GetSaleManagementResponseBatchesItemOldCorralId;
  otherCost: GetSaleManagementResponseBatchesItemOtherCost;
};

export type GetSaleAnnouncementResponseTrucksItemPlate = null | string;

export type GetSaleAnnouncementResponseTrucksItem = {
  exitGta: GetSaleAnnouncementResponseTrucksItemExitGta;
  id: number;
  name: GetSaleAnnouncementResponseTrucksItemName;
  oxen: GetSaleAnnouncementResponseTrucksItemOxenItem[];
  plate: GetSaleAnnouncementResponseTrucksItemPlate;
};

export interface GetSaleAnnouncementResponse {
  destiny: GetSaleAnnouncementResponseDestiny;
  origin: GetSaleAnnouncementResponseOrigin;
  trucks: GetSaleAnnouncementResponseTrucksItem[];
}

export type GetSaleAnnouncementResponseTrucksItemOxenItemSisbov = null | number;

export type GetSaleAnnouncementResponseTrucksItemOxenItemPurchaseId = null | number;

export type GetSaleAnnouncementResponseTrucksItemOxenItemPriceAtSign = null | number;

export type GetSaleAnnouncementResponseTrucksItemOxenItemOwnerDocument = null | string;

export type GetSaleAnnouncementResponseTrucksItemOxenItemOwner = {
  document: GetSaleAnnouncementResponseTrucksItemOxenItemOwnerDocument;
  name: string;
};

export type GetSaleAnnouncementResponseTrucksItemOxenItemInvoice = null | string;

export type GetSaleAnnouncementResponseTrucksItemOxenItemIdentifier = null | string;

export type GetSaleAnnouncementResponseTrucksItemOxenItemGtaId = null | number;

export type GetSaleAnnouncementResponseTrucksItemOxenItemExitTruckId = null | number;

export type GetSaleAnnouncementResponseTrucksItemOxenItemElectronic = null | string;

export type GetSaleAnnouncementResponseTrucksItemOxenItemDiedAt = string | null;

export type GetSaleAnnouncementResponseTrucksItemOxenItemDeathNecropsy = EnumsDeathNecropsy | null;

export type GetSaleAnnouncementResponseTrucksItemOxenItemDeathDetails = null | string;

export type GetSaleAnnouncementResponseTrucksItemOxenItemDeathCause = EnumsDeathCause | null;

export type GetSaleAnnouncementResponseTrucksItemOxenItemComments = null | string;

export type GetSaleAnnouncementResponseTrucksItemOxenItemCarcassWeight = null | number;

export type GetSaleAnnouncementResponseTrucksItemOxenItemCarcassType = EnumsCarcassType | null;

export type GetSaleAnnouncementResponseTrucksItemOxenItemCarcassThin = null | number;

export type GetSaleAnnouncementResponseTrucksItemOxenItemCarcassPrice = null | number;

export type GetSaleAnnouncementResponseTrucksItemOxenItemCarcassMissing = null | number;

export type GetSaleAnnouncementResponseTrucksItemOxenItemCarcassMedium = null | number;

export type GetSaleAnnouncementResponseTrucksItemOxenItemCarcassExcessive = null | number;

export type GetSaleAnnouncementResponseTrucksItemOxenItemCarcass = null | number;

export type GetSaleAnnouncementResponseTrucksItemOxenItem = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: GetSaleAnnouncementResponseTrucksItemOxenItemCarcass;
  carcassExcessive: GetSaleAnnouncementResponseTrucksItemOxenItemCarcassExcessive;
  carcassMedium: GetSaleAnnouncementResponseTrucksItemOxenItemCarcassMedium;
  carcassMissing: GetSaleAnnouncementResponseTrucksItemOxenItemCarcassMissing;
  carcassPrice: GetSaleAnnouncementResponseTrucksItemOxenItemCarcassPrice;
  carcassThin: GetSaleAnnouncementResponseTrucksItemOxenItemCarcassThin;
  carcassType: GetSaleAnnouncementResponseTrucksItemOxenItemCarcassType;
  carcassWeight: GetSaleAnnouncementResponseTrucksItemOxenItemCarcassWeight;
  comments: GetSaleAnnouncementResponseTrucksItemOxenItemComments;
  deathCause: GetSaleAnnouncementResponseTrucksItemOxenItemDeathCause;
  deathDetails: GetSaleAnnouncementResponseTrucksItemOxenItemDeathDetails;
  deathNecropsy: GetSaleAnnouncementResponseTrucksItemOxenItemDeathNecropsy;
  diedAt: GetSaleAnnouncementResponseTrucksItemOxenItemDiedAt;
  electronic: GetSaleAnnouncementResponseTrucksItemOxenItemElectronic;
  exitTruckId: GetSaleAnnouncementResponseTrucksItemOxenItemExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: GetSaleAnnouncementResponseTrucksItemOxenItemGtaId;
  id: number;
  identifier: GetSaleAnnouncementResponseTrucksItemOxenItemIdentifier;
  includedAt: string;
  invoice: GetSaleAnnouncementResponseTrucksItemOxenItemInvoice;
  owner: GetSaleAnnouncementResponseTrucksItemOxenItemOwner;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: GetSaleAnnouncementResponseTrucksItemOxenItemPriceAtSign;
  purchaseId: GetSaleAnnouncementResponseTrucksItemOxenItemPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: GetSaleAnnouncementResponseTrucksItemOxenItemSisbov;
  sisbovHistory: number[];
  variety: string;
};

export type GetSaleAnnouncementResponseTrucksItemName = null | string;

export type GetSaleAnnouncementResponseTrucksItemExitGta = null | string;

export type GetSaleAnnouncementResponseOriginOwnerItemDocument = null | string;

export type GetSaleAnnouncementResponseOriginOwnerItem = {
  document: GetSaleAnnouncementResponseOriginOwnerItemDocument;
  name: string;
};

export type GetSaleAnnouncementResponseOriginAddress = {
  city: string;
  state: string;
};

export type GetSaleAnnouncementResponseOrigin = {
  address: GetSaleAnnouncementResponseOriginAddress;
  bndCode: number;
  id: number;
  name: string;
  owner: GetSaleAnnouncementResponseOriginOwnerItem[];
};

export type GetSaleAnnouncementResponseDestinyPropertyCode = null | string;

export type GetSaleAnnouncementResponseDestinyOwnerPhone = null | string;

export type GetSaleAnnouncementResponseDestinyOwnerDocument = null | string;

export type GetSaleAnnouncementResponseDestinyOwnerDescription = null | string;

export type GetSaleAnnouncementResponseDestinyOwner = {
  createdAt: string;
  description: GetSaleAnnouncementResponseDestinyOwnerDescription;
  document: GetSaleAnnouncementResponseDestinyOwnerDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: GetSaleAnnouncementResponseDestinyOwnerPhone;
  profiles: EnumsProfile[];
};

export type GetSaleAnnouncementResponseDestinyAddress = {
  city: string;
  state: string;
};

export type GetSaleAnnouncementResponseDestiny = {
  address: GetSaleAnnouncementResponseDestinyAddress;
  id: number;
  name: string;
  owner: GetSaleAnnouncementResponseDestinyOwner;
  propertyCode: GetSaleAnnouncementResponseDestinyPropertyCode;
};

export type GetReIdentificationAnnouncementResponseReIdentificationsItem = {
  age: number;
  birth: string;
  earringBrand?: string;
  newSisbov: number;
  oldSisbov: number;
  oxId: number;
};

export type GetReIdentificationAnnouncementResponseOwnerItemDocument = null | string;

export type GetReIdentificationAnnouncementResponseOwnerItem = {
  document: GetReIdentificationAnnouncementResponseOwnerItemDocument;
  name: string;
};

export type GetReIdentificationAnnouncementResponseFarm = {
  id: number;
  name: string;
};

export type GetReIdentificationAnnouncementResponseAddress = {
  city: string;
  state: string;
};

export interface GetReIdentificationAnnouncementResponse {
  address: GetReIdentificationAnnouncementResponseAddress;
  farm: GetReIdentificationAnnouncementResponseFarm;
  id: number;
  owner: GetReIdentificationAnnouncementResponseOwnerItem[];
  reIdentifications: GetReIdentificationAnnouncementResponseReIdentificationsItem[];
}

export type GetPurchaseResponseStock = GetPurchaseResponseStockAnyOf | null;

export interface GetPurchaseResponse {
  category: EnumsPurchaseCategory;
  earringsPerPage: GetPurchaseResponseEarringsPerPage;
  farmId: number;
  id: number;
  invoice: string;
  issuedAt: string;
  name: string;
  observation: GetPurchaseResponseObservation;
  oxen: GetPurchaseResponseOxen;
  price: number;
  receivedAt: GetPurchaseResponseReceivedAt;
  series: string;
  sisbov: GetPurchaseResponseSisbov;
  status: EnumsPurchaseStatus;
  stock: GetPurchaseResponseStock;
}

export type GetPurchaseResponseStockAnyOfStockPremix = null | boolean;

export type GetPurchaseResponseStockAnyOfStock = {
  code: string;
  elg: number;
  elm: number;
  farmId: number;
  id: number;
  loadMode: EnumsLoadMode;
  name: string;
  premix: GetPurchaseResponseStockAnyOfStockPremix;
};

export type GetPurchaseResponseStockAnyOf = {
  amount: number;
  id: number;
  purchaseId: number;
  stock: GetPurchaseResponseStockAnyOfStock;
  stockId: number;
};

export type GetPurchaseResponseSisbovAnyOf = {
  brand: string;
  cancelled: number[];
  finalSisbov: number;
  id: number;
  initialSisbov: number;
  owner: GetPurchaseResponseSisbovAnyOfOwner;
  ownerId: number;
  purchaseId: number;
};

export type GetPurchaseResponseSisbov = GetPurchaseResponseSisbovAnyOf | null;

export type GetPurchaseResponseSisbovAnyOfOwnerPhone = null | string;

export type GetPurchaseResponseSisbovAnyOfOwnerDocument = null | string;

export type GetPurchaseResponseSisbovAnyOfOwnerDescription = null | string;

export type GetPurchaseResponseSisbovAnyOfOwner = {
  createdAt: string;
  description: GetPurchaseResponseSisbovAnyOfOwnerDescription;
  document: GetPurchaseResponseSisbovAnyOfOwnerDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: GetPurchaseResponseSisbovAnyOfOwnerPhone;
  profiles: EnumsProfile[];
};

export type GetPurchaseResponseReceivedAt = string | null;

export type GetPurchaseResponseOxenAnyOf = {
  buyer: GetPurchaseResponseOxenAnyOfBuyer;
  buyerId: number;
  commission: number;
  distance: number;
  gtas: GetPurchaseResponseOxenAnyOfGtasItem[];
  id: number;
  owner: GetPurchaseResponseOxenAnyOfOwner;
  ownerId: number;
  purchaseId: number;
  purpose: EnumsOxPurpose;
  shipping: number;
  traceable: boolean;
  weightAverage: number;
};

export type GetPurchaseResponseOxen = GetPurchaseResponseOxenAnyOf | null;

export type GetPurchaseResponseOxenAnyOfOwnerPhone = null | string;

export type GetPurchaseResponseOxenAnyOfOwnerDocument = null | string;

export type GetPurchaseResponseOxenAnyOfOwnerDescription = null | string;

export type GetPurchaseResponseOxenAnyOfOwner = {
  createdAt: string;
  description: GetPurchaseResponseOxenAnyOfOwnerDescription;
  document: GetPurchaseResponseOxenAnyOfOwnerDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: GetPurchaseResponseOxenAnyOfOwnerPhone;
  profiles: EnumsProfile[];
};

export type GetPurchaseResponseOxenAnyOfGtasItemPurchaseId = null | number;

export type GetPurchaseResponseOxenAnyOfGtasItem = {
  ages: GetPurchaseResponseOxenAnyOfGtasItemAgesItem[];
  completed: boolean;
  description: GetPurchaseResponseOxenAnyOfGtasItemDescription;
  emission: string;
  entry: string;
  expiration: string;
  farmId: number;
  id: number;
  number: string;
  origin: GetPurchaseResponseOxenAnyOfGtasItemOrigin;
  originId: number;
  purchaseId: GetPurchaseResponseOxenAnyOfGtasItemPurchaseId;
  series: string;
};

export type GetPurchaseResponseOxenAnyOfGtasItemOriginResponsiblePhone = null | string;

export type GetPurchaseResponseOxenAnyOfGtasItemOriginResponsibleDocument = null | string;

export type GetPurchaseResponseOxenAnyOfGtasItemOriginResponsibleDescription = null | string;

export type GetPurchaseResponseOxenAnyOfGtasItemOriginResponsible = {
  createdAt: string;
  description: GetPurchaseResponseOxenAnyOfGtasItemOriginResponsibleDescription;
  document: GetPurchaseResponseOxenAnyOfGtasItemOriginResponsibleDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: GetPurchaseResponseOxenAnyOfGtasItemOriginResponsiblePhone;
  profiles: EnumsProfile[];
};

export type GetPurchaseResponseOxenAnyOfGtasItemOriginCode = null | string;

export type GetPurchaseResponseOxenAnyOfGtasItemOrigin = {
  addressId: number;
  code: GetPurchaseResponseOxenAnyOfGtasItemOriginCode;
  destiny: boolean;
  farmId: number;
  gov: GlobalPrismaJsonGovDocuments;
  id: number;
  name: string;
  origin: boolean;
  responsible: GetPurchaseResponseOxenAnyOfGtasItemOriginResponsible;
  responsibleId: number;
};

export type GetPurchaseResponseOxenAnyOfGtasItemDescription = null | string;

export type GetPurchaseResponseOxenAnyOfGtasItemAgesItem = {
  femaleManaged: number;
  femaleTotal: number;
  gtaId: number;
  id: number;
  maleManaged: number;
  maleTotal: number;
  yearsOld: number;
};

export type GetPurchaseResponseOxenAnyOfBuyerPhone = null | string;

export type GetPurchaseResponseOxenAnyOfBuyerDocument = null | string;

export type GetPurchaseResponseOxenAnyOfBuyerDescription = null | string;

export type GetPurchaseResponseOxenAnyOfBuyer = {
  createdAt: string;
  description: GetPurchaseResponseOxenAnyOfBuyerDescription;
  document: GetPurchaseResponseOxenAnyOfBuyerDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: GetPurchaseResponseOxenAnyOfBuyerPhone;
  profiles: EnumsProfile[];
};

export type GetPurchaseResponseObservation = null | string;

export type GetPurchaseResponseEarringsPerPage = null | number;

export type GetPersonResponseUser = GetPersonResponseUserAnyOf | null;

export type GetPersonResponseUserAnyOfGov = GlobalPrismaJsonGovDocuments | null;

export type GetPersonResponseUserAnyOfEmail = null | string;

export type GetPersonResponseUserAnyOf = {
  email: GetPersonResponseUserAnyOfEmail;
  gov: GetPersonResponseUserAnyOfGov;
};

export type GetPersonResponsePhone = null | string;

export type GetPersonResponseDocument = null | string;

export type GetPersonResponseDescription = null | string;

export interface GetPersonResponse {
  description: GetPersonResponseDescription;
  document: GetPersonResponseDocument;
  name: string;
  phone: GetPersonResponsePhone;
  profiles: EnumsProfile[];
  user: GetPersonResponseUser;
}

export type GetOccurrenceByIdResponseOldFactor = null | number;

export type GetOccurrenceByIdResponseNewFactor = null | number;

export type GetOccurrenceByIdResponseName = null | string;

export interface GetOccurrenceByIdResponse {
  category: EnumsOccurrenceCategory;
  date: string;
  description: string;
  farmId: number;
  id: number;
  name: GetOccurrenceByIdResponseName;
  newFactor: GetOccurrenceByIdResponseNewFactor;
  oldFactor: GetOccurrenceByIdResponseOldFactor;
  type: EnumsOccurrenceType;
}

export type GetMedicinesResponseItemsItemKiloDose = null | number;

export type GetMedicinesResponseItemsItem = {
  blockingDays: number;
  createdAt: string;
  dosePrice: number;
  farmId: number;
  id: number;
  kiloDose: GetMedicinesResponseItemsItemKiloDose;
  manufacturer: string;
  measurement: EnumsMeasurement;
  name: string;
  type: EnumsMedicineType;
};

export interface GetMedicinesResponse {
  items: GetMedicinesResponseItemsItem[];
  next?: number;
  previous?: number;
}

export type GetMedicineResponse = GetMedicineResponseAnyOf | null;

export type GetMedicineResponseAnyOfKiloDose = null | number;

export type GetMedicineResponseAnyOfImagesItemName = null | string;

export type GetMedicineResponseAnyOfImagesItemMedicineId = null | number;

export type GetMedicineResponseAnyOfImagesItemHealthProtocolId = null | number;

export type GetMedicineResponseAnyOfImagesItem = {
  base64: string;
  farmId: number;
  healthProtocolId: GetMedicineResponseAnyOfImagesItemHealthProtocolId;
  id: number;
  medicineId: GetMedicineResponseAnyOfImagesItemMedicineId;
  name: GetMedicineResponseAnyOfImagesItemName;
};

export type GetMedicineResponseAnyOf = {
  blockingDays: number;
  createdAt: string;
  dosePrice: number;
  farmId: number;
  id: number;
  Images: GetMedicineResponseAnyOfImagesItem[];
  kiloDose: GetMedicineResponseAnyOfKiloDose;
  manufacturer: string;
  measurement: EnumsMeasurement;
  name: string;
  type: EnumsMedicineType;
};

export type GetIndividualCattleClosingReportXlsxResponse = unknown;

export type GetHistoryReportXlsxResponse = unknown;

export type GetHealthProtocolResponse = GetHealthProtocolResponseAnyOf | null;

export type GetHealthProtocolResponseAnyOfMedicinesItemKiloDose = null | number;

export type GetHealthProtocolResponseAnyOfMedicinesItem = {
  blockingDays: number;
  createdAt: string;
  dosePrice: number;
  farmId: number;
  id: number;
  kiloDose: GetHealthProtocolResponseAnyOfMedicinesItemKiloDose;
  manufacturer: string;
  measurement: EnumsMeasurement;
  name: string;
  type: EnumsMedicineType;
};

export type GetHealthProtocolResponseAnyOf = {
  createdAt: string;
  farmId: number;
  id: number;
  images: GetHealthProtocolResponseAnyOfImagesItem[];
  medicines: GetHealthProtocolResponseAnyOfMedicinesItem[];
  name: string;
};

export type GetHealthProtocolResponseAnyOfImagesItemName = null | string;

export type GetHealthProtocolResponseAnyOfImagesItemMedicineId = null | number;

export type GetHealthProtocolResponseAnyOfImagesItemHealthProtocolId = null | number;

export type GetHealthProtocolResponseAnyOfImagesItem = {
  base64: string;
  farmId: number;
  healthProtocolId: GetHealthProtocolResponseAnyOfImagesItemHealthProtocolId;
  id: number;
  medicineId: GetHealthProtocolResponseAnyOfImagesItemMedicineId;
  name: GetHealthProtocolResponseAnyOfImagesItemName;
};

export type GetGtaResponsePurchaseId = null | number;

export type GetGtaResponsePurchaseAnyOf = {
  buyerId: number;
  commission: number;
  distance: number;
  id: number;
  ownerId: number;
  purchaseId: number;
  purpose: EnumsOxPurpose;
  shipping: number;
  traceable: boolean;
  weightAverage: number;
};

export type GetGtaResponsePurchase = GetGtaResponsePurchaseAnyOf | null;

export type GetGtaResponseOxenItemOwnerPhone = null | string;

export type GetGtaResponseOxenItemOwnerDocument = null | string;

export type GetGtaResponseOxenItemOwnerDescription = null | string;

export type GetGtaResponseOxenItemOwner = {
  createdAt: string;
  description: GetGtaResponseOxenItemOwnerDescription;
  document: GetGtaResponseOxenItemOwnerDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: GetGtaResponseOxenItemOwnerPhone;
  profiles: EnumsProfile[];
};

export type GetGtaResponseOxenItem = {
  owner: GetGtaResponseOxenItemOwner;
};

export type GetGtaResponseOriginCode = null | string;

export type GetGtaResponseOrigin = {
  addressId: number;
  code: GetGtaResponseOriginCode;
  destiny: boolean;
  farmId: number;
  gov: GlobalPrismaJsonGovDocuments;
  id: number;
  name: string;
  origin: boolean;
  responsibleId: number;
};

export type GetGtaResponseFarmMatinalReadTime = string | null;

export type GetGtaResponseFarmLastCertifierImport = string | null;

export type GetGtaResponseFarmLastBndImport = string | null;

export type GetGtaResponseFarmAlias = null | string;

export type GetGtaResponseFarm = {
  addressId: number;
  alias: GetGtaResponseFarmAlias;
  blockedDays: number;
  createdAt: string;
  dailyCost: number;
  day: string;
  divisionThreshold: number;
  factory: GlobalPrismaJsonLatLng[];
  gradeInfo: unknown;
  gradeMethod: EnumsGradeMethod;
  id: number;
  lastBndImport: GetGtaResponseFarmLastBndImport;
  lastCertifierImport: GetGtaResponseFarmLastCertifierImport;
  managementConfig: unknown;
  matinalReadTime: GetGtaResponseFarmMatinalReadTime;
  name: string;
  nightlyReadTimes: string[];
  position: GlobalPrismaJsonLatLng;
};

export type GetGtaResponseDescription = null | string;

export type GetGtaResponseAgesItem = {
  femaleManaged: number;
  femaleTotal: number;
  gtaId: number;
  id: number;
  maleManaged: number;
  maleTotal: number;
  yearsOld: number;
};

export interface GetGtaResponse {
  ages: GetGtaResponseAgesItem[];
  completed: boolean;
  description: GetGtaResponseDescription;
  emission: string;
  entry: string;
  expiration: string;
  farm: GetGtaResponseFarm;
  farmId: number;
  id: number;
  number: string;
  origin: GetGtaResponseOrigin;
  originId: number;
  oxen: GetGtaResponseOxenItem[];
  purchase: GetGtaResponsePurchase;
  purchaseId: GetGtaResponsePurchaseId;
  series: string;
}

export type GetFoodStockReportResponseItem = {
  daysInStock: number;
  food: string;
  matter: number;
  naturalMatter: number;
  naturalMatterFactor: number;
  naturalMatterPrice: number;
};

export type GetFoodStockReportResponse = GetFoodStockReportResponseItem[];

export type GetFoodStockHistoryResponseHistoryItem = {
  date: string;
  dryMatter: number;
  dryMatterInStock: number;
  dryMatterKilePrice: number;
  dryMatterPercentage: number;
  naturalMatter: number;
  naturalMatterInStock: number;
  naturalMatterKilePrice: number;
  operation: string;
};

export interface GetFoodStockHistoryResponse {
  history: GetFoodStockHistoryResponseHistoryItem[];
  name: string;
}

export type GetFoodReportXlsxResponse = unknown;

export type GetFoodHistoryReportXlsxResponse = unknown;

export type GetFinancialReportXlsxResponse = unknown;

export type GetFarmDaysResponseItem = {
  day: string;
  management: boolean;
  treatment: boolean;
};

export type GetFarmDaysResponse = GetFarmDaysResponseItem[];

export type GetExitTruckResponseSaleId = null | number;

export type GetExitTruckResponsePlate = null | string;

export type GetExitTruckResponseName = null | string;

export type GetExitTruckResponseExitGta = null | string;

export type GetExitTruckResponseDestinyId = null | number;

export interface GetExitTruckResponse {
  announcementId: GetExitTruckResponseAnnouncementId;
  boarding: GetExitTruckResponseBoardingItem[];
  capacity: number;
  destinyId: GetExitTruckResponseDestinyId;
  exitAt: string;
  exitGta: GetExitTruckResponseExitGta;
  farmId: number;
  id: number;
  name: GetExitTruckResponseName;
  plate: GetExitTruckResponsePlate;
  reason: EnumsExitReason;
  saleId: GetExitTruckResponseSaleId;
  status: EnumsExitTruckStatus;
}

export type GetExitTruckResponseBoardingItemSisbov = null | number;

export type GetExitTruckResponseBoardingItemPurchaseId = null | number;

export type GetExitTruckResponseBoardingItemPriceAtSign = null | number;

export type GetExitTruckResponseBoardingItemInvoice = null | string;

export type GetExitTruckResponseBoardingItemIdentifier = null | string;

export type GetExitTruckResponseBoardingItemGtaId = null | number;

export type GetExitTruckResponseBoardingItemExitTruckId = null | number;

export type GetExitTruckResponseBoardingItemElectronic = null | string;

export type GetExitTruckResponseBoardingItemDiedAt = string | null;

export type GetExitTruckResponseBoardingItemDeathNecropsy = EnumsDeathNecropsy | null;

export type GetExitTruckResponseBoardingItemDeathDetails = null | string;

export type GetExitTruckResponseBoardingItemDeathCause = EnumsDeathCause | null;

export type GetExitTruckResponseBoardingItemComments = null | string;

export type GetExitTruckResponseBoardingItemCarcassWeight = null | number;

export type GetExitTruckResponseBoardingItemCarcassType = EnumsCarcassType | null;

export type GetExitTruckResponseBoardingItemCarcassThin = null | number;

export type GetExitTruckResponseBoardingItemCarcassPrice = null | number;

export type GetExitTruckResponseBoardingItemCarcassMissing = null | number;

export type GetExitTruckResponseBoardingItemCarcassMedium = null | number;

export type GetExitTruckResponseBoardingItemCarcassExcessive = null | number;

export type GetExitTruckResponseBoardingItemCarcass = null | number;

export type GetExitTruckResponseBoardingItem = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: GetExitTruckResponseBoardingItemCarcass;
  carcassExcessive: GetExitTruckResponseBoardingItemCarcassExcessive;
  carcassMedium: GetExitTruckResponseBoardingItemCarcassMedium;
  carcassMissing: GetExitTruckResponseBoardingItemCarcassMissing;
  carcassPrice: GetExitTruckResponseBoardingItemCarcassPrice;
  carcassThin: GetExitTruckResponseBoardingItemCarcassThin;
  carcassType: GetExitTruckResponseBoardingItemCarcassType;
  carcassWeight: GetExitTruckResponseBoardingItemCarcassWeight;
  comments: GetExitTruckResponseBoardingItemComments;
  deathCause: GetExitTruckResponseBoardingItemDeathCause;
  deathDetails: GetExitTruckResponseBoardingItemDeathDetails;
  deathNecropsy: GetExitTruckResponseBoardingItemDeathNecropsy;
  diedAt: GetExitTruckResponseBoardingItemDiedAt;
  electronic: GetExitTruckResponseBoardingItemElectronic;
  exitTruckId: GetExitTruckResponseBoardingItemExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: GetExitTruckResponseBoardingItemGtaId;
  id: number;
  identifier: GetExitTruckResponseBoardingItemIdentifier;
  includedAt: string;
  invoice: GetExitTruckResponseBoardingItemInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: GetExitTruckResponseBoardingItemPriceAtSign;
  purchaseId: GetExitTruckResponseBoardingItemPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: GetExitTruckResponseBoardingItemSisbov;
  sisbovHistory: number[];
  variety: string;
};

export type GetExitTruckResponseAnnouncementId = null | number;

export type GetExitAnnouncementResponseTrucksItemPlate = null | string;

export type GetExitAnnouncementResponseTrucksItem = {
  exitGta: GetExitAnnouncementResponseTrucksItemExitGta;
  id: number;
  name: GetExitAnnouncementResponseTrucksItemName;
  oxen: GetExitAnnouncementResponseTrucksItemOxenItem[];
  plate: GetExitAnnouncementResponseTrucksItemPlate;
};

export interface GetExitAnnouncementResponse {
  destiny: GetExitAnnouncementResponseDestiny;
  origin: GetExitAnnouncementResponseOrigin;
  trucks: GetExitAnnouncementResponseTrucksItem[];
}

export type GetExitAnnouncementResponseTrucksItemOxenItemSisbov = null | number;

export type GetExitAnnouncementResponseTrucksItemOxenItemPurchaseId = null | number;

export type GetExitAnnouncementResponseTrucksItemOxenItemPriceAtSign = null | number;

export type GetExitAnnouncementResponseTrucksItemOxenItemOwnerDocument = null | string;

export type GetExitAnnouncementResponseTrucksItemOxenItemOwner = {
  document: GetExitAnnouncementResponseTrucksItemOxenItemOwnerDocument;
  name: string;
};

export type GetExitAnnouncementResponseTrucksItemOxenItemInvoice = null | string;

export type GetExitAnnouncementResponseTrucksItemOxenItemIdentifier = null | string;

export type GetExitAnnouncementResponseTrucksItemOxenItemGtaId = null | number;

export type GetExitAnnouncementResponseTrucksItemOxenItemExitTruckId = null | number;

export type GetExitAnnouncementResponseTrucksItemOxenItemElectronic = null | string;

export type GetExitAnnouncementResponseTrucksItemOxenItemDiedAt = string | null;

export type GetExitAnnouncementResponseTrucksItemOxenItemDeathNecropsy = EnumsDeathNecropsy | null;

export type GetExitAnnouncementResponseTrucksItemOxenItemDeathDetails = null | string;

export type GetExitAnnouncementResponseTrucksItemOxenItemDeathCause = EnumsDeathCause | null;

export type GetExitAnnouncementResponseTrucksItemOxenItemComments = null | string;

export type GetExitAnnouncementResponseTrucksItemOxenItemCarcassWeight = null | number;

export type GetExitAnnouncementResponseTrucksItemOxenItemCarcassType = EnumsCarcassType | null;

export type GetExitAnnouncementResponseTrucksItemOxenItemCarcassThin = null | number;

export type GetExitAnnouncementResponseTrucksItemOxenItemCarcassPrice = null | number;

export type GetExitAnnouncementResponseTrucksItemOxenItemCarcassMissing = null | number;

export type GetExitAnnouncementResponseTrucksItemOxenItemCarcassMedium = null | number;

export type GetExitAnnouncementResponseTrucksItemOxenItemCarcassExcessive = null | number;

export type GetExitAnnouncementResponseTrucksItemOxenItemCarcass = null | number;

export type GetExitAnnouncementResponseTrucksItemOxenItem = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: GetExitAnnouncementResponseTrucksItemOxenItemCarcass;
  carcassExcessive: GetExitAnnouncementResponseTrucksItemOxenItemCarcassExcessive;
  carcassMedium: GetExitAnnouncementResponseTrucksItemOxenItemCarcassMedium;
  carcassMissing: GetExitAnnouncementResponseTrucksItemOxenItemCarcassMissing;
  carcassPrice: GetExitAnnouncementResponseTrucksItemOxenItemCarcassPrice;
  carcassThin: GetExitAnnouncementResponseTrucksItemOxenItemCarcassThin;
  carcassType: GetExitAnnouncementResponseTrucksItemOxenItemCarcassType;
  carcassWeight: GetExitAnnouncementResponseTrucksItemOxenItemCarcassWeight;
  comments: GetExitAnnouncementResponseTrucksItemOxenItemComments;
  deathCause: GetExitAnnouncementResponseTrucksItemOxenItemDeathCause;
  deathDetails: GetExitAnnouncementResponseTrucksItemOxenItemDeathDetails;
  deathNecropsy: GetExitAnnouncementResponseTrucksItemOxenItemDeathNecropsy;
  diedAt: GetExitAnnouncementResponseTrucksItemOxenItemDiedAt;
  electronic: GetExitAnnouncementResponseTrucksItemOxenItemElectronic;
  exitTruckId: GetExitAnnouncementResponseTrucksItemOxenItemExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: GetExitAnnouncementResponseTrucksItemOxenItemGtaId;
  id: number;
  identifier: GetExitAnnouncementResponseTrucksItemOxenItemIdentifier;
  includedAt: string;
  invoice: GetExitAnnouncementResponseTrucksItemOxenItemInvoice;
  owner: GetExitAnnouncementResponseTrucksItemOxenItemOwner;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: GetExitAnnouncementResponseTrucksItemOxenItemPriceAtSign;
  purchaseId: GetExitAnnouncementResponseTrucksItemOxenItemPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: GetExitAnnouncementResponseTrucksItemOxenItemSisbov;
  sisbovHistory: number[];
  variety: string;
};

export type GetExitAnnouncementResponseTrucksItemName = null | string;

export type GetExitAnnouncementResponseTrucksItemExitGta = null | string;

export type GetExitAnnouncementResponseOriginOwnerItemDocument = null | string;

export type GetExitAnnouncementResponseOriginOwnerItem = {
  document: GetExitAnnouncementResponseOriginOwnerItemDocument;
  name: string;
};

export type GetExitAnnouncementResponseOriginAddress = {
  city: string;
  state: string;
};

export type GetExitAnnouncementResponseOrigin = {
  address: GetExitAnnouncementResponseOriginAddress;
  bndCode: number;
  id: number;
  name: string;
  owner: GetExitAnnouncementResponseOriginOwnerItem[];
};

export type GetExitAnnouncementResponseDestinyPropertyCode = null | string;

export type GetExitAnnouncementResponseDestinyOwnerPhone = null | string;

export type GetExitAnnouncementResponseDestinyOwnerDocument = null | string;

export type GetExitAnnouncementResponseDestinyOwnerDescription = null | string;

export type GetExitAnnouncementResponseDestinyOwner = {
  createdAt: string;
  description: GetExitAnnouncementResponseDestinyOwnerDescription;
  document: GetExitAnnouncementResponseDestinyOwnerDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: GetExitAnnouncementResponseDestinyOwnerPhone;
  profiles: EnumsProfile[];
};

export type GetExitAnnouncementResponseDestinyAddress = {
  city: string;
  state: string;
};

export type GetExitAnnouncementResponseDestiny = {
  address: GetExitAnnouncementResponseDestinyAddress;
  id: number;
  name: string;
  owner: GetExitAnnouncementResponseDestinyOwner;
  propertyCode: GetExitAnnouncementResponseDestinyPropertyCode;
};

export type GetExitAnnouncementByDateResponseTrucksItemPlate = null | string;

export type GetExitAnnouncementByDateResponseTrucksItem = {
  exitGta: GetExitAnnouncementByDateResponseTrucksItemExitGta;
  id: number;
  name: GetExitAnnouncementByDateResponseTrucksItemName;
  oxen: GetExitAnnouncementByDateResponseTrucksItemOxenItem[];
  plate: GetExitAnnouncementByDateResponseTrucksItemPlate;
};

export interface GetExitAnnouncementByDateResponse {
  destiny: GetExitAnnouncementByDateResponseDestiny;
  origin: GetExitAnnouncementByDateResponseOrigin;
  trucks: GetExitAnnouncementByDateResponseTrucksItem[];
}

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemSisbov = null | number;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemPurchaseId = null | number;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemPurchase =
  GetExitAnnouncementByDateResponseTrucksItemOxenItemPurchaseAnyOf | null;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemPurchaseAnyOfOwnerDocument = null | string;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemPurchaseAnyOfOwner = {
  document: GetExitAnnouncementByDateResponseTrucksItemOxenItemPurchaseAnyOfOwnerDocument;
  name: string;
};

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemPurchaseAnyOf = {
  owner: GetExitAnnouncementByDateResponseTrucksItemOxenItemPurchaseAnyOfOwner;
};

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemPriceAtSign = null | number;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemInvoice = null | string;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemIdentifier = null | string;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemGtaId = null | number;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemExitTruckId = null | number;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemElectronic = null | string;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemDiedAt = string | null;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemDeathNecropsy = EnumsDeathNecropsy | null;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemDeathDetails = null | string;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemDeathCause = EnumsDeathCause | null;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemComments = null | string;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcassWeight = null | number;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcassType = EnumsCarcassType | null;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcassThin = null | number;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcassPrice = null | number;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcassMissing = null | number;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcassMedium = null | number;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcassExcessive = null | number;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcass = null | number;

export type GetExitAnnouncementByDateResponseTrucksItemOxenItem = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcass;
  carcassExcessive: GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcassExcessive;
  carcassMedium: GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcassMedium;
  carcassMissing: GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcassMissing;
  carcassPrice: GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcassPrice;
  carcassThin: GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcassThin;
  carcassType: GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcassType;
  carcassWeight: GetExitAnnouncementByDateResponseTrucksItemOxenItemCarcassWeight;
  comments: GetExitAnnouncementByDateResponseTrucksItemOxenItemComments;
  deathCause: GetExitAnnouncementByDateResponseTrucksItemOxenItemDeathCause;
  deathDetails: GetExitAnnouncementByDateResponseTrucksItemOxenItemDeathDetails;
  deathNecropsy: GetExitAnnouncementByDateResponseTrucksItemOxenItemDeathNecropsy;
  diedAt: GetExitAnnouncementByDateResponseTrucksItemOxenItemDiedAt;
  electronic: GetExitAnnouncementByDateResponseTrucksItemOxenItemElectronic;
  exitTruckId: GetExitAnnouncementByDateResponseTrucksItemOxenItemExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: GetExitAnnouncementByDateResponseTrucksItemOxenItemGtaId;
  id: number;
  identifier: GetExitAnnouncementByDateResponseTrucksItemOxenItemIdentifier;
  includedAt: string;
  invoice: GetExitAnnouncementByDateResponseTrucksItemOxenItemInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: GetExitAnnouncementByDateResponseTrucksItemOxenItemPriceAtSign;
  purchase: GetExitAnnouncementByDateResponseTrucksItemOxenItemPurchase;
  purchaseId: GetExitAnnouncementByDateResponseTrucksItemOxenItemPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: GetExitAnnouncementByDateResponseTrucksItemOxenItemSisbov;
  sisbovHistory: number[];
  variety: string;
};

export type GetExitAnnouncementByDateResponseTrucksItemName = null | string;

export type GetExitAnnouncementByDateResponseTrucksItemExitGta = null | string;

export type GetExitAnnouncementByDateResponseOriginOwnerItemDocument = null | string;

export type GetExitAnnouncementByDateResponseOriginOwnerItem = {
  document: GetExitAnnouncementByDateResponseOriginOwnerItemDocument;
  name: string;
};

export type GetExitAnnouncementByDateResponseOriginAddress = {
  city: string;
  state: string;
};

export type GetExitAnnouncementByDateResponseOrigin = {
  address: GetExitAnnouncementByDateResponseOriginAddress;
  bndCode: number;
  id: number;
  name: string;
  owner: GetExitAnnouncementByDateResponseOriginOwnerItem[];
};

export type GetExitAnnouncementByDateResponseDestinyPropertyCode = null | string;

export type GetExitAnnouncementByDateResponseDestiny = {
  address: GetExitAnnouncementByDateResponseDestinyAddress;
  id: number;
  name: string;
  owner: GetExitAnnouncementByDateResponseDestinyOwner;
  propertyCode: GetExitAnnouncementByDateResponseDestinyPropertyCode;
};

export type GetExitAnnouncementByDateResponseDestinyOwnerPhone = null | string;

export type GetExitAnnouncementByDateResponseDestinyOwnerDocument = null | string;

export type GetExitAnnouncementByDateResponseDestinyOwnerDescription = null | string;

export type GetExitAnnouncementByDateResponseDestinyOwner = {
  createdAt: string;
  description: GetExitAnnouncementByDateResponseDestinyOwnerDescription;
  document: GetExitAnnouncementByDateResponseDestinyOwnerDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: GetExitAnnouncementByDateResponseDestinyOwnerPhone;
  profiles: EnumsProfile[];
};

export type GetExitAnnouncementByDateResponseDestinyAddress = {
  city: string;
  state: string;
};

export interface GetEstablishmentByIdResponse {
  address: GetEstablishmentByIdResponseAddress;
  addressId: number;
  code: GetEstablishmentByIdResponseCode;
  destiny: boolean;
  farmId: number;
  gov: GlobalPrismaJsonGovDocuments;
  id: number;
  name: string;
  origin: boolean;
  responsible: GetEstablishmentByIdResponseResponsible;
  responsibleId: number;
}

export type GetEstablishmentByIdResponseResponsiblePhone = null | string;

export type GetEstablishmentByIdResponseResponsibleDocument = null | string;

export type GetEstablishmentByIdResponseResponsibleDescription = null | string;

export type GetEstablishmentByIdResponseResponsible = {
  createdAt: string;
  description: GetEstablishmentByIdResponseResponsibleDescription;
  document: GetEstablishmentByIdResponseResponsibleDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: GetEstablishmentByIdResponseResponsiblePhone;
  profiles: EnumsProfile[];
};

export type GetEstablishmentByIdResponseCode = null | string;

export type GetEstablishmentByIdResponseAddressStreet = null | string;

export type GetEstablishmentByIdResponseAddressNumber = null | string;

export type GetEstablishmentByIdResponseAddressNeighborhood = null | string;

export type GetEstablishmentByIdResponseAddressComplement = null | string;

export type GetEstablishmentByIdResponseAddress = {
  city: string;
  complement: GetEstablishmentByIdResponseAddressComplement;
  country: string;
  id: number;
  neighborhood: GetEstablishmentByIdResponseAddressNeighborhood;
  number: GetEstablishmentByIdResponseAddressNumber;
  state: string;
  street: GetEstablishmentByIdResponseAddressStreet;
  zip: string;
};

export type GetEntryAnnouncementResponseOxenItemSisbov = null | number;

export type GetEntryAnnouncementResponseOxenItemElectronic = null | string;

export type GetEntryAnnouncementResponseOxenItem = {
  electronic: GetEntryAnnouncementResponseOxenItemElectronic;
  sisbov: GetEntryAnnouncementResponseOxenItemSisbov;
};

export interface GetEntryAnnouncementResponse {
  destiny: GetEntryAnnouncementResponseDestiny;
  gtas: string[];
  origins: GetEntryAnnouncementResponseOriginsItem[];
  oxen: GetEntryAnnouncementResponseOxenItem[];
}

export type GetEntryAnnouncementResponseOriginsItemOwnerDocument = null | string;

export type GetEntryAnnouncementResponseOriginsItemOwner = {
  document: GetEntryAnnouncementResponseOriginsItemOwnerDocument;
  name: string;
};

export type GetEntryAnnouncementResponseOriginsItemAddress = {
  city: string;
  state: string;
};

export type GetEntryAnnouncementResponseOriginsItem = {
  address: GetEntryAnnouncementResponseOriginsItemAddress;
  bndCode: GlobalPrismaJsonGovDocuments;
  name: string;
  owner: GetEntryAnnouncementResponseOriginsItemOwner;
};

export type GetEntryAnnouncementResponseDestinyPropertyCodeItem = GlobalPrismaJsonGovDocuments | null | unknown;

export type GetEntryAnnouncementResponseDestinyOwnerItemDocument = null | string;

export type GetEntryAnnouncementResponseDestinyOwnerItem = {
  document: GetEntryAnnouncementResponseDestinyOwnerItemDocument;
  name: string;
};

export type GetEntryAnnouncementResponseDestinyAddress = {
  city: string;
  state: string;
};

export type GetEntryAnnouncementResponseDestiny = {
  address: GetEntryAnnouncementResponseDestinyAddress;
  name: string;
  owner: GetEntryAnnouncementResponseDestinyOwnerItem[];
  propertyCode: GetEntryAnnouncementResponseDestinyPropertyCodeItem[];
};

export type GetEfficiencyReportXlsxResponse = unknown;

export type GetDietResponseObservation = null | string;

export type GetDietResponseIngredientsItem = {
  dietId: number;
  id: number;
  percentage: number;
  stockId: number;
};

export type GetDietResponseCategory = EnumsDietCategory | null;

export interface GetDietResponse {
  active: boolean;
  category: GetDietResponseCategory;
  createdAt: string;
  estimatedWeightGain: number;
  expiry: number;
  farmId: number;
  id: number;
  ingredients: GetDietResponseIngredientsItem[];
  name: string;
  observation: GetDietResponseObservation;
}

export type GetDeathAnnouncementResponseOwnerItemDocument = null | string;

export type GetDeathAnnouncementResponseOwnerItem = {
  document: GetDeathAnnouncementResponseOwnerItemDocument;
  name: string;
};

export type GetDeathAnnouncementResponseFarm = {
  id: number;
  name: string;
};

export type GetDeathAnnouncementResponseAddress = {
  city: string;
  state: string;
};

export interface GetDeathAnnouncementResponse {
  address: GetDeathAnnouncementResponseAddress;
  deaths: DeathAnnouncement[];
  farm: GetDeathAnnouncementResponseFarm;
  owner: GetDeathAnnouncementResponseOwnerItem[];
}

export type GetCurrentFarmResponse = GetCurrentFarmResponseAnyOf | null;

export type GetCurrentFarmResponseAnyOfMatinalReadTime = string | null;

export type GetCurrentFarmResponseAnyOfLastCertifierImport = string | null;

export type GetCurrentFarmResponseAnyOfLastBndImport = string | null;

export type GetCurrentFarmResponseAnyOfAlias = null | string;

export type GetCurrentFarmResponseAnyOf = {
  addressId: number;
  alias: GetCurrentFarmResponseAnyOfAlias;
  blockedDays: number;
  createdAt: string;
  dailyCost: number;
  day: string;
  divisionThreshold: number;
  factory: GlobalPrismaJsonLatLng[];
  gradeInfo: unknown;
  gradeMethod: EnumsGradeMethod;
  id: number;
  lastBndImport: GetCurrentFarmResponseAnyOfLastBndImport;
  lastCertifierImport: GetCurrentFarmResponseAnyOfLastCertifierImport;
  managementConfig: unknown;
  matinalReadTime: GetCurrentFarmResponseAnyOfMatinalReadTime;
  name: string;
  nightlyReadTimes: string[];
  position: GlobalPrismaJsonLatLng;
};

export type GetCorralResponseBatch = GetCorralResponseBatchAnyOf | null;

export interface GetCorralResponse {
  area: number;
  batch: GetCorralResponseBatch;
  capacity: number;
  name: string;
  polygon: GlobalPrismaJsonLatLng[];
  position: number;
}

export type GetCorralResponseBatchAnyOfOtherCost = null | number;

export type GetCorralResponseBatchAnyOfOldCorralId = null | number;

export type GetCorralResponseBatchAnyOfForeseenWeight = null | number;

export type GetCorralResponseBatchAnyOfForeseenCarcass = null | number;

export type GetCorralResponseBatchAnyOfFinalizedAt = string | null;

export type GetCorralResponseBatchAnyOfExitPrice = null | number;

export type GetCorralResponseBatchAnyOfCorralId = null | number;

export type GetCorralResponseBatchAnyOfCarcass = null | number;

export type GetCorralResponseBatchAnyOfAdmCost = null | number;

export type GetCorralResponseBatchAnyOfActivatedAt = string | null;

export type GetCorralResponseBatchAnyOf = {
  activatedAt: GetCorralResponseBatchAnyOfActivatedAt;
  admCost: GetCorralResponseBatchAnyOfAdmCost;
  carcass: GetCorralResponseBatchAnyOfCarcass;
  corralId: GetCorralResponseBatchAnyOfCorralId;
  createdAt: string;
  exitPrice: GetCorralResponseBatchAnyOfExitPrice;
  farmId: number;
  finalizedAt: GetCorralResponseBatchAnyOfFinalizedAt;
  foreseenCarcass: GetCorralResponseBatchAnyOfForeseenCarcass;
  foreseenWeight: GetCorralResponseBatchAnyOfForeseenWeight;
  id: number;
  liveWeight: number;
  name: string;
  oldCorralId: GetCorralResponseBatchAnyOfOldCorralId;
  otherCost: GetCorralResponseBatchAnyOfOtherCost;
};

export type GetChangeOfCharacteristicAnnouncementResponseOwnerItemDocument = null | string;

export type GetChangeOfCharacteristicAnnouncementResponseOwnerItem = {
  document: GetChangeOfCharacteristicAnnouncementResponseOwnerItemDocument;
  name: string;
};

export type GetChangeOfCharacteristicAnnouncementResponseFarm = {
  id: number;
  name: string;
};

export type GetChangeOfCharacteristicAnnouncementResponseChangesItem = {
  age: number;
  birth: string;
  newBreed?: string;
  newGender?: EnumsOxGender;
  oldBreed?: string;
  oldGender?: EnumsOxGender;
  oxId: number;
};

export type GetChangeOfCharacteristicAnnouncementResponseAddress = {
  city: string;
  state: string;
};

export interface GetChangeOfCharacteristicAnnouncementResponse {
  address: GetChangeOfCharacteristicAnnouncementResponseAddress;
  changes: GetChangeOfCharacteristicAnnouncementResponseChangesItem[];
  farm: GetChangeOfCharacteristicAnnouncementResponseFarm;
  id: number;
  owner: GetChangeOfCharacteristicAnnouncementResponseOwnerItem[];
}

export type GetBatchHistoryResponseReadValue = null | number;

export type GetBatchHistoryResponseReadType = EnumsReadType | null;

export type GetBatchHistoryResponseManagedAt = string | null;

export interface GetBatchHistoryResponse {
  batchId: number;
  day: string;
  dietId: number;
  farmId: number;
  id: number;
  managedAt: GetBatchHistoryResponseManagedAt;
  matterVariation: number;
  nightlyReads: GlobalPrismaJsonNightlyRead[];
  oxenAmount: number;
  readType: GetBatchHistoryResponseReadType;
  readValue: GetBatchHistoryResponseReadValue;
  realizedTreatDistributions: number[];
  realizedTreatMatter: number;
  realizedTreatNaturalMatter: number;
  resultingMatter: number;
  tracerRealizedSequential: GlobalPrismaJsonTracerRealizedSequential[];
  treatmentId: number;
  weightAverage: number;
}

export type GetAddressResponseStreet = null | string;

export type GetAddressResponseNumber = null | string;

export type GetAddressResponseNeighborhood = null | string;

export type GetAddressResponseComplement = null | string;

export interface GetAddressResponse {
  city: string;
  complement: GetAddressResponseComplement;
  country: string;
  id: number;
  neighborhood: GetAddressResponseNeighborhood;
  number: GetAddressResponseNumber;
  state: string;
  street: GetAddressResponseStreet;
  zip: string;
}

export interface GetActiveBatchesReportResponse {
  average: GetActiveBatchesReportResponseAverage;
  batches: GetActiveBatchesReportResponseBatchesItem[];
  reportDay: string;
}

export type GetActiveBatchesReportResponseBatchesItemRecreationDays = {
  ADAPTATION: number;
  FINISHING: number;
  GROWTH: number;
  RECREATION: number;
  TERMINATION: number;
};

export type GetActiveBatchesReportResponseBatchesItemForeseenWeight = null | number;

export type GetActiveBatchesReportResponseBatchesItemForeseenSlaughter = 0 | string | null;

export type GetActiveBatchesReportResponseBatchesItemEntryDate = string | null;

export type GetActiveBatchesReportResponseBatchesItemConsume = {
  d1dm: number;
  d1nm: number;
  d1pv: number;
  d3dm: number;
  d3nm: number;
  d3pv: number;
  dm: number;
  nm: number;
  pv: number;
};

export type GetActiveBatchesReportResponseBatchesItem = {
  category: EnumsOxGender[];
  confinementDays: number;
  consume: GetActiveBatchesReportResponseBatchesItemConsume;
  corral?: string;
  deathCount: number;
  dietDays: number;
  dietName: string;
  entryDate: GetActiveBatchesReportResponseBatchesItemEntryDate;
  entryType?: EnumsOxPurpose;
  estimatedWeightGain: number;
  foodCost?: number;
  foreseenSlaughter: GetActiveBatchesReportResponseBatchesItemForeseenSlaughter;
  foreseenWeight: GetActiveBatchesReportResponseBatchesItemForeseenWeight;
  initialWeightAverage: number;
  name: string;
  operationalCost: number;
  ownerName: string;
  oxenAmount: number;
  position: number;
  preConfinement: number;
  recreationDays: GetActiveBatchesReportResponseBatchesItemRecreationDays;
  totalDaily: number;
  variety: string[];
  weightAverage: number;
};

export type GetActiveBatchesReportResponseAverageConsume = {
  d1dm: number;
  d1nm: number;
  d1pv: number;
  d3dm: number;
  d3nm: number;
  d3pv: number;
  dm: number;
  nm: number;
  pv: number;
};

export type GetActiveBatchesReportResponseAverage = {
  confinementDays: number;
  consume: GetActiveBatchesReportResponseAverageConsume;
  deathCount: number;
  estimatedWeightGain: number;
  foodCost: number;
  foreseenWeight: number;
  initialWeightAverage: number;
  operationalCost: number;
  oxenAmount: number;
  preConfinement: number;
  totalDaily: number;
  weightAverage: number;
};

export type GenerateUnusabilityResponseUnusabilitiesItem = {
  brand: string;
  observation?: string;
  sisbov: number;
};

export type GenerateUnusabilityResponseOriginPropertyCodeItem = string | unknown;

export type GenerateUnusabilityResponseOriginOwnerItemDocument = null | string;

export type GenerateUnusabilityResponseOriginOwnerItem = {
  document: GenerateUnusabilityResponseOriginOwnerItemDocument;
  name: string;
};

export type GenerateUnusabilityResponseOriginAddress = {
  city: string;
  state: string;
};

export type GenerateUnusabilityResponseOrigin = {
  address: GenerateUnusabilityResponseOriginAddress;
  id: number;
  name: string;
  owner: GenerateUnusabilityResponseOriginOwnerItem[];
  propertyCode: GenerateUnusabilityResponseOriginPropertyCodeItem[];
};

export interface GenerateUnusabilityResponse {
  origin: GenerateUnusabilityResponseOrigin;
  unusabilities: GenerateUnusabilityResponseUnusabilitiesItem[];
}

export type GenerateReIdentificationAnnouncementResponseReIdentificationsItem = {
  age: number;
  birth: string;
  earringBrand?: string;
  newSisbov: number;
  oldSisbov: number;
  oxId: number;
};

export type GenerateReIdentificationAnnouncementResponseOwnerItemDocument = null | string;

export type GenerateReIdentificationAnnouncementResponseOwnerItem = {
  document: GenerateReIdentificationAnnouncementResponseOwnerItemDocument;
  name: string;
};

export type GenerateReIdentificationAnnouncementResponseFarm = {
  id: number;
  name: string;
};

export type GenerateReIdentificationAnnouncementResponseAddress = {
  city: string;
  state: string;
};

export interface GenerateReIdentificationAnnouncementResponse {
  address: GenerateReIdentificationAnnouncementResponseAddress;
  farm: GenerateReIdentificationAnnouncementResponseFarm;
  id: number;
  owner: GenerateReIdentificationAnnouncementResponseOwnerItem[];
  reIdentifications: GenerateReIdentificationAnnouncementResponseReIdentificationsItem[];
}

export type GenerateDeathAnnouncementResponseOwnerItemDocument = null | string;

export type GenerateDeathAnnouncementResponseOwnerItem = {
  document: GenerateDeathAnnouncementResponseOwnerItemDocument;
  name: string;
};

export type GenerateDeathAnnouncementResponseFarm = {
  id: number;
  name: string;
};

export type GenerateDeathAnnouncementResponseAddress = {
  city: string;
  state: string;
};

export interface GenerateDeathAnnouncementResponse {
  address: GenerateDeathAnnouncementResponseAddress;
  deaths: DeathAnnouncement[];
  farm: GenerateDeathAnnouncementResponseFarm;
  owner: GenerateDeathAnnouncementResponseOwnerItem[];
}

export type GenerateChangeOfCharacteristicsResponseFarm = {
  id: number;
  name: string;
};

export type GenerateChangeOfCharacteristicsResponseChangesItem = {
  age: number;
  birth: string;
  newBreed?: string;
  newGender?: EnumsOxGender;
  oldBreed?: string;
  oldGender?: EnumsOxGender;
  oxId: number;
};

export type GenerateChangeOfCharacteristicsResponseAddress = {
  city: string;
  state: string;
};

export interface GenerateChangeOfCharacteristicsResponse {
  address: GenerateChangeOfCharacteristicsResponseAddress;
  changes: GenerateChangeOfCharacteristicsResponseChangesItem[];
  farm: GenerateChangeOfCharacteristicsResponseFarm;
  id: number;
  owner: string;
}

export interface FoodHistoryFilter {
  end?: string;
  start: string;
  stockId: number;
}

export type FindOxResponseWeightsItem = {
  date: string;
  id: number;
  oxId: number;
  source: EnumsWeightSource;
  weight: number;
};

export type FindOxResponseSisbov = null | number;

export type FindOxResponsePurchaseId = null | number;

export type FindOxResponsePriceAtSign = null | number;

export interface FindOxResponse {
  batch?: FindOxResponseBatch;
  batches: FindOxResponseBatchesItem[];
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: FindOxResponseCarcass;
  carcassExcessive: FindOxResponseCarcassExcessive;
  carcassMedium: FindOxResponseCarcassMedium;
  carcassMissing: FindOxResponseCarcassMissing;
  carcassPrice: FindOxResponseCarcassPrice;
  carcassThin: FindOxResponseCarcassThin;
  carcassType: FindOxResponseCarcassType;
  carcassWeight: FindOxResponseCarcassWeight;
  comments: FindOxResponseComments;
  corral?: FindOxResponseCorral;
  currentWeight?: number;
  deathCause: FindOxResponseDeathCause;
  deathDetails: FindOxResponseDeathDetails;
  deathNecropsy: FindOxResponseDeathNecropsy;
  diedAt: FindOxResponseDiedAt;
  electronic: FindOxResponseElectronic;
  entryWeight: number;
  exitTruckId: FindOxResponseExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: FindOxResponseGtaId;
  id: number;
  identifier: FindOxResponseIdentifier;
  includedAt: string;
  invoice: FindOxResponseInvoice;
  owner: FindOxResponseOwner;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: FindOxResponsePriceAtSign;
  purchaseId: FindOxResponsePurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: FindOxResponseSisbov;
  sisbovHistory: number[];
  variety: string;
  weights: FindOxResponseWeightsItem[];
}

export type FindOxResponseOwnerPhone = null | string;

export type FindOxResponseOwnerDocument = null | string;

export type FindOxResponseOwnerDescription = null | string;

export type FindOxResponseOwner = {
  createdAt: string;
  description: FindOxResponseOwnerDescription;
  document: FindOxResponseOwnerDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: FindOxResponseOwnerPhone;
  profiles: EnumsProfile[];
};

export type FindOxResponseInvoice = null | string;

export type FindOxResponseIdentifier = null | string;

export type FindOxResponseGtaId = null | number;

export type FindOxResponseExitTruckId = null | number;

export type FindOxResponseElectronic = null | string;

export type FindOxResponseDiedAt = string | null;

export type FindOxResponseDeathNecropsy = EnumsDeathNecropsy | null;

export type FindOxResponseDeathDetails = null | string;

export type FindOxResponseDeathCause = EnumsDeathCause | null;

export type FindOxResponseCorralAnyOf = {
  area: number;
  capacity: number;
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
  polygon: GlobalPrismaJsonLatLng[];
  position: number;
  type: EnumsCorralType;
};

export type FindOxResponseCorral = FindOxResponseCorralAnyOf | null;

export type FindOxResponseComments = null | string;

export type FindOxResponseCarcassWeight = null | number;

export type FindOxResponseCarcassType = EnumsCarcassType | null;

export type FindOxResponseCarcassThin = null | number;

export type FindOxResponseCarcassPrice = null | number;

export type FindOxResponseCarcassMissing = null | number;

export type FindOxResponseCarcassMedium = null | number;

export type FindOxResponseCarcassExcessive = null | number;

export type FindOxResponseCarcass = null | number;

export type FindOxResponseBatchesItemFinalizedAt = string | null;

export type FindOxResponseBatchesItemBatchOtherCost = null | number;

export type FindOxResponseBatchesItemBatchOldCorralId = null | number;

export type FindOxResponseBatchesItemBatchForeseenWeight = null | number;

export type FindOxResponseBatchesItemBatchForeseenCarcass = null | number;

export type FindOxResponseBatchesItemBatchFinalizedAt = string | null;

export type FindOxResponseBatchesItemBatchExitPrice = null | number;

export type FindOxResponseBatchesItemBatchCorralId = null | number;

export type FindOxResponseBatchesItemBatchCorralAnyOf = {
  area: number;
  capacity: number;
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
  polygon: GlobalPrismaJsonLatLng[];
  position: number;
  type: EnumsCorralType;
};

export type FindOxResponseBatchesItemBatchCorral = FindOxResponseBatchesItemBatchCorralAnyOf | null;

export type FindOxResponseBatchesItemBatchCarcass = null | number;

export type FindOxResponseBatchesItemBatchAdmCost = null | number;

export type FindOxResponseBatchesItemBatchActivatedAt = string | null;

export type FindOxResponseBatchesItemBatch = {
  activatedAt: FindOxResponseBatchesItemBatchActivatedAt;
  admCost: FindOxResponseBatchesItemBatchAdmCost;
  carcass: FindOxResponseBatchesItemBatchCarcass;
  corral: FindOxResponseBatchesItemBatchCorral;
  corralId: FindOxResponseBatchesItemBatchCorralId;
  createdAt: string;
  exitPrice: FindOxResponseBatchesItemBatchExitPrice;
  farmId: number;
  finalizedAt: FindOxResponseBatchesItemBatchFinalizedAt;
  foreseenCarcass: FindOxResponseBatchesItemBatchForeseenCarcass;
  foreseenWeight: FindOxResponseBatchesItemBatchForeseenWeight;
  id: number;
  liveWeight: number;
  name: string;
  oldCorralId: FindOxResponseBatchesItemBatchOldCorralId;
  otherCost: FindOxResponseBatchesItemBatchOtherCost;
};

export type FindOxResponseBatchesItem = {
  batch: FindOxResponseBatchesItemBatch;
  batchId: number;
  createdAt: string;
  finalizedAt: FindOxResponseBatchesItemFinalizedAt;
  id: number;
  oxId: number;
};

export type FindOxResponseBatchOtherCost = null | number;

export type FindOxResponseBatchOldCorralId = null | number;

export type FindOxResponseBatchForeseenWeight = null | number;

export type FindOxResponseBatchForeseenCarcass = null | number;

export type FindOxResponseBatchFinalizedAt = string | null;

export type FindOxResponseBatchExitPrice = null | number;

export type FindOxResponseBatchCorralId = null | number;

export type FindOxResponseBatchCorralAnyOf = {
  area: number;
  capacity: number;
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
  polygon: GlobalPrismaJsonLatLng[];
  position: number;
  type: EnumsCorralType;
};

export type FindOxResponseBatchCorral = FindOxResponseBatchCorralAnyOf | null;

export type FindOxResponseBatchCarcass = null | number;

export type FindOxResponseBatchAdmCost = null | number;

export type FindOxResponseBatchActivatedAt = string | null;

export type FindOxResponseBatch = {
  activatedAt: FindOxResponseBatchActivatedAt;
  admCost: FindOxResponseBatchAdmCost;
  carcass: FindOxResponseBatchCarcass;
  corral: FindOxResponseBatchCorral;
  corralId: FindOxResponseBatchCorralId;
  createdAt: string;
  exitPrice: FindOxResponseBatchExitPrice;
  farmId: number;
  finalizedAt: FindOxResponseBatchFinalizedAt;
  foreseenCarcass: FindOxResponseBatchForeseenCarcass;
  foreseenWeight: FindOxResponseBatchForeseenWeight;
  id: number;
  liveWeight: number;
  name: string;
  oldCorralId: FindOxResponseBatchOldCorralId;
  otherCost: FindOxResponseBatchOtherCost;
};

export interface FilterDietTransition {
  divisions?: number;
  limit?: number;
  page?: number;
}

export type ExportFeedDataResponse = unknown;

export type ExitTruckStatus = EnumsExitTruckStatus;

export type ExitOxenViewResponse = ExitOxenViewResponseItem[];

export type ExitOxenViewResponseItemOxenItemSisbov = null | number;

export type ExitOxenViewResponseItemOxenItemLastWeight = {
  date: string;
  id: number;
  oxId: number;
  source: EnumsWeightSource;
  weight: number;
};

export type ExitOxenViewResponseItemOxenItem = {
  lastWeight?: ExitOxenViewResponseItemOxenItemLastWeight;
  sisbov: ExitOxenViewResponseItemOxenItemSisbov;
};

export type ExitOxenViewResponseItemName = null | string;

export type ExitOxenViewResponseItem = {
  name: ExitOxenViewResponseItemName;
  oxen: ExitOxenViewResponseItemOxenItem[];
};

export interface ExitAnnouncement {
  driver: string;
  exitGta?: string;
  exitTruckId: number;
  plate: string;
}

export type EntryManagementViewResponseOxenItemWeightsItem = {
  weight: number;
};

export type EntryManagementViewResponseOxenItemSisbov = null | number;

export type EntryManagementViewResponseOxenItem = {
  breed: string;
  gender: EnumsOxGender;
  id: number;
  sisbov: EntryManagementViewResponseOxenItemSisbov;
  weights: EntryManagementViewResponseOxenItemWeightsItem[];
};

export interface EntryManagementViewResponse {
  averageWeight: number;
  cov: number;
  oxen: EntryManagementViewResponseOxenItem[];
  totalOxenCount: number;
}

export type EntryAnnouncementPurchaseId = null | number;

export type EntryAnnouncementEstablishmentId = null | number;

export interface EntryAnnouncement {
  date: string;
  establishmentId?: EntryAnnouncementEstablishmentId;
  gtasIds?: number[];
  oxenIds: number[];
  purchaseId?: EntryAnnouncementPurchaseId;
}

export type EmitEntryResponseEmittedAt = string | null;

export interface EmitEntryResponse {
  data: GlobalPrismaJsonAnnouncementData;
  emittedAt: EmitEntryResponseEmittedAt;
  farmId: number;
  id: number;
  type: EnumsAnnouncementType;
}

export type Disease = EnumsDisease;

export type DietsViewResponseItemCompositionItemStockChangesItem = {
  date: string;
  kiloPrice: number;
  naturalMatterFactor: number;
};

export type DietsViewResponseItemCompositionItemStock = {
  changes: DietsViewResponseItemCompositionItemStockChangesItem[];
  elg: number;
  elm: number;
  name: string;
};

export type DietsViewResponseItemCompositionItem = {
  stock: DietsViewResponseItemCompositionItemStock;
};

export type DietsViewResponseItemCategory = EnumsDietCategory | null;

export type DietsViewResponseItem = {
  category: DietsViewResponseItemCategory;
  composition: DietsViewResponseItemCompositionItem[];
  id: number;
  ingredients: string[];
  name: string;
  nmFactor: number;
  price: number;
};

export type DietsViewResponse = DietsViewResponseItem[];

export type DietTransitionListViewResponseItemEndsAt = string | null;

export type DietTransitionListViewResponseItem = {
  currentPhase: number;
  endingFormulation: string;
  endsAt: DietTransitionListViewResponseItemEndsAt;
  name?: string;
  startedAt: string;
  startingFormulation?: string;
  transitionName: string;
};

export type DietTransitionListViewResponse = DietTransitionListViewResponseItem[];

export interface DietFilter {
  category?: EnumsDietCategory;
  limit?: number;
  page?: number;
}

export type DietCategory = EnumsDietCategory;

export type DeletePurchaseResponseReceivedAt = string | null;

export type DeletePurchaseResponseObservation = null | string;

export type DeletePurchaseResponseEarringsPerPage = null | number;

export interface DeletePurchaseResponse {
  category: EnumsPurchaseCategory;
  earringsPerPage: DeletePurchaseResponseEarringsPerPage;
  farmId: number;
  id: number;
  invoice: string;
  issuedAt: string;
  name: string;
  observation: DeletePurchaseResponseObservation;
  price: number;
  receivedAt: DeletePurchaseResponseReceivedAt;
  series: string;
  status: EnumsPurchaseStatus;
}

export type DeleteMedicineResponseKiloDose = null | number;

export interface DeleteMedicineResponse {
  blockingDays: number;
  createdAt: string;
  dosePrice: number;
  farmId: number;
  id: number;
  kiloDose: DeleteMedicineResponseKiloDose;
  manufacturer: string;
  measurement: EnumsMeasurement;
  name: string;
  type: EnumsMedicineType;
}

export interface DeleteHealthProtocolResponse {
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
}

export type DeleteGtaResponsePurchaseId = null | number;

export type DeleteGtaResponseDescription = null | string;

export interface DeleteGtaResponse {
  completed: boolean;
  description: DeleteGtaResponseDescription;
  emission: string;
  entry: string;
  expiration: string;
  farmId: number;
  id: number;
  number: string;
  originId: number;
  purchaseId: DeleteGtaResponsePurchaseId;
  series: string;
}

export type DeleteEstablishmentResponseCode = null | string;

export interface DeleteEstablishmentResponse {
  addressId: number;
  code: DeleteEstablishmentResponseCode;
  destiny: boolean;
  farmId: number;
  gov: GlobalPrismaJsonGovDocuments;
  id: number;
  name: string;
  origin: boolean;
  responsibleId: number;
}

export type DeleteDietResponseObservation = null | string;

export type DeleteDietResponseCategory = EnumsDietCategory | null;

export interface DeleteDietResponse {
  active: boolean;
  category: DeleteDietResponseCategory;
  createdAt: string;
  estimatedWeightGain: number;
  expiry: number;
  farmId: number;
  id: number;
  name: string;
  observation: DeleteDietResponseObservation;
}

export type DeleteAnnouncementResponseEmittedAt = string | null;

export interface DeleteAnnouncementResponse {
  data: GlobalPrismaJsonAnnouncementData;
  emittedAt: DeleteAnnouncementResponseEmittedAt;
  farmId: number;
  id: number;
  type: EnumsAnnouncementType;
}

export type DeleteAddressResponseStreet = null | string;

export type DeleteAddressResponseNumber = null | string;

export type DeleteAddressResponseNeighborhood = null | string;

export type DeleteAddressResponseComplement = null | string;

export interface DeleteAddressResponse {
  city: string;
  complement: DeleteAddressResponseComplement;
  country: string;
  id: number;
  neighborhood: DeleteAddressResponseNeighborhood;
  number: DeleteAddressResponseNumber;
  state: string;
  street: DeleteAddressResponseStreet;
  zip: string;
}

export type DeathNecropsy = EnumsDeathNecropsy;

export type DeathCause = EnumsDeathCause;

export interface DeathAnnouncement {
  /** Se a morte foi acidental */
  accidental: boolean;
  cause: EnumsDeathCause;
  /** Dia da morte */
  date: string;
  necropsy: EnumsDeathNecropsy;
  observation?: string;
  sisbov: number;
}

export type CreateTruckResponseSaleId = null | number;

export type CreateTruckResponsePlate = null | string;

export type CreateTruckResponseName = null | string;

export type CreateTruckResponseExitGta = null | string;

export type CreateTruckResponseDestinyId = null | number;

export type CreateTruckResponseAnnouncementId = null | number;

export interface CreateTruckResponse {
  announcementId: CreateTruckResponseAnnouncementId;
  capacity: number;
  destinyId: CreateTruckResponseDestinyId;
  exitAt: string;
  exitGta: CreateTruckResponseExitGta;
  farmId: number;
  id: number;
  name: CreateTruckResponseName;
  plate: CreateTruckResponsePlate;
  reason: EnumsExitReason;
  saleId: CreateTruckResponseSaleId;
  status: EnumsExitTruckStatus;
}

export interface CreateTruck {
  capacity: number;
  destinyId?: number;
  exitGta?: string;
  name?: string;
  plate?: string;
}

export interface CreateTreatmentResponse {
  createdAt: string;
  divisions: number[];
  farmId: number;
  hours: string[];
  id: number;
  name: string;
}

export interface CreateStructureResponse {
  category: EnumsStructureCategory;
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
}

export interface CreateStructure {
  category: EnumsStructureCategory;
  name: string;
}

export type CreateStockResponsePremix = null | boolean;

export interface CreateStockResponse {
  code: string;
  elg: number;
  elm: number;
  farmId: number;
  id: number;
  loadMode: EnumsLoadMode;
  name: string;
  premix: CreateStockResponsePremix;
}

export type CreateStockRelativeTo = number | string;

export interface CreateStock {
  code: string;
  /** Energia Liquida de Ganho */
  elg?: number;
  /** Energia Liquida de Manuntenção */
  elm?: number;
  kiloPrice?: number;
  name: string;
  naturalMatterFactor?: number;
  relativeTo: CreateStockRelativeTo;
}

export type CreateSaleManagementResponseAnnouncementId = null | number;

export interface CreateSaleManagementResponse {
  announcementId: CreateSaleManagementResponseAnnouncementId;
  closed: boolean;
  date: string;
  farmId: number;
  id: number;
  ownerId: number;
}

export interface CreateSaleManagement {
  ownerId: number;
  trucksIds: number[];
}

export interface CreatePurchase {
  invoice: string;
  issuedAt: string;
  name: string;
  observation?: string;
  price: number;
  series: string;
}

export interface CreatePremixStock {
  code: string;
  ingredients: Ingredient[];
  name: string;
  reflect: boolean;
}

export type CreatePremixResponsePremix = null | boolean;

export type CreatePremixResponseChangesItem = {
  date: string;
  id: number;
  kiloPrice: number;
  naturalMatterFactor: number;
  stockId: number;
};

export interface CreatePremixResponse {
  changes: CreatePremixResponseChangesItem[];
  code: string;
  elg: number;
  elm: number;
  farmId: number;
  id: number;
  loadMode: EnumsLoadMode;
  name: string;
  premix: CreatePremixResponsePremix;
}

export type CreatePersonResponsePhone = null | string;

export type CreatePersonResponseDocument = null | string;

export type CreatePersonResponseDescription = null | string;

export interface CreatePersonResponse {
  createdAt: string;
  description: CreatePersonResponseDescription;
  document: CreatePersonResponseDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: CreatePersonResponsePhone;
  profiles: EnumsProfile[];
}

export interface CreatePerson {
  description?: string;
  document?: string;
  imageUrl?: string;
  name: string;
  phone?: string;
  profiles: EnumsProfile[];
  user?: CreatePersonUser;
}

export type CreatePersonUserPassword = string | null;

export type CreatePersonUserEmail = string | null;

export type CreatePersonUser = {
  email: CreatePersonUserEmail;
  gov?: GlobalPrismaJsonGovDocuments;
  password: CreatePersonUserPassword;
};

export type CreateOxenPurchaseResponseReceivedAt = string | null;

export type CreateOxenPurchaseResponseObservation = null | string;

export type CreateOxenPurchaseResponseEarringsPerPage = null | number;

export interface CreateOxenPurchaseResponse {
  category: EnumsPurchaseCategory;
  earringsPerPage: CreateOxenPurchaseResponseEarringsPerPage;
  farmId: number;
  id: number;
  invoice: string;
  issuedAt: string;
  name: string;
  observation: CreateOxenPurchaseResponseObservation;
  price: number;
  receivedAt: CreateOxenPurchaseResponseReceivedAt;
  series: string;
  status: EnumsPurchaseStatus;
}

export interface CreateOxenPurchase {
  buyerId: number;
  commission: number;
  distance: number;
  gtaIds: number[];
  invoice: string;
  issuedAt: string;
  name: string;
  observation?: string;
  ownerId: number;
  price: number;
  purpose: EnumsOxPurpose;
  series: string;
  shipping: number;
  traceable: boolean;
  weightAverage: number;
}

export type CreateOxResponseSisbov = null | number;

export type CreateOxResponsePurchaseId = null | number;

export type CreateOxResponsePriceAtSign = null | number;

export type CreateOxResponseInvoice = null | string;

export type CreateOxResponseIdentifier = null | string;

export type CreateOxResponseGtaId = null | number;

export type CreateOxResponseExitTruckId = null | number;

export type CreateOxResponseElectronic = null | string;

export type CreateOxResponseDiedAt = string | null;

export type CreateOxResponseDeathNecropsy = EnumsDeathNecropsy | null;

export type CreateOxResponseDeathDetails = null | string;

export type CreateOxResponseDeathCause = EnumsDeathCause | null;

export type CreateOxResponseComments = null | string;

export type CreateOxResponseCarcassWeight = null | number;

export type CreateOxResponseCarcassType = EnumsCarcassType | null;

export type CreateOxResponseCarcassThin = null | number;

export type CreateOxResponseCarcassPrice = null | number;

export type CreateOxResponseCarcassMissing = null | number;

export type CreateOxResponseCarcassMedium = null | number;

export type CreateOxResponseCarcassExcessive = null | number;

export type CreateOxResponseCarcass = null | number;

export interface CreateOxResponse {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: CreateOxResponseCarcass;
  carcassExcessive: CreateOxResponseCarcassExcessive;
  carcassMedium: CreateOxResponseCarcassMedium;
  carcassMissing: CreateOxResponseCarcassMissing;
  carcassPrice: CreateOxResponseCarcassPrice;
  carcassThin: CreateOxResponseCarcassThin;
  carcassType: CreateOxResponseCarcassType;
  carcassWeight: CreateOxResponseCarcassWeight;
  comments: CreateOxResponseComments;
  deathCause: CreateOxResponseDeathCause;
  deathDetails: CreateOxResponseDeathDetails;
  deathNecropsy: CreateOxResponseDeathNecropsy;
  diedAt: CreateOxResponseDiedAt;
  electronic: CreateOxResponseElectronic;
  exitTruckId: CreateOxResponseExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: CreateOxResponseGtaId;
  id: number;
  identifier: CreateOxResponseIdentifier;
  includedAt: string;
  invoice: CreateOxResponseInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: CreateOxResponsePriceAtSign;
  purchaseId: CreateOxResponsePurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: CreateOxResponseSisbov;
  sisbovHistory: number[];
  variety: string;
}

export interface CreateOx {
  ageInMonths: number;
  /** O batch que o boi vai começar */
  batchId: number;
  /** Raça da BND */
  breed: string;
  comments?: string;
  /** Dados do brinco eletrônico */
  electronic?: string;
  /** Id do protocolo de saúde */
  extraMedicineIds?: number[];
  gender: EnumsOxGender;
  /** Id da gta */
  gtaId?: number;
  /** Id do protocolo de saúde */
  healthProtocolId?: number;
  identifier?: string;
  /** Id do pedido de compra */
  purchaseId?: number;
  runtime: number;
  sisbov?: number;
  /** Raça interna */
  variety: string;
  weight: number;
}

export type CreateOccurrenceResponseOldFactor = null | number;

export type CreateOccurrenceResponseNewFactor = null | number;

export type CreateOccurrenceResponseName = null | string;

export interface CreateOccurrenceResponse {
  category: EnumsOccurrenceCategory;
  date: string;
  description: string;
  farmId: number;
  id: number;
  name: CreateOccurrenceResponseName;
  newFactor: CreateOccurrenceResponseNewFactor;
  oldFactor: CreateOccurrenceResponseOldFactor;
  type: EnumsOccurrenceType;
}

export interface CreateOccurrence {
  batchIds?: number[];
  category: EnumsOccurrenceCategory;
  date: string;
  description: string;
  name?: string;
  newFactor?: number;
  oldFactor?: number;
  type: EnumsOccurrenceType;
}

export type CreateMedicineResponseKiloDose = null | number;

export interface CreateMedicineResponse {
  blockingDays: number;
  createdAt: string;
  dosePrice: number;
  farmId: number;
  id: number;
  kiloDose: CreateMedicineResponseKiloDose;
  manufacturer: string;
  measurement: EnumsMeasurement;
  name: string;
  type: EnumsMedicineType;
}

export interface CreateMedicine {
  blockingDays: number;
  dosePrice: number;
  kiloDose: number;
  manufacturer: string;
  measurement: EnumsMeasurement;
  name: string;
  type: EnumsMedicineType;
}

export interface CreateHealthProtocolResponse {
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
}

export interface CreateHealthProtocol {
  medicineIds: number[];
  name: string;
}

export type CreateGtaResponsePurchaseId = null | number;

export type CreateGtaResponseDescription = null | string;

export interface CreateGtaResponse {
  completed: boolean;
  description: CreateGtaResponseDescription;
  emission: string;
  entry: string;
  expiration: string;
  farmId: number;
  id: number;
  number: string;
  originId: number;
  purchaseId: CreateGtaResponsePurchaseId;
  series: string;
}

export type CreateGtaOxenAgesItem = {
  femaleTotal: number;
  maleTotal: number;
  yearsOld: number;
};

export interface CreateGta {
  description?: string;
  emission: string;
  entry: string;
  expiration: string;
  number: string;
  originId: number;
  oxenAges: CreateGtaOxenAgesItem[];
  series: string;
}

export type CreateFoodPurchaseResponseReceivedAt = string | null;

export type CreateFoodPurchaseResponseObservation = null | string;

export type CreateFoodPurchaseResponseEarringsPerPage = null | number;

export interface CreateFoodPurchaseResponse {
  category: EnumsPurchaseCategory;
  earringsPerPage: CreateFoodPurchaseResponseEarringsPerPage;
  farmId: number;
  id: number;
  invoice: string;
  issuedAt: string;
  name: string;
  observation: CreateFoodPurchaseResponseObservation;
  price: number;
  receivedAt: CreateFoodPurchaseResponseReceivedAt;
  series: string;
  status: EnumsPurchaseStatus;
}

export interface CreateFoodPurchase {
  amount: number;
  invoice: string;
  issuedAt: string;
  name: string;
  observation?: string;
  price: number;
  series: string;
  stockId: number;
}

export type CreateFarmResponseUserPersonPhone = null | string;

export type CreateFarmResponseUserPersonDocument = null | string;

export type CreateFarmResponseUserPersonDescription = null | string;

export type CreateFarmResponseUserPerson = {
  createdAt: string;
  description: CreateFarmResponseUserPersonDescription;
  document: CreateFarmResponseUserPersonDocument;
  farmId: number;
  id: number;
  imageUrl: string;
  name: string;
  phone: CreateFarmResponseUserPersonPhone;
  profiles: EnumsProfile[];
};

export type CreateFarmResponseUserPassword = null | string;

export type CreateFarmResponseUserGov = GlobalPrismaJsonGovDocuments | null;

export type CreateFarmResponseUserEmail = null | string;

export type CreateFarmResponseUser = {
  email: CreateFarmResponseUserEmail;
  farmId: number;
  gov: CreateFarmResponseUserGov;
  id: number;
  password: CreateFarmResponseUserPassword;
  person: CreateFarmResponseUserPerson;
  personId: number;
};

export interface CreateFarmResponse {
  farm: CreateFarmResponseFarm;
  user: CreateFarmResponseUser;
}

export type CreateFarmResponseFarmMatinalReadTime = string | null;

export type CreateFarmResponseFarmLastCertifierImport = string | null;

export type CreateFarmResponseFarmLastBndImport = string | null;

export type CreateFarmResponseFarmAlias = null | string;

export type CreateFarmResponseFarm = {
  addressId: number;
  alias: CreateFarmResponseFarmAlias;
  blockedDays: number;
  createdAt: string;
  dailyCost: number;
  day: string;
  divisionThreshold: number;
  factory: GlobalPrismaJsonLatLng[];
  gradeInfo: unknown;
  gradeMethod: EnumsGradeMethod;
  id: number;
  lastBndImport: CreateFarmResponseFarmLastBndImport;
  lastCertifierImport: CreateFarmResponseFarmLastCertifierImport;
  managementConfig: unknown;
  matinalReadTime: CreateFarmResponseFarmMatinalReadTime;
  name: string;
  nightlyReadTimes: string[];
  position: GlobalPrismaJsonLatLng;
};

export interface CreateFarmOwner {
  document?: string;
  email?: string;
  ERAS?: string;
  image?: string;
  INCRA?: string;
  IS?: string;
  name: string;
  NIRF?: string;
  password: string;
}

export interface CreateFarm {
  address: CreateAddress;
  alias?: string;
  /** Dia de operação inicial */
  day: string;
  factory: GlobalPrismaJsonLatLng[];
  name: string;
  owner: CreateFarmOwner;
  position: GlobalPrismaJsonLatLng;
  structures: CreateStructure[];
}

export type CreateEstablishmentResponseCode = null | string;

export interface CreateEstablishmentResponse {
  addressId: number;
  code: CreateEstablishmentResponseCode;
  destiny: boolean;
  farmId: number;
  gov: GlobalPrismaJsonGovDocuments;
  id: number;
  name: string;
  origin: boolean;
  responsibleId: number;
}

export type CreateEstablishmentResponsibleAnyOf = {
  description?: string;
  document?: string;
  imageUrl?: string;
  name: string;
  phone?: string;
};

/** (EXTERN) Usuário responsável por este estabelecimento. */
export type CreateEstablishmentResponsible = number | CreateEstablishmentResponsibleAnyOf;

export interface CreateEstablishment {
  address: CreateEstablishmentAddress;
  /** Se este estabelecimento pode ser usado como destino */
  destiny: boolean;
  /** [GovDocuments] Documentos deste estabelecimento */
  gov: GlobalPrismaJsonGovDocuments;
  /** Nome do estabelecimento */
  name: string;
  /** Se este estabelecimento pode ser usado como origem */
  origin: boolean;
  /** (EXTERN) Usuário responsável por este estabelecimento. */
  responsible: CreateEstablishmentResponsible;
}

export type CreateEstablishmentAddressAnyOf = {
  city: string;
  complement?: string;
  country: string;
  neighborhood?: string;
  number?: string;
  state: string;
  street?: string;
  zip: string;
};

export type CreateEstablishmentAddress = number | CreateEstablishmentAddressAnyOf;

export type CreateEarringPurchaseResponseReceivedAt = string | null;

export type CreateEarringPurchaseResponseObservation = null | string;

export type CreateEarringPurchaseResponseEarringsPerPage = null | number;

export interface CreateEarringPurchaseResponse {
  category: EnumsPurchaseCategory;
  earringsPerPage: CreateEarringPurchaseResponseEarringsPerPage;
  farmId: number;
  id: number;
  invoice: string;
  issuedAt: string;
  name: string;
  observation: CreateEarringPurchaseResponseObservation;
  price: number;
  receivedAt: CreateEarringPurchaseResponseReceivedAt;
  series: string;
  status: EnumsPurchaseStatus;
}

export interface CreateEarringPurchase {
  brand: string;
  earringsPerPage: number;
  finalSisbov: number;
  initialSisbov: number;
  invoice: string;
  issuedAt: string;
  name: string;
  observation?: string;
  ownerId: number;
  price: number;
  series: string;
}

export interface CreateDietTransitionResponse {
  days: GlobalPrismaJsonDietTransitionDay[];
  divisions: number;
  farmId: number;
  id: number;
  name: string;
}

export interface CreateDietTransition {
  /** Um array de boolean para como true -> novo trato e false -> trato antigo (length = quantia de dias). */
  days: GlobalPrismaJsonDietTransitionDay[];
  name: string;
}

export type CreateDietResponseObservation = null | string;

export type CreateDietResponseCategory = EnumsDietCategory | null;

export interface CreateDietResponse {
  active: boolean;
  category: CreateDietResponseCategory;
  createdAt: string;
  estimatedWeightGain: number;
  expiry: number;
  farmId: number;
  id: number;
  name: string;
  observation: CreateDietResponseObservation;
}

export interface CreateDiet {
  category?: EnumsDietCategory;
  estimatedWeightGain: number;
  expiry: number;
  ingredients: Ingredient[];
  name: string;
  observation?: string;
}

export interface CreateCorralResponse {
  area: number;
  capacity: number;
  createdAt: string;
  farmId: number;
  id: number;
  name: string;
  polygon: GlobalPrismaJsonLatLng[];
  position: number;
  type: EnumsCorralType;
}

export interface CreateCorral {
  area: number;
  capacity: number;
  name: string;
  polygon: GlobalPrismaJsonLatLng[];
  /** Position auto preenchido, caso não fornecido. */
  position?: number;
  type: EnumsCorralType;
}

export type CreateBatchResponseOtherCost = null | number;

export type CreateBatchResponseOldCorralId = null | number;

export type CreateBatchResponseForeseenWeight = null | number;

export type CreateBatchResponseForeseenCarcass = null | number;

export type CreateBatchResponseFinalizedAt = string | null;

export type CreateBatchResponseExitPrice = null | number;

export type CreateBatchResponseCorralId = null | number;

export type CreateBatchResponseCarcass = null | number;

export type CreateBatchResponseAdmCost = null | number;

export type CreateBatchResponseActivatedAt = string | null;

export interface CreateBatchResponse {
  activatedAt: CreateBatchResponseActivatedAt;
  admCost: CreateBatchResponseAdmCost;
  carcass: CreateBatchResponseCarcass;
  corralId: CreateBatchResponseCorralId;
  createdAt: string;
  exitPrice: CreateBatchResponseExitPrice;
  farmId: number;
  finalizedAt: CreateBatchResponseFinalizedAt;
  foreseenCarcass: CreateBatchResponseForeseenCarcass;
  foreseenWeight: CreateBatchResponseForeseenWeight;
  id: number;
  liveWeight: number;
  name: string;
  oldCorralId: CreateBatchResponseOldCorralId;
  otherCost: CreateBatchResponseOtherCost;
}

export interface CreateBatch {
  corralId: number;
  name: string;
}

export type CreateAnnouncementResponseEmittedAt = string | null;

export interface CreateAnnouncementResponse {
  data: GlobalPrismaJsonAnnouncementData;
  emittedAt: CreateAnnouncementResponseEmittedAt;
  farmId: number;
  id: number;
  type: EnumsAnnouncementType;
}

export interface CreateAnnouncement {
  data: GlobalPrismaJsonAnnouncementData;
  date: string;
  type: EnumsAnnouncementType;
}

export type CreateAddressResponseStreet = null | string;

export type CreateAddressResponseNumber = null | string;

export type CreateAddressResponseNeighborhood = null | string;

export type CreateAddressResponseComplement = null | string;

export interface CreateAddressResponse {
  city: string;
  complement: CreateAddressResponseComplement;
  country: string;
  id: number;
  neighborhood: CreateAddressResponseNeighborhood;
  number: CreateAddressResponseNumber;
  state: string;
  street: CreateAddressResponseStreet;
  zip: string;
}

export interface CreateAddress {
  city: string;
  complement?: string;
  country: string;
  neighborhood?: string;
  number?: string;
  state: string;
  street?: string;
  zip: string;
}

export type CorralType = EnumsCorralType;

export interface CorralFilter {
  /** Não tem lotes no momento */
  empty?: boolean;
  type?: EnumsCorralType;
}

export interface CorralChangePosition {
  corralId: number;
  position: number;
}

export interface CloseSaleManagementResponse {
  carcassWeightAverage: number;
  carcassYieldAverage: number;
  oxenUpdated: CloseSaleManagementResponseOxenUpdatedItem[];
  priceAtSignAverage: number;
}

export type CloseSaleManagementResponseOxenUpdatedItemSisbov = null | number;

export type CloseSaleManagementResponseOxenUpdatedItemPurchaseId = null | number;

export type CloseSaleManagementResponseOxenUpdatedItemPriceAtSign = null | number;

export type CloseSaleManagementResponseOxenUpdatedItemInvoice = null | string;

export type CloseSaleManagementResponseOxenUpdatedItemIdentifier = null | string;

export type CloseSaleManagementResponseOxenUpdatedItemGtaId = null | number;

export type CloseSaleManagementResponseOxenUpdatedItemExitTruckId = null | number;

export type CloseSaleManagementResponseOxenUpdatedItemElectronic = null | string;

export type CloseSaleManagementResponseOxenUpdatedItemDiedAt = string | null;

export type CloseSaleManagementResponseOxenUpdatedItemDeathNecropsy = EnumsDeathNecropsy | null;

export type CloseSaleManagementResponseOxenUpdatedItemDeathDetails = null | string;

export type CloseSaleManagementResponseOxenUpdatedItemDeathCause = EnumsDeathCause | null;

export type CloseSaleManagementResponseOxenUpdatedItemComments = null | string;

export type CloseSaleManagementResponseOxenUpdatedItemCarcassWeight = null | number;

export type CloseSaleManagementResponseOxenUpdatedItemCarcassType = EnumsCarcassType | null;

export type CloseSaleManagementResponseOxenUpdatedItemCarcassThin = null | number;

export type CloseSaleManagementResponseOxenUpdatedItemCarcassPrice = null | number;

export type CloseSaleManagementResponseOxenUpdatedItemCarcassMissing = null | number;

export type CloseSaleManagementResponseOxenUpdatedItemCarcassMedium = null | number;

export type CloseSaleManagementResponseOxenUpdatedItemCarcassExcessive = null | number;

export type CloseSaleManagementResponseOxenUpdatedItemCarcass = null | number;

export type CloseSaleManagementResponseOxenUpdatedItem = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: CloseSaleManagementResponseOxenUpdatedItemCarcass;
  carcassExcessive: CloseSaleManagementResponseOxenUpdatedItemCarcassExcessive;
  carcassMedium: CloseSaleManagementResponseOxenUpdatedItemCarcassMedium;
  carcassMissing: CloseSaleManagementResponseOxenUpdatedItemCarcassMissing;
  carcassPrice: CloseSaleManagementResponseOxenUpdatedItemCarcassPrice;
  carcassThin: CloseSaleManagementResponseOxenUpdatedItemCarcassThin;
  carcassType: CloseSaleManagementResponseOxenUpdatedItemCarcassType;
  carcassWeight: CloseSaleManagementResponseOxenUpdatedItemCarcassWeight;
  comments: CloseSaleManagementResponseOxenUpdatedItemComments;
  deathCause: CloseSaleManagementResponseOxenUpdatedItemDeathCause;
  deathDetails: CloseSaleManagementResponseOxenUpdatedItemDeathDetails;
  deathNecropsy: CloseSaleManagementResponseOxenUpdatedItemDeathNecropsy;
  diedAt: CloseSaleManagementResponseOxenUpdatedItemDiedAt;
  electronic: CloseSaleManagementResponseOxenUpdatedItemElectronic;
  exitTruckId: CloseSaleManagementResponseOxenUpdatedItemExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: CloseSaleManagementResponseOxenUpdatedItemGtaId;
  id: number;
  identifier: CloseSaleManagementResponseOxenUpdatedItemIdentifier;
  includedAt: string;
  invoice: CloseSaleManagementResponseOxenUpdatedItemInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: CloseSaleManagementResponseOxenUpdatedItemPriceAtSign;
  purchaseId: CloseSaleManagementResponseOxenUpdatedItemPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: CloseSaleManagementResponseOxenUpdatedItemSisbov;
  sisbovHistory: number[];
  variety: string;
};

export interface CheckOxIdentification {
  /** Dados do brinco eletrônico */
  electronic?: string;
  sisbov?: number;
}

export interface CheckGtasResponse {
  emitted: boolean;
  gtas: number[];
}

export type ChangeTreatmentResponseOtherCost = null | number;

export type ChangeTreatmentResponseOldCorralId = null | number;

export type ChangeTreatmentResponseForeseenWeight = null | number;

export type ChangeTreatmentResponseForeseenCarcass = null | number;

export type ChangeTreatmentResponseFinalizedAt = string | null;

export type ChangeTreatmentResponseExitPrice = null | number;

export type ChangeTreatmentResponseCorralId = null | number;

export type ChangeTreatmentResponseCarcass = null | number;

export type ChangeTreatmentResponseAdmCost = null | number;

export type ChangeTreatmentResponseActivatedAt = string | null;

export interface ChangeTreatmentResponse {
  activatedAt: ChangeTreatmentResponseActivatedAt;
  admCost: ChangeTreatmentResponseAdmCost;
  carcass: ChangeTreatmentResponseCarcass;
  corralId: ChangeTreatmentResponseCorralId;
  createdAt: string;
  exitPrice: ChangeTreatmentResponseExitPrice;
  farmId: number;
  finalizedAt: ChangeTreatmentResponseFinalizedAt;
  foreseenCarcass: ChangeTreatmentResponseForeseenCarcass;
  foreseenWeight: ChangeTreatmentResponseForeseenWeight;
  id: number;
  liveWeight: number;
  name: string;
  oldCorralId: ChangeTreatmentResponseOldCorralId;
  otherCost: ChangeTreatmentResponseOtherCost;
}

export interface ChangeTreatment {
  batchId: number;
  treatmentId: number;
}

export interface ChangeOfCharacteristicAnnouncement {
  newBreed?: string;
  newGender?: EnumsOxGender;
  oldBreed?: string;
  oldGender?: EnumsOxGender;
  oxId: number;
}

export type CarcassType = EnumsCarcassType;

export interface CanAdvanceFarmDayResponse {
  can: boolean;
  reason: AdvanceDayReason;
}

export interface BoardOxResponse {
  covWeight: number;
  gmd: number;
  totalBoarded: number;
  truck: BoardOxResponseTruck;
  weightAverage: number;
}

export type BoardOxResponseTruckSaleId = null | number;

export type BoardOxResponseTruckPlate = null | string;

export type BoardOxResponseTruckName = null | string;

export type BoardOxResponseTruckExitGta = null | string;

export type BoardOxResponseTruckDestinyId = null | number;

export type BoardOxResponseTruck = {
  announcementId: BoardOxResponseTruckAnnouncementId;
  boarding: BoardOxResponseTruckBoardingItem[];
  capacity: number;
  destinyId: BoardOxResponseTruckDestinyId;
  exitAt: string;
  exitGta: BoardOxResponseTruckExitGta;
  farmId: number;
  id: number;
  name: BoardOxResponseTruckName;
  plate: BoardOxResponseTruckPlate;
  reason: EnumsExitReason;
  saleId: BoardOxResponseTruckSaleId;
  status: EnumsExitTruckStatus;
};

export type BoardOxResponseTruckBoardingItemSisbov = null | number;

export type BoardOxResponseTruckBoardingItemPurchaseId = null | number;

export type BoardOxResponseTruckBoardingItemPriceAtSign = null | number;

export type BoardOxResponseTruckBoardingItemInvoice = null | string;

export type BoardOxResponseTruckBoardingItemIdentifier = null | string;

export type BoardOxResponseTruckBoardingItemGtaId = null | number;

export type BoardOxResponseTruckBoardingItemExitTruckId = null | number;

export type BoardOxResponseTruckBoardingItemElectronic = null | string;

export type BoardOxResponseTruckBoardingItemDiedAt = string | null;

export type BoardOxResponseTruckBoardingItemDeathNecropsy = EnumsDeathNecropsy | null;

export type BoardOxResponseTruckBoardingItemDeathDetails = null | string;

export type BoardOxResponseTruckBoardingItemDeathCause = EnumsDeathCause | null;

export type BoardOxResponseTruckBoardingItemComments = null | string;

export type BoardOxResponseTruckBoardingItemCarcassWeight = null | number;

export type BoardOxResponseTruckBoardingItemCarcassType = EnumsCarcassType | null;

export type BoardOxResponseTruckBoardingItemCarcassThin = null | number;

export type BoardOxResponseTruckBoardingItemCarcassPrice = null | number;

export type BoardOxResponseTruckBoardingItemCarcassMissing = null | number;

export type BoardOxResponseTruckBoardingItemCarcassMedium = null | number;

export type BoardOxResponseTruckBoardingItemCarcassExcessive = null | number;

export type BoardOxResponseTruckBoardingItemCarcass = null | number;

export type BoardOxResponseTruckBoardingItem = {
  birth: string;
  blockedUntil: string;
  breed: string;
  carcass: BoardOxResponseTruckBoardingItemCarcass;
  carcassExcessive: BoardOxResponseTruckBoardingItemCarcassExcessive;
  carcassMedium: BoardOxResponseTruckBoardingItemCarcassMedium;
  carcassMissing: BoardOxResponseTruckBoardingItemCarcassMissing;
  carcassPrice: BoardOxResponseTruckBoardingItemCarcassPrice;
  carcassThin: BoardOxResponseTruckBoardingItemCarcassThin;
  carcassType: BoardOxResponseTruckBoardingItemCarcassType;
  carcassWeight: BoardOxResponseTruckBoardingItemCarcassWeight;
  comments: BoardOxResponseTruckBoardingItemComments;
  deathCause: BoardOxResponseTruckBoardingItemDeathCause;
  deathDetails: BoardOxResponseTruckBoardingItemDeathDetails;
  deathNecropsy: BoardOxResponseTruckBoardingItemDeathNecropsy;
  diedAt: BoardOxResponseTruckBoardingItemDiedAt;
  electronic: BoardOxResponseTruckBoardingItemElectronic;
  exitTruckId: BoardOxResponseTruckBoardingItemExitTruckId;
  farmId: number;
  gender: EnumsOxGender;
  gtaId: BoardOxResponseTruckBoardingItemGtaId;
  id: number;
  identifier: BoardOxResponseTruckBoardingItemIdentifier;
  includedAt: string;
  invoice: BoardOxResponseTruckBoardingItemInvoice;
  ownerId: number;
  preConfinementDays: number;
  priceAtSign: BoardOxResponseTruckBoardingItemPriceAtSign;
  purchaseId: BoardOxResponseTruckBoardingItemPurchaseId;
  purpose: EnumsOxPurpose;
  runtime: number;
  sisbov: BoardOxResponseTruckBoardingItemSisbov;
  sisbovHistory: number[];
  variety: string;
};

export type BoardOxResponseTruckAnnouncementId = null | number;

export interface BoardOx {
  currentOxWeight: number;
  oxId: number;
}

export type BeatViewResponseItemStocksItem = {
  foreseen: number;
  id: number;
  name: string;
};

export type BeatViewResponseItem = {
  foreseen: number;
  id: number;
  name: string;
  stocks: BeatViewResponseItemStocksItem[];
};

export type BeatViewResponse = BeatViewResponseItem[];

export type BatchesOxenResponseOwnerItem = string | unknown;

export type BatchesOxenResponseCorralAnyOf = {
  id: number;
  name: string;
};

export type BatchesOxenResponseCorral = BatchesOxenResponseCorralAnyOf | null;

export interface BatchesOxenResponse {
  corral: BatchesOxenResponseCorral;
  id: number;
  name: string;
  owner: BatchesOxenResponseOwnerItem[];
  oxenAmount: number;
  weightAverage: number;
}

export type BatchesDetailsResponse = BatchesDetailsResponseItem[];

export type BatchesDetailsResponseItemOwnerItem = string | unknown;

export type BatchesDetailsResponseItem = {
  breeds: string[];
  corral: BatchesDetailsResponseItemCorral;
  createdAt: string;
  diet?: BatchesDetailsResponseItemDiet;
  entryType: EnumsOxPurpose[];
  genders: EnumsOxGender[];
  id: number;
  name: string;
  owner: BatchesDetailsResponseItemOwnerItem[];
  oxenAmount: number;
  price: number;
  weightAverage?: number;
};

export type BatchesDetailsResponseItemDietDiet = {
  name: string;
};

export type BatchesDetailsResponseItemDiet = {
  diet: BatchesDetailsResponseItemDietDiet;
};

export type BatchesDetailsResponseItemCorralAnyOf = {
  name: string;
  type: EnumsCorralType;
};

export type BatchesDetailsResponseItemCorral = BatchesDetailsResponseItemCorralAnyOf | null;

export interface BatchStatsResponse {
  cov: number;
  oxen: BatchStatsResponseOxenItem[];
  oxenAmount: number;
  weightAverage: number;
}

export type BatchStatsResponseOxenItemSisbov = null | number;

export type BatchStatsResponseOxenItemIdentifier = null | string;

export type BatchStatsResponseOxenItem = {
  breed: string;
  gender: EnumsOxGender;
  identifier: BatchStatsResponseOxenItemIdentifier;
  sisbov: BatchStatsResponseOxenItemSisbov;
  weight: number;
};

/** 1 horário para cada lote (Usado em array para vários lotes) */
export interface BatchNightlyRead {
  batchId: number;
  hadFood: boolean;
  hour: string;
}

export type BatchHistoryReportResponseCarcass = null | number;

export interface BatchHistoryReportResponse {
  carcass: BatchHistoryReportResponseCarcass;
  corral?: string;
  history: BatchHistoryReport[];
  name: string;
}

export interface BatchHistoryReportFilter {
  batchId: number;
  end?: string;
  start: string;
}

/** Total $ extra */
export type BatchHistoryReportTotalExtra = null | number;

export interface BatchHistoryReport {
  /** Compra de Animais (???) */
  animalsPurchase: number;
  /** Comissão da compra de animais */
  animalsPurchaseCommission: number;
  /** Frete da compra de animais */
  animalsPurchaseShipping: number;
  /** PV (kg/cab) */
  avgWeight?: number;
  /** Diária de trati ($/cab/d) */
  dailyTract?: number;
  day: string;
  /** Dias de uso da dieta atual */
  daysOfCurrentDiet: number;
  /** Morte de animais */
  deaths: number;
  /** Custo da dieta */
  dietTreatDayCost: number;
  /** Consumo ms (kg/cab) */
  dryMatterConsume?: number;
  /** Saldo */
  entryAndExitOxenBalance: number;
  /** Entrada de animais */
  entryOxen: number;
  /** Saida de animais */
  exitOxen: number;
  /** Consumo previsto ms (kg) */
  foreseenDryMatterConsume?: number;
  /** Consumo previsto mo (kg) */
  foreseenNaturalMatterConsume: number;
  /** PV Ini (kg/cab) */
  initialAvgWeight: number;
  /** Cons (kgMS/%PV) */
  liveWeightDryMatterConsume: number;
  /** Cons (kgMO/%PV) */
  liveWeightNaturalMatterConsume: number;
  /** Consumo mo (kg/cab) */
  naturalMatterConsume?: number;
  /** Cons Fabr MO(kg) */
  naturalMatterFabricationConsume?: number;
  /** Animais (cab) */
  oxenAmount?: number;
  /** Retorno à pasto */
  pastureReturns: number;
  /** Consumo real ms */
  realDryMatterConsume?: number;
  /** Consumo real mo */
  realNaturalMatterConsume?: number;
  /** Total $ administrativo */
  totalAdministrativeCost: number;
  /** Total $ Compra de Animais */
  totalAnimalPurchase: number;
  /** Total diaria */
  totalDaily: number;
  /** Total diaria de trato */
  totalDailyTract?: number;
  /** Total $ fabricação de dieta */
  totalDietFabrication: number;
  /** Total $ extra */
  totalExtra: BatchHistoryReportTotalExtra;
  /** Total consumo mo fabricação */
  totalNaturalMatterFabricationConsume?: number;
  /** Total $ total */
  totalTotal: number;
  /** Total $ custo de trato */
  totalTractCost: number;
  /** Tota consumo ms do trato */
  totalTractDryMatterConsume?: number;
  /** Total consumo mo do trato */
  totalTractNaturalMatterConsume?: number;
  /** Total de insumos veterinários */
  totalVeterinarySupplies: number;
  /** Total PV */
  totalWeight: number;
  /** Custo de trato */
  tractDayCost: number;
  /** Tratos */
  tracts?: number;
}

export type BatchEconomicEvaluationResponseResume = {
  admCost: number;
  animalPurchaseCost: number;
  animalPurchaseCostPerCarcass: number;
  avgDailyGain: number;
  avgDaysOfConfinament: number;
  avgDaysOfTract: number;
  carcass: number;
  carcassGain: number;
  carcassWeightAtSign: number;
  carcassWeightKile: number;
  closedBatches: number;
  dailyCarcassGain: number;
  deaths: number;
  entryLiveWeight: number;
  estimatedWeigthCarcassEntryAtSign: number;
  estimatedWeigthCarcassEntryKile: number;
  exitLiveWeight: number;
  feedEfficiency: number;
  financialFeedback: number;
  foodConversion: number;
  foodCost: number;
  gainFoodCost: number;
  gainManagementCost: number;
  managementCost: number;
  mortality: number;
  oxenEntries: number;
  oxenExits: number;
  pastureReturns: number;
  pastureReturnsPercentage: number;
  relatedCorrals: number;
  result: number;
  sellingPrice: number;
  sellingPricePerCarcass: number;
  slaughteredOxen: number;
  veterinarySupplisCost: number;
};

export interface BatchEconomicEvaluationResponse {
  history: BatchEconomicEvaluationResponseHistoryItem[];
  resume: BatchEconomicEvaluationResponseResume;
}

export type BatchEconomicEvaluationResponseHistoryItemResults = {
  batchProfit: number;
  carcassGainPerOx: number;
  dailyOxCost: number;
  deaths: number;
  financialReturn: number;
  foodConversion: number;
  foodEfficiency: number;
  gainCost: number;
  gainFoodCost: number;
  managedCostPerOx: number;
  mortality: number;
  oxenAmount: number;
  profitPerOx: number;
  purchaseValue: number;
  totalAnimalPurchase: number;
  totalBatchCost: number;
  totalExtraCost: number;
  totalManagementCost: number;
  totalTractCost: number;
  totalVeterinarySupplies: number;
  tractDays: number;
};

export type BatchEconomicEvaluationResponseHistoryItemIdentification = {
  batch: string;
  corral?: string;
};

export type BatchEconomicEvaluationResponseHistoryItemExitsDate = string | null;

export type BatchEconomicEvaluationResponseHistoryItemExits = {
  carcass: number;
  carcassOxWeight: number;
  carcassWeightAtSign: number;
  dailyCarcassGain: number;
  dailyWeightGain: number;
  date: BatchEconomicEvaluationResponseHistoryItemExitsDate;
  oxenAmount: number;
  oxWeight: number;
  priceOx: number;
};

export type BatchEconomicEvaluationResponseHistoryItemEntries = {
  carcass: number;
  commissionPerOx: number;
  date: string;
  oxenAmount: number;
  oxWeight: number;
  priceOx: number;
  shippingPerOx: number;
};

export type BatchEconomicEvaluationResponseHistoryItem = {
  entries: BatchEconomicEvaluationResponseHistoryItemEntries;
  exits: BatchEconomicEvaluationResponseHistoryItemExits;
  identification: BatchEconomicEvaluationResponseHistoryItemIdentification;
  results: BatchEconomicEvaluationResponseHistoryItemResults;
};

export type BatchDetailsResponseSlaughter = 0 | string | null;

export type BatchDetailsResponseOwner = {
  id: number;
  name: string;
};

export type BatchDetailsResponseForeseenWeight = null | number;

export type BatchDetailsResponseForeseenCarcass = null | number;

export type BatchDetailsResponseExitPrice = null | number;

export type BatchDetailsResponseDiet = {
  estimatedWeightGain: number;
  name: string;
};

export type BatchDetailsResponseCorralAnyOf = {
  id: number;
  name: string;
};

export type BatchDetailsResponseCorral = BatchDetailsResponseCorralAnyOf | null;

export type BatchDetailsResponseCarcass = null | number;

export type BatchDetailsResponseAdmCost = null | number;

export interface BatchDetailsResponse {
  admCost: BatchDetailsResponseAdmCost;
  breeds: string[];
  carcass: BatchDetailsResponseCarcass;
  corral: BatchDetailsResponseCorral;
  date: string;
  diet?: BatchDetailsResponseDiet;
  exitPrice: BatchDetailsResponseExitPrice;
  foreseenCarcass: BatchDetailsResponseForeseenCarcass;
  foreseenWeight: BatchDetailsResponseForeseenWeight;
  genders: EnumsOxGender[];
  id: number;
  initialWeightAverage: number;
  name: string;
  otherCost: number;
  owner?: BatchDetailsResponseOwner;
  oxenAmount: number;
  price: number;
  priceAtSign: number;
  slaughter: BatchDetailsResponseSlaughter;
  type?: EnumsOxPurpose;
  varieties: string[];
  weightAverage: number;
}

/** Leitura sem ajuste diurna */
export interface BatchDailyRead {
  readType: EnumsReadType;
  /** Somente o ReadType.INVENTORY usa, no momento */
  readValue?: number;
}

export type AnnouncementType = EnumsAnnouncementType;

export interface AnnouncementSearch {
  emitted?: boolean;
  limit?: number;
  page?: number;
  type?: EnumsAnnouncementType;
}

export interface AnalyticalBatchReportReturn {
  /** Categoria */
  category: EnumsOxGender[];
  /** Confinamento */
  confinement: string;
  /** Piquete */
  corral?: string;
  diets: AnalyticalBatchReportReturnDietsItem[];
  /** Dados de Entrada */
  entryData: AnalyticalBatchReportReturnEntryData;
  /** Tipo de Entrada */
  entryType?: EnumsOxPurpose;
  /** Dados de saida */
  exitData: AnalyticalBatchReportReturnExitData;
  financialPerformance: AnalyticalBatchReportReturnFinancialPerformance;
  foods: AnalyticalBatchReportReturnFoodsItem[];
  /** Ultima dieta */
  lastDiet?: string;
  movimentationResume: AnalyticalBatchReportReturnMovimentationResume;
  /** Lote */
  name: string;
  /** Proprietário */
  owner?: string;
  technicalPerformance: AnalyticalBatchReportReturnTechnicalPerformance;
  /** Raça */
  variety: string[];
}

export type AnalyticalBatchReportReturnTechnicalPerformanceForeseenCarcass = null | number;

export type AnalyticalBatchReportReturnTechnicalPerformanceCarcass = null | number;

export type AnalyticalBatchReportReturnTechnicalPerformanceAvgNaturalMatterConsume = null | number;

export type AnalyticalBatchReportReturnTechnicalPerformanceAvgDryMatterConsumeKile = null | number;

export type AnalyticalBatchReportReturnTechnicalPerformanceAvgDryMatterConsume = null | number;

export type AnalyticalBatchReportReturnTechnicalPerformance = {
  avgDailyGain: number;
  avgDryMatterConsume: AnalyticalBatchReportReturnTechnicalPerformanceAvgDryMatterConsume;
  avgDryMatterConsumeKile: AnalyticalBatchReportReturnTechnicalPerformanceAvgDryMatterConsumeKile;
  avgNaturalMatterConsume: AnalyticalBatchReportReturnTechnicalPerformanceAvgNaturalMatterConsume;
  carcass: AnalyticalBatchReportReturnTechnicalPerformanceCarcass;
  confinementDays: number;
  dailyCarcassGain: number;
  foodConversion: number;
  foodEfficiency: number;
  foreseenCarcass: AnalyticalBatchReportReturnTechnicalPerformanceForeseenCarcass;
  mortality: number;
  oxCarcassGain: number;
  totalCarcassGain: number;
};

export type AnalyticalBatchReportReturnMovimentationResume = {
  deaths: number;
  entryAndExit: number;
  entryOxenAmount: number;
  entryTransfer: number;
  exitOxenAmount: number;
  exitTransfer: number;
  pastureReturns: number;
  totalOxenEntry: number;
  totalOxenExit: number;
};

export type AnalyticalBatchReportReturnFoodsItem = {
  days: number;
  id: number;
  matter: number;
  name: string;
  naturalMatter: number;
};

export type AnalyticalBatchReportReturnFinancialPerformanceTotalExtraCost = null | number;

export type AnalyticalBatchReportReturnFinancialPerformanceTotalAdmCost = null | number;

export type AnalyticalBatchReportReturnFinancialPerformance = {
  animalDailyCost: number;
  animalDailyFoodCost: number;
  animalPurchase: number;
  avgAnimalPurchase: number;
  avgAnimalPurchaseAtSign: number;
  finacialReturn: number;
  foreseenSalesCost: number;
  gainFoodCost: number;
  gateIn: number;
  gateOut: number;
  monthlyFinacialReturn: number;
  operacionalCost: number;
  profitPerOx: number;
  totalAdmCost: AnalyticalBatchReportReturnFinancialPerformanceTotalAdmCost;
  totalAnimalSales: number;
  totalBatchCost: number;
  totalCostFabricatedDiet: number;
  totalCostTreatedDiet: number;
  totalDaily: number;
  totalExtraCost: AnalyticalBatchReportReturnFinancialPerformanceTotalExtraCost;
  totalGainCost: number;
  totalManagementCost: number;
  totalProfit: number;
  totalSalesCost: number;
  totalTractDaily: number;
  totalVeterinarySupplies: number;
  /** Dias de trato = qtde dia * avg tratos / dia */
  tractDays: number;
};

/** Maior data */
export type AnalyticalBatchReportReturnExitDataLastExitDate = string | null;

/** Menor data */
export type AnalyticalBatchReportReturnExitDataFirstExitDate = string | null;

/** Rendimento de carcaça */
export type AnalyticalBatchReportReturnExitDataCarcass = null | number;

/** Data média */
export type AnalyticalBatchReportReturnExitDataAvgExitDate = string | null;

/** Dados de saida */
export type AnalyticalBatchReportReturnExitData = {
  /** Carcaça média */
  avgCarcass: number;
  /** Data média */
  avgExitDate: AnalyticalBatchReportReturnExitDataAvgExitDate;
  /** Peso médio */
  avgWeight?: number;
  /** Rendimento de carcaça */
  carcass: AnalyticalBatchReportReturnExitDataCarcass;
  /** Menor data */
  firstExitDate: AnalyticalBatchReportReturnExitDataFirstExitDate;
  /** Maior data */
  lastExitDate: AnalyticalBatchReportReturnExitDataLastExitDate;
  /** Animais (cab) */
  oxenExitAmount: number;
  /** Peso vivo total (kg) */
  totalLiveWeight: number;
};

/** Rendimento de carcaça */
export type AnalyticalBatchReportReturnEntryDataCarcass = null | number;

/** Dados de Entrada */
export type AnalyticalBatchReportReturnEntryData = {
  /** Data Média */
  avgEntryDate?: string;
  /** Peso médio (kg/cab) */
  avgWeight: number;
  /** Rendimento de carcaça */
  carcass: AnalyticalBatchReportReturnEntryDataCarcass;
  /** Comissão de compra ($/cab) */
  commission: number;
  /** Menor Data */
  firstEntryDate?: string;
  /** Maior Data */
  lastEntryDate?: string;
  /** Animais (cab) */
  oxenAmount: number;
  /** Frete da compra ($/cab) */
  shipping: number;
  /** Peso vivo total (kg) */
  totalLiveWeight: number;
};

export type AnalyticalBatchReportReturnDietsItem = {
  days: number;
  matter: number;
  name: string;
  naturalMatter: number;
};

export type AdvanceDayReason = (typeof AdvanceDayReason)[keyof typeof AdvanceDayReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdvanceDayReason = {
  NO_HISTORY_DONE: 'NO_HISTORY_DONE',
  NOT_ALL_BATCHES_WERE_MANAGED: 'NOT_ALL_BATCHES_WERE_MANAGED',
  NO_TRACT_REALIZED: 'NO_TRACT_REALIZED',
  NOT_ALL_TRACTS_WERE_REALIZED: 'NOT_ALL_TRACTS_WERE_REALIZED',
  ALL_REQUIREMENTS_WERE_MET: 'ALL_REQUIREMENTS_WERE_MET',
  CANNOT_ADVANCE_CURRENT_DAY: 'CANNOT_ADVANCE_CURRENT_DAY'
} as const;

export type AdjustCurrentForeseenResponseReadValue = null | number;

export type AdjustCurrentForeseenResponseReadType = EnumsReadType | null;

export type AdjustCurrentForeseenResponseManagedAt = string | null;

export interface AdjustCurrentForeseenResponse {
  batchId: number;
  day: string;
  dietId: number;
  farmId: number;
  id: number;
  managedAt: AdjustCurrentForeseenResponseManagedAt;
  matterVariation: number;
  nightlyReads: GlobalPrismaJsonNightlyRead[];
  oxenAmount: number;
  readType: AdjustCurrentForeseenResponseReadType;
  readValue: AdjustCurrentForeseenResponseReadValue;
  realizedTreatDistributions: number[];
  realizedTreatMatter: number;
  realizedTreatNaturalMatter: number;
  resultingMatter: number;
  tracerRealizedSequential: GlobalPrismaJsonTracerRealizedSequential[];
  treatmentId: number;
  weightAverage: number;
}

/** Todos os horários para um lote */
export interface AdjustCurrentForeseen {
  /** Pode ser undefined caso o ignore management for true */
  adjust?: number;
  /** Caso não devemos marcar o manejo como feito */
  ignoreManagement?: boolean;
  /** Caso queira informar a leitura noturna de */
  nightlyReads?: GlobalPrismaJsonNightlyRead[];
  readType: EnumsReadType;
  /** Somente o ReadType.INVENTORY usa, no momento */
  readValue?: number;
}

export type AddMedicineImagesResponse = AddMedicineImagesResponseItem[];

export type AddMedicineImagesResponseItemName = null | string;

export type AddMedicineImagesResponseItemMedicineId = null | number;

export type AddMedicineImagesResponseItemHealthProtocolId = null | number;

export type AddMedicineImagesResponseItem = {
  base64: string;
  farmId: number;
  healthProtocolId: AddMedicineImagesResponseItemHealthProtocolId;
  id: number;
  medicineId: AddMedicineImagesResponseItemMedicineId;
  name: AddMedicineImagesResponseItemName;
};

export interface AddMedicineImage {
  base64: string;
  name?: string;
}

export type AddHealthProtocolImagesResponse = AddHealthProtocolImagesResponseItem[];

export type AddHealthProtocolImagesResponseItemName = null | string;

export type AddHealthProtocolImagesResponseItemMedicineId = null | number;

export type AddHealthProtocolImagesResponseItemHealthProtocolId = null | number;

export type AddHealthProtocolImagesResponseItem = {
  base64: string;
  farmId: number;
  healthProtocolId: AddHealthProtocolImagesResponseItemHealthProtocolId;
  id: number;
  medicineId: AddHealthProtocolImagesResponseItemMedicineId;
  name: AddHealthProtocolImagesResponseItemName;
};

export interface AddHealthProtocolImage {
  base64: string;
  name?: string;
}

export type ActivateBatchResponseOtherCost = null | number;

export type ActivateBatchResponseOldCorralId = null | number;

export type ActivateBatchResponseForeseenWeight = null | number;

export type ActivateBatchResponseForeseenCarcass = null | number;

export type ActivateBatchResponseFinalizedAt = string | null;

export type ActivateBatchResponseExitPrice = null | number;

export type ActivateBatchResponseCorralId = null | number;

export type ActivateBatchResponseCarcass = null | number;

export type ActivateBatchResponseAdmCost = null | number;

export type ActivateBatchResponseActivatedAt = string | null;

export interface ActivateBatchResponse {
  activatedAt: ActivateBatchResponseActivatedAt;
  admCost: ActivateBatchResponseAdmCost;
  carcass: ActivateBatchResponseCarcass;
  corralId: ActivateBatchResponseCorralId;
  createdAt: string;
  exitPrice: ActivateBatchResponseExitPrice;
  farmId: number;
  finalizedAt: ActivateBatchResponseFinalizedAt;
  foreseenCarcass: ActivateBatchResponseForeseenCarcass;
  foreseenWeight: ActivateBatchResponseForeseenWeight;
  id: number;
  liveWeight: number;
  name: string;
  oldCorralId: ActivateBatchResponseOldCorralId;
  otherCost: ActivateBatchResponseOtherCost;
}

export type EnumsWeightSource = (typeof EnumsWeightSource)[keyof typeof EnumsWeightSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsWeightSource = {
  CALCULATED: 'CALCULATED',
  WEIGHTED: 'WEIGHTED'
} as const;

export type EnumsStructureCategory = (typeof EnumsStructureCategory)[keyof typeof EnumsStructureCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsStructureCategory = {
  BOX: 'BOX',
  AERIAL_SILO: 'AERIAL_SILO',
  TRENCH_SILO: 'TRENCH_SILO',
  SURFACE_SILO: 'SURFACE_SILO',
  MIXER: 'MIXER',
  MILL: 'MILL',
  OTHER: 'OTHER'
} as const;

export type EnumsReadType = (typeof EnumsReadType)[keyof typeof EnumsReadType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsReadType = {
  DRY: 'DRY',
  CRUMB: 'CRUMB',
  INVENTORY: 'INVENTORY',
  DAY_0: 'DAY_0',
  REPLICATED: 'REPLICATED',
  GRADE: 'GRADE'
} as const;

export type EnumsPurchaseStatus = (typeof EnumsPurchaseStatus)[keyof typeof EnumsPurchaseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsPurchaseStatus = {
  PENDING: 'PENDING',
  RECEIVED: 'RECEIVED',
  STORED: 'STORED'
} as const;

export type EnumsPurchaseCategory = (typeof EnumsPurchaseCategory)[keyof typeof EnumsPurchaseCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsPurchaseCategory = {
  FOODS: 'FOODS',
  ANIMALS: 'ANIMALS',
  EARRINGS: 'EARRINGS'
} as const;

export type EnumsProfile = (typeof EnumsProfile)[keyof typeof EnumsProfile];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsProfile = {
  CATTLE_BUYER: 'CATTLE_BUYER',
  SHOVELMAN: 'SHOVELMAN',
  TREATER: 'TREATER',
  EXTERN: 'EXTERN',
  CATTLE_OWNER: 'CATTLE_OWNER'
} as const;

export type EnumsOxPurpose = (typeof EnumsOxPurpose)[keyof typeof EnumsOxPurpose];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsOxPurpose = {
  IN_HOUSE: 'IN_HOUSE',
  PURCHASE: 'PURCHASE',
  PARTNERSHIP: 'PARTNERSHIP',
  BOITEL: 'BOITEL',
  RECREATE: 'RECREATE'
} as const;

export type EnumsOxGender = (typeof EnumsOxGender)[keyof typeof EnumsOxGender];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsOxGender = {
  MALE: 'MALE',
  FEMALE: 'FEMALE'
} as const;

export type EnumsOccurrenceType = (typeof EnumsOccurrenceType)[keyof typeof EnumsOccurrenceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsOccurrenceType = {
  CORRAL: 'CORRAL',
  FARM: 'FARM',
  LINE: 'LINE'
} as const;

export type EnumsOccurrenceCategory = (typeof EnumsOccurrenceCategory)[keyof typeof EnumsOccurrenceCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsOccurrenceCategory = {
  CLIMATE: 'CLIMATE',
  EQUIPMENT: 'EQUIPMENT',
  INGREDIENTS: 'INGREDIENTS',
  DRY_MATTER_CHANGE: 'DRY_MATTER_CHANGE',
  SYSTEM_FAILURE: 'SYSTEM_FAILURE',
  WATER: 'WATER',
  RAIN: 'RAIN',
  DIET: 'DIET',
  INFRASTRUCTURE: 'INFRASTRUCTURE',
  MACHINES: 'MACHINES',
  PEOPLE: 'PEOPLE',
  LAW_SUIT: 'LAW_SUIT',
  OTHERS: 'OTHERS'
} as const;

export type EnumsMedicineType = (typeof EnumsMedicineType)[keyof typeof EnumsMedicineType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsMedicineType = {
  ANTIPARASITIC: 'ANTIPARASITIC',
  ANTIMICROBIALS: 'ANTIMICROBIALS',
  OTHER: 'OTHER',
  HORMONE: 'HORMONE',
  VITAMINS_AND_MINERALS: 'VITAMINS_AND_MINERALS',
  ANTISEPTIC: 'ANTISEPTIC',
  NON_STEROIDAL_ANTI_INFLAMMATORY: 'NON_STEROIDAL_ANTI_INFLAMMATORY',
  DISINFECTANT: 'DISINFECTANT',
  ANTIMICROBIAL_PERFORMANCE_ENHANCING_ADDITIVES: 'ANTIMICROBIAL_PERFORMANCE_ENHANCING_ADDITIVES',
  ANESTHETIC: 'ANESTHETIC',
  ANALGESIC: 'ANALGESIC',
  STEROIDAL_ANTI_INFLAMMATORY: 'STEROIDAL_ANTI_INFLAMMATORY',
  ANTICOCCIDIAL: 'ANTICOCCIDIAL',
  HYDRATION_AND_SUPPORTIVE_MEDICATION: 'HYDRATION_AND_SUPPORTIVE_MEDICATION',
  ANTISPASMODIC: 'ANTISPASMODIC',
  ANTICHOLINERGIC: 'ANTICHOLINERGIC',
  MUSCLE_RELAXANT: 'MUSCLE_RELAXANT',
  ANTIARRHYTHMIC: 'ANTIARRHYTHMIC',
  ANTIPHYSETICS: 'ANTIPHYSETICS',
  ANTIPYRETIC: 'ANTIPYRETIC',
  ANTIVIRAL: 'ANTIVIRAL',
  BRONCHODILATOR: 'BRONCHODILATOR',
  CHOLINERGIC: 'CHOLINERGIC',
  MUCOLYTIC: 'MUCOLYTIC'
} as const;

export type EnumsMeasurement = (typeof EnumsMeasurement)[keyof typeof EnumsMeasurement];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsMeasurement = {
  UNIT: 'UNIT',
  MILLILITER: 'MILLILITER',
  GRAMS: 'GRAMS',
  IMPLANT: 'IMPLANT',
  KILOGRAM: 'KILOGRAM',
  LITER: 'LITER',
  MILLIGRAM: 'MILLIGRAM',
  MICROGRAMS: 'MICROGRAMS',
  INTERNATIONAL_UNIT: 'INTERNATIONAL_UNIT',
  DOSE: 'DOSE'
} as const;

export type EnumsLoadMode = (typeof EnumsLoadMode)[keyof typeof EnumsLoadMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsLoadMode = {
  WEIGHING: 'WEIGHING',
  CONFIRMATION: 'CONFIRMATION'
} as const;

export type EnumsGradeMethod = (typeof EnumsGradeMethod)[keyof typeof EnumsGradeMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsGradeMethod = {
  GRADES: 'GRADES',
  CARGILL: 'CARGILL'
} as const;

export type EnumsExitTruckStatus = (typeof EnumsExitTruckStatus)[keyof typeof EnumsExitTruckStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsExitTruckStatus = {
  PENDING: 'PENDING',
  WAITING_FOR_EMISSION: 'WAITING_FOR_EMISSION',
  EMITTED: 'EMITTED'
} as const;

export type EnumsExitReason = (typeof EnumsExitReason)[keyof typeof EnumsExitReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsExitReason = {
  SALE: 'SALE',
  TRANSFER: 'TRANSFER',
  DONATION: 'DONATION',
  OWN_CONSUMPTION: 'OWN_CONSUMPTION'
} as const;

export type EnumsDisease = (typeof EnumsDisease)[keyof typeof EnumsDisease];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsDisease = {
  ENTRY_MANAGEMENT: 'ENTRY_MANAGEMENT',
  NOT_SPECIFIED: 'NOT_SPECIFIED',
  ABSCESSES: 'ABSCESSES',
  MISCELLANEOUS_ACCIDENTS: 'MISCELLANEOUS_ACCIDENTS',
  ACIDOSIS: 'ACIDOSIS',
  BOTULISM: 'BOTULISM',
  SYMPTOMATIC_CARBUNCLE_LAMENESS: 'SYMPTOMATIC_CARBUNCLE_LAMENESS',
  COLIC: 'COLIC',
  NEUROLOGICAL_DISEASE: 'NEUROLOGICAL_DISEASE',
  ENTEROTOXEMIA: 'ENTEROTOXEMIA',
  INJURIES: 'INJURIES',
  WEAKNESS_CACHEXIA: 'WEAKNESS_CACHEXIA',
  FRACTURES: 'FRACTURES',
  GAS_GANGRENE: 'GAS_GANGRENE',
  INTOXICATION: 'INTOXICATION',
  LAMINITIS: 'LAMINITIS',
  NOT_IDENTIFIED: 'NOT_IDENTIFIED',
  OTHER_CAUSES: 'OTHER_CAUSES',
  PARASITOSIS: 'PARASITOSIS',
  PNEUMONIA: 'PNEUMONIA',
  PODODERMATITIS_HOOF_PROBLEMS: 'PODODERMATITIS_HOOF_PROBLEMS',
  POLYARTHRITIS: 'POLYARTHRITIS',
  POLYENCEPHALOMALACIA_PEM: 'POLYENCEPHALOMALACIA_PEM',
  TROUGH_REFUSE_INAPPETENCE: 'TROUGH_REFUSE_INAPPETENCE',
  URINARY_RETENTION: 'URINARY_RETENTION',
  PERITONITIS_RETICULUM: 'PERITONITIS_RETICULUM',
  SODOMY: 'SODOMY',
  BLOAT: 'BLOAT',
  INTESTINAL_TWIST: 'INTESTINAL_TWIST',
  TRANSPORT_TRAUMA: 'TRANSPORT_TRAUMA',
  PARASITIC_SADNESS: 'PARASITIC_SADNESS'
} as const;

export type EnumsDietCategory = (typeof EnumsDietCategory)[keyof typeof EnumsDietCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsDietCategory = {
  RECREATION: 'RECREATION',
  ADAPTATION: 'ADAPTATION',
  GROWTH: 'GROWTH',
  TERMINATION: 'TERMINATION',
  FINISHING: 'FINISHING'
} as const;

export type EnumsDeathNecropsy = (typeof EnumsDeathNecropsy)[keyof typeof EnumsDeathNecropsy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsDeathNecropsy = {
  COWBOY: 'COWBOY',
  VETERINARY: 'VETERINARY',
  HEALTH_CONSULTANT: 'HEALTH_CONSULTANT'
} as const;

export type EnumsDeathCause = (typeof EnumsDeathCause)[keyof typeof EnumsDeathCause];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsDeathCause = {
  NATURAL_DEATH: 'NATURAL_DEATH',
  ACCIDENTAL_DEATH: 'ACCIDENTAL_DEATH',
  SACRIFICE: 'SACRIFICE',
  SLAUGHTER: 'SLAUGHTER',
  OTHER: 'OTHER',
  SLAUGHTER_FOR_CONSUMPTION_ON_THE_PROPERTY: 'SLAUGHTER_FOR_CONSUMPTION_ON_THE_PROPERTY',
  ABIGEOUS_OR_DISAPPEARANCE: 'ABIGEOUS_OR_DISAPPEARANCE',
  OPHIDIC_ACCIDENT: 'OPHIDIC_ACCIDENT',
  DROWNING_OR_JAMMING: 'DROWNING_OR_JAMMING',
  ANAPLASMOSIS_OR_BABESIOSIS_OR_PYROPLASMOSIS: 'ANAPLASMOSIS_OR_BABESIOSIS_OR_PYROPLASMOSIS',
  ATTACK_BY_PREDATORS: 'ATTACK_BY_PREDATORS',
  BOTULISM: 'BOTULISM',
  POSTPARTUM_COMPLICATIONS_OR_DYSTOCYC_DELIVERY: 'POSTPARTUM_COMPLICATIONS_OR_DYSTOCYC_DELIVERY',
  DEBILITY_BY_ADVANCED_AGE: 'DEBILITY_BY_ADVANCED_AGE',
  NUTRITIONAL_DEFICIENCY: 'NUTRITIONAL_DEFICIENCY',
  ELECTRICAL_DISCHARGE: 'ELECTRICAL_DISCHARGE',
  DISPOSAL: 'DISPOSAL',
  METABOLIC_DISORDERS: 'METABOLIC_DISORDERS',
  PARASITARY_DISEASE: 'PARASITARY_DISEASE',
  BACTERIAL_DISEASES: 'BACTERIAL_DISEASES',
  INFORMATION_UNAVAILABLE: 'INFORMATION_UNAVAILABLE',
  FOREIGN_BODY_INGESTION: 'FOREIGN_BODY_INGESTION',
  CARDIAC_INSUFFICIENCY: 'CARDIAC_INSUFFICIENCY',
  INTOXICATION: 'INTOXICATION',
  LEUKOSIS: 'LEUKOSIS',
  CHRONIC_MASTITIS: 'CHRONIC_MASTITIS',
  SEPTICEMIC_OR_GANGRENOUS_MASTITIS: 'SEPTICEMIC_OR_GANGRENOUS_MASTITIS',
  METRITE: 'METRITE',
  NATURAL_DEATH_BY_ADVANCED_AGE: 'NATURAL_DEATH_BY_ADVANCED_AGE',
  NEOPLASIA: 'NEOPLASIA',
  PLEUROPNEUMONIA: 'PLEUROPNEUMONIA',
  PODODERMATITIS: 'PODODERMATITIS',
  SEPTICEMIA: 'SEPTICEMIA',
  TIMPANISM: 'TIMPANISM',
  ABOMASUM_TORSION: 'ABOMASUM_TORSION',
  TRAUMA_OR_FRACTURE: 'TRAUMA_OR_FRACTURE'
} as const;

export type EnumsCorralType = (typeof EnumsCorralType)[keyof typeof EnumsCorralType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsCorralType = {
  PICKET: 'PICKET',
  PASTURE: 'PASTURE'
} as const;

export type EnumsCarcassType = (typeof EnumsCarcassType)[keyof typeof EnumsCarcassType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsCarcassType = {
  MISSING: 'MISSING',
  THIN: 'THIN',
  MEDIUM: 'MEDIUM',
  EXCESSIVE: 'EXCESSIVE'
} as const;

export type EnumsAnnouncementType = (typeof EnumsAnnouncementType)[keyof typeof EnumsAnnouncementType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnumsAnnouncementType = {
  DEATH: 'DEATH',
  EXIT: 'EXIT',
  ENTRY: 'ENTRY',
  RE_IDENTIFICATION: 'RE_IDENTIFICATION',
  CHANGE_OF_CHARACTERISTIC: 'CHANGE_OF_CHARACTERISTIC',
  UNUSABILITY: 'UNUSABILITY',
  SALE: 'SALE'
} as const;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

export const listAddresses = (
  params?: ListAddressesParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListAddressesResponse>({ url: `/addresses`, method: 'get', params, signal }, options);
};

export const getListAddressesQueryKey = (params?: ListAddressesParams) => {
  return [`/addresses`, ...(params ? [params] : [])] as const;
};

export const getListAddressesInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listAddresses>>,
  TError = unknown
>(
  params?: ListAddressesParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAddresses>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAddressesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAddresses>>> = ({ signal, pageParam }) =>
    listAddresses({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listAddresses>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAddressesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listAddresses>>>;
export type ListAddressesInfiniteQueryError = unknown;

export const useListAddressesInfinite = <TData = Awaited<ReturnType<typeof listAddresses>>, TError = unknown>(
  params?: ListAddressesParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAddresses>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAddressesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListAddressesQueryOptions = <TData = Awaited<ReturnType<typeof listAddresses>>, TError = unknown>(
  params?: ListAddressesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listAddresses>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAddressesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAddresses>>> = ({ signal }) =>
    listAddresses(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAddresses>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAddressesQueryResult = NonNullable<Awaited<ReturnType<typeof listAddresses>>>;
export type ListAddressesQueryError = unknown;

export const useListAddresses = <TData = Awaited<ReturnType<typeof listAddresses>>, TError = unknown>(
  params?: ListAddressesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listAddresses>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAddressesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createAddress = (createAddress: CreateAddress, options?: SecondParameter<typeof request>) => {
  return request<CreateAddressResponse>(
    { url: `/addresses`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createAddress },
    options
  );
};

export const getCreateAddressMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createAddress>>, TError, { data: CreateAddress }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createAddress>>, TError, { data: CreateAddress }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAddress>>, { data: CreateAddress }> = (props) => {
    const { data } = props ?? {};

    return createAddress(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAddressMutationResult = NonNullable<Awaited<ReturnType<typeof createAddress>>>;
export type CreateAddressMutationBody = CreateAddress;
export type CreateAddressMutationError = unknown;

export const useCreateAddress = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createAddress>>, TError, { data: CreateAddress }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateAddressMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteAddress = (id: number, options?: SecondParameter<typeof request>) => {
  return request<DeleteAddressResponse>({ url: `/addresses/${id}`, method: 'delete' }, options);
};

export const getDeleteAddressMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAddress>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteAddress>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAddress>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return deleteAddress(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAddressMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAddress>>>;

export type DeleteAddressMutationError = unknown;

export const useDeleteAddress = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAddress>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getDeleteAddressMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getAddress = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetAddressResponse>({ url: `/addresses/${id}`, method: 'get', signal }, options);
};

export const getGetAddressQueryKey = (id: number) => {
  return [`/addresses/${id}`] as const;
};

export const getGetAddressInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof getAddress>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAddress>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAddressQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAddress>>> = ({ signal }) =>
    getAddress(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getAddress>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAddressInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAddress>>>;
export type GetAddressInfiniteQueryError = unknown;

export const useGetAddressInfinite = <TData = Awaited<ReturnType<typeof getAddress>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAddress>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAddressInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetAddressQueryOptions = <TData = Awaited<ReturnType<typeof getAddress>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAddress>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAddressQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAddress>>> = ({ signal }) =>
    getAddress(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAddress>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAddressQueryResult = NonNullable<Awaited<ReturnType<typeof getAddress>>>;
export type GetAddressQueryError = unknown;

export const useGetAddress = <TData = Awaited<ReturnType<typeof getAddress>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAddress>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAddressQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateAddress = (id: number, updateAddress: UpdateAddress, options?: SecondParameter<typeof request>) => {
  return request<UpdateAddressResponse>(
    { url: `/addresses/${id}`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: updateAddress },
    options
  );
};

export const getUpdateAddressMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAddress>>,
    TError,
    { id: number; data: UpdateAddress },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAddress>>,
  TError,
  { id: number; data: UpdateAddress },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAddress>>, { id: number; data: UpdateAddress }> = (
    props
  ) => {
    const { id, data } = props ?? {};

    return updateAddress(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAddressMutationResult = NonNullable<Awaited<ReturnType<typeof updateAddress>>>;
export type UpdateAddressMutationBody = UpdateAddress;
export type UpdateAddressMutationError = unknown;

export const useUpdateAddress = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAddress>>,
    TError,
    { id: number; data: UpdateAddress },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateAddressMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listAnnouncements = (
  params?: ListAnnouncementsParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListAnnouncementsResponse>({ url: `/announcements`, method: 'get', params, signal }, options);
};

export const getListAnnouncementsQueryKey = (params?: ListAnnouncementsParams) => {
  return [`/announcements`, ...(params ? [params] : [])] as const;
};

export const getListAnnouncementsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listAnnouncements>>,
  TError = unknown
>(
  params?: ListAnnouncementsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAnnouncements>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAnnouncementsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAnnouncements>>> = ({ signal, pageParam }) =>
    listAnnouncements({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listAnnouncements>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAnnouncementsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listAnnouncements>>>;
export type ListAnnouncementsInfiniteQueryError = unknown;

export const useListAnnouncementsInfinite = <TData = Awaited<ReturnType<typeof listAnnouncements>>, TError = unknown>(
  params?: ListAnnouncementsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAnnouncements>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAnnouncementsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListAnnouncementsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAnnouncements>>,
  TError = unknown
>(
  params?: ListAnnouncementsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listAnnouncements>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAnnouncementsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAnnouncements>>> = ({ signal }) =>
    listAnnouncements(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAnnouncements>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAnnouncementsQueryResult = NonNullable<Awaited<ReturnType<typeof listAnnouncements>>>;
export type ListAnnouncementsQueryError = unknown;

export const useListAnnouncements = <TData = Awaited<ReturnType<typeof listAnnouncements>>, TError = unknown>(
  params?: ListAnnouncementsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listAnnouncements>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAnnouncementsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createAnnouncement = (
  createAnnouncement: CreateAnnouncement,
  options?: SecondParameter<typeof request>
) => {
  return request<CreateAnnouncementResponse>(
    {
      url: `/announcements`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createAnnouncement
    },
    options
  );
};

export const getCreateAnnouncementMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAnnouncement>>,
    TError,
    { data: CreateAnnouncement },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createAnnouncement>>,
  TError,
  { data: CreateAnnouncement },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAnnouncement>>, { data: CreateAnnouncement }> = (
    props
  ) => {
    const { data } = props ?? {};

    return createAnnouncement(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAnnouncementMutationResult = NonNullable<Awaited<ReturnType<typeof createAnnouncement>>>;
export type CreateAnnouncementMutationBody = CreateAnnouncement;
export type CreateAnnouncementMutationError = HttpError;

export const useCreateAnnouncement = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAnnouncement>>,
    TError,
    { data: CreateAnnouncement },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateAnnouncementMutationOptions(options);

  return useMutation(mutationOptions);
};

export const createEntryAnnouncements = (
  createEntryAnnouncementsBody: CreateEntryAnnouncementsBody,
  options?: SecondParameter<typeof request>
) => {
  return request<number[]>(
    {
      url: `/announcements/entry`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createEntryAnnouncementsBody
    },
    options
  );
};

export const getCreateEntryAnnouncementsMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEntryAnnouncements>>,
    TError,
    { data: CreateEntryAnnouncementsBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createEntryAnnouncements>>,
  TError,
  { data: CreateEntryAnnouncementsBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createEntryAnnouncements>>,
    { data: CreateEntryAnnouncementsBody }
  > = (props) => {
    const { data } = props ?? {};

    return createEntryAnnouncements(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateEntryAnnouncementsMutationResult = NonNullable<Awaited<ReturnType<typeof createEntryAnnouncements>>>;
export type CreateEntryAnnouncementsMutationBody = CreateEntryAnnouncementsBody;
export type CreateEntryAnnouncementsMutationError = HttpError;

export const useCreateEntryAnnouncements = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEntryAnnouncements>>,
    TError,
    { data: CreateEntryAnnouncementsBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateEntryAnnouncementsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const checkGtas = (checkGtasBody: CheckGtasBody, options?: SecondParameter<typeof request>) => {
  return request<CheckGtasResponse>(
    {
      url: `/announcements/entry`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: checkGtasBody
    },
    options
  );
};

export const getCheckGtasMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof checkGtas>>, TError, { data: CheckGtasBody }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof checkGtas>>, TError, { data: CheckGtasBody }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkGtas>>, { data: CheckGtasBody }> = (props) => {
    const { data } = props ?? {};

    return checkGtas(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CheckGtasMutationResult = NonNullable<Awaited<ReturnType<typeof checkGtas>>>;
export type CheckGtasMutationBody = CheckGtasBody;
export type CheckGtasMutationError = unknown;

export const useCheckGtas = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof checkGtas>>, TError, { data: CheckGtasBody }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCheckGtasMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getExitAnnouncementByDate = (
  params: GetExitAnnouncementByDateParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<GetExitAnnouncementByDateResponse>(
    { url: `/announcements/exit-truck`, method: 'get', params, signal },
    options
  );
};

export const getGetExitAnnouncementByDateQueryKey = (params: GetExitAnnouncementByDateParams) => {
  return [`/announcements/exit-truck`, ...(params ? [params] : [])] as const;
};

export const getGetExitAnnouncementByDateInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getExitAnnouncementByDate>>,
  TError = unknown
>(
  params: GetExitAnnouncementByDateParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExitAnnouncementByDate>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetExitAnnouncementByDateQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExitAnnouncementByDate>>> = ({ signal, pageParam }) =>
    getExitAnnouncementByDate({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getExitAnnouncementByDate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetExitAnnouncementByDateInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExitAnnouncementByDate>>
>;
export type GetExitAnnouncementByDateInfiniteQueryError = unknown;

export const useGetExitAnnouncementByDateInfinite = <
  TData = Awaited<ReturnType<typeof getExitAnnouncementByDate>>,
  TError = unknown
>(
  params: GetExitAnnouncementByDateParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExitAnnouncementByDate>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetExitAnnouncementByDateInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetExitAnnouncementByDateQueryOptions = <
  TData = Awaited<ReturnType<typeof getExitAnnouncementByDate>>,
  TError = unknown
>(
  params: GetExitAnnouncementByDateParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getExitAnnouncementByDate>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetExitAnnouncementByDateQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExitAnnouncementByDate>>> = ({ signal }) =>
    getExitAnnouncementByDate(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getExitAnnouncementByDate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetExitAnnouncementByDateQueryResult = NonNullable<Awaited<ReturnType<typeof getExitAnnouncementByDate>>>;
export type GetExitAnnouncementByDateQueryError = unknown;

export const useGetExitAnnouncementByDate = <
  TData = Awaited<ReturnType<typeof getExitAnnouncementByDate>>,
  TError = unknown
>(
  params: GetExitAnnouncementByDateParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getExitAnnouncementByDate>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetExitAnnouncementByDateQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const generateChangeOfCharacteristics = (
  generateChangeOfCharacteristicsBody: GenerateChangeOfCharacteristicsBody,
  options?: SecondParameter<typeof request>
) => {
  return request<GenerateChangeOfCharacteristicsResponse>(
    {
      url: `/announcements/generate/change-of-characteristics`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: generateChangeOfCharacteristicsBody
    },
    options
  );
};

export const getGenerateChangeOfCharacteristicsMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateChangeOfCharacteristics>>,
    TError,
    { data: GenerateChangeOfCharacteristicsBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateChangeOfCharacteristics>>,
  TError,
  { data: GenerateChangeOfCharacteristicsBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateChangeOfCharacteristics>>,
    { data: GenerateChangeOfCharacteristicsBody }
  > = (props) => {
    const { data } = props ?? {};

    return generateChangeOfCharacteristics(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateChangeOfCharacteristicsMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateChangeOfCharacteristics>>
>;
export type GenerateChangeOfCharacteristicsMutationBody = GenerateChangeOfCharacteristicsBody;
export type GenerateChangeOfCharacteristicsMutationError = HttpError;

export const useGenerateChangeOfCharacteristics = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateChangeOfCharacteristics>>,
    TError,
    { data: GenerateChangeOfCharacteristicsBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getGenerateChangeOfCharacteristicsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const generateDeathAnnouncement = (options?: SecondParameter<typeof request>) => {
  return request<GenerateDeathAnnouncementResponse>({ url: `/announcements/generate/death`, method: 'post' }, options);
};

export const getGenerateDeathAnnouncementMutationOptions = <
  TError = HttpError,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof generateDeathAnnouncement>>, TError, TVariables, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof generateDeathAnnouncement>>, TError, TVariables, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof generateDeathAnnouncement>>, TVariables> = () => {
    return generateDeathAnnouncement(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateDeathAnnouncementMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateDeathAnnouncement>>
>;

export type GenerateDeathAnnouncementMutationError = HttpError;

export const useGenerateDeathAnnouncement = <TError = HttpError, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof generateDeathAnnouncement>>, TError, TVariables, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getGenerateDeathAnnouncementMutationOptions(options);

  return useMutation(mutationOptions);
};

export const emitEntry = (params: EmitEntryParams, options?: SecondParameter<typeof request>) => {
  return request<EmitEntryResponse>({ url: `/announcements/generate/entry`, method: 'post', params }, options);
};

export const getEmitEntryMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof emitEntry>>, TError, { params: EmitEntryParams }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof emitEntry>>, TError, { params: EmitEntryParams }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof emitEntry>>, { params: EmitEntryParams }> = (props) => {
    const { params } = props ?? {};

    return emitEntry(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EmitEntryMutationResult = NonNullable<Awaited<ReturnType<typeof emitEntry>>>;

export type EmitEntryMutationError = unknown;

export const useEmitEntry = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof emitEntry>>, TError, { params: EmitEntryParams }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getEmitEntryMutationOptions(options);

  return useMutation(mutationOptions);
};

export const generateReIdentificationAnnouncement = (
  generateReIdentificationAnnouncementBody: GenerateReIdentificationAnnouncementBody,
  options?: SecondParameter<typeof request>
) => {
  return request<GenerateReIdentificationAnnouncementResponse>(
    {
      url: `/announcements/generate/re-identification`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: generateReIdentificationAnnouncementBody
    },
    options
  );
};

export const getGenerateReIdentificationAnnouncementMutationOptions = <
  TError = HttpError,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateReIdentificationAnnouncement>>,
    TError,
    { data: GenerateReIdentificationAnnouncementBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateReIdentificationAnnouncement>>,
  TError,
  { data: GenerateReIdentificationAnnouncementBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateReIdentificationAnnouncement>>,
    { data: GenerateReIdentificationAnnouncementBody }
  > = (props) => {
    const { data } = props ?? {};

    return generateReIdentificationAnnouncement(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateReIdentificationAnnouncementMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateReIdentificationAnnouncement>>
>;
export type GenerateReIdentificationAnnouncementMutationBody = GenerateReIdentificationAnnouncementBody;
export type GenerateReIdentificationAnnouncementMutationError = HttpError;

export const useGenerateReIdentificationAnnouncement = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateReIdentificationAnnouncement>>,
    TError,
    { data: GenerateReIdentificationAnnouncementBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getGenerateReIdentificationAnnouncementMutationOptions(options);

  return useMutation(mutationOptions);
};

export const generateUnusability = (
  generateUnusabilityBody: GenerateUnusabilityBody,
  options?: SecondParameter<typeof request>
) => {
  return request<GenerateUnusabilityResponse>(
    {
      url: `/announcements/generate/unusability`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: generateUnusabilityBody
    },
    options
  );
};

export const getGenerateUnusabilityMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateUnusability>>,
    TError,
    { data: GenerateUnusabilityBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateUnusability>>,
  TError,
  { data: GenerateUnusabilityBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateUnusability>>,
    { data: GenerateUnusabilityBody }
  > = (props) => {
    const { data } = props ?? {};

    return generateUnusability(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateUnusabilityMutationResult = NonNullable<Awaited<ReturnType<typeof generateUnusability>>>;
export type GenerateUnusabilityMutationBody = GenerateUnusabilityBody;
export type GenerateUnusabilityMutationError = HttpError;

export const useGenerateUnusability = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateUnusability>>,
    TError,
    { data: GenerateUnusabilityBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getGenerateUnusabilityMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteAnnouncement = (id: number, options?: SecondParameter<typeof request>) => {
  return request<DeleteAnnouncementResponse>({ url: `/announcements/${id}`, method: 'delete' }, options);
};

export const getDeleteAnnouncementMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAnnouncement>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteAnnouncement>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAnnouncement>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return deleteAnnouncement(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAnnouncementMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAnnouncement>>>;

export type DeleteAnnouncementMutationError = unknown;

export const useDeleteAnnouncement = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteAnnouncement>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getDeleteAnnouncementMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateAnnouncement = (
  id: number,
  updateAnnouncement: UpdateAnnouncement,
  options?: SecondParameter<typeof request>
) => {
  return request<UpdateAnnouncementResponse>(
    {
      url: `/announcements/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateAnnouncement
    },
    options
  );
};

export const getUpdateAnnouncementMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAnnouncement>>,
    TError,
    { id: number; data: UpdateAnnouncement },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAnnouncement>>,
  TError,
  { id: number; data: UpdateAnnouncement },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAnnouncement>>,
    { id: number; data: UpdateAnnouncement }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateAnnouncement(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAnnouncementMutationResult = NonNullable<Awaited<ReturnType<typeof updateAnnouncement>>>;
export type UpdateAnnouncementMutationBody = UpdateAnnouncement;
export type UpdateAnnouncementMutationError = unknown;

export const useUpdateAnnouncement = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAnnouncement>>,
    TError,
    { id: number; data: UpdateAnnouncement },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateAnnouncementMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getChangeOfCharacteristicAnnouncement = (
  id: number,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<GetChangeOfCharacteristicAnnouncementResponse>(
    { url: `/announcements/${id}/change-of-characteristic`, method: 'get', signal },
    options
  );
};

export const getGetChangeOfCharacteristicAnnouncementQueryKey = (id: number) => {
  return [`/announcements/${id}/change-of-characteristic`] as const;
};

export const getGetChangeOfCharacteristicAnnouncementInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getChangeOfCharacteristicAnnouncement>>,
  TError = HttpError
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChangeOfCharacteristicAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetChangeOfCharacteristicAnnouncementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeOfCharacteristicAnnouncement>>> = ({ signal }) =>
    getChangeOfCharacteristicAnnouncement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getChangeOfCharacteristicAnnouncement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetChangeOfCharacteristicAnnouncementInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getChangeOfCharacteristicAnnouncement>>
>;
export type GetChangeOfCharacteristicAnnouncementInfiniteQueryError = HttpError;

export const useGetChangeOfCharacteristicAnnouncementInfinite = <
  TData = Awaited<ReturnType<typeof getChangeOfCharacteristicAnnouncement>>,
  TError = HttpError
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChangeOfCharacteristicAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetChangeOfCharacteristicAnnouncementInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetChangeOfCharacteristicAnnouncementQueryOptions = <
  TData = Awaited<ReturnType<typeof getChangeOfCharacteristicAnnouncement>>,
  TError = HttpError
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getChangeOfCharacteristicAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetChangeOfCharacteristicAnnouncementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeOfCharacteristicAnnouncement>>> = ({ signal }) =>
    getChangeOfCharacteristicAnnouncement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getChangeOfCharacteristicAnnouncement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetChangeOfCharacteristicAnnouncementQueryResult = NonNullable<
  Awaited<ReturnType<typeof getChangeOfCharacteristicAnnouncement>>
>;
export type GetChangeOfCharacteristicAnnouncementQueryError = HttpError;

export const useGetChangeOfCharacteristicAnnouncement = <
  TData = Awaited<ReturnType<typeof getChangeOfCharacteristicAnnouncement>>,
  TError = HttpError
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getChangeOfCharacteristicAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetChangeOfCharacteristicAnnouncementQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDeathAnnouncement = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetDeathAnnouncementResponse>({ url: `/announcements/${id}/death`, method: 'get', signal }, options);
};

export const getGetDeathAnnouncementQueryKey = (id: number) => {
  return [`/announcements/${id}/death`] as const;
};

export const getGetDeathAnnouncementInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getDeathAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDeathAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDeathAnnouncementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDeathAnnouncement>>> = ({ signal }) =>
    getDeathAnnouncement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getDeathAnnouncement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDeathAnnouncementInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDeathAnnouncement>>>;
export type GetDeathAnnouncementInfiniteQueryError = unknown;

export const useGetDeathAnnouncementInfinite = <
  TData = Awaited<ReturnType<typeof getDeathAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDeathAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDeathAnnouncementInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetDeathAnnouncementQueryOptions = <
  TData = Awaited<ReturnType<typeof getDeathAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDeathAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDeathAnnouncementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDeathAnnouncement>>> = ({ signal }) =>
    getDeathAnnouncement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDeathAnnouncement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDeathAnnouncementQueryResult = NonNullable<Awaited<ReturnType<typeof getDeathAnnouncement>>>;
export type GetDeathAnnouncementQueryError = unknown;

export const useGetDeathAnnouncement = <TData = Awaited<ReturnType<typeof getDeathAnnouncement>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDeathAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDeathAnnouncementQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getEntryAnnouncement = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetEntryAnnouncementResponse>({ url: `/announcements/${id}/entry`, method: 'get', signal }, options);
};

export const getGetEntryAnnouncementQueryKey = (id: number) => {
  return [`/announcements/${id}/entry`] as const;
};

export const getGetEntryAnnouncementInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getEntryAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEntryAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEntryAnnouncementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEntryAnnouncement>>> = ({ signal }) =>
    getEntryAnnouncement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getEntryAnnouncement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEntryAnnouncementInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEntryAnnouncement>>>;
export type GetEntryAnnouncementInfiniteQueryError = unknown;

export const useGetEntryAnnouncementInfinite = <
  TData = Awaited<ReturnType<typeof getEntryAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEntryAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEntryAnnouncementInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetEntryAnnouncementQueryOptions = <
  TData = Awaited<ReturnType<typeof getEntryAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getEntryAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEntryAnnouncementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEntryAnnouncement>>> = ({ signal }) =>
    getEntryAnnouncement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getEntryAnnouncement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEntryAnnouncementQueryResult = NonNullable<Awaited<ReturnType<typeof getEntryAnnouncement>>>;
export type GetEntryAnnouncementQueryError = unknown;

export const useGetEntryAnnouncement = <TData = Awaited<ReturnType<typeof getEntryAnnouncement>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getEntryAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEntryAnnouncementQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const emmitEntryAnnouncement = (
  id: number,
  emmitEntryAnnouncementBody: EmmitEntryAnnouncementBody,
  options?: SecondParameter<typeof request>
) => {
  return request<boolean>(
    {
      url: `/announcements/${id}/entry`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: emmitEntryAnnouncementBody
    },
    options
  );
};

export const getEmmitEntryAnnouncementMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof emmitEntryAnnouncement>>,
    TError,
    { id: number; data: EmmitEntryAnnouncementBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof emmitEntryAnnouncement>>,
  TError,
  { id: number; data: EmmitEntryAnnouncementBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof emmitEntryAnnouncement>>,
    { id: number; data: EmmitEntryAnnouncementBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return emmitEntryAnnouncement(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EmmitEntryAnnouncementMutationResult = NonNullable<Awaited<ReturnType<typeof emmitEntryAnnouncement>>>;
export type EmmitEntryAnnouncementMutationBody = EmmitEntryAnnouncementBody;
export type EmmitEntryAnnouncementMutationError = unknown;

export const useEmmitEntryAnnouncement = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof emmitEntryAnnouncement>>,
    TError,
    { id: number; data: EmmitEntryAnnouncementBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getEmmitEntryAnnouncementMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getExitAnnouncement = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetExitAnnouncementResponse>({ url: `/announcements/${id}/exit`, method: 'get', signal }, options);
};

export const getGetExitAnnouncementQueryKey = (id: number) => {
  return [`/announcements/${id}/exit`] as const;
};

export const getGetExitAnnouncementInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getExitAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExitAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetExitAnnouncementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExitAnnouncement>>> = ({ signal }) =>
    getExitAnnouncement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getExitAnnouncement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetExitAnnouncementInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getExitAnnouncement>>>;
export type GetExitAnnouncementInfiniteQueryError = unknown;

export const useGetExitAnnouncementInfinite = <
  TData = Awaited<ReturnType<typeof getExitAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExitAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetExitAnnouncementInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetExitAnnouncementQueryOptions = <
  TData = Awaited<ReturnType<typeof getExitAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getExitAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetExitAnnouncementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExitAnnouncement>>> = ({ signal }) =>
    getExitAnnouncement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getExitAnnouncement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetExitAnnouncementQueryResult = NonNullable<Awaited<ReturnType<typeof getExitAnnouncement>>>;
export type GetExitAnnouncementQueryError = unknown;

export const useGetExitAnnouncement = <TData = Awaited<ReturnType<typeof getExitAnnouncement>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getExitAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetExitAnnouncementQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getReIdentificationAnnouncement = (
  id: number,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<GetReIdentificationAnnouncementResponse>(
    { url: `/announcements/${id}/re-identification`, method: 'get', signal },
    options
  );
};

export const getGetReIdentificationAnnouncementQueryKey = (id: number) => {
  return [`/announcements/${id}/re-identification`] as const;
};

export const getGetReIdentificationAnnouncementInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getReIdentificationAnnouncement>>,
  TError = HttpError
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getReIdentificationAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReIdentificationAnnouncementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReIdentificationAnnouncement>>> = ({ signal }) =>
    getReIdentificationAnnouncement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getReIdentificationAnnouncement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReIdentificationAnnouncementInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReIdentificationAnnouncement>>
>;
export type GetReIdentificationAnnouncementInfiniteQueryError = HttpError;

export const useGetReIdentificationAnnouncementInfinite = <
  TData = Awaited<ReturnType<typeof getReIdentificationAnnouncement>>,
  TError = HttpError
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getReIdentificationAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReIdentificationAnnouncementInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetReIdentificationAnnouncementQueryOptions = <
  TData = Awaited<ReturnType<typeof getReIdentificationAnnouncement>>,
  TError = HttpError
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getReIdentificationAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReIdentificationAnnouncementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReIdentificationAnnouncement>>> = ({ signal }) =>
    getReIdentificationAnnouncement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReIdentificationAnnouncement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReIdentificationAnnouncementQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReIdentificationAnnouncement>>
>;
export type GetReIdentificationAnnouncementQueryError = HttpError;

export const useGetReIdentificationAnnouncement = <
  TData = Awaited<ReturnType<typeof getReIdentificationAnnouncement>>,
  TError = HttpError
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getReIdentificationAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReIdentificationAnnouncementQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getSaleAnnouncement = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetSaleAnnouncementResponse>({ url: `/announcements/${id}/sale`, method: 'get', signal }, options);
};

export const getGetSaleAnnouncementQueryKey = (id: number) => {
  return [`/announcements/${id}/sale`] as const;
};

export const getGetSaleAnnouncementInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getSaleAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSaleAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSaleAnnouncementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSaleAnnouncement>>> = ({ signal }) =>
    getSaleAnnouncement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getSaleAnnouncement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSaleAnnouncementInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSaleAnnouncement>>>;
export type GetSaleAnnouncementInfiniteQueryError = unknown;

export const useGetSaleAnnouncementInfinite = <
  TData = Awaited<ReturnType<typeof getSaleAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSaleAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSaleAnnouncementInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetSaleAnnouncementQueryOptions = <
  TData = Awaited<ReturnType<typeof getSaleAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSaleAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSaleAnnouncementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSaleAnnouncement>>> = ({ signal }) =>
    getSaleAnnouncement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSaleAnnouncement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSaleAnnouncementQueryResult = NonNullable<Awaited<ReturnType<typeof getSaleAnnouncement>>>;
export type GetSaleAnnouncementQueryError = unknown;

export const useGetSaleAnnouncement = <TData = Awaited<ReturnType<typeof getSaleAnnouncement>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSaleAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSaleAnnouncementQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getUnusabilityAnnouncement = (
  id: number,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<GetUnusabilityAnnouncementResponse>(
    { url: `/announcements/${id}/unusability`, method: 'get', signal },
    options
  );
};

export const getGetUnusabilityAnnouncementQueryKey = (id: number) => {
  return [`/announcements/${id}/unusability`] as const;
};

export const getGetUnusabilityAnnouncementInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getUnusabilityAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUnusabilityAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUnusabilityAnnouncementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUnusabilityAnnouncement>>> = ({ signal }) =>
    getUnusabilityAnnouncement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getUnusabilityAnnouncement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUnusabilityAnnouncementInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUnusabilityAnnouncement>>
>;
export type GetUnusabilityAnnouncementInfiniteQueryError = unknown;

export const useGetUnusabilityAnnouncementInfinite = <
  TData = Awaited<ReturnType<typeof getUnusabilityAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUnusabilityAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUnusabilityAnnouncementInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetUnusabilityAnnouncementQueryOptions = <
  TData = Awaited<ReturnType<typeof getUnusabilityAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUnusabilityAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUnusabilityAnnouncementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUnusabilityAnnouncement>>> = ({ signal }) =>
    getUnusabilityAnnouncement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUnusabilityAnnouncement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUnusabilityAnnouncementQueryResult = NonNullable<Awaited<ReturnType<typeof getUnusabilityAnnouncement>>>;
export type GetUnusabilityAnnouncementQueryError = unknown;

export const useGetUnusabilityAnnouncement = <
  TData = Awaited<ReturnType<typeof getUnusabilityAnnouncement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUnusabilityAnnouncement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUnusabilityAnnouncementQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const login = (login: Login, options?: SecondParameter<typeof request>) => {
  return request<LoginResponse>(
    { url: `/auth`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: login },
    options
  );
};

export const getLoginMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof login>>, TError, { data: Login }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof login>>, TError, { data: Login }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof login>>, { data: Login }> = (props) => {
    const { data } = props ?? {};

    return login(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginMutationResult = NonNullable<Awaited<ReturnType<typeof login>>>;
export type LoginMutationBody = Login;
export type LoginMutationError = HttpError;

export const useLogin = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof login>>, TError, { data: Login }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

export const refreshToken = (refreshTokenBody: RefreshTokenBody, options?: SecondParameter<typeof request>) => {
  return request<RefreshTokenResponse>(
    { url: `/auth`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: refreshTokenBody },
    options
  );
};

export const getRefreshTokenMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refreshToken>>, TError, { data: RefreshTokenBody }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof refreshToken>>, TError, { data: RefreshTokenBody }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof refreshToken>>, { data: RefreshTokenBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return refreshToken(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RefreshTokenMutationResult = NonNullable<Awaited<ReturnType<typeof refreshToken>>>;
export type RefreshTokenMutationBody = RefreshTokenBody;
export type RefreshTokenMutationError = HttpError;

export const useRefreshToken = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof refreshToken>>, TError, { data: RefreshTokenBody }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getRefreshTokenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listBatches = (
  params?: ListBatchesParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListBatchesResponse>({ url: `/batches`, method: 'get', params, signal }, options);
};

export const getListBatchesQueryKey = (params?: ListBatchesParams) => {
  return [`/batches`, ...(params ? [params] : [])] as const;
};

export const getListBatchesInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof listBatches>>, TError = unknown>(
  params?: ListBatchesParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listBatches>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListBatchesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listBatches>>> = ({ signal, pageParam }) =>
    listBatches({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListBatchesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listBatches>>>;
export type ListBatchesInfiniteQueryError = unknown;

export const useListBatchesInfinite = <TData = Awaited<ReturnType<typeof listBatches>>, TError = unknown>(
  params?: ListBatchesParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listBatches>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListBatchesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListBatchesQueryOptions = <TData = Awaited<ReturnType<typeof listBatches>>, TError = unknown>(
  params?: ListBatchesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listBatches>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListBatchesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listBatches>>> = ({ signal }) =>
    listBatches(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListBatchesQueryResult = NonNullable<Awaited<ReturnType<typeof listBatches>>>;
export type ListBatchesQueryError = unknown;

export const useListBatches = <TData = Awaited<ReturnType<typeof listBatches>>, TError = unknown>(
  params?: ListBatchesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listBatches>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListBatchesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createBatch = (createBatch: CreateBatch, options?: SecondParameter<typeof request>) => {
  return request<CreateBatchResponse>(
    { url: `/batches`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createBatch },
    options
  );
};

export const getCreateBatchMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createBatch>>, TError, { data: CreateBatch }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createBatch>>, TError, { data: CreateBatch }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createBatch>>, { data: CreateBatch }> = (props) => {
    const { data } = props ?? {};

    return createBatch(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateBatchMutationResult = NonNullable<Awaited<ReturnType<typeof createBatch>>>;
export type CreateBatchMutationBody = CreateBatch;
export type CreateBatchMutationError = HttpError;

export const useCreateBatch = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createBatch>>, TError, { data: CreateBatch }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateBatchMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listActiveBatches = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListActiveBatchesResponse>({ url: `/batches/active`, method: 'get', signal }, options);
};

export const getListActiveBatchesQueryKey = () => {
  return [`/batches/active`] as const;
};

export const getListActiveBatchesInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listActiveBatches>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listActiveBatches>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListActiveBatchesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listActiveBatches>>> = ({ signal }) =>
    listActiveBatches(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listActiveBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListActiveBatchesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listActiveBatches>>>;
export type ListActiveBatchesInfiniteQueryError = unknown;

export const useListActiveBatchesInfinite = <
  TData = Awaited<ReturnType<typeof listActiveBatches>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listActiveBatches>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListActiveBatchesInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListActiveBatchesQueryOptions = <
  TData = Awaited<ReturnType<typeof listActiveBatches>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listActiveBatches>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListActiveBatchesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listActiveBatches>>> = ({ signal }) =>
    listActiveBatches(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listActiveBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListActiveBatchesQueryResult = NonNullable<Awaited<ReturnType<typeof listActiveBatches>>>;
export type ListActiveBatchesQueryError = unknown;

export const useListActiveBatches = <
  TData = Awaited<ReturnType<typeof listActiveBatches>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listActiveBatches>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListActiveBatchesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listActiveAndFinalizedBatches = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListActiveAndFinalizedBatchesResponse>(
    { url: `/batches/active-and-finalized-batches`, method: 'get', signal },
    options
  );
};

export const getListActiveAndFinalizedBatchesQueryKey = () => {
  return [`/batches/active-and-finalized-batches`] as const;
};

export const getListActiveAndFinalizedBatchesInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listActiveAndFinalizedBatches>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listActiveAndFinalizedBatches>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListActiveAndFinalizedBatchesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listActiveAndFinalizedBatches>>> = ({ signal }) =>
    listActiveAndFinalizedBatches(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listActiveAndFinalizedBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListActiveAndFinalizedBatchesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listActiveAndFinalizedBatches>>
>;
export type ListActiveAndFinalizedBatchesInfiniteQueryError = unknown;

export const useListActiveAndFinalizedBatchesInfinite = <
  TData = Awaited<ReturnType<typeof listActiveAndFinalizedBatches>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listActiveAndFinalizedBatches>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListActiveAndFinalizedBatchesInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListActiveAndFinalizedBatchesQueryOptions = <
  TData = Awaited<ReturnType<typeof listActiveAndFinalizedBatches>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listActiveAndFinalizedBatches>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListActiveAndFinalizedBatchesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listActiveAndFinalizedBatches>>> = ({ signal }) =>
    listActiveAndFinalizedBatches(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listActiveAndFinalizedBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListActiveAndFinalizedBatchesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listActiveAndFinalizedBatches>>
>;
export type ListActiveAndFinalizedBatchesQueryError = unknown;

export const useListActiveAndFinalizedBatches = <
  TData = Awaited<ReturnType<typeof listActiveAndFinalizedBatches>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listActiveAndFinalizedBatches>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListActiveAndFinalizedBatchesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const batchesDetails = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<BatchesDetailsResponse>({ url: `/batches/details`, method: 'get', signal }, options);
};

export const getBatchesDetailsQueryKey = () => {
  return [`/batches/details`] as const;
};

export const getBatchesDetailsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof batchesDetails>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchesDetails>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchesDetailsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchesDetails>>> = ({ signal }) =>
    batchesDetails(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof batchesDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchesDetailsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof batchesDetails>>>;
export type BatchesDetailsInfiniteQueryError = unknown;

export const useBatchesDetailsInfinite = <
  TData = Awaited<ReturnType<typeof batchesDetails>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchesDetails>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchesDetailsInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBatchesDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof batchesDetails>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof batchesDetails>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchesDetailsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchesDetails>>> = ({ signal }) =>
    batchesDetails(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof batchesDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchesDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof batchesDetails>>>;
export type BatchesDetailsQueryError = unknown;

export const useBatchesDetails = <TData = Awaited<ReturnType<typeof batchesDetails>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof batchesDetails>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchesDetailsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listBatchesInDietTransition = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListBatchesInDietTransitionResponse>(
    { url: `/batches/diet-transitions`, method: 'get', signal },
    options
  );
};

export const getListBatchesInDietTransitionQueryKey = () => {
  return [`/batches/diet-transitions`] as const;
};

export const getListBatchesInDietTransitionInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listBatchesInDietTransition>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listBatchesInDietTransition>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListBatchesInDietTransitionQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listBatchesInDietTransition>>> = ({ signal }) =>
    listBatchesInDietTransition(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listBatchesInDietTransition>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListBatchesInDietTransitionInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listBatchesInDietTransition>>
>;
export type ListBatchesInDietTransitionInfiniteQueryError = unknown;

export const useListBatchesInDietTransitionInfinite = <
  TData = Awaited<ReturnType<typeof listBatchesInDietTransition>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listBatchesInDietTransition>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListBatchesInDietTransitionInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListBatchesInDietTransitionQueryOptions = <
  TData = Awaited<ReturnType<typeof listBatchesInDietTransition>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listBatchesInDietTransition>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListBatchesInDietTransitionQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listBatchesInDietTransition>>> = ({ signal }) =>
    listBatchesInDietTransition(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listBatchesInDietTransition>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListBatchesInDietTransitionQueryResult = NonNullable<
  Awaited<ReturnType<typeof listBatchesInDietTransition>>
>;
export type ListBatchesInDietTransitionQueryError = unknown;

export const useListBatchesInDietTransition = <
  TData = Awaited<ReturnType<typeof listBatchesInDietTransition>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listBatchesInDietTransition>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListBatchesInDietTransitionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listFinalizedBatches = (
  params?: ListFinalizedBatchesParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListFinalizedBatchesResponse>({ url: `/batches/finalize`, method: 'get', params, signal }, options);
};

export const getListFinalizedBatchesQueryKey = (params?: ListFinalizedBatchesParams) => {
  return [`/batches/finalize`, ...(params ? [params] : [])] as const;
};

export const getListFinalizedBatchesInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listFinalizedBatches>>,
  TError = unknown
>(
  params?: ListFinalizedBatchesParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFinalizedBatches>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListFinalizedBatchesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listFinalizedBatches>>> = ({ signal, pageParam }) =>
    listFinalizedBatches({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listFinalizedBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListFinalizedBatchesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listFinalizedBatches>>>;
export type ListFinalizedBatchesInfiniteQueryError = unknown;

export const useListFinalizedBatchesInfinite = <
  TData = Awaited<ReturnType<typeof listFinalizedBatches>>,
  TError = unknown
>(
  params?: ListFinalizedBatchesParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFinalizedBatches>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListFinalizedBatchesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListFinalizedBatchesQueryOptions = <
  TData = Awaited<ReturnType<typeof listFinalizedBatches>>,
  TError = unknown
>(
  params?: ListFinalizedBatchesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listFinalizedBatches>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListFinalizedBatchesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listFinalizedBatches>>> = ({ signal }) =>
    listFinalizedBatches(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listFinalizedBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListFinalizedBatchesQueryResult = NonNullable<Awaited<ReturnType<typeof listFinalizedBatches>>>;
export type ListFinalizedBatchesQueryError = unknown;

export const useListFinalizedBatches = <TData = Awaited<ReturnType<typeof listFinalizedBatches>>, TError = unknown>(
  params?: ListFinalizedBatchesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listFinalizedBatches>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListFinalizedBatchesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retorna true caso todos os lotes foram manejados ou se no mínimo um lote tem o readValue como true e managedAt como
 * false.
 */
export const hasMobileSentDailyRead = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<boolean>({ url: `/batches/mobile-daily-read`, method: 'get', signal }, options);
};

export const getHasMobileSentDailyReadQueryKey = () => {
  return [`/batches/mobile-daily-read`] as const;
};

export const getHasMobileSentDailyReadInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof hasMobileSentDailyRead>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof hasMobileSentDailyRead>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getHasMobileSentDailyReadQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof hasMobileSentDailyRead>>> = ({ signal }) =>
    hasMobileSentDailyRead(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof hasMobileSentDailyRead>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type HasMobileSentDailyReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof hasMobileSentDailyRead>>>;
export type HasMobileSentDailyReadInfiniteQueryError = unknown;

export const useHasMobileSentDailyReadInfinite = <
  TData = Awaited<ReturnType<typeof hasMobileSentDailyRead>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof hasMobileSentDailyRead>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getHasMobileSentDailyReadInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getHasMobileSentDailyReadQueryOptions = <
  TData = Awaited<ReturnType<typeof hasMobileSentDailyRead>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof hasMobileSentDailyRead>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getHasMobileSentDailyReadQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof hasMobileSentDailyRead>>> = ({ signal }) =>
    hasMobileSentDailyRead(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof hasMobileSentDailyRead>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type HasMobileSentDailyReadQueryResult = NonNullable<Awaited<ReturnType<typeof hasMobileSentDailyRead>>>;
export type HasMobileSentDailyReadQueryError = unknown;

export const useHasMobileSentDailyRead = <
  TData = Awaited<ReturnType<typeof hasMobileSentDailyRead>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof hasMobileSentDailyRead>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getHasMobileSentDailyReadQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const sendNightlyReads = (batchNightlyRead: BatchNightlyRead[], options?: SecondParameter<typeof request>) => {
  return request<SendNightlyReadsResponse>(
    {
      url: `/batches/nightly-read`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: batchNightlyRead
    },
    options
  );
};

export const getSendNightlyReadsMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendNightlyReads>>,
    TError,
    { data: BatchNightlyRead[] },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendNightlyReads>>,
  TError,
  { data: BatchNightlyRead[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendNightlyReads>>, { data: BatchNightlyRead[] }> = (
    props
  ) => {
    const { data } = props ?? {};

    return sendNightlyReads(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendNightlyReadsMutationResult = NonNullable<Awaited<ReturnType<typeof sendNightlyReads>>>;
export type SendNightlyReadsMutationBody = BatchNightlyRead[];
export type SendNightlyReadsMutationError = HttpError;

export const useSendNightlyReads = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendNightlyReads>>,
    TError,
    { data: BatchNightlyRead[] },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getSendNightlyReadsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const batchesOxen = (
  params: BatchesOxenParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<BatchesOxenResponse>({ url: `/batches/oxen`, method: 'get', params, signal }, options);
};

export const getBatchesOxenQueryKey = (params: BatchesOxenParams) => {
  return [`/batches/oxen`, ...(params ? [params] : [])] as const;
};

export const getBatchesOxenInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof batchesOxen>>, TError = unknown>(
  params: BatchesOxenParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchesOxen>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchesOxenQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchesOxen>>> = ({ signal, pageParam }) =>
    batchesOxen({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof batchesOxen>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchesOxenInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof batchesOxen>>>;
export type BatchesOxenInfiniteQueryError = unknown;

export const useBatchesOxenInfinite = <TData = Awaited<ReturnType<typeof batchesOxen>>, TError = unknown>(
  params: BatchesOxenParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchesOxen>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchesOxenInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBatchesOxenQueryOptions = <TData = Awaited<ReturnType<typeof batchesOxen>>, TError = unknown>(
  params: BatchesOxenParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof batchesOxen>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchesOxenQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchesOxen>>> = ({ signal }) =>
    batchesOxen(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof batchesOxen>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchesOxenQueryResult = NonNullable<Awaited<ReturnType<typeof batchesOxen>>>;
export type BatchesOxenQueryError = unknown;

export const useBatchesOxen = <TData = Awaited<ReturnType<typeof batchesOxen>>, TError = unknown>(
  params: BatchesOxenParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof batchesOxen>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchesOxenQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const ensureBatchStatuses = (
  ensureBatchStatusesBody: EnsureBatchStatusesBody,
  options?: SecondParameter<typeof request>
) => {
  return request<boolean>(
    {
      url: `/batches/statuses`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: ensureBatchStatusesBody
    },
    options
  );
};

export const getEnsureBatchStatusesMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ensureBatchStatuses>>,
    TError,
    { data: EnsureBatchStatusesBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof ensureBatchStatuses>>,
  TError,
  { data: EnsureBatchStatusesBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ensureBatchStatuses>>,
    { data: EnsureBatchStatusesBody }
  > = (props) => {
    const { data } = props ?? {};

    return ensureBatchStatuses(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EnsureBatchStatusesMutationResult = NonNullable<Awaited<ReturnType<typeof ensureBatchStatuses>>>;
export type EnsureBatchStatusesMutationBody = EnsureBatchStatusesBody;
export type EnsureBatchStatusesMutationError = unknown;

export const useEnsureBatchStatuses = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ensureBatchStatuses>>,
    TError,
    { data: EnsureBatchStatusesBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getEnsureBatchStatusesMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listCorralsWithoutDiet = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListCorralsWithoutDietResponse>({ url: `/batches/without-diet-list`, method: 'get', signal }, options);
};

export const getListCorralsWithoutDietQueryKey = () => {
  return [`/batches/without-diet-list`] as const;
};

export const getListCorralsWithoutDietInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listCorralsWithoutDiet>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCorralsWithoutDiet>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCorralsWithoutDietQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCorralsWithoutDiet>>> = ({ signal }) =>
    listCorralsWithoutDiet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listCorralsWithoutDiet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCorralsWithoutDietInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listCorralsWithoutDiet>>>;
export type ListCorralsWithoutDietInfiniteQueryError = unknown;

export const useListCorralsWithoutDietInfinite = <
  TData = Awaited<ReturnType<typeof listCorralsWithoutDiet>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCorralsWithoutDiet>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCorralsWithoutDietInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListCorralsWithoutDietQueryOptions = <
  TData = Awaited<ReturnType<typeof listCorralsWithoutDiet>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listCorralsWithoutDiet>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCorralsWithoutDietQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCorralsWithoutDiet>>> = ({ signal }) =>
    listCorralsWithoutDiet(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCorralsWithoutDiet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCorralsWithoutDietQueryResult = NonNullable<Awaited<ReturnType<typeof listCorralsWithoutDiet>>>;
export type ListCorralsWithoutDietQueryError = unknown;

export const useListCorralsWithoutDiet = <
  TData = Awaited<ReturnType<typeof listCorralsWithoutDiet>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listCorralsWithoutDiet>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCorralsWithoutDietQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const killBatch = (id: number, killOx: KillOx, options?: SecondParameter<typeof request>) => {
  return request<KillBatchResponse>(
    { url: `/batches/${id}`, method: 'delete', headers: { 'Content-Type': 'application/json' }, data: killOx },
    options
  );
};

export const getKillBatchMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof killBatch>>, TError, { id: number; data: KillOx }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof killBatch>>, TError, { id: number; data: KillOx }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof killBatch>>, { id: number; data: KillOx }> = (props) => {
    const { id, data } = props ?? {};

    return killBatch(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type KillBatchMutationResult = NonNullable<Awaited<ReturnType<typeof killBatch>>>;
export type KillBatchMutationBody = KillOx;
export type KillBatchMutationError = unknown;

export const useKillBatch = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof killBatch>>, TError, { id: number; data: KillOx }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getKillBatchMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateBatch = (id: number, updateBatch: UpdateBatch, options?: SecondParameter<typeof request>) => {
  return request<UpdateBatchResponse>(
    { url: `/batches/${id}`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: updateBatch },
    options
  );
};

export const getUpdateBatchMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBatch>>,
    TError,
    { id: number; data: UpdateBatch },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateBatch>>,
  TError,
  { id: number; data: UpdateBatch },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateBatch>>, { id: number; data: UpdateBatch }> = (
    props
  ) => {
    const { id, data } = props ?? {};

    return updateBatch(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateBatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateBatch>>>;
export type UpdateBatchMutationBody = UpdateBatch;
export type UpdateBatchMutationError = unknown;

export const useUpdateBatch = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBatch>>,
    TError,
    { id: number; data: UpdateBatch },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateBatchMutationOptions(options);

  return useMutation(mutationOptions);
};

export const activateBatch = (id: number, params: ActivateBatchParams, options?: SecondParameter<typeof request>) => {
  return request<ActivateBatchResponse>({ url: `/batches/${id}/activate`, method: 'post', params }, options);
};

export const getActivateBatchMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activateBatch>>,
    TError,
    { id: number; params: ActivateBatchParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof activateBatch>>,
  TError,
  { id: number; params: ActivateBatchParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof activateBatch>>,
    { id: number; params: ActivateBatchParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return activateBatch(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ActivateBatchMutationResult = NonNullable<Awaited<ReturnType<typeof activateBatch>>>;

export type ActivateBatchMutationError = HttpError;

export const useActivateBatch = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof activateBatch>>,
    TError,
    { id: number; params: ActivateBatchParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getActivateBatchMutationOptions(options);

  return useMutation(mutationOptions);
};

export const sendDailyRead = (
  id: number,
  batchDailyRead: BatchDailyRead,
  options?: SecondParameter<typeof request>
) => {
  return request<SendDailyReadResponse>(
    {
      url: `/batches/${id}/daily-read`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: batchDailyRead
    },
    options
  );
};

export const getSendDailyReadMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendDailyRead>>,
    TError,
    { id: number; data: BatchDailyRead },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendDailyRead>>,
  TError,
  { id: number; data: BatchDailyRead },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendDailyRead>>,
    { id: number; data: BatchDailyRead }
  > = (props) => {
    const { id, data } = props ?? {};

    return sendDailyRead(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendDailyReadMutationResult = NonNullable<Awaited<ReturnType<typeof sendDailyRead>>>;
export type SendDailyReadMutationBody = BatchDailyRead;
export type SendDailyReadMutationError = unknown;

export const useSendDailyRead = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendDailyRead>>,
    TError,
    { id: number; data: BatchDailyRead },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getSendDailyReadMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getBatchHistory = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetBatchHistoryResponse>({ url: `/batches/${id}/histories`, method: 'get', signal }, options);
};

export const getGetBatchHistoryQueryKey = (id: number) => {
  return [`/batches/${id}/histories`] as const;
};

export const getGetBatchHistoryInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getBatchHistory>>,
  TError = HttpError
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getBatchHistory>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBatchHistoryQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBatchHistory>>> = ({ signal }) =>
    getBatchHistory(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getBatchHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBatchHistoryInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getBatchHistory>>>;
export type GetBatchHistoryInfiniteQueryError = HttpError;

export const useGetBatchHistoryInfinite = <TData = Awaited<ReturnType<typeof getBatchHistory>>, TError = HttpError>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getBatchHistory>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBatchHistoryInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetBatchHistoryQueryOptions = <TData = Awaited<ReturnType<typeof getBatchHistory>>, TError = HttpError>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBatchHistory>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBatchHistoryQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBatchHistory>>> = ({ signal }) =>
    getBatchHistory(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBatchHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBatchHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof getBatchHistory>>>;
export type GetBatchHistoryQueryError = HttpError;

export const useGetBatchHistory = <TData = Awaited<ReturnType<typeof getBatchHistory>>, TError = HttpError>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBatchHistory>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBatchHistoryQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const adjustCurrentForeseen = (
  id: number,
  adjustCurrentForeseen: AdjustCurrentForeseen,
  options?: SecondParameter<typeof request>
) => {
  return request<AdjustCurrentForeseenResponse>(
    {
      url: `/batches/${id}/histories/adjust`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: adjustCurrentForeseen
    },
    options
  );
};

export const getAdjustCurrentForeseenMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adjustCurrentForeseen>>,
    TError,
    { id: number; data: AdjustCurrentForeseen },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adjustCurrentForeseen>>,
  TError,
  { id: number; data: AdjustCurrentForeseen },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adjustCurrentForeseen>>,
    { id: number; data: AdjustCurrentForeseen }
  > = (props) => {
    const { id, data } = props ?? {};

    return adjustCurrentForeseen(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdjustCurrentForeseenMutationResult = NonNullable<Awaited<ReturnType<typeof adjustCurrentForeseen>>>;
export type AdjustCurrentForeseenMutationBody = AdjustCurrentForeseen;
export type AdjustCurrentForeseenMutationError = HttpError;

export const useAdjustCurrentForeseen = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adjustCurrentForeseen>>,
    TError,
    { id: number; data: AdjustCurrentForeseen },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getAdjustCurrentForeseenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const replicateCurrentForeseen = (id: number, options?: SecondParameter<typeof request>) => {
  return request<ReplicateCurrentForeseenResponse>({ url: `/batches/${id}/histories/adjust`, method: 'put' }, options);
};

export const getReplicateCurrentForeseenMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof replicateCurrentForeseen>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof replicateCurrentForeseen>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof replicateCurrentForeseen>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {};

    return replicateCurrentForeseen(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReplicateCurrentForeseenMutationResult = NonNullable<Awaited<ReturnType<typeof replicateCurrentForeseen>>>;

export type ReplicateCurrentForeseenMutationError = HttpError;

export const useReplicateCurrentForeseen = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof replicateCurrentForeseen>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getReplicateCurrentForeseenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const confirmTreat = (
  id: number,
  confirmTreatBody: ConfirmTreatBody,
  options?: SecondParameter<typeof request>
) => {
  return request<boolean>(
    {
      url: `/batches/${id}/histories/treat`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: confirmTreatBody
    },
    options
  );
};

export const getConfirmTreatMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmTreat>>,
    TError,
    { id: number; data: ConfirmTreatBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof confirmTreat>>,
  TError,
  { id: number; data: ConfirmTreatBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof confirmTreat>>,
    { id: number; data: ConfirmTreatBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return confirmTreat(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfirmTreatMutationResult = NonNullable<Awaited<ReturnType<typeof confirmTreat>>>;
export type ConfirmTreatMutationBody = ConfirmTreatBody;
export type ConfirmTreatMutationError = HttpError;

export const useConfirmTreat = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmTreat>>,
    TError,
    { id: number; data: ConfirmTreatBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getConfirmTreatMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listSimpleAnimal = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListSimpleAnimalResponse>(
    { url: `/batches/${id}/list-simple-animal`, method: 'get', signal },
    options
  );
};

export const getListSimpleAnimalQueryKey = (id: number) => {
  return [`/batches/${id}/list-simple-animal`] as const;
};

export const getListSimpleAnimalInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listSimpleAnimal>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listSimpleAnimal>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSimpleAnimalQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSimpleAnimal>>> = ({ signal }) =>
    listSimpleAnimal(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listSimpleAnimal>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListSimpleAnimalInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listSimpleAnimal>>>;
export type ListSimpleAnimalInfiniteQueryError = unknown;

export const useListSimpleAnimalInfinite = <TData = Awaited<ReturnType<typeof listSimpleAnimal>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listSimpleAnimal>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListSimpleAnimalInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListSimpleAnimalQueryOptions = <TData = Awaited<ReturnType<typeof listSimpleAnimal>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listSimpleAnimal>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSimpleAnimalQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSimpleAnimal>>> = ({ signal }) =>
    listSimpleAnimal(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listSimpleAnimal>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListSimpleAnimalQueryResult = NonNullable<Awaited<ReturnType<typeof listSimpleAnimal>>>;
export type ListSimpleAnimalQueryError = unknown;

export const useListSimpleAnimal = <TData = Awaited<ReturnType<typeof listSimpleAnimal>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listSimpleAnimal>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListSimpleAnimalQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listOxenByBatchId = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListOxenByBatchIdResponse>({ url: `/batches/${id}/oxen`, method: 'get', signal }, options);
};

export const getListOxenByBatchIdQueryKey = (id: number) => {
  return [`/batches/${id}/oxen`] as const;
};

export const getListOxenByBatchIdInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listOxenByBatchId>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listOxenByBatchId>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListOxenByBatchIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listOxenByBatchId>>> = ({ signal }) =>
    listOxenByBatchId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listOxenByBatchId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListOxenByBatchIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listOxenByBatchId>>>;
export type ListOxenByBatchIdInfiniteQueryError = unknown;

export const useListOxenByBatchIdInfinite = <TData = Awaited<ReturnType<typeof listOxenByBatchId>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listOxenByBatchId>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListOxenByBatchIdInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListOxenByBatchIdQueryOptions = <
  TData = Awaited<ReturnType<typeof listOxenByBatchId>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listOxenByBatchId>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListOxenByBatchIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listOxenByBatchId>>> = ({ signal }) =>
    listOxenByBatchId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listOxenByBatchId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListOxenByBatchIdQueryResult = NonNullable<Awaited<ReturnType<typeof listOxenByBatchId>>>;
export type ListOxenByBatchIdQueryError = unknown;

export const useListOxenByBatchId = <TData = Awaited<ReturnType<typeof listOxenByBatchId>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listOxenByBatchId>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListOxenByBatchIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const batchStats = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<BatchStatsResponse>({ url: `/batches/${id}/stats`, method: 'get', signal }, options);
};

export const getBatchStatsQueryKey = (id: number) => {
  return [`/batches/${id}/stats`] as const;
};

export const getBatchStatsInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof batchStats>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchStats>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchStatsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchStats>>> = ({ signal }) =>
    batchStats(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof batchStats>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchStatsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof batchStats>>>;
export type BatchStatsInfiniteQueryError = unknown;

export const useBatchStatsInfinite = <TData = Awaited<ReturnType<typeof batchStats>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchStats>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchStatsInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBatchStatsQueryOptions = <TData = Awaited<ReturnType<typeof batchStats>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof batchStats>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchStatsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchStats>>> = ({ signal }) =>
    batchStats(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof batchStats>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchStatsQueryResult = NonNullable<Awaited<ReturnType<typeof batchStats>>>;
export type BatchStatsQueryError = unknown;

export const useBatchStats = <TData = Awaited<ReturnType<typeof batchStats>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof batchStats>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchStatsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const transitionBatchDiet = (
  id: number,
  params: TransitionBatchDietParams,
  options?: SecondParameter<typeof request>
) => {
  return request<TransitionBatchDietResponse>({ url: `/batches/${id}/transition`, method: 'post', params }, options);
};

export const getTransitionBatchDietMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof transitionBatchDiet>>,
    TError,
    { id: number; params: TransitionBatchDietParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof transitionBatchDiet>>,
  TError,
  { id: number; params: TransitionBatchDietParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof transitionBatchDiet>>,
    { id: number; params: TransitionBatchDietParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return transitionBatchDiet(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TransitionBatchDietMutationResult = NonNullable<Awaited<ReturnType<typeof transitionBatchDiet>>>;

export type TransitionBatchDietMutationError = HttpError;

export const useTransitionBatchDiet = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof transitionBatchDiet>>,
    TError,
    { id: number; params: TransitionBatchDietParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getTransitionBatchDietMutationOptions(options);

  return useMutation(mutationOptions);
};

/** Essa rota não tem filtragem pois sempre precisaremos da listagem completa. */
export const listCorral = (
  params?: ListCorralParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListCorralResponse>({ url: `/corrals`, method: 'get', params, signal }, options);
};

export const getListCorralQueryKey = (params?: ListCorralParams) => {
  return [`/corrals`, ...(params ? [params] : [])] as const;
};

export const getListCorralInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof listCorral>>, TError = unknown>(
  params?: ListCorralParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCorral>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCorralQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCorral>>> = ({ signal, pageParam }) =>
    listCorral({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listCorral>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCorralInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listCorral>>>;
export type ListCorralInfiniteQueryError = unknown;

export const useListCorralInfinite = <TData = Awaited<ReturnType<typeof listCorral>>, TError = unknown>(
  params?: ListCorralParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCorral>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCorralInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListCorralQueryOptions = <TData = Awaited<ReturnType<typeof listCorral>>, TError = unknown>(
  params?: ListCorralParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listCorral>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCorralQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCorral>>> = ({ signal }) =>
    listCorral(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCorral>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCorralQueryResult = NonNullable<Awaited<ReturnType<typeof listCorral>>>;
export type ListCorralQueryError = unknown;

export const useListCorral = <TData = Awaited<ReturnType<typeof listCorral>>, TError = unknown>(
  params?: ListCorralParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listCorral>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCorralQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createCorral = (createCorral: CreateCorral, options?: SecondParameter<typeof request>) => {
  return request<CreateCorralResponse>(
    { url: `/corrals`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createCorral },
    options
  );
};

export const getCreateCorralMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createCorral>>, TError, { data: CreateCorral }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createCorral>>, TError, { data: CreateCorral }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCorral>>, { data: CreateCorral }> = (props) => {
    const { data } = props ?? {};

    return createCorral(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCorralMutationResult = NonNullable<Awaited<ReturnType<typeof createCorral>>>;
export type CreateCorralMutationBody = CreateCorral;
export type CreateCorralMutationError = HttpError;

export const useCreateCorral = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createCorral>>, TError, { data: CreateCorral }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateCorralMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listCorralsAndBatches = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListCorralsAndBatchesResponse>({ url: `/corrals/batch`, method: 'get', signal }, options);
};

export const getListCorralsAndBatchesQueryKey = () => {
  return [`/corrals/batch`] as const;
};

export const getListCorralsAndBatchesInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listCorralsAndBatches>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCorralsAndBatches>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCorralsAndBatchesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCorralsAndBatches>>> = ({ signal }) =>
    listCorralsAndBatches(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listCorralsAndBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCorralsAndBatchesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listCorralsAndBatches>>>;
export type ListCorralsAndBatchesInfiniteQueryError = unknown;

export const useListCorralsAndBatchesInfinite = <
  TData = Awaited<ReturnType<typeof listCorralsAndBatches>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCorralsAndBatches>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCorralsAndBatchesInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListCorralsAndBatchesQueryOptions = <
  TData = Awaited<ReturnType<typeof listCorralsAndBatches>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listCorralsAndBatches>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCorralsAndBatchesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCorralsAndBatches>>> = ({ signal }) =>
    listCorralsAndBatches(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCorralsAndBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCorralsAndBatchesQueryResult = NonNullable<Awaited<ReturnType<typeof listCorralsAndBatches>>>;
export type ListCorralsAndBatchesQueryError = unknown;

export const useListCorralsAndBatches = <
  TData = Awaited<ReturnType<typeof listCorralsAndBatches>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listCorralsAndBatches>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCorralsAndBatchesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listCorralPositions = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListCorralPositionsResponse>({ url: `/corrals/positions`, method: 'get', signal }, options);
};

export const getListCorralPositionsQueryKey = () => {
  return [`/corrals/positions`] as const;
};

export const getListCorralPositionsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listCorralPositions>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCorralPositions>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCorralPositionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCorralPositions>>> = ({ signal }) =>
    listCorralPositions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listCorralPositions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCorralPositionsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listCorralPositions>>>;
export type ListCorralPositionsInfiniteQueryError = unknown;

export const useListCorralPositionsInfinite = <
  TData = Awaited<ReturnType<typeof listCorralPositions>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCorralPositions>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCorralPositionsInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListCorralPositionsQueryOptions = <
  TData = Awaited<ReturnType<typeof listCorralPositions>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listCorralPositions>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCorralPositionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCorralPositions>>> = ({ signal }) =>
    listCorralPositions(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCorralPositions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCorralPositionsQueryResult = NonNullable<Awaited<ReturnType<typeof listCorralPositions>>>;
export type ListCorralPositionsQueryError = unknown;

export const useListCorralPositions = <
  TData = Awaited<ReturnType<typeof listCorralPositions>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listCorralPositions>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCorralPositionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateCorralPositions = (
  corralChangePosition: CorralChangePosition[],
  options?: SecondParameter<typeof request>
) => {
  return request<UpdateCorralPositionsResponse>(
    {
      url: `/corrals/positions`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: corralChangePosition
    },
    options
  );
};

export const getUpdateCorralPositionsMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCorralPositions>>,
    TError,
    { data: CorralChangePosition[] },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCorralPositions>>,
  TError,
  { data: CorralChangePosition[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCorralPositions>>,
    { data: CorralChangePosition[] }
  > = (props) => {
    const { data } = props ?? {};

    return updateCorralPositions(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCorralPositionsMutationResult = NonNullable<Awaited<ReturnType<typeof updateCorralPositions>>>;
export type UpdateCorralPositionsMutationBody = CorralChangePosition[];
export type UpdateCorralPositionsMutationError = unknown;

export const useUpdateCorralPositions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCorralPositions>>,
    TError,
    { data: CorralChangePosition[] },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateCorralPositionsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getCorral = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetCorralResponse>({ url: `/corrals/${id}`, method: 'get', signal }, options);
};

export const getGetCorralQueryKey = (id: number) => {
  return [`/corrals/${id}`] as const;
};

export const getGetCorralInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof getCorral>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCorral>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCorralQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCorral>>> = ({ signal }) =>
    getCorral(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getCorral>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCorralInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCorral>>>;
export type GetCorralInfiniteQueryError = unknown;

export const useGetCorralInfinite = <TData = Awaited<ReturnType<typeof getCorral>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCorral>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCorralInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetCorralQueryOptions = <TData = Awaited<ReturnType<typeof getCorral>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCorral>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCorralQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCorral>>> = ({ signal }) =>
    getCorral(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCorral>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCorralQueryResult = NonNullable<Awaited<ReturnType<typeof getCorral>>>;
export type GetCorralQueryError = unknown;

export const useGetCorral = <TData = Awaited<ReturnType<typeof getCorral>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCorral>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCorralQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateCorral = (id: number, updateCorral: UpdateCorral, options?: SecondParameter<typeof request>) => {
  return request<UpdateCorralResponse>(
    { url: `/corrals/${id}`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: updateCorral },
    options
  );
};

export const getUpdateCorralMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCorral>>,
    TError,
    { id: number; data: UpdateCorral },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCorral>>,
  TError,
  { id: number; data: UpdateCorral },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCorral>>, { id: number; data: UpdateCorral }> = (
    props
  ) => {
    const { id, data } = props ?? {};

    return updateCorral(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCorralMutationResult = NonNullable<Awaited<ReturnType<typeof updateCorral>>>;
export type UpdateCorralMutationBody = UpdateCorral;
export type UpdateCorralMutationError = unknown;

export const useUpdateCorral = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCorral>>,
    TError,
    { id: number; data: UpdateCorral },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateCorralMutationOptions(options);

  return useMutation(mutationOptions);
};

export const moveBatch = (id: number, params: MoveBatchParams, options?: SecondParameter<typeof request>) => {
  return request<MoveBatchResponse>({ url: `/corrals/${id}/movement`, method: 'post', params }, options);
};

export const getMoveBatchMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof moveBatch>>,
    TError,
    { id: number; params: MoveBatchParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof moveBatch>>,
  TError,
  { id: number; params: MoveBatchParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveBatch>>, { id: number; params: MoveBatchParams }> = (
    props
  ) => {
    const { id, params } = props ?? {};

    return moveBatch(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MoveBatchMutationResult = NonNullable<Awaited<ReturnType<typeof moveBatch>>>;

export type MoveBatchMutationError = HttpError;

export const useMoveBatch = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof moveBatch>>,
    TError,
    { id: number; params: MoveBatchParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getMoveBatchMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listCorralOxen = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListCorralOxenResponse>({ url: `/corrals/${id}/oxen`, method: 'get', signal }, options);
};

export const getListCorralOxenQueryKey = (id: number) => {
  return [`/corrals/${id}/oxen`] as const;
};

export const getListCorralOxenInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listCorralOxen>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCorralOxen>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCorralOxenQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCorralOxen>>> = ({ signal }) =>
    listCorralOxen(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listCorralOxen>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCorralOxenInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listCorralOxen>>>;
export type ListCorralOxenInfiniteQueryError = unknown;

export const useListCorralOxenInfinite = <TData = Awaited<ReturnType<typeof listCorralOxen>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCorralOxen>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCorralOxenInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListCorralOxenQueryOptions = <TData = Awaited<ReturnType<typeof listCorralOxen>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listCorralOxen>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCorralOxenQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCorralOxen>>> = ({ signal }) =>
    listCorralOxen(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCorralOxen>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCorralOxenQueryResult = NonNullable<Awaited<ReturnType<typeof listCorralOxen>>>;
export type ListCorralOxenQueryError = unknown;

export const useListCorralOxen = <TData = Awaited<ReturnType<typeof listCorralOxen>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listCorralOxen>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCorralOxenQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listDietTransitions = (
  params?: ListDietTransitionsParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListDietTransitionsResponse>({ url: `/diet-transitions`, method: 'get', params, signal }, options);
};

export const getListDietTransitionsQueryKey = (params?: ListDietTransitionsParams) => {
  return [`/diet-transitions`, ...(params ? [params] : [])] as const;
};

export const getListDietTransitionsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listDietTransitions>>,
  TError = unknown
>(
  params?: ListDietTransitionsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listDietTransitions>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDietTransitionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listDietTransitions>>> = ({ signal, pageParam }) =>
    listDietTransitions({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listDietTransitions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListDietTransitionsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listDietTransitions>>>;
export type ListDietTransitionsInfiniteQueryError = unknown;

export const useListDietTransitionsInfinite = <
  TData = Awaited<ReturnType<typeof listDietTransitions>>,
  TError = unknown
>(
  params?: ListDietTransitionsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listDietTransitions>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListDietTransitionsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListDietTransitionsQueryOptions = <
  TData = Awaited<ReturnType<typeof listDietTransitions>>,
  TError = unknown
>(
  params?: ListDietTransitionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listDietTransitions>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDietTransitionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listDietTransitions>>> = ({ signal }) =>
    listDietTransitions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listDietTransitions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListDietTransitionsQueryResult = NonNullable<Awaited<ReturnType<typeof listDietTransitions>>>;
export type ListDietTransitionsQueryError = unknown;

export const useListDietTransitions = <TData = Awaited<ReturnType<typeof listDietTransitions>>, TError = unknown>(
  params?: ListDietTransitionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listDietTransitions>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListDietTransitionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createDietTransition = (
  createDietTransition: CreateDietTransition,
  options?: SecondParameter<typeof request>
) => {
  return request<CreateDietTransitionResponse>(
    {
      url: `/diet-transitions`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createDietTransition
    },
    options
  );
};

export const getCreateDietTransitionMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createDietTransition>>,
    TError,
    { data: CreateDietTransition },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createDietTransition>>,
  TError,
  { data: CreateDietTransition },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createDietTransition>>,
    { data: CreateDietTransition }
  > = (props) => {
    const { data } = props ?? {};

    return createDietTransition(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateDietTransitionMutationResult = NonNullable<Awaited<ReturnType<typeof createDietTransition>>>;
export type CreateDietTransitionMutationBody = CreateDietTransition;
export type CreateDietTransitionMutationError = HttpError;

export const useCreateDietTransition = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createDietTransition>>,
    TError,
    { data: CreateDietTransition },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateDietTransitionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listDiets = (
  params?: ListDietsParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListDietsResponse>({ url: `/diets`, method: 'get', params, signal }, options);
};

export const getListDietsQueryKey = (params?: ListDietsParams) => {
  return [`/diets`, ...(params ? [params] : [])] as const;
};

export const getListDietsInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof listDiets>>, TError = unknown>(
  params?: ListDietsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listDiets>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDietsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listDiets>>> = ({ signal, pageParam }) =>
    listDiets({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listDiets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListDietsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listDiets>>>;
export type ListDietsInfiniteQueryError = unknown;

export const useListDietsInfinite = <TData = Awaited<ReturnType<typeof listDiets>>, TError = unknown>(
  params?: ListDietsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listDiets>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListDietsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListDietsQueryOptions = <TData = Awaited<ReturnType<typeof listDiets>>, TError = unknown>(
  params?: ListDietsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listDiets>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDietsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listDiets>>> = ({ signal }) =>
    listDiets(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listDiets>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListDietsQueryResult = NonNullable<Awaited<ReturnType<typeof listDiets>>>;
export type ListDietsQueryError = unknown;

export const useListDiets = <TData = Awaited<ReturnType<typeof listDiets>>, TError = unknown>(
  params?: ListDietsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listDiets>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListDietsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createDiet = (createDiet: CreateDiet, options?: SecondParameter<typeof request>) => {
  return request<CreateDietResponse>(
    { url: `/diets`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createDiet },
    options
  );
};

export const getCreateDietMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createDiet>>, TError, { data: CreateDiet }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createDiet>>, TError, { data: CreateDiet }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDiet>>, { data: CreateDiet }> = (props) => {
    const { data } = props ?? {};

    return createDiet(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateDietMutationResult = NonNullable<Awaited<ReturnType<typeof createDiet>>>;
export type CreateDietMutationBody = CreateDiet;
export type CreateDietMutationError = HttpError;

export const useCreateDiet = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createDiet>>, TError, { data: CreateDiet }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateDietMutationOptions(options);

  return useMutation(mutationOptions);
};

export const changeBatchesDiet = (
  changeBatchesDietBody: ChangeBatchesDietBody,
  options?: SecondParameter<typeof request>
) => {
  return request<boolean>(
    {
      url: `/diets/change-batches-diet`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: changeBatchesDietBody
    },
    options
  );
};

export const getChangeBatchesDietMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeBatchesDiet>>,
    TError,
    { data: ChangeBatchesDietBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changeBatchesDiet>>,
  TError,
  { data: ChangeBatchesDietBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof changeBatchesDiet>>, { data: ChangeBatchesDietBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return changeBatchesDiet(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangeBatchesDietMutationResult = NonNullable<Awaited<ReturnType<typeof changeBatchesDiet>>>;
export type ChangeBatchesDietMutationBody = ChangeBatchesDietBody;
export type ChangeBatchesDietMutationError = HttpError;

export const useChangeBatchesDiet = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeBatchesDiet>>,
    TError,
    { data: ChangeBatchesDietBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getChangeBatchesDietMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteDiet = (id: number, options?: SecondParameter<typeof request>) => {
  return request<DeleteDietResponse>({ url: `/diets/${id}`, method: 'delete' }, options);
};

export const getDeleteDietMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteDiet>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteDiet>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDiet>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return deleteDiet(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteDietMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDiet>>>;

export type DeleteDietMutationError = unknown;

export const useDeleteDiet = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteDiet>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getDeleteDietMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getDiet = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetDietResponse>({ url: `/diets/${id}`, method: 'get', signal }, options);
};

export const getGetDietQueryKey = (id: number) => {
  return [`/diets/${id}`] as const;
};

export const getGetDietInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof getDiet>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDiet>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDietQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDiet>>> = ({ signal }) =>
    getDiet(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getDiet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDietInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getDiet>>>;
export type GetDietInfiniteQueryError = unknown;

export const useGetDietInfinite = <TData = Awaited<ReturnType<typeof getDiet>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getDiet>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDietInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetDietQueryOptions = <TData = Awaited<ReturnType<typeof getDiet>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDiet>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDietQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDiet>>> = ({ signal }) =>
    getDiet(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDiet>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDietQueryResult = NonNullable<Awaited<ReturnType<typeof getDiet>>>;
export type GetDietQueryError = unknown;

export const useGetDiet = <TData = Awaited<ReturnType<typeof getDiet>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getDiet>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDietQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/** Move todos os lotes com a dieta atual para a dieta sucessora. */
export const succeedDiet = (id: number, params: SucceedDietParams, options?: SecondParameter<typeof request>) => {
  return request<boolean>({ url: `/diets/${id}/succeed`, method: 'post', params }, options);
};

export const getSucceedDietMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof succeedDiet>>,
    TError,
    { id: number; params: SucceedDietParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof succeedDiet>>,
  TError,
  { id: number; params: SucceedDietParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof succeedDiet>>,
    { id: number; params: SucceedDietParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return succeedDiet(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SucceedDietMutationResult = NonNullable<Awaited<ReturnType<typeof succeedDiet>>>;

export type SucceedDietMutationError = HttpError;

export const useSucceedDiet = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof succeedDiet>>,
    TError,
    { id: number; params: SucceedDietParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getSucceedDietMutationOptions(options);

  return useMutation(mutationOptions);
};

export const registerEarrings = (params: RegisterEarringsParams, options?: SecondParameter<typeof request>) => {
  return request<number[]>({ url: `/earrings/register`, method: 'put', params }, options);
};

export const getRegisterEarringsMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerEarrings>>,
    TError,
    { params: RegisterEarringsParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof registerEarrings>>,
  TError,
  { params: RegisterEarringsParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registerEarrings>>,
    { params: RegisterEarringsParams }
  > = (props) => {
    const { params } = props ?? {};

    return registerEarrings(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegisterEarringsMutationResult = NonNullable<Awaited<ReturnType<typeof registerEarrings>>>;

export type RegisterEarringsMutationError = HttpError;

export const useRegisterEarrings = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerEarrings>>,
    TError,
    { params: RegisterEarringsParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getRegisterEarringsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const cancelEarring = (sisbov: number, options?: SecondParameter<typeof request>) => {
  return request<boolean>({ url: `/earrings/${sisbov}`, method: 'delete' }, options);
};

export const getCancelEarringMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelEarring>>, TError, { sisbov: number }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof cancelEarring>>, TError, { sisbov: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelEarring>>, { sisbov: number }> = (props) => {
    const { sisbov } = props ?? {};

    return cancelEarring(sisbov, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelEarringMutationResult = NonNullable<Awaited<ReturnType<typeof cancelEarring>>>;

export type CancelEarringMutationError = HttpError;

export const useCancelEarring = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelEarring>>, TError, { sisbov: number }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCancelEarringMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listEstablishments = (
  params?: ListEstablishmentsParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListEstablishmentsResponse>({ url: `/establishments`, method: 'get', params, signal }, options);
};

export const getListEstablishmentsQueryKey = (params?: ListEstablishmentsParams) => {
  return [`/establishments`, ...(params ? [params] : [])] as const;
};

export const getListEstablishmentsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listEstablishments>>,
  TError = unknown
>(
  params?: ListEstablishmentsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listEstablishments>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListEstablishmentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listEstablishments>>> = ({ signal, pageParam }) =>
    listEstablishments({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listEstablishments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListEstablishmentsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listEstablishments>>>;
export type ListEstablishmentsInfiniteQueryError = unknown;

export const useListEstablishmentsInfinite = <TData = Awaited<ReturnType<typeof listEstablishments>>, TError = unknown>(
  params?: ListEstablishmentsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listEstablishments>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListEstablishmentsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListEstablishmentsQueryOptions = <
  TData = Awaited<ReturnType<typeof listEstablishments>>,
  TError = unknown
>(
  params?: ListEstablishmentsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listEstablishments>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListEstablishmentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listEstablishments>>> = ({ signal }) =>
    listEstablishments(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listEstablishments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListEstablishmentsQueryResult = NonNullable<Awaited<ReturnType<typeof listEstablishments>>>;
export type ListEstablishmentsQueryError = unknown;

export const useListEstablishments = <TData = Awaited<ReturnType<typeof listEstablishments>>, TError = unknown>(
  params?: ListEstablishmentsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listEstablishments>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListEstablishmentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createEstablishment = (
  createEstablishment: CreateEstablishment,
  options?: SecondParameter<typeof request>
) => {
  return request<CreateEstablishmentResponse>(
    {
      url: `/establishments`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createEstablishment
    },
    options
  );
};

export const getCreateEstablishmentMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEstablishment>>,
    TError,
    { data: CreateEstablishment },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createEstablishment>>,
  TError,
  { data: CreateEstablishment },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEstablishment>>, { data: CreateEstablishment }> = (
    props
  ) => {
    const { data } = props ?? {};

    return createEstablishment(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateEstablishmentMutationResult = NonNullable<Awaited<ReturnType<typeof createEstablishment>>>;
export type CreateEstablishmentMutationBody = CreateEstablishment;
export type CreateEstablishmentMutationError = unknown;

export const useCreateEstablishment = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEstablishment>>,
    TError,
    { data: CreateEstablishment },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateEstablishmentMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteEstablishment = (id: number, options?: SecondParameter<typeof request>) => {
  return request<DeleteEstablishmentResponse>({ url: `/establishments/${id}`, method: 'delete' }, options);
};

export const getDeleteEstablishmentMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteEstablishment>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteEstablishment>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEstablishment>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return deleteEstablishment(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteEstablishmentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEstablishment>>>;

export type DeleteEstablishmentMutationError = unknown;

export const useDeleteEstablishment = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteEstablishment>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getDeleteEstablishmentMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getEstablishmentById = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetEstablishmentByIdResponse>({ url: `/establishments/${id}`, method: 'get', signal }, options);
};

export const getGetEstablishmentByIdQueryKey = (id: number) => {
  return [`/establishments/${id}`] as const;
};

export const getGetEstablishmentByIdInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getEstablishmentById>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEstablishmentById>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEstablishmentByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEstablishmentById>>> = ({ signal }) =>
    getEstablishmentById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getEstablishmentById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEstablishmentByIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEstablishmentById>>>;
export type GetEstablishmentByIdInfiniteQueryError = unknown;

export const useGetEstablishmentByIdInfinite = <
  TData = Awaited<ReturnType<typeof getEstablishmentById>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEstablishmentById>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEstablishmentByIdInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetEstablishmentByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getEstablishmentById>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getEstablishmentById>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEstablishmentByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEstablishmentById>>> = ({ signal }) =>
    getEstablishmentById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getEstablishmentById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEstablishmentByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEstablishmentById>>>;
export type GetEstablishmentByIdQueryError = unknown;

export const useGetEstablishmentById = <TData = Awaited<ReturnType<typeof getEstablishmentById>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getEstablishmentById>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEstablishmentByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateEstablishment = (
  id: number,
  updateEstablishment: UpdateEstablishment,
  options?: SecondParameter<typeof request>
) => {
  return request<UpdateEstablishmentResponse>(
    {
      url: `/establishments/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateEstablishment
    },
    options
  );
};

export const getUpdateEstablishmentMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEstablishment>>,
    TError,
    { id: number; data: UpdateEstablishment },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateEstablishment>>,
  TError,
  { id: number; data: UpdateEstablishment },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateEstablishment>>,
    { id: number; data: UpdateEstablishment }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateEstablishment(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateEstablishmentMutationResult = NonNullable<Awaited<ReturnType<typeof updateEstablishment>>>;
export type UpdateEstablishmentMutationBody = UpdateEstablishment;
export type UpdateEstablishmentMutationError = unknown;

export const useUpdateEstablishment = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEstablishment>>,
    TError,
    { id: number; data: UpdateEstablishment },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateEstablishmentMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listExitTrucks = (
  params?: ListExitTrucksParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListExitTrucksResponse>({ url: `/exit-trucks`, method: 'get', params, signal }, options);
};

export const getListExitTrucksQueryKey = (params?: ListExitTrucksParams) => {
  return [`/exit-trucks`, ...(params ? [params] : [])] as const;
};

export const getListExitTrucksInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listExitTrucks>>,
  TError = HttpError
>(
  params?: ListExitTrucksParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listExitTrucks>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListExitTrucksQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listExitTrucks>>> = ({ signal, pageParam }) =>
    listExitTrucks({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listExitTrucks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListExitTrucksInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listExitTrucks>>>;
export type ListExitTrucksInfiniteQueryError = HttpError;

export const useListExitTrucksInfinite = <TData = Awaited<ReturnType<typeof listExitTrucks>>, TError = HttpError>(
  params?: ListExitTrucksParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listExitTrucks>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListExitTrucksInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListExitTrucksQueryOptions = <TData = Awaited<ReturnType<typeof listExitTrucks>>, TError = HttpError>(
  params?: ListExitTrucksParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listExitTrucks>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListExitTrucksQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listExitTrucks>>> = ({ signal }) =>
    listExitTrucks(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listExitTrucks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListExitTrucksQueryResult = NonNullable<Awaited<ReturnType<typeof listExitTrucks>>>;
export type ListExitTrucksQueryError = HttpError;

export const useListExitTrucks = <TData = Awaited<ReturnType<typeof listExitTrucks>>, TError = HttpError>(
  params?: ListExitTrucksParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listExitTrucks>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListExitTrucksQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createTruck = (createTruck: CreateTruck, options?: SecondParameter<typeof request>) => {
  return request<CreateTruckResponse>(
    { url: `/exit-trucks`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createTruck },
    options
  );
};

export const getCreateTruckMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createTruck>>, TError, { data: CreateTruck }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createTruck>>, TError, { data: CreateTruck }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTruck>>, { data: CreateTruck }> = (props) => {
    const { data } = props ?? {};

    return createTruck(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTruckMutationResult = NonNullable<Awaited<ReturnType<typeof createTruck>>>;
export type CreateTruckMutationBody = CreateTruck;
export type CreateTruckMutationError = HttpError;

export const useCreateTruck = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createTruck>>, TError, { data: CreateTruck }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateTruckMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getExitTruck = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetExitTruckResponse>({ url: `/exit-trucks/${id}`, method: 'get', signal }, options);
};

export const getGetExitTruckQueryKey = (id: number) => {
  return [`/exit-trucks/${id}`] as const;
};

export const getGetExitTruckInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof getExitTruck>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExitTruck>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetExitTruckQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExitTruck>>> = ({ signal }) =>
    getExitTruck(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getExitTruck>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetExitTruckInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getExitTruck>>>;
export type GetExitTruckInfiniteQueryError = unknown;

export const useGetExitTruckInfinite = <TData = Awaited<ReturnType<typeof getExitTruck>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExitTruck>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetExitTruckInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetExitTruckQueryOptions = <TData = Awaited<ReturnType<typeof getExitTruck>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getExitTruck>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetExitTruckQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExitTruck>>> = ({ signal }) =>
    getExitTruck(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getExitTruck>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetExitTruckQueryResult = NonNullable<Awaited<ReturnType<typeof getExitTruck>>>;
export type GetExitTruckQueryError = unknown;

export const useGetExitTruck = <TData = Awaited<ReturnType<typeof getExitTruck>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getExitTruck>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetExitTruckQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateExitTruck = (id: number, updateTruck: UpdateTruck, options?: SecondParameter<typeof request>) => {
  return request<UpdateExitTruckResponse>(
    { url: `/exit-trucks/${id}`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: updateTruck },
    options
  );
};

export const getUpdateExitTruckMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateExitTruck>>,
    TError,
    { id: number; data: UpdateTruck },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateExitTruck>>,
  TError,
  { id: number; data: UpdateTruck },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateExitTruck>>, { id: number; data: UpdateTruck }> = (
    props
  ) => {
    const { id, data } = props ?? {};

    return updateExitTruck(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateExitTruckMutationResult = NonNullable<Awaited<ReturnType<typeof updateExitTruck>>>;
export type UpdateExitTruckMutationBody = UpdateTruck;
export type UpdateExitTruckMutationError = HttpError;

export const useUpdateExitTruck = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateExitTruck>>,
    TError,
    { id: number; data: UpdateTruck },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateExitTruckMutationOptions(options);

  return useMutation(mutationOptions);
};

export const boardOx = (id: number, boardOx: BoardOx, options?: SecondParameter<typeof request>) => {
  return request<BoardOxResponse>(
    { url: `/exit-trucks/${id}/board`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: boardOx },
    options
  );
};

export const getBoardOxMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof boardOx>>, TError, { id: number; data: BoardOx }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof boardOx>>, TError, { id: number; data: BoardOx }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof boardOx>>, { id: number; data: BoardOx }> = (props) => {
    const { id, data } = props ?? {};

    return boardOx(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BoardOxMutationResult = NonNullable<Awaited<ReturnType<typeof boardOx>>>;
export type BoardOxMutationBody = BoardOx;
export type BoardOxMutationError = HttpError;

export const useBoardOx = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof boardOx>>, TError, { id: number; data: BoardOx }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getBoardOxMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getTuckDetails = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetTuckDetailsResponse>({ url: `/exit-trucks/${id}/details`, method: 'get', signal }, options);
};

export const getGetTuckDetailsQueryKey = (id: number) => {
  return [`/exit-trucks/${id}/details`] as const;
};

export const getGetTuckDetailsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getTuckDetails>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTuckDetails>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTuckDetailsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTuckDetails>>> = ({ signal }) =>
    getTuckDetails(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getTuckDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTuckDetailsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTuckDetails>>>;
export type GetTuckDetailsInfiniteQueryError = unknown;

export const useGetTuckDetailsInfinite = <TData = Awaited<ReturnType<typeof getTuckDetails>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTuckDetails>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTuckDetailsInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetTuckDetailsQueryOptions = <TData = Awaited<ReturnType<typeof getTuckDetails>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTuckDetails>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTuckDetailsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTuckDetails>>> = ({ signal }) =>
    getTuckDetails(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTuckDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTuckDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getTuckDetails>>>;
export type GetTuckDetailsQueryError = unknown;

export const useGetTuckDetails = <TData = Awaited<ReturnType<typeof getTuckDetails>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTuckDetails>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTuckDetailsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const waitForEmission = (id: number, options?: SecondParameter<typeof request>) => {
  return request<WaitForEmissionResponse>({ url: `/exit-trucks/${id}/wait-for-emission`, method: 'post' }, options);
};

export const getWaitForEmissionMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof waitForEmission>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof waitForEmission>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof waitForEmission>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return waitForEmission(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WaitForEmissionMutationResult = NonNullable<Awaited<ReturnType<typeof waitForEmission>>>;

export type WaitForEmissionMutationError = HttpError;

export const useWaitForEmission = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof waitForEmission>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getWaitForEmissionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getCurrentFarm = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetCurrentFarmResponse>({ url: `/farms`, method: 'get', signal }, options);
};

export const getGetCurrentFarmQueryKey = () => {
  return [`/farms`] as const;
};

export const getGetCurrentFarmInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getCurrentFarm>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCurrentFarm>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCurrentFarmQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentFarm>>> = ({ signal }) =>
    getCurrentFarm(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getCurrentFarm>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCurrentFarmInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentFarm>>>;
export type GetCurrentFarmInfiniteQueryError = unknown;

export const useGetCurrentFarmInfinite = <
  TData = Awaited<ReturnType<typeof getCurrentFarm>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCurrentFarm>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCurrentFarmInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetCurrentFarmQueryOptions = <
  TData = Awaited<ReturnType<typeof getCurrentFarm>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCurrentFarm>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCurrentFarmQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentFarm>>> = ({ signal }) =>
    getCurrentFarm(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCurrentFarm>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCurrentFarmQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentFarm>>>;
export type GetCurrentFarmQueryError = unknown;

export const useGetCurrentFarm = <TData = Awaited<ReturnType<typeof getCurrentFarm>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCurrentFarm>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCurrentFarmQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createFarm = (createFarm: CreateFarm, options?: SecondParameter<typeof request>) => {
  return request<CreateFarmResponse>(
    { url: `/farms`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createFarm },
    options
  );
};

export const getCreateFarmMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createFarm>>, TError, { data: CreateFarm }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createFarm>>, TError, { data: CreateFarm }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFarm>>, { data: CreateFarm }> = (props) => {
    const { data } = props ?? {};

    return createFarm(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateFarmMutationResult = NonNullable<Awaited<ReturnType<typeof createFarm>>>;
export type CreateFarmMutationBody = CreateFarm;
export type CreateFarmMutationError = unknown;

export const useCreateFarm = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createFarm>>, TError, { data: CreateFarm }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateFarmMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateFarm = (updateFarm: UpdateFarm, options?: SecondParameter<typeof request>) => {
  return request<UpdateFarmResponse>(
    { url: `/farms`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: updateFarm },
    options
  );
};

export const getUpdateFarmMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateFarm>>, TError, { data: UpdateFarm }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof updateFarm>>, TError, { data: UpdateFarm }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFarm>>, { data: UpdateFarm }> = (props) => {
    const { data } = props ?? {};

    return updateFarm(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateFarmMutationResult = NonNullable<Awaited<ReturnType<typeof updateFarm>>>;
export type UpdateFarmMutationBody = UpdateFarm;
export type UpdateFarmMutationError = unknown;

export const useUpdateFarm = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateFarm>>, TError, { data: UpdateFarm }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateFarmMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateFarmConfig = (updateFarmConfig: UpdateFarmConfig, options?: SecondParameter<typeof request>) => {
  return request<UpdateFarmConfigResponse>(
    { url: `/farms/config`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: updateFarmConfig },
    options
  );
};

export const getUpdateFarmConfigMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFarmConfig>>,
    TError,
    { data: UpdateFarmConfig },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof updateFarmConfig>>, TError, { data: UpdateFarmConfig }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFarmConfig>>, { data: UpdateFarmConfig }> = (
    props
  ) => {
    const { data } = props ?? {};

    return updateFarmConfig(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateFarmConfigMutationResult = NonNullable<Awaited<ReturnType<typeof updateFarmConfig>>>;
export type UpdateFarmConfigMutationBody = UpdateFarmConfig;
export type UpdateFarmConfigMutationError = unknown;

export const useUpdateFarmConfig = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFarmConfig>>,
    TError,
    { data: UpdateFarmConfig },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateFarmConfigMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getFarmDays = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetFarmDaysResponse>({ url: `/farms/day`, method: 'get', signal }, options);
};

export const getGetFarmDaysQueryKey = () => {
  return [`/farms/day`] as const;
};

export const getGetFarmDaysInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getFarmDays>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFarmDays>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFarmDaysQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFarmDays>>> = ({ signal }) =>
    getFarmDays(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getFarmDays>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFarmDaysInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFarmDays>>>;
export type GetFarmDaysInfiniteQueryError = unknown;

export const useGetFarmDaysInfinite = <TData = Awaited<ReturnType<typeof getFarmDays>>, TError = unknown>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFarmDays>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFarmDaysInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetFarmDaysQueryOptions = <
  TData = Awaited<ReturnType<typeof getFarmDays>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFarmDays>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFarmDaysQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFarmDays>>> = ({ signal }) =>
    getFarmDays(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFarmDays>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFarmDaysQueryResult = NonNullable<Awaited<ReturnType<typeof getFarmDays>>>;
export type GetFarmDaysQueryError = unknown;

export const useGetFarmDays = <TData = Awaited<ReturnType<typeof getFarmDays>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFarmDays>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFarmDaysQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const canAdvanceFarmDay = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<CanAdvanceFarmDayResponse>({ url: `/farms/day/advance`, method: 'get', signal }, options);
};

export const getCanAdvanceFarmDayQueryKey = () => {
  return [`/farms/day/advance`] as const;
};

export const getCanAdvanceFarmDayInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof canAdvanceFarmDay>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof canAdvanceFarmDay>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCanAdvanceFarmDayQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof canAdvanceFarmDay>>> = ({ signal }) =>
    canAdvanceFarmDay(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof canAdvanceFarmDay>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CanAdvanceFarmDayInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof canAdvanceFarmDay>>>;
export type CanAdvanceFarmDayInfiniteQueryError = unknown;

export const useCanAdvanceFarmDayInfinite = <
  TData = Awaited<ReturnType<typeof canAdvanceFarmDay>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof canAdvanceFarmDay>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCanAdvanceFarmDayInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCanAdvanceFarmDayQueryOptions = <
  TData = Awaited<ReturnType<typeof canAdvanceFarmDay>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof canAdvanceFarmDay>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCanAdvanceFarmDayQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof canAdvanceFarmDay>>> = ({ signal }) =>
    canAdvanceFarmDay(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof canAdvanceFarmDay>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CanAdvanceFarmDayQueryResult = NonNullable<Awaited<ReturnType<typeof canAdvanceFarmDay>>>;
export type CanAdvanceFarmDayQueryError = unknown;

export const useCanAdvanceFarmDay = <
  TData = Awaited<ReturnType<typeof canAdvanceFarmDay>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof canAdvanceFarmDay>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCanAdvanceFarmDayQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const advanceFarmDay = (options?: SecondParameter<typeof request>) => {
  return request<string>({ url: `/farms/day/advance`, method: 'post' }, options);
};

export const getAdvanceFarmDayMutationOptions = <TError = HttpError, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof advanceFarmDay>>, TError, TVariables, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof advanceFarmDay>>, TError, TVariables, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof advanceFarmDay>>, TVariables> = () => {
    return advanceFarmDay(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdvanceFarmDayMutationResult = NonNullable<Awaited<ReturnType<typeof advanceFarmDay>>>;

export type AdvanceFarmDayMutationError = HttpError;

export const useAdvanceFarmDay = <TError = HttpError, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof advanceFarmDay>>, TError, TVariables, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getAdvanceFarmDayMutationOptions(options);

  return useMutation(mutationOptions);
};

export const loadFinancialReport = (
  params: LoadFinancialReportParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<LoadFinancialReportResponse>({ url: `/financial/report`, method: 'get', params, signal }, options);
};

export const getLoadFinancialReportQueryKey = (params: LoadFinancialReportParams) => {
  return [`/financial/report`, ...(params ? [params] : [])] as const;
};

export const getLoadFinancialReportInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof loadFinancialReport>>,
  TError = HttpError
>(
  params: LoadFinancialReportParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof loadFinancialReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLoadFinancialReportQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof loadFinancialReport>>> = ({ signal, pageParam }) =>
    loadFinancialReport({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof loadFinancialReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LoadFinancialReportInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof loadFinancialReport>>>;
export type LoadFinancialReportInfiniteQueryError = HttpError;

export const useLoadFinancialReportInfinite = <
  TData = Awaited<ReturnType<typeof loadFinancialReport>>,
  TError = HttpError
>(
  params: LoadFinancialReportParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof loadFinancialReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLoadFinancialReportInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLoadFinancialReportQueryOptions = <
  TData = Awaited<ReturnType<typeof loadFinancialReport>>,
  TError = HttpError
>(
  params: LoadFinancialReportParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof loadFinancialReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLoadFinancialReportQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof loadFinancialReport>>> = ({ signal }) =>
    loadFinancialReport(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof loadFinancialReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LoadFinancialReportQueryResult = NonNullable<Awaited<ReturnType<typeof loadFinancialReport>>>;
export type LoadFinancialReportQueryError = HttpError;

export const useLoadFinancialReport = <TData = Awaited<ReturnType<typeof loadFinancialReport>>, TError = HttpError>(
  params: LoadFinancialReportParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof loadFinancialReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLoadFinancialReportQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listGtas = (params?: ListGtasParams, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListGtasResponse>({ url: `/gtas`, method: 'get', params, signal }, options);
};

export const getListGtasQueryKey = (params?: ListGtasParams) => {
  return [`/gtas`, ...(params ? [params] : [])] as const;
};

export const getListGtasInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof listGtas>>, TError = unknown>(
  params?: ListGtasParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listGtas>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListGtasQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listGtas>>> = ({ signal, pageParam }) =>
    listGtas({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listGtas>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListGtasInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listGtas>>>;
export type ListGtasInfiniteQueryError = unknown;

export const useListGtasInfinite = <TData = Awaited<ReturnType<typeof listGtas>>, TError = unknown>(
  params?: ListGtasParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listGtas>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListGtasInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListGtasQueryOptions = <TData = Awaited<ReturnType<typeof listGtas>>, TError = unknown>(
  params?: ListGtasParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listGtas>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListGtasQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listGtas>>> = ({ signal }) =>
    listGtas(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listGtas>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListGtasQueryResult = NonNullable<Awaited<ReturnType<typeof listGtas>>>;
export type ListGtasQueryError = unknown;

export const useListGtas = <TData = Awaited<ReturnType<typeof listGtas>>, TError = unknown>(
  params?: ListGtasParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listGtas>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListGtasQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createGta = (createGta: CreateGta, options?: SecondParameter<typeof request>) => {
  return request<CreateGtaResponse>(
    { url: `/gtas`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createGta },
    options
  );
};

export const getCreateGtaMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createGta>>, TError, { data: CreateGta }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createGta>>, TError, { data: CreateGta }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGta>>, { data: CreateGta }> = (props) => {
    const { data } = props ?? {};

    return createGta(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateGtaMutationResult = NonNullable<Awaited<ReturnType<typeof createGta>>>;
export type CreateGtaMutationBody = CreateGta;
export type CreateGtaMutationError = unknown;

export const useCreateGta = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createGta>>, TError, { data: CreateGta }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateGtaMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteGta = (id: number, options?: SecondParameter<typeof request>) => {
  return request<DeleteGtaResponse>({ url: `/gtas/${id}`, method: 'delete' }, options);
};

export const getDeleteGtaMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteGta>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteGta>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteGta>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return deleteGta(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteGtaMutationResult = NonNullable<Awaited<ReturnType<typeof deleteGta>>>;

export type DeleteGtaMutationError = HttpError;

export const useDeleteGta = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteGta>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getDeleteGtaMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getGta = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetGtaResponse>({ url: `/gtas/${id}`, method: 'get', signal }, options);
};

export const getGetGtaQueryKey = (id: number) => {
  return [`/gtas/${id}`] as const;
};

export const getGetGtaInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof getGta>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getGta>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGtaQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGta>>> = ({ signal }) => getGta(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getGta>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGtaInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getGta>>>;
export type GetGtaInfiniteQueryError = unknown;

export const useGetGtaInfinite = <TData = Awaited<ReturnType<typeof getGta>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getGta>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGtaInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetGtaQueryOptions = <TData = Awaited<ReturnType<typeof getGta>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getGta>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGtaQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGta>>> = ({ signal }) => getGta(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getGta>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGtaQueryResult = NonNullable<Awaited<ReturnType<typeof getGta>>>;
export type GetGtaQueryError = unknown;

export const useGetGta = <TData = Awaited<ReturnType<typeof getGta>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getGta>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGtaQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateGta = (id: number, updateGta: UpdateGta, options?: SecondParameter<typeof request>) => {
  return request<UpdateGtaResponse>(
    { url: `/gtas/${id}`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: updateGta },
    options
  );
};

export const getUpdateGtaMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGta>>,
    TError,
    { id: number; data: UpdateGta },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof updateGta>>, TError, { id: number; data: UpdateGta }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateGta>>, { id: number; data: UpdateGta }> = (
    props
  ) => {
    const { id, data } = props ?? {};

    return updateGta(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGtaMutationResult = NonNullable<Awaited<ReturnType<typeof updateGta>>>;
export type UpdateGtaMutationBody = UpdateGta;
export type UpdateGtaMutationError = HttpError;

export const useUpdateGta = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGta>>,
    TError,
    { id: number; data: UpdateGta },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateGtaMutationOptions(options);

  return useMutation(mutationOptions);
};

export const isManagedGta = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<boolean>({ url: `/gtas/${id}/managed`, method: 'get', signal }, options);
};

export const getIsManagedGtaQueryKey = (id: number) => {
  return [`/gtas/${id}/managed`] as const;
};

export const getIsManagedGtaInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof isManagedGta>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof isManagedGta>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getIsManagedGtaQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof isManagedGta>>> = ({ signal }) =>
    isManagedGta(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof isManagedGta>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IsManagedGtaInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof isManagedGta>>>;
export type IsManagedGtaInfiniteQueryError = unknown;

export const useIsManagedGtaInfinite = <TData = Awaited<ReturnType<typeof isManagedGta>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof isManagedGta>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIsManagedGtaInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getIsManagedGtaQueryOptions = <TData = Awaited<ReturnType<typeof isManagedGta>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof isManagedGta>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getIsManagedGtaQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof isManagedGta>>> = ({ signal }) =>
    isManagedGta(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof isManagedGta>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IsManagedGtaQueryResult = NonNullable<Awaited<ReturnType<typeof isManagedGta>>>;
export type IsManagedGtaQueryError = unknown;

export const useIsManagedGta = <TData = Awaited<ReturnType<typeof isManagedGta>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof isManagedGta>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIsManagedGtaQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listHealthProtocols = (
  params?: ListHealthProtocolsParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListHealthProtocolsResponse>({ url: `/health-protocols`, method: 'get', params, signal }, options);
};

export const getListHealthProtocolsQueryKey = (params?: ListHealthProtocolsParams) => {
  return [`/health-protocols`, ...(params ? [params] : [])] as const;
};

export const getListHealthProtocolsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listHealthProtocols>>,
  TError = unknown
>(
  params?: ListHealthProtocolsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listHealthProtocols>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListHealthProtocolsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listHealthProtocols>>> = ({ signal, pageParam }) =>
    listHealthProtocols({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listHealthProtocols>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListHealthProtocolsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listHealthProtocols>>>;
export type ListHealthProtocolsInfiniteQueryError = unknown;

export const useListHealthProtocolsInfinite = <
  TData = Awaited<ReturnType<typeof listHealthProtocols>>,
  TError = unknown
>(
  params?: ListHealthProtocolsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listHealthProtocols>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListHealthProtocolsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListHealthProtocolsQueryOptions = <
  TData = Awaited<ReturnType<typeof listHealthProtocols>>,
  TError = unknown
>(
  params?: ListHealthProtocolsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listHealthProtocols>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListHealthProtocolsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listHealthProtocols>>> = ({ signal }) =>
    listHealthProtocols(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listHealthProtocols>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListHealthProtocolsQueryResult = NonNullable<Awaited<ReturnType<typeof listHealthProtocols>>>;
export type ListHealthProtocolsQueryError = unknown;

export const useListHealthProtocols = <TData = Awaited<ReturnType<typeof listHealthProtocols>>, TError = unknown>(
  params?: ListHealthProtocolsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listHealthProtocols>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListHealthProtocolsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createHealthProtocol = (
  createHealthProtocol: CreateHealthProtocol,
  options?: SecondParameter<typeof request>
) => {
  return request<CreateHealthProtocolResponse>(
    {
      url: `/health-protocols`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createHealthProtocol
    },
    options
  );
};

export const getCreateHealthProtocolMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createHealthProtocol>>,
    TError,
    { data: CreateHealthProtocol },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createHealthProtocol>>,
  TError,
  { data: CreateHealthProtocol },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createHealthProtocol>>,
    { data: CreateHealthProtocol }
  > = (props) => {
    const { data } = props ?? {};

    return createHealthProtocol(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateHealthProtocolMutationResult = NonNullable<Awaited<ReturnType<typeof createHealthProtocol>>>;
export type CreateHealthProtocolMutationBody = CreateHealthProtocol;
export type CreateHealthProtocolMutationError = unknown;

export const useCreateHealthProtocol = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createHealthProtocol>>,
    TError,
    { data: CreateHealthProtocol },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateHealthProtocolMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteHealthProtocol = (id: number, options?: SecondParameter<typeof request>) => {
  return request<DeleteHealthProtocolResponse>({ url: `/health-protocols/${id}`, method: 'delete' }, options);
};

export const getDeleteHealthProtocolMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteHealthProtocol>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteHealthProtocol>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteHealthProtocol>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return deleteHealthProtocol(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteHealthProtocolMutationResult = NonNullable<Awaited<ReturnType<typeof deleteHealthProtocol>>>;

export type DeleteHealthProtocolMutationError = unknown;

export const useDeleteHealthProtocol = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteHealthProtocol>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getDeleteHealthProtocolMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getHealthProtocol = (
  id: number,
  params?: GetHealthProtocolParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<GetHealthProtocolResponse>({ url: `/health-protocols/${id}`, method: 'get', params, signal }, options);
};

export const getGetHealthProtocolQueryKey = (id: number, params?: GetHealthProtocolParams) => {
  return [`/health-protocols/${id}`, ...(params ? [params] : [])] as const;
};

export const getGetHealthProtocolInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getHealthProtocol>>,
  TError = unknown
>(
  id: number,
  params?: GetHealthProtocolParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getHealthProtocol>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHealthProtocolQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHealthProtocol>>> = ({ signal, pageParam }) =>
    getHealthProtocol(id, { page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getHealthProtocol>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetHealthProtocolInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getHealthProtocol>>>;
export type GetHealthProtocolInfiniteQueryError = unknown;

export const useGetHealthProtocolInfinite = <TData = Awaited<ReturnType<typeof getHealthProtocol>>, TError = unknown>(
  id: number,
  params?: GetHealthProtocolParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getHealthProtocol>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetHealthProtocolInfiniteQueryOptions(id, params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetHealthProtocolQueryOptions = <
  TData = Awaited<ReturnType<typeof getHealthProtocol>>,
  TError = unknown
>(
  id: number,
  params?: GetHealthProtocolParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getHealthProtocol>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHealthProtocolQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHealthProtocol>>> = ({ signal }) =>
    getHealthProtocol(id, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getHealthProtocol>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetHealthProtocolQueryResult = NonNullable<Awaited<ReturnType<typeof getHealthProtocol>>>;
export type GetHealthProtocolQueryError = unknown;

export const useGetHealthProtocol = <TData = Awaited<ReturnType<typeof getHealthProtocol>>, TError = unknown>(
  id: number,
  params?: GetHealthProtocolParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getHealthProtocol>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetHealthProtocolQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const addHealthProtocolImages = (
  id: number,
  addHealthProtocolImage: AddHealthProtocolImage[],
  options?: SecondParameter<typeof request>
) => {
  return request<AddHealthProtocolImagesResponse>(
    {
      url: `/health-protocols/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: addHealthProtocolImage
    },
    options
  );
};

export const getAddHealthProtocolImagesMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addHealthProtocolImages>>,
    TError,
    { id: number; data: AddHealthProtocolImage[] },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addHealthProtocolImages>>,
  TError,
  { id: number; data: AddHealthProtocolImage[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addHealthProtocolImages>>,
    { id: number; data: AddHealthProtocolImage[] }
  > = (props) => {
    const { id, data } = props ?? {};

    return addHealthProtocolImages(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddHealthProtocolImagesMutationResult = NonNullable<Awaited<ReturnType<typeof addHealthProtocolImages>>>;
export type AddHealthProtocolImagesMutationBody = AddHealthProtocolImage[];
export type AddHealthProtocolImagesMutationError = unknown;

export const useAddHealthProtocolImages = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addHealthProtocolImages>>,
    TError,
    { id: number; data: AddHealthProtocolImage[] },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getAddHealthProtocolImagesMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getMedicines = (
  params?: GetMedicinesParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<GetMedicinesResponse>({ url: `/medicines`, method: 'get', params, signal }, options);
};

export const getGetMedicinesQueryKey = (params?: GetMedicinesParams) => {
  return [`/medicines`, ...(params ? [params] : [])] as const;
};

export const getGetMedicinesInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof getMedicines>>, TError = unknown>(
  params?: GetMedicinesParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getMedicines>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMedicinesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMedicines>>> = ({ signal, pageParam }) =>
    getMedicines({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getMedicines>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMedicinesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getMedicines>>>;
export type GetMedicinesInfiniteQueryError = unknown;

export const useGetMedicinesInfinite = <TData = Awaited<ReturnType<typeof getMedicines>>, TError = unknown>(
  params?: GetMedicinesParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getMedicines>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMedicinesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetMedicinesQueryOptions = <TData = Awaited<ReturnType<typeof getMedicines>>, TError = unknown>(
  params?: GetMedicinesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getMedicines>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMedicinesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMedicines>>> = ({ signal }) =>
    getMedicines(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMedicines>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMedicinesQueryResult = NonNullable<Awaited<ReturnType<typeof getMedicines>>>;
export type GetMedicinesQueryError = unknown;

export const useGetMedicines = <TData = Awaited<ReturnType<typeof getMedicines>>, TError = unknown>(
  params?: GetMedicinesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getMedicines>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMedicinesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createMedicine = (createMedicine: CreateMedicine, options?: SecondParameter<typeof request>) => {
  return request<CreateMedicineResponse>(
    { url: `/medicines`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createMedicine },
    options
  );
};

export const getCreateMedicineMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createMedicine>>, TError, { data: CreateMedicine }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createMedicine>>, TError, { data: CreateMedicine }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMedicine>>, { data: CreateMedicine }> = (
    props
  ) => {
    const { data } = props ?? {};

    return createMedicine(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMedicineMutationResult = NonNullable<Awaited<ReturnType<typeof createMedicine>>>;
export type CreateMedicineMutationBody = CreateMedicine;
export type CreateMedicineMutationError = unknown;

export const useCreateMedicine = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createMedicine>>, TError, { data: CreateMedicine }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateMedicineMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteMedicine = (id: number, options?: SecondParameter<typeof request>) => {
  return request<DeleteMedicineResponse>({ url: `/medicines/${id}`, method: 'delete' }, options);
};

export const getDeleteMedicineMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteMedicine>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteMedicine>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMedicine>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return deleteMedicine(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMedicineMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMedicine>>>;

export type DeleteMedicineMutationError = unknown;

export const useDeleteMedicine = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteMedicine>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getDeleteMedicineMutationOptions(options);

  return useMutation(mutationOptions);
};

/** ### ID string is name, number is ID. */
export const getMedicine = (id: number | string, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetMedicineResponse>({ url: `/medicines/${id}`, method: 'get', signal }, options);
};

export const getGetMedicineQueryKey = (id: number | string) => {
  return [`/medicines/${id}`] as const;
};

export const getGetMedicineInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof getMedicine>>, TError = unknown>(
  id: number | string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getMedicine>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMedicineQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMedicine>>> = ({ signal }) =>
    getMedicine(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getMedicine>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMedicineInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getMedicine>>>;
export type GetMedicineInfiniteQueryError = unknown;

export const useGetMedicineInfinite = <TData = Awaited<ReturnType<typeof getMedicine>>, TError = unknown>(
  id: number | string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getMedicine>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMedicineInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetMedicineQueryOptions = <TData = Awaited<ReturnType<typeof getMedicine>>, TError = unknown>(
  id: number | string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getMedicine>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMedicineQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMedicine>>> = ({ signal }) =>
    getMedicine(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMedicine>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMedicineQueryResult = NonNullable<Awaited<ReturnType<typeof getMedicine>>>;
export type GetMedicineQueryError = unknown;

export const useGetMedicine = <TData = Awaited<ReturnType<typeof getMedicine>>, TError = unknown>(
  id: number | string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getMedicine>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMedicineQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const addMedicineImages = (
  id: number,
  addMedicineImage: AddMedicineImage[],
  options?: SecondParameter<typeof request>
) => {
  return request<AddMedicineImagesResponse>(
    {
      url: `/medicines/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: addMedicineImage
    },
    options
  );
};

export const getAddMedicineImagesMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addMedicineImages>>,
    TError,
    { id: number; data: AddMedicineImage[] },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addMedicineImages>>,
  TError,
  { id: number; data: AddMedicineImage[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addMedicineImages>>,
    { id: number; data: AddMedicineImage[] }
  > = (props) => {
    const { id, data } = props ?? {};

    return addMedicineImages(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddMedicineImagesMutationResult = NonNullable<Awaited<ReturnType<typeof addMedicineImages>>>;
export type AddMedicineImagesMutationBody = AddMedicineImage[];
export type AddMedicineImagesMutationError = unknown;

export const useAddMedicineImages = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addMedicineImages>>,
    TError,
    { id: number; data: AddMedicineImage[] },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getAddMedicineImagesMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateMedicine = (
  id: number,
  updateMedicine: UpdateMedicine,
  options?: SecondParameter<typeof request>
) => {
  return request<UpdateMedicineResponse>(
    { url: `/medicines/${id}`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: updateMedicine },
    options
  );
};

export const getUpdateMedicineMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMedicine>>,
    TError,
    { id: number; data: UpdateMedicine },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateMedicine>>,
  TError,
  { id: number; data: UpdateMedicine },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateMedicine>>,
    { id: number; data: UpdateMedicine }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateMedicine(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMedicineMutationResult = NonNullable<Awaited<ReturnType<typeof updateMedicine>>>;
export type UpdateMedicineMutationBody = UpdateMedicine;
export type UpdateMedicineMutationError = unknown;

export const useUpdateMedicine = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMedicine>>,
    TError,
    { id: number; data: UpdateMedicine },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateMedicineMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listOccurrences = (
  params?: ListOccurrencesParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListOccurrencesResponse>({ url: `/occurrences`, method: 'get', params, signal }, options);
};

export const getListOccurrencesQueryKey = (params?: ListOccurrencesParams) => {
  return [`/occurrences`, ...(params ? [params] : [])] as const;
};

export const getListOccurrencesInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listOccurrences>>,
  TError = unknown
>(
  params?: ListOccurrencesParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listOccurrences>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListOccurrencesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listOccurrences>>> = ({ signal, pageParam }) =>
    listOccurrences({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listOccurrences>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListOccurrencesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listOccurrences>>>;
export type ListOccurrencesInfiniteQueryError = unknown;

export const useListOccurrencesInfinite = <TData = Awaited<ReturnType<typeof listOccurrences>>, TError = unknown>(
  params?: ListOccurrencesParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listOccurrences>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListOccurrencesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListOccurrencesQueryOptions = <TData = Awaited<ReturnType<typeof listOccurrences>>, TError = unknown>(
  params?: ListOccurrencesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listOccurrences>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListOccurrencesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listOccurrences>>> = ({ signal }) =>
    listOccurrences(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listOccurrences>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListOccurrencesQueryResult = NonNullable<Awaited<ReturnType<typeof listOccurrences>>>;
export type ListOccurrencesQueryError = unknown;

export const useListOccurrences = <TData = Awaited<ReturnType<typeof listOccurrences>>, TError = unknown>(
  params?: ListOccurrencesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listOccurrences>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListOccurrencesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createOccurrence = (createOccurrence: CreateOccurrence, options?: SecondParameter<typeof request>) => {
  return request<CreateOccurrenceResponse>(
    { url: `/occurrences`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createOccurrence },
    options
  );
};

export const getCreateOccurrenceMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOccurrence>>,
    TError,
    { data: CreateOccurrence },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createOccurrence>>, TError, { data: CreateOccurrence }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOccurrence>>, { data: CreateOccurrence }> = (
    props
  ) => {
    const { data } = props ?? {};

    return createOccurrence(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateOccurrenceMutationResult = NonNullable<Awaited<ReturnType<typeof createOccurrence>>>;
export type CreateOccurrenceMutationBody = CreateOccurrence;
export type CreateOccurrenceMutationError = unknown;

export const useCreateOccurrence = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOccurrence>>,
    TError,
    { data: CreateOccurrence },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateOccurrenceMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getOccurrenceById = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetOccurrenceByIdResponse>({ url: `/occurrences/${id}`, method: 'get', signal }, options);
};

export const getGetOccurrenceByIdQueryKey = (id: number) => {
  return [`/occurrences/${id}`] as const;
};

export const getGetOccurrenceByIdInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getOccurrenceById>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOccurrenceById>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOccurrenceByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOccurrenceById>>> = ({ signal }) =>
    getOccurrenceById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getOccurrenceById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOccurrenceByIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOccurrenceById>>>;
export type GetOccurrenceByIdInfiniteQueryError = unknown;

export const useGetOccurrenceByIdInfinite = <TData = Awaited<ReturnType<typeof getOccurrenceById>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOccurrenceById>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOccurrenceByIdInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetOccurrenceByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getOccurrenceById>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getOccurrenceById>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOccurrenceByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOccurrenceById>>> = ({ signal }) =>
    getOccurrenceById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOccurrenceById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOccurrenceByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getOccurrenceById>>>;
export type GetOccurrenceByIdQueryError = unknown;

export const useGetOccurrenceById = <TData = Awaited<ReturnType<typeof getOccurrenceById>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getOccurrenceById>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOccurrenceByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createOx = (createOx: CreateOx, options?: SecondParameter<typeof request>) => {
  return request<CreateOxResponse>(
    { url: `/oxen`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createOx },
    options
  );
};

export const getCreateOxMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createOx>>, TError, { data: CreateOx }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createOx>>, TError, { data: CreateOx }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOx>>, { data: CreateOx }> = (props) => {
    const { data } = props ?? {};

    return createOx(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateOxMutationResult = NonNullable<Awaited<ReturnType<typeof createOx>>>;
export type CreateOxMutationBody = CreateOx;
export type CreateOxMutationError = HttpError;

export const useCreateOx = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createOx>>, TError, { data: CreateOx }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateOxMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listAvailableOxen = (
  params?: ListAvailableOxenParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListAvailableOxenResponse>({ url: `/oxen/available`, method: 'get', params, signal }, options);
};

export const getListAvailableOxenQueryKey = (params?: ListAvailableOxenParams) => {
  return [`/oxen/available`, ...(params ? [params] : [])] as const;
};

export const getListAvailableOxenInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listAvailableOxen>>,
  TError = unknown
>(
  params?: ListAvailableOxenParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAvailableOxen>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAvailableOxenQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAvailableOxen>>> = ({ signal, pageParam }) =>
    listAvailableOxen({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listAvailableOxen>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAvailableOxenInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listAvailableOxen>>>;
export type ListAvailableOxenInfiniteQueryError = unknown;

export const useListAvailableOxenInfinite = <TData = Awaited<ReturnType<typeof listAvailableOxen>>, TError = unknown>(
  params?: ListAvailableOxenParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listAvailableOxen>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAvailableOxenInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListAvailableOxenQueryOptions = <
  TData = Awaited<ReturnType<typeof listAvailableOxen>>,
  TError = unknown
>(
  params?: ListAvailableOxenParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listAvailableOxen>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAvailableOxenQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAvailableOxen>>> = ({ signal }) =>
    listAvailableOxen(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAvailableOxen>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAvailableOxenQueryResult = NonNullable<Awaited<ReturnType<typeof listAvailableOxen>>>;
export type ListAvailableOxenQueryError = unknown;

export const useListAvailableOxen = <TData = Awaited<ReturnType<typeof listAvailableOxen>>, TError = unknown>(
  params?: ListAvailableOxenParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listAvailableOxen>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAvailableOxenQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const oxCheckup = (oxCheckup: OxCheckup, options?: SecondParameter<typeof request>) => {
  return request<OxCheckupResponse>(
    { url: `/oxen/checkup`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: oxCheckup },
    options
  );
};

export const getOxCheckupMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof oxCheckup>>, TError, { data: OxCheckup }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof oxCheckup>>, TError, { data: OxCheckup }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof oxCheckup>>, { data: OxCheckup }> = (props) => {
    const { data } = props ?? {};

    return oxCheckup(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type OxCheckupMutationResult = NonNullable<Awaited<ReturnType<typeof oxCheckup>>>;
export type OxCheckupMutationBody = OxCheckup;
export type OxCheckupMutationError = HttpError;

export const useOxCheckup = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof oxCheckup>>, TError, { data: OxCheckup }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getOxCheckupMutationOptions(options);

  return useMutation(mutationOptions);
};

export const oxClosure = (
  oxClosureBody: OxClosureBody,
  params: OxClosureParams,
  options?: SecondParameter<typeof request>
) => {
  const formData = new FormData();
  formData.append('oxClosure', oxClosureBody.oxClosure);

  return request<OxClosureResponse>(
    {
      url: `/oxen/closure`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      params
    },
    options
  );
};

export const getOxClosureMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof oxClosure>>,
    TError,
    { data: OxClosureBody; params: OxClosureParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof oxClosure>>,
  TError,
  { data: OxClosureBody; params: OxClosureParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof oxClosure>>,
    { data: OxClosureBody; params: OxClosureParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return oxClosure(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type OxClosureMutationResult = NonNullable<Awaited<ReturnType<typeof oxClosure>>>;
export type OxClosureMutationBody = OxClosureBody;
export type OxClosureMutationError = HttpError;

export const useOxClosure = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof oxClosure>>,
    TError,
    { data: OxClosureBody; params: OxClosureParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getOxClosureMutationOptions(options);

  return useMutation(mutationOptions);
};

export const oxClosureByDate = (
  oxClosureByDateBody: OxClosureByDateBody,
  options?: SecondParameter<typeof request>
) => {
  return request<OxClosureByDateResponse>(
    {
      url: `/oxen/closure-by-day`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: oxClosureByDateBody
    },
    options
  );
};

export const getOxClosureByDateMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof oxClosureByDate>>,
    TError,
    { data: OxClosureByDateBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof oxClosureByDate>>,
  TError,
  { data: OxClosureByDateBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof oxClosureByDate>>, { data: OxClosureByDateBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return oxClosureByDate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type OxClosureByDateMutationResult = NonNullable<Awaited<ReturnType<typeof oxClosureByDate>>>;
export type OxClosureByDateMutationBody = OxClosureByDateBody;
export type OxClosureByDateMutationError = HttpError;

export const useOxClosureByDate = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof oxClosureByDate>>,
    TError,
    { data: OxClosureByDateBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getOxClosureByDateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listOxenHealth = (
  params?: ListOxenHealthParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListOxenHealthResponse>({ url: `/oxen/health`, method: 'get', params, signal }, options);
};

export const getListOxenHealthQueryKey = (params?: ListOxenHealthParams) => {
  return [`/oxen/health`, ...(params ? [params] : [])] as const;
};

export const getListOxenHealthInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listOxenHealth>>,
  TError = unknown
>(
  params?: ListOxenHealthParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listOxenHealth>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListOxenHealthQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listOxenHealth>>> = ({ signal, pageParam }) =>
    listOxenHealth({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listOxenHealth>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListOxenHealthInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listOxenHealth>>>;
export type ListOxenHealthInfiniteQueryError = unknown;

export const useListOxenHealthInfinite = <TData = Awaited<ReturnType<typeof listOxenHealth>>, TError = unknown>(
  params?: ListOxenHealthParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listOxenHealth>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListOxenHealthInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListOxenHealthQueryOptions = <TData = Awaited<ReturnType<typeof listOxenHealth>>, TError = unknown>(
  params?: ListOxenHealthParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listOxenHealth>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListOxenHealthQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listOxenHealth>>> = ({ signal }) =>
    listOxenHealth(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listOxenHealth>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListOxenHealthQueryResult = NonNullable<Awaited<ReturnType<typeof listOxenHealth>>>;
export type ListOxenHealthQueryError = unknown;

export const useListOxenHealth = <TData = Awaited<ReturnType<typeof listOxenHealth>>, TError = unknown>(
  params?: ListOxenHealthParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listOxenHealth>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListOxenHealthQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const checkOxIdentification = (
  params?: CheckOxIdentificationParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<boolean>({ url: `/oxen/identification`, method: 'get', params, signal }, options);
};

export const getCheckOxIdentificationQueryKey = (params?: CheckOxIdentificationParams) => {
  return [`/oxen/identification`, ...(params ? [params] : [])] as const;
};

export const getCheckOxIdentificationInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof checkOxIdentification>>,
  TError = HttpError
>(
  params?: CheckOxIdentificationParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof checkOxIdentification>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCheckOxIdentificationQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkOxIdentification>>> = ({ signal, pageParam }) =>
    checkOxIdentification({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof checkOxIdentification>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CheckOxIdentificationInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof checkOxIdentification>>>;
export type CheckOxIdentificationInfiniteQueryError = HttpError;

export const useCheckOxIdentificationInfinite = <
  TData = Awaited<ReturnType<typeof checkOxIdentification>>,
  TError = HttpError
>(
  params?: CheckOxIdentificationParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof checkOxIdentification>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCheckOxIdentificationInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCheckOxIdentificationQueryOptions = <
  TData = Awaited<ReturnType<typeof checkOxIdentification>>,
  TError = HttpError
>(
  params?: CheckOxIdentificationParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof checkOxIdentification>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCheckOxIdentificationQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkOxIdentification>>> = ({ signal }) =>
    checkOxIdentification(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof checkOxIdentification>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CheckOxIdentificationQueryResult = NonNullable<Awaited<ReturnType<typeof checkOxIdentification>>>;
export type CheckOxIdentificationQueryError = HttpError;

export const useCheckOxIdentification = <TData = Awaited<ReturnType<typeof checkOxIdentification>>, TError = HttpError>(
  params?: CheckOxIdentificationParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof checkOxIdentification>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCheckOxIdentificationQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const oxIdentification = (
  identificationOx: IdentificationOx,
  params: OxIdentificationParams,
  options?: SecondParameter<typeof request>
) => {
  return request<OxIdentificationResponse>(
    {
      url: `/oxen/identification`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: identificationOx,
      params
    },
    options
  );
};

export const getOxIdentificationMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof oxIdentification>>,
    TError,
    { data: IdentificationOx; params: OxIdentificationParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof oxIdentification>>,
  TError,
  { data: IdentificationOx; params: OxIdentificationParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof oxIdentification>>,
    { data: IdentificationOx; params: OxIdentificationParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return oxIdentification(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type OxIdentificationMutationResult = NonNullable<Awaited<ReturnType<typeof oxIdentification>>>;
export type OxIdentificationMutationBody = IdentificationOx;
export type OxIdentificationMutationError = HttpError;

export const useOxIdentification = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof oxIdentification>>,
    TError,
    { data: IdentificationOx; params: OxIdentificationParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getOxIdentificationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listDeadOxen = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListDeadOxenResponse>({ url: `/oxen/list-dead-oxen`, method: 'get', signal }, options);
};

export const getListDeadOxenQueryKey = () => {
  return [`/oxen/list-dead-oxen`] as const;
};

export const getListDeadOxenInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listDeadOxen>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listDeadOxen>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDeadOxenQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listDeadOxen>>> = ({ signal }) =>
    listDeadOxen(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listDeadOxen>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListDeadOxenInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listDeadOxen>>>;
export type ListDeadOxenInfiniteQueryError = unknown;

export const useListDeadOxenInfinite = <TData = Awaited<ReturnType<typeof listDeadOxen>>, TError = unknown>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listDeadOxen>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListDeadOxenInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListDeadOxenQueryOptions = <
  TData = Awaited<ReturnType<typeof listDeadOxen>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listDeadOxen>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDeadOxenQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listDeadOxen>>> = ({ signal }) =>
    listDeadOxen(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listDeadOxen>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListDeadOxenQueryResult = NonNullable<Awaited<ReturnType<typeof listDeadOxen>>>;
export type ListDeadOxenQueryError = unknown;

export const useListDeadOxen = <TData = Awaited<ReturnType<typeof listDeadOxen>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listDeadOxen>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListDeadOxenQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const medicateOx = (medicateOx: MedicateOx, options?: SecondParameter<typeof request>) => {
  return request<boolean>(
    { url: `/oxen/medicate`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: medicateOx },
    options
  );
};

export const getMedicateOxMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof medicateOx>>, TError, { data: MedicateOx }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof medicateOx>>, TError, { data: MedicateOx }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof medicateOx>>, { data: MedicateOx }> = (props) => {
    const { data } = props ?? {};

    return medicateOx(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MedicateOxMutationResult = NonNullable<Awaited<ReturnType<typeof medicateOx>>>;
export type MedicateOxMutationBody = MedicateOx;
export type MedicateOxMutationError = unknown;

export const useMedicateOx = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof medicateOx>>, TError, { data: MedicateOx }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getMedicateOxMutationOptions(options);

  return useMutation(mutationOptions);
};

export const medicateRandomOx = (medicateRandomOx: MedicateRandomOx, options?: SecondParameter<typeof request>) => {
  return request<boolean>(
    { url: `/oxen/medicate`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: medicateRandomOx },
    options
  );
};

export const getMedicateRandomOxMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof medicateRandomOx>>,
    TError,
    { data: MedicateRandomOx },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof medicateRandomOx>>, TError, { data: MedicateRandomOx }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof medicateRandomOx>>, { data: MedicateRandomOx }> = (
    props
  ) => {
    const { data } = props ?? {};

    return medicateRandomOx(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MedicateRandomOxMutationResult = NonNullable<Awaited<ReturnType<typeof medicateRandomOx>>>;
export type MedicateRandomOxMutationBody = MedicateRandomOx;
export type MedicateRandomOxMutationError = HttpError;

export const useMedicateRandomOx = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof medicateRandomOx>>,
    TError,
    { data: MedicateRandomOx },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getMedicateRandomOxMutationOptions(options);

  return useMutation(mutationOptions);
};

export const moveOxen = (moveOxenBody: MoveOxenBody, options?: SecondParameter<typeof request>) => {
  return request<boolean>(
    { url: `/oxen/movement`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: moveOxenBody },
    options
  );
};

export const getMoveOxenMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof moveOxen>>, TError, { data: MoveOxenBody }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof moveOxen>>, TError, { data: MoveOxenBody }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof moveOxen>>, { data: MoveOxenBody }> = (props) => {
    const { data } = props ?? {};

    return moveOxen(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MoveOxenMutationResult = NonNullable<Awaited<ReturnType<typeof moveOxen>>>;
export type MoveOxenMutationBody = MoveOxenBody;
export type MoveOxenMutationError = HttpError;

export const useMoveOxen = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof moveOxen>>, TError, { data: MoveOxenBody }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getMoveOxenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const weighingOx = (weighingOx: WeighingOx, options?: SecondParameter<typeof request>) => {
  return request<void>(
    { url: `/oxen/weighing`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: weighingOx },
    options
  );
};

export const getWeighingOxMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof weighingOx>>, TError, { data: WeighingOx }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof weighingOx>>, TError, { data: WeighingOx }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof weighingOx>>, { data: WeighingOx }> = (props) => {
    const { data } = props ?? {};

    return weighingOx(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type WeighingOxMutationResult = NonNullable<Awaited<ReturnType<typeof weighingOx>>>;
export type WeighingOxMutationBody = WeighingOx;
export type WeighingOxMutationError = unknown;

export const useWeighingOx = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof weighingOx>>, TError, { data: WeighingOx }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getWeighingOxMutationOptions(options);

  return useMutation(mutationOptions);
};

export const oxGmd = (id: number, options?: SecondParameter<typeof request>) => {
  return request<OxGmdResponse>({ url: `/oxen/${id}/gmd`, method: 'post' }, options);
};

export const getOxGmdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof oxGmd>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof oxGmd>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof oxGmd>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return oxGmd(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type OxGmdMutationResult = NonNullable<Awaited<ReturnType<typeof oxGmd>>>;

export type OxGmdMutationError = unknown;

export const useOxGmd = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof oxGmd>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getOxGmdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const killOx = (id: number, killOx: KillOx, options?: SecondParameter<typeof request>) => {
  return request<KillOxResponse>(
    { url: `/oxen/${id}/kill`, method: 'delete', headers: { 'Content-Type': 'application/json' }, data: killOx },
    options
  );
};

export const getKillOxMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof killOx>>, TError, { id: number; data: KillOx }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof killOx>>, TError, { id: number; data: KillOx }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof killOx>>, { id: number; data: KillOx }> = (props) => {
    const { id, data } = props ?? {};

    return killOx(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type KillOxMutationResult = NonNullable<Awaited<ReturnType<typeof killOx>>>;
export type KillOxMutationBody = KillOx;
export type KillOxMutationError = unknown;

export const useKillOx = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof killOx>>, TError, { id: number; data: KillOx }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getKillOxMutationOptions(options);

  return useMutation(mutationOptions);
};

export const searchPeople = (personFilter: PersonFilter, options?: SecondParameter<typeof request>) => {
  return request<SearchPeopleResponse>(
    { url: `/person`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: personFilter },
    options
  );
};

export const getSearchPeopleMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof searchPeople>>, TError, { data: PersonFilter }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof searchPeople>>, TError, { data: PersonFilter }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchPeople>>, { data: PersonFilter }> = (props) => {
    const { data } = props ?? {};

    return searchPeople(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchPeopleMutationResult = NonNullable<Awaited<ReturnType<typeof searchPeople>>>;
export type SearchPeopleMutationBody = PersonFilter;
export type SearchPeopleMutationError = unknown;

export const useSearchPeople = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof searchPeople>>, TError, { data: PersonFilter }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getSearchPeopleMutationOptions(options);

  return useMutation(mutationOptions);
};

export const createPerson = (createPerson: CreatePerson, options?: SecondParameter<typeof request>) => {
  return request<CreatePersonResponse>(
    { url: `/person`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: createPerson },
    options
  );
};

export const getCreatePersonMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createPerson>>, TError, { data: CreatePerson }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createPerson>>, TError, { data: CreatePerson }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPerson>>, { data: CreatePerson }> = (props) => {
    const { data } = props ?? {};

    return createPerson(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePersonMutationResult = NonNullable<Awaited<ReturnType<typeof createPerson>>>;
export type CreatePersonMutationBody = CreatePerson;
export type CreatePersonMutationError = HttpError;

export const useCreatePerson = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createPerson>>, TError, { data: CreatePerson }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreatePersonMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getPerson = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetPersonResponse>({ url: `/person/${id}`, method: 'get', signal }, options);
};

export const getGetPersonQueryKey = (id: number) => {
  return [`/person/${id}`] as const;
};

export const getGetPersonInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof getPerson>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getPerson>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPersonQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPerson>>> = ({ signal }) =>
    getPerson(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getPerson>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPersonInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getPerson>>>;
export type GetPersonInfiniteQueryError = unknown;

export const useGetPersonInfinite = <TData = Awaited<ReturnType<typeof getPerson>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getPerson>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPersonInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetPersonQueryOptions = <TData = Awaited<ReturnType<typeof getPerson>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPerson>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPersonQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPerson>>> = ({ signal }) =>
    getPerson(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPerson>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPersonQueryResult = NonNullable<Awaited<ReturnType<typeof getPerson>>>;
export type GetPersonQueryError = unknown;

export const useGetPerson = <TData = Awaited<ReturnType<typeof getPerson>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPerson>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPersonQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updatePerson = (id: number, updatePerson: UpdatePerson, options?: SecondParameter<typeof request>) => {
  return request<UpdatePersonResponse>(
    { url: `/person/${id}`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: updatePerson },
    options
  );
};

export const getUpdatePersonMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePerson>>,
    TError,
    { id: number; data: UpdatePerson },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePerson>>,
  TError,
  { id: number; data: UpdatePerson },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePerson>>, { id: number; data: UpdatePerson }> = (
    props
  ) => {
    const { id, data } = props ?? {};

    return updatePerson(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePersonMutationResult = NonNullable<Awaited<ReturnType<typeof updatePerson>>>;
export type UpdatePersonMutationBody = UpdatePerson;
export type UpdatePersonMutationError = unknown;

export const useUpdatePerson = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePerson>>,
    TError,
    { id: number; data: UpdatePerson },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdatePersonMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listPurchases = (
  params?: ListPurchasesParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListPurchasesResponse>({ url: `/purchases`, method: 'get', params, signal }, options);
};

export const getListPurchasesQueryKey = (params?: ListPurchasesParams) => {
  return [`/purchases`, ...(params ? [params] : [])] as const;
};

export const getListPurchasesInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listPurchases>>,
  TError = unknown
>(
  params?: ListPurchasesParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listPurchases>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPurchasesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPurchases>>> = ({ signal, pageParam }) =>
    listPurchases({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listPurchases>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPurchasesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listPurchases>>>;
export type ListPurchasesInfiniteQueryError = unknown;

export const useListPurchasesInfinite = <TData = Awaited<ReturnType<typeof listPurchases>>, TError = unknown>(
  params?: ListPurchasesParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listPurchases>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPurchasesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListPurchasesQueryOptions = <TData = Awaited<ReturnType<typeof listPurchases>>, TError = unknown>(
  params?: ListPurchasesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listPurchases>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPurchasesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPurchases>>> = ({ signal }) =>
    listPurchases(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPurchases>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPurchasesQueryResult = NonNullable<Awaited<ReturnType<typeof listPurchases>>>;
export type ListPurchasesQueryError = unknown;

export const useListPurchases = <TData = Awaited<ReturnType<typeof listPurchases>>, TError = unknown>(
  params?: ListPurchasesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listPurchases>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPurchasesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createEarringPurchase = (
  createEarringPurchase: CreateEarringPurchase,
  options?: SecondParameter<typeof request>
) => {
  return request<CreateEarringPurchaseResponse>(
    {
      url: `/purchases/earring`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createEarringPurchase
    },
    options
  );
};

export const getCreateEarringPurchaseMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEarringPurchase>>,
    TError,
    { data: CreateEarringPurchase },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createEarringPurchase>>,
  TError,
  { data: CreateEarringPurchase },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createEarringPurchase>>,
    { data: CreateEarringPurchase }
  > = (props) => {
    const { data } = props ?? {};

    return createEarringPurchase(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateEarringPurchaseMutationResult = NonNullable<Awaited<ReturnType<typeof createEarringPurchase>>>;
export type CreateEarringPurchaseMutationBody = CreateEarringPurchase;
export type CreateEarringPurchaseMutationError = HttpError;

export const useCreateEarringPurchase = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEarringPurchase>>,
    TError,
    { data: CreateEarringPurchase },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateEarringPurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const purchasesAvailableEarrings = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<PurchasesAvailableEarringsResponse>(
    { url: `/purchases/earring/available`, method: 'get', signal },
    options
  );
};

export const getPurchasesAvailableEarringsQueryKey = () => {
  return [`/purchases/earring/available`] as const;
};

export const getPurchasesAvailableEarringsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof purchasesAvailableEarrings>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof purchasesAvailableEarrings>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPurchasesAvailableEarringsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof purchasesAvailableEarrings>>> = ({ signal }) =>
    purchasesAvailableEarrings(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof purchasesAvailableEarrings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PurchasesAvailableEarringsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof purchasesAvailableEarrings>>
>;
export type PurchasesAvailableEarringsInfiniteQueryError = unknown;

export const usePurchasesAvailableEarringsInfinite = <
  TData = Awaited<ReturnType<typeof purchasesAvailableEarrings>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof purchasesAvailableEarrings>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPurchasesAvailableEarringsInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPurchasesAvailableEarringsQueryOptions = <
  TData = Awaited<ReturnType<typeof purchasesAvailableEarrings>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof purchasesAvailableEarrings>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPurchasesAvailableEarringsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof purchasesAvailableEarrings>>> = ({ signal }) =>
    purchasesAvailableEarrings(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof purchasesAvailableEarrings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PurchasesAvailableEarringsQueryResult = NonNullable<Awaited<ReturnType<typeof purchasesAvailableEarrings>>>;
export type PurchasesAvailableEarringsQueryError = unknown;

export const usePurchasesAvailableEarrings = <
  TData = Awaited<ReturnType<typeof purchasesAvailableEarrings>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof purchasesAvailableEarrings>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPurchasesAvailableEarringsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const sisbovExists = (
  params: SisbovExistsParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<boolean>({ url: `/purchases/earring/exists`, method: 'get', params, signal }, options);
};

export const getSisbovExistsQueryKey = (params: SisbovExistsParams) => {
  return [`/purchases/earring/exists`, ...(params ? [params] : [])] as const;
};

export const getSisbovExistsInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof sisbovExists>>, TError = unknown>(
  params: SisbovExistsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sisbovExists>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSisbovExistsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sisbovExists>>> = ({ signal, pageParam }) =>
    sisbovExists({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sisbovExists>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SisbovExistsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof sisbovExists>>>;
export type SisbovExistsInfiniteQueryError = unknown;

export const useSisbovExistsInfinite = <TData = Awaited<ReturnType<typeof sisbovExists>>, TError = unknown>(
  params: SisbovExistsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sisbovExists>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSisbovExistsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getSisbovExistsQueryOptions = <TData = Awaited<ReturnType<typeof sisbovExists>>, TError = unknown>(
  params: SisbovExistsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sisbovExists>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSisbovExistsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sisbovExists>>> = ({ signal }) =>
    sisbovExists(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sisbovExists>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SisbovExistsQueryResult = NonNullable<Awaited<ReturnType<typeof sisbovExists>>>;
export type SisbovExistsQueryError = unknown;

export const useSisbovExists = <TData = Awaited<ReturnType<typeof sisbovExists>>, TError = unknown>(
  params: SisbovExistsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sisbovExists>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSisbovExistsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createFoodPurchase = (
  createFoodPurchase: CreateFoodPurchase,
  options?: SecondParameter<typeof request>
) => {
  return request<CreateFoodPurchaseResponse>(
    {
      url: `/purchases/foods`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createFoodPurchase
    },
    options
  );
};

export const getCreateFoodPurchaseMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFoodPurchase>>,
    TError,
    { data: CreateFoodPurchase },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createFoodPurchase>>,
  TError,
  { data: CreateFoodPurchase },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFoodPurchase>>, { data: CreateFoodPurchase }> = (
    props
  ) => {
    const { data } = props ?? {};

    return createFoodPurchase(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateFoodPurchaseMutationResult = NonNullable<Awaited<ReturnType<typeof createFoodPurchase>>>;
export type CreateFoodPurchaseMutationBody = CreateFoodPurchase;
export type CreateFoodPurchaseMutationError = unknown;

export const useCreateFoodPurchase = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFoodPurchase>>,
    TError,
    { data: CreateFoodPurchase },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateFoodPurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const purchaseNameExists = (
  params: PurchaseNameExistsParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<boolean>({ url: `/purchases/name-exists`, method: 'get', params, signal }, options);
};

export const getPurchaseNameExistsQueryKey = (params: PurchaseNameExistsParams) => {
  return [`/purchases/name-exists`, ...(params ? [params] : [])] as const;
};

export const getPurchaseNameExistsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof purchaseNameExists>>,
  TError = unknown
>(
  params: PurchaseNameExistsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof purchaseNameExists>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPurchaseNameExistsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof purchaseNameExists>>> = ({ signal, pageParam }) =>
    purchaseNameExists({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof purchaseNameExists>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PurchaseNameExistsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof purchaseNameExists>>>;
export type PurchaseNameExistsInfiniteQueryError = unknown;

export const usePurchaseNameExistsInfinite = <TData = Awaited<ReturnType<typeof purchaseNameExists>>, TError = unknown>(
  params: PurchaseNameExistsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof purchaseNameExists>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPurchaseNameExistsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPurchaseNameExistsQueryOptions = <
  TData = Awaited<ReturnType<typeof purchaseNameExists>>,
  TError = unknown
>(
  params: PurchaseNameExistsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof purchaseNameExists>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getPurchaseNameExistsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof purchaseNameExists>>> = ({ signal }) =>
    purchaseNameExists(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof purchaseNameExists>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PurchaseNameExistsQueryResult = NonNullable<Awaited<ReturnType<typeof purchaseNameExists>>>;
export type PurchaseNameExistsQueryError = unknown;

export const usePurchaseNameExists = <TData = Awaited<ReturnType<typeof purchaseNameExists>>, TError = unknown>(
  params: PurchaseNameExistsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof purchaseNameExists>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPurchaseNameExistsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createOxenPurchase = (
  createOxenPurchase: CreateOxenPurchase,
  options?: SecondParameter<typeof request>
) => {
  return request<CreateOxenPurchaseResponse>(
    {
      url: `/purchases/oxen`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createOxenPurchase
    },
    options
  );
};

export const getCreateOxenPurchaseMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOxenPurchase>>,
    TError,
    { data: CreateOxenPurchase },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createOxenPurchase>>,
  TError,
  { data: CreateOxenPurchase },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOxenPurchase>>, { data: CreateOxenPurchase }> = (
    props
  ) => {
    const { data } = props ?? {};

    return createOxenPurchase(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateOxenPurchaseMutationResult = NonNullable<Awaited<ReturnType<typeof createOxenPurchase>>>;
export type CreateOxenPurchaseMutationBody = CreateOxenPurchase;
export type CreateOxenPurchaseMutationError = HttpError;

export const useCreateOxenPurchase = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOxenPurchase>>,
    TError,
    { data: CreateOxenPurchase },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateOxenPurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deletePurchase = (id: number, params: DeletePurchaseParams, options?: SecondParameter<typeof request>) => {
  return request<DeletePurchaseResponse>({ url: `/purchases/${id}`, method: 'delete', params }, options);
};

export const getDeletePurchaseMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePurchase>>,
    TError,
    { id: number; params: DeletePurchaseParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePurchase>>,
  TError,
  { id: number; params: DeletePurchaseParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePurchase>>,
    { id: number; params: DeletePurchaseParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return deletePurchase(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePurchaseMutationResult = NonNullable<Awaited<ReturnType<typeof deletePurchase>>>;

export type DeletePurchaseMutationError = HttpError;

export const useDeletePurchase = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePurchase>>,
    TError,
    { id: number; params: DeletePurchaseParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getDeletePurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getPurchase = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetPurchaseResponse>({ url: `/purchases/${id}`, method: 'get', signal }, options);
};

export const getGetPurchaseQueryKey = (id: number) => {
  return [`/purchases/${id}`] as const;
};

export const getGetPurchaseInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof getPurchase>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getPurchase>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPurchaseQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPurchase>>> = ({ signal }) =>
    getPurchase(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getPurchase>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPurchaseInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getPurchase>>>;
export type GetPurchaseInfiniteQueryError = unknown;

export const useGetPurchaseInfinite = <TData = Awaited<ReturnType<typeof getPurchase>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getPurchase>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPurchaseInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetPurchaseQueryOptions = <TData = Awaited<ReturnType<typeof getPurchase>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPurchase>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPurchaseQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPurchase>>> = ({ signal }) =>
    getPurchase(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPurchase>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPurchaseQueryResult = NonNullable<Awaited<ReturnType<typeof getPurchase>>>;
export type GetPurchaseQueryError = unknown;

export const useGetPurchase = <TData = Awaited<ReturnType<typeof getPurchase>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPurchase>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPurchaseQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const receivePurchase = (id: number, options?: SecondParameter<typeof request>) => {
  return request<ReceivePurchaseResponse>({ url: `/purchases/${id}`, method: 'post' }, options);
};

export const getReceivePurchaseMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof receivePurchase>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof receivePurchase>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof receivePurchase>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return receivePurchase(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceivePurchaseMutationResult = NonNullable<Awaited<ReturnType<typeof receivePurchase>>>;

export type ReceivePurchaseMutationError = HttpError;

export const useReceivePurchase = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof receivePurchase>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getReceivePurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listPurchasesEarrings = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListPurchasesEarringsResponse>({ url: `/purchases/${id}/earrings`, method: 'get', signal }, options);
};

export const getListPurchasesEarringsQueryKey = (id: number) => {
  return [`/purchases/${id}/earrings`] as const;
};

export const getListPurchasesEarringsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listPurchasesEarrings>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listPurchasesEarrings>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPurchasesEarringsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPurchasesEarrings>>> = ({ signal }) =>
    listPurchasesEarrings(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listPurchasesEarrings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPurchasesEarringsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listPurchasesEarrings>>>;
export type ListPurchasesEarringsInfiniteQueryError = unknown;

export const useListPurchasesEarringsInfinite = <
  TData = Awaited<ReturnType<typeof listPurchasesEarrings>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listPurchasesEarrings>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPurchasesEarringsInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListPurchasesEarringsQueryOptions = <
  TData = Awaited<ReturnType<typeof listPurchasesEarrings>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listPurchasesEarrings>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPurchasesEarringsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPurchasesEarrings>>> = ({ signal }) =>
    listPurchasesEarrings(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPurchasesEarrings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPurchasesEarringsQueryResult = NonNullable<Awaited<ReturnType<typeof listPurchasesEarrings>>>;
export type ListPurchasesEarringsQueryError = unknown;

export const useListPurchasesEarrings = <TData = Awaited<ReturnType<typeof listPurchasesEarrings>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listPurchasesEarrings>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPurchasesEarringsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateEarringPurchase = (
  id: number,
  updateEarringPurchase: UpdateEarringPurchase,
  options?: SecondParameter<typeof request>
) => {
  return request<UpdateEarringPurchaseResponse>(
    {
      url: `/purchases/${id}/earrings`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateEarringPurchase
    },
    options
  );
};

export const getUpdateEarringPurchaseMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEarringPurchase>>,
    TError,
    { id: number; data: UpdateEarringPurchase },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateEarringPurchase>>,
  TError,
  { id: number; data: UpdateEarringPurchase },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateEarringPurchase>>,
    { id: number; data: UpdateEarringPurchase }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateEarringPurchase(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateEarringPurchaseMutationResult = NonNullable<Awaited<ReturnType<typeof updateEarringPurchase>>>;
export type UpdateEarringPurchaseMutationBody = UpdateEarringPurchase;
export type UpdateEarringPurchaseMutationError = HttpError;

export const useUpdateEarringPurchase = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEarringPurchase>>,
    TError,
    { id: number; data: UpdateEarringPurchase },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateEarringPurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateFoodPurchase = (
  id: number,
  updateFoodPurchase: UpdateFoodPurchase,
  options?: SecondParameter<typeof request>
) => {
  return request<UpdateFoodPurchaseResponse>(
    {
      url: `/purchases/${id}/foods`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateFoodPurchase
    },
    options
  );
};

export const getUpdateFoodPurchaseMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFoodPurchase>>,
    TError,
    { id: number; data: UpdateFoodPurchase },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFoodPurchase>>,
  TError,
  { id: number; data: UpdateFoodPurchase },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFoodPurchase>>,
    { id: number; data: UpdateFoodPurchase }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateFoodPurchase(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateFoodPurchaseMutationResult = NonNullable<Awaited<ReturnType<typeof updateFoodPurchase>>>;
export type UpdateFoodPurchaseMutationBody = UpdateFoodPurchase;
export type UpdateFoodPurchaseMutationError = HttpError;

export const useUpdateFoodPurchase = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFoodPurchase>>,
    TError,
    { id: number; data: UpdateFoodPurchase },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateFoodPurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateAnimalPurchase = (
  id: number,
  updateOxenPurchase: UpdateOxenPurchase,
  options?: SecondParameter<typeof request>
) => {
  return request<UpdateAnimalPurchaseResponse>(
    {
      url: `/purchases/${id}/oxen`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateOxenPurchase
    },
    options
  );
};

export const getUpdateAnimalPurchaseMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAnimalPurchase>>,
    TError,
    { id: number; data: UpdateOxenPurchase },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAnimalPurchase>>,
  TError,
  { id: number; data: UpdateOxenPurchase },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAnimalPurchase>>,
    { id: number; data: UpdateOxenPurchase }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateAnimalPurchase(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAnimalPurchaseMutationResult = NonNullable<Awaited<ReturnType<typeof updateAnimalPurchase>>>;
export type UpdateAnimalPurchaseMutationBody = UpdateOxenPurchase;
export type UpdateAnimalPurchaseMutationError = HttpError;

export const useUpdateAnimalPurchase = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAnimalPurchase>>,
    TError,
    { id: number; data: UpdateOxenPurchase },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateAnimalPurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listGtasByPurchaseId = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListGtasByPurchaseIdResponse>({ url: `/purchases/${id}/oxen/gtas`, method: 'get', signal }, options);
};

export const getListGtasByPurchaseIdQueryKey = (id: number) => {
  return [`/purchases/${id}/oxen/gtas`] as const;
};

export const getListGtasByPurchaseIdInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listGtasByPurchaseId>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listGtasByPurchaseId>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListGtasByPurchaseIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listGtasByPurchaseId>>> = ({ signal }) =>
    listGtasByPurchaseId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listGtasByPurchaseId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListGtasByPurchaseIdInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listGtasByPurchaseId>>>;
export type ListGtasByPurchaseIdInfiniteQueryError = unknown;

export const useListGtasByPurchaseIdInfinite = <
  TData = Awaited<ReturnType<typeof listGtasByPurchaseId>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listGtasByPurchaseId>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListGtasByPurchaseIdInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListGtasByPurchaseIdQueryOptions = <
  TData = Awaited<ReturnType<typeof listGtasByPurchaseId>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listGtasByPurchaseId>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListGtasByPurchaseIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listGtasByPurchaseId>>> = ({ signal }) =>
    listGtasByPurchaseId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listGtasByPurchaseId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListGtasByPurchaseIdQueryResult = NonNullable<Awaited<ReturnType<typeof listGtasByPurchaseId>>>;
export type ListGtasByPurchaseIdQueryError = unknown;

export const useListGtasByPurchaseId = <TData = Awaited<ReturnType<typeof listGtasByPurchaseId>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listGtasByPurchaseId>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListGtasByPurchaseIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const storageOxenPurchase = (id: number, options?: SecondParameter<typeof request>) => {
  return request<StorageOxenPurchaseResponse>({ url: `/purchases/${id}/oxen/gtas`, method: 'post' }, options);
};

export const getStorageOxenPurchaseMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof storageOxenPurchase>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof storageOxenPurchase>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof storageOxenPurchase>>, { id: number }> = (props) => {
    const { id } = props ?? {};

    return storageOxenPurchase(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StorageOxenPurchaseMutationResult = NonNullable<Awaited<ReturnType<typeof storageOxenPurchase>>>;

export type StorageOxenPurchaseMutationError = HttpError;

export const useStorageOxenPurchase = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof storageOxenPurchase>>, TError, { id: number }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getStorageOxenPurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getActiveBatchesReport = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetActiveBatchesReportResponse>({ url: `/reports/active`, method: 'get', signal }, options);
};

export const getGetActiveBatchesReportQueryKey = () => {
  return [`/reports/active`] as const;
};

export const getGetActiveBatchesReportInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveBatchesReport>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getActiveBatchesReport>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveBatchesReportQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActiveBatchesReport>>> = ({ signal }) =>
    getActiveBatchesReport(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getActiveBatchesReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetActiveBatchesReportInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getActiveBatchesReport>>>;
export type GetActiveBatchesReportInfiniteQueryError = unknown;

export const useGetActiveBatchesReportInfinite = <
  TData = Awaited<ReturnType<typeof getActiveBatchesReport>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getActiveBatchesReport>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetActiveBatchesReportInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetActiveBatchesReportQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveBatchesReport>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getActiveBatchesReport>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveBatchesReportQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActiveBatchesReport>>> = ({ signal }) =>
    getActiveBatchesReport(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getActiveBatchesReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetActiveBatchesReportQueryResult = NonNullable<Awaited<ReturnType<typeof getActiveBatchesReport>>>;
export type GetActiveBatchesReportQueryError = unknown;

export const useGetActiveBatchesReport = <
  TData = Awaited<ReturnType<typeof getActiveBatchesReport>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getActiveBatchesReport>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetActiveBatchesReportQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const analyticalBatchReport = (
  params: AnalyticalBatchReportParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<AnalyticalBatchReportReturn>({ url: `/reports/analytical`, method: 'get', params, signal }, options);
};

export const getAnalyticalBatchReportQueryKey = (params: AnalyticalBatchReportParams) => {
  return [`/reports/analytical`, ...(params ? [params] : [])] as const;
};

export const getAnalyticalBatchReportInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof analyticalBatchReport>>,
  TError = unknown
>(
  params: AnalyticalBatchReportParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof analyticalBatchReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAnalyticalBatchReportQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof analyticalBatchReport>>> = ({ signal, pageParam }) =>
    analyticalBatchReport({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof analyticalBatchReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AnalyticalBatchReportInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof analyticalBatchReport>>>;
export type AnalyticalBatchReportInfiniteQueryError = unknown;

export const useAnalyticalBatchReportInfinite = <
  TData = Awaited<ReturnType<typeof analyticalBatchReport>>,
  TError = unknown
>(
  params: AnalyticalBatchReportParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof analyticalBatchReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAnalyticalBatchReportInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAnalyticalBatchReportQueryOptions = <
  TData = Awaited<ReturnType<typeof analyticalBatchReport>>,
  TError = unknown
>(
  params: AnalyticalBatchReportParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof analyticalBatchReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAnalyticalBatchReportQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof analyticalBatchReport>>> = ({ signal }) =>
    analyticalBatchReport(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof analyticalBatchReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AnalyticalBatchReportQueryResult = NonNullable<Awaited<ReturnType<typeof analyticalBatchReport>>>;
export type AnalyticalBatchReportQueryError = unknown;

export const useAnalyticalBatchReport = <TData = Awaited<ReturnType<typeof analyticalBatchReport>>, TError = unknown>(
  params: AnalyticalBatchReportParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof analyticalBatchReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAnalyticalBatchReportQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const batchEconomicEvaluation = (
  params: BatchEconomicEvaluationParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<BatchEconomicEvaluationResponse>(
    { url: `/reports/economic-evaluation`, method: 'get', params, signal },
    options
  );
};

export const getBatchEconomicEvaluationQueryKey = (params: BatchEconomicEvaluationParams) => {
  return [`/reports/economic-evaluation`, ...(params ? [params] : [])] as const;
};

export const getBatchEconomicEvaluationInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof batchEconomicEvaluation>>,
  TError = HttpError
>(
  params: BatchEconomicEvaluationParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchEconomicEvaluation>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchEconomicEvaluationQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchEconomicEvaluation>>> = ({ signal, pageParam }) =>
    batchEconomicEvaluation({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof batchEconomicEvaluation>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchEconomicEvaluationInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof batchEconomicEvaluation>>
>;
export type BatchEconomicEvaluationInfiniteQueryError = HttpError;

export const useBatchEconomicEvaluationInfinite = <
  TData = Awaited<ReturnType<typeof batchEconomicEvaluation>>,
  TError = HttpError
>(
  params: BatchEconomicEvaluationParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchEconomicEvaluation>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchEconomicEvaluationInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBatchEconomicEvaluationQueryOptions = <
  TData = Awaited<ReturnType<typeof batchEconomicEvaluation>>,
  TError = HttpError
>(
  params: BatchEconomicEvaluationParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof batchEconomicEvaluation>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchEconomicEvaluationQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchEconomicEvaluation>>> = ({ signal }) =>
    batchEconomicEvaluation(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof batchEconomicEvaluation>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchEconomicEvaluationQueryResult = NonNullable<Awaited<ReturnType<typeof batchEconomicEvaluation>>>;
export type BatchEconomicEvaluationQueryError = HttpError;

export const useBatchEconomicEvaluation = <
  TData = Awaited<ReturnType<typeof batchEconomicEvaluation>>,
  TError = HttpError
>(
  params: BatchEconomicEvaluationParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof batchEconomicEvaluation>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchEconomicEvaluationQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const treatEfficiency = (
  params: TreatEfficiencyParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<TreatEfficiencyResponse>({ url: `/reports/efficiency`, method: 'get', params, signal }, options);
};

export const getTreatEfficiencyQueryKey = (params: TreatEfficiencyParams) => {
  return [`/reports/efficiency`, ...(params ? [params] : [])] as const;
};

export const getTreatEfficiencyInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof treatEfficiency>>,
  TError = HttpError
>(
  params: TreatEfficiencyParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof treatEfficiency>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTreatEfficiencyQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof treatEfficiency>>> = ({ signal, pageParam }) =>
    treatEfficiency({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof treatEfficiency>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TreatEfficiencyInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof treatEfficiency>>>;
export type TreatEfficiencyInfiniteQueryError = HttpError;

export const useTreatEfficiencyInfinite = <TData = Awaited<ReturnType<typeof treatEfficiency>>, TError = HttpError>(
  params: TreatEfficiencyParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof treatEfficiency>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTreatEfficiencyInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getTreatEfficiencyQueryOptions = <TData = Awaited<ReturnType<typeof treatEfficiency>>, TError = HttpError>(
  params: TreatEfficiencyParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof treatEfficiency>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTreatEfficiencyQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof treatEfficiency>>> = ({ signal }) =>
    treatEfficiency(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof treatEfficiency>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TreatEfficiencyQueryResult = NonNullable<Awaited<ReturnType<typeof treatEfficiency>>>;
export type TreatEfficiencyQueryError = HttpError;

export const useTreatEfficiency = <TData = Awaited<ReturnType<typeof treatEfficiency>>, TError = HttpError>(
  params: TreatEfficiencyParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof treatEfficiency>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTreatEfficiencyQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFoodStockReport = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetFoodStockReportResponse>({ url: `/reports/food`, method: 'get', signal }, options);
};

export const getGetFoodStockReportQueryKey = () => {
  return [`/reports/food`] as const;
};

export const getGetFoodStockReportInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getFoodStockReport>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFoodStockReport>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFoodStockReportQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFoodStockReport>>> = ({ signal }) =>
    getFoodStockReport(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getFoodStockReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFoodStockReportInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFoodStockReport>>>;
export type GetFoodStockReportInfiniteQueryError = unknown;

export const useGetFoodStockReportInfinite = <
  TData = Awaited<ReturnType<typeof getFoodStockReport>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFoodStockReport>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFoodStockReportInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetFoodStockReportQueryOptions = <
  TData = Awaited<ReturnType<typeof getFoodStockReport>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFoodStockReport>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFoodStockReportQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFoodStockReport>>> = ({ signal }) =>
    getFoodStockReport(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFoodStockReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFoodStockReportQueryResult = NonNullable<Awaited<ReturnType<typeof getFoodStockReport>>>;
export type GetFoodStockReportQueryError = unknown;

export const useGetFoodStockReport = <
  TData = Awaited<ReturnType<typeof getFoodStockReport>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFoodStockReport>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFoodStockReportQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFoodStockHistory = (
  params: GetFoodStockHistoryParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<GetFoodStockHistoryResponse>({ url: `/reports/food-history`, method: 'get', params, signal }, options);
};

export const getGetFoodStockHistoryQueryKey = (params: GetFoodStockHistoryParams) => {
  return [`/reports/food-history`, ...(params ? [params] : [])] as const;
};

export const getGetFoodStockHistoryInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getFoodStockHistory>>,
  TError = HttpError
>(
  params: GetFoodStockHistoryParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFoodStockHistory>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFoodStockHistoryQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFoodStockHistory>>> = ({ signal, pageParam }) =>
    getFoodStockHistory({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getFoodStockHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFoodStockHistoryInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFoodStockHistory>>>;
export type GetFoodStockHistoryInfiniteQueryError = HttpError;

export const useGetFoodStockHistoryInfinite = <
  TData = Awaited<ReturnType<typeof getFoodStockHistory>>,
  TError = HttpError
>(
  params: GetFoodStockHistoryParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFoodStockHistory>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFoodStockHistoryInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetFoodStockHistoryQueryOptions = <
  TData = Awaited<ReturnType<typeof getFoodStockHistory>>,
  TError = HttpError
>(
  params: GetFoodStockHistoryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getFoodStockHistory>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFoodStockHistoryQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFoodStockHistory>>> = ({ signal }) =>
    getFoodStockHistory(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFoodStockHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFoodStockHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof getFoodStockHistory>>>;
export type GetFoodStockHistoryQueryError = HttpError;

export const useGetFoodStockHistory = <TData = Awaited<ReturnType<typeof getFoodStockHistory>>, TError = HttpError>(
  params: GetFoodStockHistoryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getFoodStockHistory>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFoodStockHistoryQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const batchHistoryReport = (
  params: BatchHistoryReportParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<BatchHistoryReportResponse>({ url: `/reports/history`, method: 'get', params, signal }, options);
};

export const getBatchHistoryReportQueryKey = (params: BatchHistoryReportParams) => {
  return [`/reports/history`, ...(params ? [params] : [])] as const;
};

export const getBatchHistoryReportInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof batchHistoryReport>>,
  TError = HttpError
>(
  params: BatchHistoryReportParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchHistoryReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchHistoryReportQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchHistoryReport>>> = ({ signal, pageParam }) =>
    batchHistoryReport({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof batchHistoryReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchHistoryReportInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof batchHistoryReport>>>;
export type BatchHistoryReportInfiniteQueryError = HttpError;

export const useBatchHistoryReportInfinite = <
  TData = Awaited<ReturnType<typeof batchHistoryReport>>,
  TError = HttpError
>(
  params: BatchHistoryReportParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchHistoryReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchHistoryReportInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBatchHistoryReportQueryOptions = <
  TData = Awaited<ReturnType<typeof batchHistoryReport>>,
  TError = HttpError
>(
  params: BatchHistoryReportParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof batchHistoryReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchHistoryReportQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchHistoryReport>>> = ({ signal }) =>
    batchHistoryReport(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof batchHistoryReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchHistoryReportQueryResult = NonNullable<Awaited<ReturnType<typeof batchHistoryReport>>>;
export type BatchHistoryReportQueryError = HttpError;

export const useBatchHistoryReport = <TData = Awaited<ReturnType<typeof batchHistoryReport>>, TError = HttpError>(
  params: BatchHistoryReportParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof batchHistoryReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchHistoryReportQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const individualCattleClosingReport = (
  params?: IndividualCattleClosingReportParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<IndividualCattleClosingReportResponse>(
    { url: `/reports/individual-cattle-closing`, method: 'get', params, signal },
    options
  );
};

export const getIndividualCattleClosingReportQueryKey = (params?: IndividualCattleClosingReportParams) => {
  return [`/reports/individual-cattle-closing`, ...(params ? [params] : [])] as const;
};

export const getIndividualCattleClosingReportInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof individualCattleClosingReport>>,
  TError = unknown
>(
  params?: IndividualCattleClosingReportParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof individualCattleClosingReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getIndividualCattleClosingReportQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof individualCattleClosingReport>>> = ({ signal, pageParam }) =>
    individualCattleClosingReport({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof individualCattleClosingReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IndividualCattleClosingReportInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof individualCattleClosingReport>>
>;
export type IndividualCattleClosingReportInfiniteQueryError = unknown;

export const useIndividualCattleClosingReportInfinite = <
  TData = Awaited<ReturnType<typeof individualCattleClosingReport>>,
  TError = unknown
>(
  params?: IndividualCattleClosingReportParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof individualCattleClosingReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIndividualCattleClosingReportInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getIndividualCattleClosingReportQueryOptions = <
  TData = Awaited<ReturnType<typeof individualCattleClosingReport>>,
  TError = unknown
>(
  params?: IndividualCattleClosingReportParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof individualCattleClosingReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getIndividualCattleClosingReportQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof individualCattleClosingReport>>> = ({ signal }) =>
    individualCattleClosingReport(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof individualCattleClosingReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type IndividualCattleClosingReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof individualCattleClosingReport>>
>;
export type IndividualCattleClosingReportQueryError = unknown;

export const useIndividualCattleClosingReport = <
  TData = Awaited<ReturnType<typeof individualCattleClosingReport>>,
  TError = unknown
>(
  params?: IndividualCattleClosingReportParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof individualCattleClosingReport>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getIndividualCattleClosingReportQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getActiveBatchesReportXlsx = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GlobalBuffer>({ url: `/reports/xlsx/active`, method: 'get', signal }, options);
};

export const getGetActiveBatchesReportXlsxQueryKey = () => {
  return [`/reports/xlsx/active`] as const;
};

export const getGetActiveBatchesReportXlsxInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveBatchesReportXlsx>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getActiveBatchesReportXlsx>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveBatchesReportXlsxQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActiveBatchesReportXlsx>>> = ({ signal }) =>
    getActiveBatchesReportXlsx(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getActiveBatchesReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetActiveBatchesReportXlsxInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getActiveBatchesReportXlsx>>
>;
export type GetActiveBatchesReportXlsxInfiniteQueryError = unknown;

export const useGetActiveBatchesReportXlsxInfinite = <
  TData = Awaited<ReturnType<typeof getActiveBatchesReportXlsx>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getActiveBatchesReportXlsx>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetActiveBatchesReportXlsxInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetActiveBatchesReportXlsxQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveBatchesReportXlsx>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getActiveBatchesReportXlsx>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveBatchesReportXlsxQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActiveBatchesReportXlsx>>> = ({ signal }) =>
    getActiveBatchesReportXlsx(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getActiveBatchesReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetActiveBatchesReportXlsxQueryResult = NonNullable<Awaited<ReturnType<typeof getActiveBatchesReportXlsx>>>;
export type GetActiveBatchesReportXlsxQueryError = unknown;

export const useGetActiveBatchesReportXlsx = <
  TData = Awaited<ReturnType<typeof getActiveBatchesReportXlsx>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getActiveBatchesReportXlsx>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetActiveBatchesReportXlsxQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAnalyticalBatchesReportXlsx = (
  params: GetAnalyticalBatchesReportXlsxParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<GlobalBuffer>({ url: `/reports/xlsx/analytical`, method: 'get', params, signal }, options);
};

export const getGetAnalyticalBatchesReportXlsxQueryKey = (params: GetAnalyticalBatchesReportXlsxParams) => {
  return [`/reports/xlsx/analytical`, ...(params ? [params] : [])] as const;
};

export const getGetAnalyticalBatchesReportXlsxInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getAnalyticalBatchesReportXlsx>>,
  TError = unknown
>(
  params: GetAnalyticalBatchesReportXlsxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAnalyticalBatchesReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAnalyticalBatchesReportXlsxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAnalyticalBatchesReportXlsx>>> = ({ signal, pageParam }) =>
    getAnalyticalBatchesReportXlsx({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getAnalyticalBatchesReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAnalyticalBatchesReportXlsxInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnalyticalBatchesReportXlsx>>
>;
export type GetAnalyticalBatchesReportXlsxInfiniteQueryError = unknown;

export const useGetAnalyticalBatchesReportXlsxInfinite = <
  TData = Awaited<ReturnType<typeof getAnalyticalBatchesReportXlsx>>,
  TError = unknown
>(
  params: GetAnalyticalBatchesReportXlsxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAnalyticalBatchesReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAnalyticalBatchesReportXlsxInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetAnalyticalBatchesReportXlsxQueryOptions = <
  TData = Awaited<ReturnType<typeof getAnalyticalBatchesReportXlsx>>,
  TError = unknown
>(
  params: GetAnalyticalBatchesReportXlsxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAnalyticalBatchesReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAnalyticalBatchesReportXlsxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAnalyticalBatchesReportXlsx>>> = ({ signal }) =>
    getAnalyticalBatchesReportXlsx(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAnalyticalBatchesReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAnalyticalBatchesReportXlsxQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnalyticalBatchesReportXlsx>>
>;
export type GetAnalyticalBatchesReportXlsxQueryError = unknown;

export const useGetAnalyticalBatchesReportXlsx = <
  TData = Awaited<ReturnType<typeof getAnalyticalBatchesReportXlsx>>,
  TError = unknown
>(
  params: GetAnalyticalBatchesReportXlsxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAnalyticalBatchesReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAnalyticalBatchesReportXlsxQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getEconomicEvaluationReportXlsx = (
  params: GetEconomicEvaluationReportXlsxParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<GlobalBuffer>({ url: `/reports/xlsx/economic-evaluation`, method: 'get', params, signal }, options);
};

export const getGetEconomicEvaluationReportXlsxQueryKey = (params: GetEconomicEvaluationReportXlsxParams) => {
  return [`/reports/xlsx/economic-evaluation`, ...(params ? [params] : [])] as const;
};

export const getGetEconomicEvaluationReportXlsxInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getEconomicEvaluationReportXlsx>>,
  TError = unknown
>(
  params: GetEconomicEvaluationReportXlsxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEconomicEvaluationReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEconomicEvaluationReportXlsxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEconomicEvaluationReportXlsx>>> = ({ signal, pageParam }) =>
    getEconomicEvaluationReportXlsx({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getEconomicEvaluationReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEconomicEvaluationReportXlsxInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEconomicEvaluationReportXlsx>>
>;
export type GetEconomicEvaluationReportXlsxInfiniteQueryError = unknown;

export const useGetEconomicEvaluationReportXlsxInfinite = <
  TData = Awaited<ReturnType<typeof getEconomicEvaluationReportXlsx>>,
  TError = unknown
>(
  params: GetEconomicEvaluationReportXlsxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEconomicEvaluationReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEconomicEvaluationReportXlsxInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetEconomicEvaluationReportXlsxQueryOptions = <
  TData = Awaited<ReturnType<typeof getEconomicEvaluationReportXlsx>>,
  TError = unknown
>(
  params: GetEconomicEvaluationReportXlsxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getEconomicEvaluationReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEconomicEvaluationReportXlsxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEconomicEvaluationReportXlsx>>> = ({ signal }) =>
    getEconomicEvaluationReportXlsx(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getEconomicEvaluationReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEconomicEvaluationReportXlsxQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEconomicEvaluationReportXlsx>>
>;
export type GetEconomicEvaluationReportXlsxQueryError = unknown;

export const useGetEconomicEvaluationReportXlsx = <
  TData = Awaited<ReturnType<typeof getEconomicEvaluationReportXlsx>>,
  TError = unknown
>(
  params: GetEconomicEvaluationReportXlsxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getEconomicEvaluationReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEconomicEvaluationReportXlsxQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getEfficiencyReportXlsx = (
  params: GetEfficiencyReportXlsxParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<GetEfficiencyReportXlsxResponse>(
    { url: `/reports/xlsx/efficiency`, method: 'get', params, signal },
    options
  );
};

export const getGetEfficiencyReportXlsxQueryKey = (params: GetEfficiencyReportXlsxParams) => {
  return [`/reports/xlsx/efficiency`, ...(params ? [params] : [])] as const;
};

export const getGetEfficiencyReportXlsxInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getEfficiencyReportXlsx>>,
  TError = unknown
>(
  params: GetEfficiencyReportXlsxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEfficiencyReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEfficiencyReportXlsxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEfficiencyReportXlsx>>> = ({ signal, pageParam }) =>
    getEfficiencyReportXlsx({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getEfficiencyReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEfficiencyReportXlsxInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEfficiencyReportXlsx>>
>;
export type GetEfficiencyReportXlsxInfiniteQueryError = unknown;

export const useGetEfficiencyReportXlsxInfinite = <
  TData = Awaited<ReturnType<typeof getEfficiencyReportXlsx>>,
  TError = unknown
>(
  params: GetEfficiencyReportXlsxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEfficiencyReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEfficiencyReportXlsxInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetEfficiencyReportXlsxQueryOptions = <
  TData = Awaited<ReturnType<typeof getEfficiencyReportXlsx>>,
  TError = unknown
>(
  params: GetEfficiencyReportXlsxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getEfficiencyReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEfficiencyReportXlsxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEfficiencyReportXlsx>>> = ({ signal }) =>
    getEfficiencyReportXlsx(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getEfficiencyReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEfficiencyReportXlsxQueryResult = NonNullable<Awaited<ReturnType<typeof getEfficiencyReportXlsx>>>;
export type GetEfficiencyReportXlsxQueryError = unknown;

export const useGetEfficiencyReportXlsx = <
  TData = Awaited<ReturnType<typeof getEfficiencyReportXlsx>>,
  TError = unknown
>(
  params: GetEfficiencyReportXlsxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getEfficiencyReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEfficiencyReportXlsxQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFinancialReportXlsx = (
  params: GetFinancialReportXlsxParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<GetFinancialReportXlsxResponse>(
    { url: `/reports/xlsx/financial`, method: 'get', params, signal },
    options
  );
};

export const getGetFinancialReportXlsxQueryKey = (params: GetFinancialReportXlsxParams) => {
  return [`/reports/xlsx/financial`, ...(params ? [params] : [])] as const;
};

export const getGetFinancialReportXlsxInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getFinancialReportXlsx>>,
  TError = unknown
>(
  params: GetFinancialReportXlsxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFinancialReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFinancialReportXlsxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFinancialReportXlsx>>> = ({ signal, pageParam }) =>
    getFinancialReportXlsx({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getFinancialReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFinancialReportXlsxInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFinancialReportXlsx>>>;
export type GetFinancialReportXlsxInfiniteQueryError = unknown;

export const useGetFinancialReportXlsxInfinite = <
  TData = Awaited<ReturnType<typeof getFinancialReportXlsx>>,
  TError = unknown
>(
  params: GetFinancialReportXlsxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFinancialReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFinancialReportXlsxInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetFinancialReportXlsxQueryOptions = <
  TData = Awaited<ReturnType<typeof getFinancialReportXlsx>>,
  TError = unknown
>(
  params: GetFinancialReportXlsxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getFinancialReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFinancialReportXlsxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFinancialReportXlsx>>> = ({ signal }) =>
    getFinancialReportXlsx(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFinancialReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFinancialReportXlsxQueryResult = NonNullable<Awaited<ReturnType<typeof getFinancialReportXlsx>>>;
export type GetFinancialReportXlsxQueryError = unknown;

export const useGetFinancialReportXlsx = <TData = Awaited<ReturnType<typeof getFinancialReportXlsx>>, TError = unknown>(
  params: GetFinancialReportXlsxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getFinancialReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFinancialReportXlsxQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFoodHistoryReportXlsx = (
  params: GetFoodHistoryReportXlsxParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<GetFoodHistoryReportXlsxResponse>(
    { url: `/reports/xlsx/food-history`, method: 'get', params, signal },
    options
  );
};

export const getGetFoodHistoryReportXlsxQueryKey = (params: GetFoodHistoryReportXlsxParams) => {
  return [`/reports/xlsx/food-history`, ...(params ? [params] : [])] as const;
};

export const getGetFoodHistoryReportXlsxInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getFoodHistoryReportXlsx>>,
  TError = unknown
>(
  params: GetFoodHistoryReportXlsxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFoodHistoryReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFoodHistoryReportXlsxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFoodHistoryReportXlsx>>> = ({ signal, pageParam }) =>
    getFoodHistoryReportXlsx({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getFoodHistoryReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFoodHistoryReportXlsxInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFoodHistoryReportXlsx>>
>;
export type GetFoodHistoryReportXlsxInfiniteQueryError = unknown;

export const useGetFoodHistoryReportXlsxInfinite = <
  TData = Awaited<ReturnType<typeof getFoodHistoryReportXlsx>>,
  TError = unknown
>(
  params: GetFoodHistoryReportXlsxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFoodHistoryReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFoodHistoryReportXlsxInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetFoodHistoryReportXlsxQueryOptions = <
  TData = Awaited<ReturnType<typeof getFoodHistoryReportXlsx>>,
  TError = unknown
>(
  params: GetFoodHistoryReportXlsxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getFoodHistoryReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFoodHistoryReportXlsxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFoodHistoryReportXlsx>>> = ({ signal }) =>
    getFoodHistoryReportXlsx(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFoodHistoryReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFoodHistoryReportXlsxQueryResult = NonNullable<Awaited<ReturnType<typeof getFoodHistoryReportXlsx>>>;
export type GetFoodHistoryReportXlsxQueryError = unknown;

export const useGetFoodHistoryReportXlsx = <
  TData = Awaited<ReturnType<typeof getFoodHistoryReportXlsx>>,
  TError = unknown
>(
  params: GetFoodHistoryReportXlsxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getFoodHistoryReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFoodHistoryReportXlsxQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFoodReportXlsx = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetFoodReportXlsxResponse>({ url: `/reports/xlsx/foods`, method: 'get', signal }, options);
};

export const getGetFoodReportXlsxQueryKey = () => {
  return [`/reports/xlsx/foods`] as const;
};

export const getGetFoodReportXlsxInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getFoodReportXlsx>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFoodReportXlsx>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFoodReportXlsxQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFoodReportXlsx>>> = ({ signal }) =>
    getFoodReportXlsx(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getFoodReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFoodReportXlsxInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getFoodReportXlsx>>>;
export type GetFoodReportXlsxInfiniteQueryError = unknown;

export const useGetFoodReportXlsxInfinite = <
  TData = Awaited<ReturnType<typeof getFoodReportXlsx>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getFoodReportXlsx>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFoodReportXlsxInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetFoodReportXlsxQueryOptions = <
  TData = Awaited<ReturnType<typeof getFoodReportXlsx>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFoodReportXlsx>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFoodReportXlsxQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFoodReportXlsx>>> = ({ signal }) =>
    getFoodReportXlsx(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFoodReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFoodReportXlsxQueryResult = NonNullable<Awaited<ReturnType<typeof getFoodReportXlsx>>>;
export type GetFoodReportXlsxQueryError = unknown;

export const useGetFoodReportXlsx = <
  TData = Awaited<ReturnType<typeof getFoodReportXlsx>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getFoodReportXlsx>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFoodReportXlsxQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getHistoryReportXlsx = (
  params: GetHistoryReportXlsxParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<GetHistoryReportXlsxResponse>(
    { url: `/reports/xlsx/history`, method: 'get', params, signal },
    options
  );
};

export const getGetHistoryReportXlsxQueryKey = (params: GetHistoryReportXlsxParams) => {
  return [`/reports/xlsx/history`, ...(params ? [params] : [])] as const;
};

export const getGetHistoryReportXlsxInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getHistoryReportXlsx>>,
  TError = unknown
>(
  params: GetHistoryReportXlsxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getHistoryReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHistoryReportXlsxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHistoryReportXlsx>>> = ({ signal, pageParam }) =>
    getHistoryReportXlsx({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getHistoryReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetHistoryReportXlsxInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getHistoryReportXlsx>>>;
export type GetHistoryReportXlsxInfiniteQueryError = unknown;

export const useGetHistoryReportXlsxInfinite = <
  TData = Awaited<ReturnType<typeof getHistoryReportXlsx>>,
  TError = unknown
>(
  params: GetHistoryReportXlsxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getHistoryReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetHistoryReportXlsxInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetHistoryReportXlsxQueryOptions = <
  TData = Awaited<ReturnType<typeof getHistoryReportXlsx>>,
  TError = unknown
>(
  params: GetHistoryReportXlsxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getHistoryReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHistoryReportXlsxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHistoryReportXlsx>>> = ({ signal }) =>
    getHistoryReportXlsx(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getHistoryReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetHistoryReportXlsxQueryResult = NonNullable<Awaited<ReturnType<typeof getHistoryReportXlsx>>>;
export type GetHistoryReportXlsxQueryError = unknown;

export const useGetHistoryReportXlsx = <TData = Awaited<ReturnType<typeof getHistoryReportXlsx>>, TError = unknown>(
  params: GetHistoryReportXlsxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getHistoryReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetHistoryReportXlsxQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getIndividualCattleClosingReportXlsx = (
  params: GetIndividualCattleClosingReportXlsxParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<GetIndividualCattleClosingReportXlsxResponse>(
    { url: `/reports/xlsx/individual-cattle-closing`, method: 'get', params, signal },
    options
  );
};

export const getGetIndividualCattleClosingReportXlsxQueryKey = (params: GetIndividualCattleClosingReportXlsxParams) => {
  return [`/reports/xlsx/individual-cattle-closing`, ...(params ? [params] : [])] as const;
};

export const getGetIndividualCattleClosingReportXlsxInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getIndividualCattleClosingReportXlsx>>,
  TError = unknown
>(
  params: GetIndividualCattleClosingReportXlsxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getIndividualCattleClosingReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIndividualCattleClosingReportXlsxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIndividualCattleClosingReportXlsx>>> = ({
    signal,
    pageParam
  }) => getIndividualCattleClosingReportXlsx({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getIndividualCattleClosingReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIndividualCattleClosingReportXlsxInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIndividualCattleClosingReportXlsx>>
>;
export type GetIndividualCattleClosingReportXlsxInfiniteQueryError = unknown;

export const useGetIndividualCattleClosingReportXlsxInfinite = <
  TData = Awaited<ReturnType<typeof getIndividualCattleClosingReportXlsx>>,
  TError = unknown
>(
  params: GetIndividualCattleClosingReportXlsxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getIndividualCattleClosingReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIndividualCattleClosingReportXlsxInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetIndividualCattleClosingReportXlsxQueryOptions = <
  TData = Awaited<ReturnType<typeof getIndividualCattleClosingReportXlsx>>,
  TError = unknown
>(
  params: GetIndividualCattleClosingReportXlsxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getIndividualCattleClosingReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIndividualCattleClosingReportXlsxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIndividualCattleClosingReportXlsx>>> = ({ signal }) =>
    getIndividualCattleClosingReportXlsx(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getIndividualCattleClosingReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetIndividualCattleClosingReportXlsxQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIndividualCattleClosingReportXlsx>>
>;
export type GetIndividualCattleClosingReportXlsxQueryError = unknown;

export const useGetIndividualCattleClosingReportXlsx = <
  TData = Awaited<ReturnType<typeof getIndividualCattleClosingReportXlsx>>,
  TError = unknown
>(
  params: GetIndividualCattleClosingReportXlsxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getIndividualCattleClosingReportXlsx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIndividualCattleClosingReportXlsxQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getZootechnicalIndicesReportXlsx = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetZootechnicalIndicesReportXlsxResponse>(
    { url: `/reports/xlsx/zootechnical-indices`, method: 'get', signal },
    options
  );
};

export const getGetZootechnicalIndicesReportXlsxQueryKey = () => {
  return [`/reports/xlsx/zootechnical-indices`] as const;
};

export const getGetZootechnicalIndicesReportXlsxInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getZootechnicalIndicesReportXlsx>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getZootechnicalIndicesReportXlsx>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetZootechnicalIndicesReportXlsxQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getZootechnicalIndicesReportXlsx>>> = ({ signal }) =>
    getZootechnicalIndicesReportXlsx(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getZootechnicalIndicesReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetZootechnicalIndicesReportXlsxInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getZootechnicalIndicesReportXlsx>>
>;
export type GetZootechnicalIndicesReportXlsxInfiniteQueryError = unknown;

export const useGetZootechnicalIndicesReportXlsxInfinite = <
  TData = Awaited<ReturnType<typeof getZootechnicalIndicesReportXlsx>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getZootechnicalIndicesReportXlsx>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetZootechnicalIndicesReportXlsxInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetZootechnicalIndicesReportXlsxQueryOptions = <
  TData = Awaited<ReturnType<typeof getZootechnicalIndicesReportXlsx>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getZootechnicalIndicesReportXlsx>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetZootechnicalIndicesReportXlsxQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getZootechnicalIndicesReportXlsx>>> = ({ signal }) =>
    getZootechnicalIndicesReportXlsx(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getZootechnicalIndicesReportXlsx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetZootechnicalIndicesReportXlsxQueryResult = NonNullable<
  Awaited<ReturnType<typeof getZootechnicalIndicesReportXlsx>>
>;
export type GetZootechnicalIndicesReportXlsxQueryError = unknown;

export const useGetZootechnicalIndicesReportXlsx = <
  TData = Awaited<ReturnType<typeof getZootechnicalIndicesReportXlsx>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getZootechnicalIndicesReportXlsx>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetZootechnicalIndicesReportXlsxQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const batchZootechnicalIndices = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ZootechnicalReturn>({ url: `/reports/zootechnical-indices`, method: 'get', signal }, options);
};

export const getBatchZootechnicalIndicesQueryKey = () => {
  return [`/reports/zootechnical-indices`] as const;
};

export const getBatchZootechnicalIndicesInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof batchZootechnicalIndices>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchZootechnicalIndices>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchZootechnicalIndicesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchZootechnicalIndices>>> = ({ signal }) =>
    batchZootechnicalIndices(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof batchZootechnicalIndices>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchZootechnicalIndicesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof batchZootechnicalIndices>>
>;
export type BatchZootechnicalIndicesInfiniteQueryError = unknown;

export const useBatchZootechnicalIndicesInfinite = <
  TData = Awaited<ReturnType<typeof batchZootechnicalIndices>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchZootechnicalIndices>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchZootechnicalIndicesInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBatchZootechnicalIndicesQueryOptions = <
  TData = Awaited<ReturnType<typeof batchZootechnicalIndices>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof batchZootechnicalIndices>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchZootechnicalIndicesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchZootechnicalIndices>>> = ({ signal }) =>
    batchZootechnicalIndices(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof batchZootechnicalIndices>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchZootechnicalIndicesQueryResult = NonNullable<Awaited<ReturnType<typeof batchZootechnicalIndices>>>;
export type BatchZootechnicalIndicesQueryError = unknown;

export const useBatchZootechnicalIndices = <
  TData = Awaited<ReturnType<typeof batchZootechnicalIndices>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof batchZootechnicalIndices>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchZootechnicalIndicesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listSaleManagements = (
  params?: ListSaleManagementsParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListSaleManagementsResponse>({ url: `/sale-management`, method: 'get', params, signal }, options);
};

export const getListSaleManagementsQueryKey = (params?: ListSaleManagementsParams) => {
  return [`/sale-management`, ...(params ? [params] : [])] as const;
};

export const getListSaleManagementsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listSaleManagements>>,
  TError = unknown
>(
  params?: ListSaleManagementsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listSaleManagements>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSaleManagementsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSaleManagements>>> = ({ signal, pageParam }) =>
    listSaleManagements({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listSaleManagements>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListSaleManagementsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listSaleManagements>>>;
export type ListSaleManagementsInfiniteQueryError = unknown;

export const useListSaleManagementsInfinite = <
  TData = Awaited<ReturnType<typeof listSaleManagements>>,
  TError = unknown
>(
  params?: ListSaleManagementsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listSaleManagements>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListSaleManagementsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListSaleManagementsQueryOptions = <
  TData = Awaited<ReturnType<typeof listSaleManagements>>,
  TError = unknown
>(
  params?: ListSaleManagementsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listSaleManagements>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSaleManagementsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSaleManagements>>> = ({ signal }) =>
    listSaleManagements(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listSaleManagements>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListSaleManagementsQueryResult = NonNullable<Awaited<ReturnType<typeof listSaleManagements>>>;
export type ListSaleManagementsQueryError = unknown;

export const useListSaleManagements = <TData = Awaited<ReturnType<typeof listSaleManagements>>, TError = unknown>(
  params?: ListSaleManagementsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listSaleManagements>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListSaleManagementsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createSaleManagement = (
  createSaleManagement: CreateSaleManagement,
  options?: SecondParameter<typeof request>
) => {
  return request<CreateSaleManagementResponse>(
    {
      url: `/sale-management`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createSaleManagement
    },
    options
  );
};

export const getCreateSaleManagementMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSaleManagement>>,
    TError,
    { data: CreateSaleManagement },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createSaleManagement>>,
  TError,
  { data: CreateSaleManagement },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createSaleManagement>>,
    { data: CreateSaleManagement }
  > = (props) => {
    const { data } = props ?? {};

    return createSaleManagement(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSaleManagementMutationResult = NonNullable<Awaited<ReturnType<typeof createSaleManagement>>>;
export type CreateSaleManagementMutationBody = CreateSaleManagement;
export type CreateSaleManagementMutationError = HttpError;

export const useCreateSaleManagement = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSaleManagement>>,
    TError,
    { data: CreateSaleManagement },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateSaleManagementMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getSaleManagement = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetSaleManagementResponse>({ url: `/sale-management/${id}`, method: 'get', signal }, options);
};

export const getGetSaleManagementQueryKey = (id: number) => {
  return [`/sale-management/${id}`] as const;
};

export const getGetSaleManagementInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getSaleManagement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSaleManagement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSaleManagementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSaleManagement>>> = ({ signal }) =>
    getSaleManagement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getSaleManagement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSaleManagementInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSaleManagement>>>;
export type GetSaleManagementInfiniteQueryError = unknown;

export const useGetSaleManagementInfinite = <TData = Awaited<ReturnType<typeof getSaleManagement>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSaleManagement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSaleManagementInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetSaleManagementQueryOptions = <
  TData = Awaited<ReturnType<typeof getSaleManagement>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSaleManagement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSaleManagementQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSaleManagement>>> = ({ signal }) =>
    getSaleManagement(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSaleManagement>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSaleManagementQueryResult = NonNullable<Awaited<ReturnType<typeof getSaleManagement>>>;
export type GetSaleManagementQueryError = unknown;

export const useGetSaleManagement = <TData = Awaited<ReturnType<typeof getSaleManagement>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSaleManagement>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSaleManagementQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateSaleManagement = (
  id: number,
  updateSaleManagement: UpdateSaleManagement,
  options?: SecondParameter<typeof request>
) => {
  return request<UpdateSaleManagementResponse>(
    {
      url: `/sale-management/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateSaleManagement
    },
    options
  );
};

export const getUpdateSaleManagementMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSaleManagement>>,
    TError,
    { id: number; data: UpdateSaleManagement },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSaleManagement>>,
  TError,
  { id: number; data: UpdateSaleManagement },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSaleManagement>>,
    { id: number; data: UpdateSaleManagement }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateSaleManagement(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSaleManagementMutationResult = NonNullable<Awaited<ReturnType<typeof updateSaleManagement>>>;
export type UpdateSaleManagementMutationBody = UpdateSaleManagement;
export type UpdateSaleManagementMutationError = unknown;

export const useUpdateSaleManagement = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSaleManagement>>,
    TError,
    { id: number; data: UpdateSaleManagement },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateSaleManagementMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listSoldBatches = (id: number, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListSoldBatchesResponse>({ url: `/sale-management/${id}/batch`, method: 'get', signal }, options);
};

export const getListSoldBatchesQueryKey = (id: number) => {
  return [`/sale-management/${id}/batch`] as const;
};

export const getListSoldBatchesInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listSoldBatches>>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listSoldBatches>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSoldBatchesQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSoldBatches>>> = ({ signal }) =>
    listSoldBatches(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listSoldBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListSoldBatchesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listSoldBatches>>>;
export type ListSoldBatchesInfiniteQueryError = unknown;

export const useListSoldBatchesInfinite = <TData = Awaited<ReturnType<typeof listSoldBatches>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listSoldBatches>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListSoldBatchesInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListSoldBatchesQueryOptions = <TData = Awaited<ReturnType<typeof listSoldBatches>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listSoldBatches>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSoldBatchesQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSoldBatches>>> = ({ signal }) =>
    listSoldBatches(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listSoldBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListSoldBatchesQueryResult = NonNullable<Awaited<ReturnType<typeof listSoldBatches>>>;
export type ListSoldBatchesQueryError = unknown;

export const useListSoldBatches = <TData = Awaited<ReturnType<typeof listSoldBatches>>, TError = unknown>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listSoldBatches>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListSoldBatchesQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const closeSaleManagement = (
  id: number,
  closeSaleManagementBody: CloseSaleManagementBody,
  options?: SecondParameter<typeof request>
) => {
  return request<CloseSaleManagementResponse>(
    {
      url: `/sale-management/${id}/closure`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: closeSaleManagementBody
    },
    options
  );
};

export const getCloseSaleManagementMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof closeSaleManagement>>,
    TError,
    { id: number; data: CloseSaleManagementBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof closeSaleManagement>>,
  TError,
  { id: number; data: CloseSaleManagementBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof closeSaleManagement>>,
    { id: number; data: CloseSaleManagementBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return closeSaleManagement(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CloseSaleManagementMutationResult = NonNullable<Awaited<ReturnType<typeof closeSaleManagement>>>;
export type CloseSaleManagementMutationBody = CloseSaleManagementBody;
export type CloseSaleManagementMutationError = HttpError;

export const useCloseSaleManagement = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof closeSaleManagement>>,
    TError,
    { id: number; data: CloseSaleManagementBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCloseSaleManagementMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listBreeds = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<string[]>({ url: `/static/breeds`, method: 'get', signal }, options);
};

export const getListBreedsQueryKey = () => {
  return [`/static/breeds`] as const;
};

export const getListBreedsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listBreeds>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listBreeds>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListBreedsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listBreeds>>> = ({ signal }) =>
    listBreeds(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listBreeds>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListBreedsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listBreeds>>>;
export type ListBreedsInfiniteQueryError = unknown;

export const useListBreedsInfinite = <TData = Awaited<ReturnType<typeof listBreeds>>, TError = unknown>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listBreeds>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListBreedsInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListBreedsQueryOptions = <TData = Awaited<ReturnType<typeof listBreeds>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listBreeds>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListBreedsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listBreeds>>> = ({ signal }) =>
    listBreeds(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listBreeds>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListBreedsQueryResult = NonNullable<Awaited<ReturnType<typeof listBreeds>>>;
export type ListBreedsQueryError = unknown;

export const useListBreeds = <TData = Awaited<ReturnType<typeof listBreeds>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listBreeds>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListBreedsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listCategories = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<string[]>({ url: `/static/categories`, method: 'get', signal }, options);
};

export const getListCategoriesQueryKey = () => {
  return [`/static/categories`] as const;
};

export const getListCategoriesInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listCategories>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCategories>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCategoriesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCategories>>> = ({ signal }) =>
    listCategories(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCategoriesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listCategories>>>;
export type ListCategoriesInfiniteQueryError = unknown;

export const useListCategoriesInfinite = <
  TData = Awaited<ReturnType<typeof listCategories>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listCategories>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCategoriesInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListCategoriesQueryOptions = <
  TData = Awaited<ReturnType<typeof listCategories>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listCategories>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCategoriesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCategories>>> = ({ signal }) =>
    listCategories(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof listCategories>>>;
export type ListCategoriesQueryError = unknown;

export const useListCategories = <TData = Awaited<ReturnType<typeof listCategories>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listCategories>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCategoriesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listFoods = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ListFoodsResponse>({ url: `/static/foods`, method: 'get', signal }, options);
};

export const getListFoodsQueryKey = () => {
  return [`/static/foods`] as const;
};

export const getListFoodsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listFoods>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFoods>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListFoodsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listFoods>>> = ({ signal }) =>
    listFoods(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listFoods>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListFoodsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listFoods>>>;
export type ListFoodsInfiniteQueryError = unknown;

export const useListFoodsInfinite = <TData = Awaited<ReturnType<typeof listFoods>>, TError = unknown>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listFoods>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListFoodsInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListFoodsQueryOptions = <TData = Awaited<ReturnType<typeof listFoods>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listFoods>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListFoodsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listFoods>>> = ({ signal }) =>
    listFoods(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listFoods>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListFoodsQueryResult = NonNullable<Awaited<ReturnType<typeof listFoods>>>;
export type ListFoodsQueryError = unknown;

export const useListFoods = <TData = Awaited<ReturnType<typeof listFoods>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listFoods>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListFoodsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listStocks = (
  params?: ListStocksParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListStocksResponse>({ url: `/stocks`, method: 'get', params, signal }, options);
};

export const getListStocksQueryKey = (params?: ListStocksParams) => {
  return [`/stocks`, ...(params ? [params] : [])] as const;
};

export const getListStocksInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof listStocks>>, TError = unknown>(
  params?: ListStocksParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listStocks>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListStocksQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listStocks>>> = ({ signal, pageParam }) =>
    listStocks({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listStocks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListStocksInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listStocks>>>;
export type ListStocksInfiniteQueryError = unknown;

export const useListStocksInfinite = <TData = Awaited<ReturnType<typeof listStocks>>, TError = unknown>(
  params?: ListStocksParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listStocks>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListStocksInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListStocksQueryOptions = <TData = Awaited<ReturnType<typeof listStocks>>, TError = unknown>(
  params?: ListStocksParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listStocks>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListStocksQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listStocks>>> = ({ signal }) =>
    listStocks(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listStocks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListStocksQueryResult = NonNullable<Awaited<ReturnType<typeof listStocks>>>;
export type ListStocksQueryError = unknown;

export const useListStocks = <TData = Awaited<ReturnType<typeof listStocks>>, TError = unknown>(
  params?: ListStocksParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listStocks>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListStocksQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createStock = (createStock: CreateStock, options?: SecondParameter<typeof request>) => {
  return request<CreateStockResponse>(
    { url: `/stocks`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createStock },
    options
  );
};

export const getCreateStockMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createStock>>, TError, { data: CreateStock }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createStock>>, TError, { data: CreateStock }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createStock>>, { data: CreateStock }> = (props) => {
    const { data } = props ?? {};

    return createStock(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateStockMutationResult = NonNullable<Awaited<ReturnType<typeof createStock>>>;
export type CreateStockMutationBody = CreateStock;
export type CreateStockMutationError = HttpError;

export const useCreateStock = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createStock>>, TError, { data: CreateStock }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateStockMutationOptions(options);

  return useMutation(mutationOptions);
};

export const createPremix = (createPremixStock: CreatePremixStock, options?: SecondParameter<typeof request>) => {
  return request<CreatePremixResponse>(
    { url: `/stocks/premix`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createPremixStock },
    options
  );
};

export const getCreatePremixMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPremix>>,
    TError,
    { data: CreatePremixStock },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createPremix>>, TError, { data: CreatePremixStock }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPremix>>, { data: CreatePremixStock }> = (
    props
  ) => {
    const { data } = props ?? {};

    return createPremix(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePremixMutationResult = NonNullable<Awaited<ReturnType<typeof createPremix>>>;
export type CreatePremixMutationBody = CreatePremixStock;
export type CreatePremixMutationError = unknown;

export const useCreatePremix = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPremix>>,
    TError,
    { data: CreatePremixStock },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreatePremixMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getStockAverageInfo = (
  getStockAverageInfoBody: GetStockAverageInfoBody,
  options?: SecondParameter<typeof request>
) => {
  return request<GetStockAverageInfoResponse>(
    {
      url: `/stocks/premix`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: getStockAverageInfoBody
    },
    options
  );
};

export const getGetStockAverageInfoMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getStockAverageInfo>>,
    TError,
    { data: GetStockAverageInfoBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof getStockAverageInfo>>,
  TError,
  { data: GetStockAverageInfoBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getStockAverageInfo>>,
    { data: GetStockAverageInfoBody }
  > = (props) => {
    const { data } = props ?? {};

    return getStockAverageInfo(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetStockAverageInfoMutationResult = NonNullable<Awaited<ReturnType<typeof getStockAverageInfo>>>;
export type GetStockAverageInfoMutationBody = GetStockAverageInfoBody;
export type GetStockAverageInfoMutationError = unknown;

export const useGetStockAverageInfo = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getStockAverageInfo>>,
    TError,
    { data: GetStockAverageInfoBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getGetStockAverageInfoMutationOptions(options);

  return useMutation(mutationOptions);
};

/** ### ID string is name, number is ID. */
export const getStock = (id: number | string, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<GetStockResponse>({ url: `/stocks/${id}`, method: 'get', signal }, options);
};

export const getGetStockQueryKey = (id: number | string) => {
  return [`/stocks/${id}`] as const;
};

export const getGetStockInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof getStock>>, TError = unknown>(
  id: number | string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getStock>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStockQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStock>>> = ({ signal }) =>
    getStock(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getStock>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetStockInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getStock>>>;
export type GetStockInfiniteQueryError = unknown;

export const useGetStockInfinite = <TData = Awaited<ReturnType<typeof getStock>>, TError = unknown>(
  id: number | string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getStock>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetStockInfiniteQueryOptions(id, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetStockQueryOptions = <TData = Awaited<ReturnType<typeof getStock>>, TError = unknown>(
  id: number | string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getStock>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStockQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStock>>> = ({ signal }) =>
    getStock(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getStock>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetStockQueryResult = NonNullable<Awaited<ReturnType<typeof getStock>>>;
export type GetStockQueryError = unknown;

export const useGetStock = <TData = Awaited<ReturnType<typeof getStock>>, TError = unknown>(
  id: number | string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getStock>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetStockQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateStockNmFactor = (
  id: number,
  updateStockNmFactorBody: number,
  options?: SecondParameter<typeof request>
) => {
  return request<boolean>(
    {
      url: `/stocks/${id}/nm-factor`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateStockNmFactorBody
    },
    options
  );
};

export const getUpdateStockNmFactorMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStockNmFactor>>,
    TError,
    { id: number; data: number },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateStockNmFactor>>,
  TError,
  { id: number; data: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateStockNmFactor>>, { id: number; data: number }> = (
    props
  ) => {
    const { id, data } = props ?? {};

    return updateStockNmFactor(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateStockNmFactorMutationResult = NonNullable<Awaited<ReturnType<typeof updateStockNmFactor>>>;
export type UpdateStockNmFactorMutationBody = number;
export type UpdateStockNmFactorMutationError = HttpError;

export const useUpdateStockNmFactor = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStockNmFactor>>,
    TError,
    { id: number; data: number },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateStockNmFactorMutationOptions(options);

  return useMutation(mutationOptions);
};

export const receiveStockPurchase = (
  id: number,
  params: ReceiveStockPurchaseParams,
  options?: SecondParameter<typeof request>
) => {
  return request<ReceiveStockPurchaseResponse>({ url: `/stocks/${id}/receive`, method: 'post', params }, options);
};

export const getReceiveStockPurchaseMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof receiveStockPurchase>>,
    TError,
    { id: number; params: ReceiveStockPurchaseParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof receiveStockPurchase>>,
  TError,
  { id: number; params: ReceiveStockPurchaseParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof receiveStockPurchase>>,
    { id: number; params: ReceiveStockPurchaseParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return receiveStockPurchase(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceiveStockPurchaseMutationResult = NonNullable<Awaited<ReturnType<typeof receiveStockPurchase>>>;

export type ReceiveStockPurchaseMutationError = HttpError;

export const useReceiveStockPurchase = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof receiveStockPurchase>>,
    TError,
    { id: number; params: ReceiveStockPurchaseParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getReceiveStockPurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const receiveStock = (id: number, params: ReceiveStockParams, options?: SecondParameter<typeof request>) => {
  return request<ReceiveStockResponse>({ url: `/stocks/${id}/receive`, method: 'put', params }, options);
};

export const getReceiveStockMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof receiveStock>>,
    TError,
    { id: number; params: ReceiveStockParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof receiveStock>>,
  TError,
  { id: number; params: ReceiveStockParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof receiveStock>>,
    { id: number; params: ReceiveStockParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return receiveStock(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceiveStockMutationResult = NonNullable<Awaited<ReturnType<typeof receiveStock>>>;

export type ReceiveStockMutationError = HttpError;

export const useReceiveStock = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof receiveStock>>,
    TError,
    { id: number; params: ReceiveStockParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getReceiveStockMutationOptions(options);

  return useMutation(mutationOptions);
};

export const receivePremix = (
  id: number,
  receivePremixBody: ReceivePremixBody,
  options?: SecondParameter<typeof request>
) => {
  return request<ReceivePremixResponse>(
    {
      url: `/stocks/${id}/receive-premix`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: receivePremixBody
    },
    options
  );
};

export const getReceivePremixMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof receivePremix>>,
    TError,
    { id: number; data: ReceivePremixBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof receivePremix>>,
  TError,
  { id: number; data: ReceivePremixBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof receivePremix>>,
    { id: number; data: ReceivePremixBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return receivePremix(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReceivePremixMutationResult = NonNullable<Awaited<ReturnType<typeof receivePremix>>>;
export type ReceivePremixMutationBody = ReceivePremixBody;
export type ReceivePremixMutationError = HttpError;

export const useReceivePremix = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof receivePremix>>,
    TError,
    { id: number; data: ReceivePremixBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getReceivePremixMutationOptions(options);

  return useMutation(mutationOptions);
};

export const removeStock = (id: number, params: RemoveStockParams, options?: SecondParameter<typeof request>) => {
  return request<RemoveStockResponse>({ url: `/stocks/${id}/remove`, method: 'delete', params }, options);
};

export const getRemoveStockMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeStock>>,
    TError,
    { id: number; params: RemoveStockParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof removeStock>>,
  TError,
  { id: number; params: RemoveStockParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeStock>>,
    { id: number; params: RemoveStockParams }
  > = (props) => {
    const { id, params } = props ?? {};

    return removeStock(id, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveStockMutationResult = NonNullable<Awaited<ReturnType<typeof removeStock>>>;

export type RemoveStockMutationError = HttpError;

export const useRemoveStock = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeStock>>,
    TError,
    { id: number; params: RemoveStockParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getRemoveStockMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listStructures = (
  params?: ListStructuresParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListStructuresResponse>({ url: `/structures`, method: 'get', params, signal }, options);
};

export const getListStructuresQueryKey = (params?: ListStructuresParams) => {
  return [`/structures`, ...(params ? [params] : [])] as const;
};

export const getListStructuresInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listStructures>>,
  TError = unknown
>(
  params?: ListStructuresParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listStructures>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListStructuresQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listStructures>>> = ({ signal, pageParam }) =>
    listStructures({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listStructures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListStructuresInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listStructures>>>;
export type ListStructuresInfiniteQueryError = unknown;

export const useListStructuresInfinite = <TData = Awaited<ReturnType<typeof listStructures>>, TError = unknown>(
  params?: ListStructuresParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listStructures>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListStructuresInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListStructuresQueryOptions = <TData = Awaited<ReturnType<typeof listStructures>>, TError = unknown>(
  params?: ListStructuresParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listStructures>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListStructuresQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listStructures>>> = ({ signal }) =>
    listStructures(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listStructures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListStructuresQueryResult = NonNullable<Awaited<ReturnType<typeof listStructures>>>;
export type ListStructuresQueryError = unknown;

export const useListStructures = <TData = Awaited<ReturnType<typeof listStructures>>, TError = unknown>(
  params?: ListStructuresParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listStructures>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListStructuresQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createStructure = (createStructure: CreateStructure, options?: SecondParameter<typeof request>) => {
  return request<CreateStructureResponse>(
    { url: `/structures`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createStructure },
    options
  );
};

export const getCreateStructureMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createStructure>>,
    TError,
    { data: CreateStructure },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createStructure>>, TError, { data: CreateStructure }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createStructure>>, { data: CreateStructure }> = (
    props
  ) => {
    const { data } = props ?? {};

    return createStructure(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateStructureMutationResult = NonNullable<Awaited<ReturnType<typeof createStructure>>>;
export type CreateStructureMutationBody = CreateStructure;
export type CreateStructureMutationError = unknown;

export const useCreateStructure = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createStructure>>,
    TError,
    { data: CreateStructure },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateStructureMutationOptions(options);

  return useMutation(mutationOptions);
};

export const updateStructure = (
  id: number,
  updateStructure: UpdateStructure,
  options?: SecondParameter<typeof request>
) => {
  return request<UpdateStructureResponse>(
    {
      url: `/structures/${id}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateStructure
    },
    options
  );
};

export const getUpdateStructureMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStructure>>,
    TError,
    { id: number; data: UpdateStructure },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateStructure>>,
  TError,
  { id: number; data: UpdateStructure },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateStructure>>,
    { id: number; data: UpdateStructure }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateStructure(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateStructureMutationResult = NonNullable<Awaited<ReturnType<typeof updateStructure>>>;
export type UpdateStructureMutationBody = UpdateStructure;
export type UpdateStructureMutationError = unknown;

export const useUpdateStructure = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStructure>>,
    TError,
    { id: number; data: UpdateStructure },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getUpdateStructureMutationOptions(options);

  return useMutation(mutationOptions);
};

export const readTracerInfo = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<ReadTracerInfoResponse>({ url: `/tracer`, method: 'get', signal }, options);
};

export const getReadTracerInfoQueryKey = () => {
  return [`/tracer`] as const;
};

export const getReadTracerInfoInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof readTracerInfo>>,
  TError = HttpError
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof readTracerInfo>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReadTracerInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readTracerInfo>>> = ({ signal }) =>
    readTracerInfo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof readTracerInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ReadTracerInfoInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof readTracerInfo>>>;
export type ReadTracerInfoInfiniteQueryError = HttpError;

export const useReadTracerInfoInfinite = <
  TData = Awaited<ReturnType<typeof readTracerInfo>>,
  TError = HttpError
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof readTracerInfo>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getReadTracerInfoInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getReadTracerInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof readTracerInfo>>,
  TError = HttpError
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof readTracerInfo>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getReadTracerInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof readTracerInfo>>> = ({ signal }) =>
    readTracerInfo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof readTracerInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ReadTracerInfoQueryResult = NonNullable<Awaited<ReturnType<typeof readTracerInfo>>>;
export type ReadTracerInfoQueryError = HttpError;

export const useReadTracerInfo = <TData = Awaited<ReturnType<typeof readTracerInfo>>, TError = HttpError>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof readTracerInfo>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getReadTracerInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const submitTracerBat = (submitTracerBat: SubmitTracerBat[], options?: SecondParameter<typeof request>) => {
  return request<boolean>(
    {
      url: `/tracer/submit/bat`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: submitTracerBat
    },
    options
  );
};

export const getSubmitTracerBatMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitTracerBat>>,
    TError,
    { data: SubmitTracerBat[] },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof submitTracerBat>>, TError, { data: SubmitTracerBat[] }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitTracerBat>>, { data: SubmitTracerBat[] }> = (
    props
  ) => {
    const { data } = props ?? {};

    return submitTracerBat(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubmitTracerBatMutationResult = NonNullable<Awaited<ReturnType<typeof submitTracerBat>>>;
export type SubmitTracerBatMutationBody = SubmitTracerBat[];
export type SubmitTracerBatMutationError = unknown;

export const useSubmitTracerBat = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitTracerBat>>,
    TError,
    { data: SubmitTracerBat[] },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getSubmitTracerBatMutationOptions(options);

  return useMutation(mutationOptions);
};

export const submitTracerBatAlim = (
  submitTracerBatAlim: SubmitTracerBatAlim[],
  options?: SecondParameter<typeof request>
) => {
  return request<boolean>(
    {
      url: `/tracer/submit/batalim`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: submitTracerBatAlim
    },
    options
  );
};

export const getSubmitTracerBatAlimMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitTracerBatAlim>>,
    TError,
    { data: SubmitTracerBatAlim[] },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitTracerBatAlim>>,
  TError,
  { data: SubmitTracerBatAlim[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof submitTracerBatAlim>>,
    { data: SubmitTracerBatAlim[] }
  > = (props) => {
    const { data } = props ?? {};

    return submitTracerBatAlim(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubmitTracerBatAlimMutationResult = NonNullable<Awaited<ReturnType<typeof submitTracerBatAlim>>>;
export type SubmitTracerBatAlimMutationBody = SubmitTracerBatAlim[];
export type SubmitTracerBatAlimMutationError = unknown;

export const useSubmitTracerBatAlim = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitTracerBatAlim>>,
    TError,
    { data: SubmitTracerBatAlim[] },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getSubmitTracerBatAlimMutationOptions(options);

  return useMutation(mutationOptions);
};

export const submitTracerTrato = (
  submitTracerTrato: SubmitTracerTrato[],
  options?: SecondParameter<typeof request>
) => {
  return request<boolean>(
    {
      url: `/tracer/submit/trato`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: submitTracerTrato
    },
    options
  );
};

export const getSubmitTracerTratoMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitTracerTrato>>,
    TError,
    { data: SubmitTracerTrato[] },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitTracerTrato>>,
  TError,
  { data: SubmitTracerTrato[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitTracerTrato>>, { data: SubmitTracerTrato[] }> = (
    props
  ) => {
    const { data } = props ?? {};

    return submitTracerTrato(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubmitTracerTratoMutationResult = NonNullable<Awaited<ReturnType<typeof submitTracerTrato>>>;
export type SubmitTracerTratoMutationBody = SubmitTracerTrato[];
export type SubmitTracerTratoMutationError = HttpError;

export const useSubmitTracerTrato = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitTracerTrato>>,
    TError,
    { data: SubmitTracerTrato[] },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getSubmitTracerTratoMutationOptions(options);

  return useMutation(mutationOptions);
};

export const listTreatments = (
  params?: ListTreatmentsParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ListTreatmentsResponse>({ url: `/treatments`, method: 'get', params, signal }, options);
};

export const getListTreatmentsQueryKey = (params?: ListTreatmentsParams) => {
  return [`/treatments`, ...(params ? [params] : [])] as const;
};

export const getListTreatmentsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listTreatments>>,
  TError = unknown
>(
  params?: ListTreatmentsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listTreatments>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListTreatmentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listTreatments>>> = ({ signal, pageParam }) =>
    listTreatments({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listTreatments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListTreatmentsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof listTreatments>>>;
export type ListTreatmentsInfiniteQueryError = unknown;

export const useListTreatmentsInfinite = <TData = Awaited<ReturnType<typeof listTreatments>>, TError = unknown>(
  params?: ListTreatmentsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof listTreatments>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListTreatmentsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getListTreatmentsQueryOptions = <TData = Awaited<ReturnType<typeof listTreatments>>, TError = unknown>(
  params?: ListTreatmentsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listTreatments>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListTreatmentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listTreatments>>> = ({ signal }) =>
    listTreatments(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listTreatments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListTreatmentsQueryResult = NonNullable<Awaited<ReturnType<typeof listTreatments>>>;
export type ListTreatmentsQueryError = unknown;

export const useListTreatments = <TData = Awaited<ReturnType<typeof listTreatments>>, TError = unknown>(
  params?: ListTreatmentsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof listTreatments>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListTreatmentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createTreatment = (
  createTreatmentBody: CreateTreatmentBody,
  options?: SecondParameter<typeof request>
) => {
  return request<CreateTreatmentResponse>(
    { url: `/treatments`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createTreatmentBody },
    options
  );
};

export const getCreateTreatmentMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTreatment>>,
    TError,
    { data: CreateTreatmentBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTreatment>>,
  TError,
  { data: CreateTreatmentBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTreatment>>, { data: CreateTreatmentBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return createTreatment(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTreatmentMutationResult = NonNullable<Awaited<ReturnType<typeof createTreatment>>>;
export type CreateTreatmentMutationBody = CreateTreatmentBody;
export type CreateTreatmentMutationError = unknown;

export const useCreateTreatment = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTreatment>>,
    TError,
    { data: CreateTreatmentBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getCreateTreatmentMutationOptions(options);

  return useMutation(mutationOptions);
};

export const changeTreatment = (changeTreatment: ChangeTreatment, options?: SecondParameter<typeof request>) => {
  return request<ChangeTreatmentResponse>(
    {
      url: `/treatments/change`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: changeTreatment
    },
    options
  );
};

export const getChangeTreatmentMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeTreatment>>,
    TError,
    { data: ChangeTreatment },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof changeTreatment>>, TError, { data: ChangeTreatment }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof changeTreatment>>, { data: ChangeTreatment }> = (
    props
  ) => {
    const { data } = props ?? {};

    return changeTreatment(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangeTreatmentMutationResult = NonNullable<Awaited<ReturnType<typeof changeTreatment>>>;
export type ChangeTreatmentMutationBody = ChangeTreatment;
export type ChangeTreatmentMutationError = HttpError;

export const useChangeTreatment = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeTreatment>>,
    TError,
    { data: ChangeTreatment },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getChangeTreatmentMutationOptions(options);

  return useMutation(mutationOptions);
};

export const exportFeedData = (
  params: ExportFeedDataParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ExportFeedDataResponse>({ url: `/utils/backup`, method: 'get', params, signal }, options);
};

export const getExportFeedDataQueryKey = (params: ExportFeedDataParams) => {
  return [`/utils/backup`, ...(params ? [params] : [])] as const;
};

export const getExportFeedDataInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof exportFeedData>>,
  TError = unknown
>(
  params: ExportFeedDataParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof exportFeedData>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExportFeedDataQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof exportFeedData>>> = ({ signal, pageParam }) =>
    exportFeedData({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof exportFeedData>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ExportFeedDataInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof exportFeedData>>>;
export type ExportFeedDataInfiniteQueryError = unknown;

export const useExportFeedDataInfinite = <TData = Awaited<ReturnType<typeof exportFeedData>>, TError = unknown>(
  params: ExportFeedDataParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof exportFeedData>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExportFeedDataInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getExportFeedDataQueryOptions = <TData = Awaited<ReturnType<typeof exportFeedData>>, TError = unknown>(
  params: ExportFeedDataParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof exportFeedData>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExportFeedDataQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof exportFeedData>>> = ({ signal }) =>
    exportFeedData(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof exportFeedData>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ExportFeedDataQueryResult = NonNullable<Awaited<ReturnType<typeof exportFeedData>>>;
export type ExportFeedDataQueryError = unknown;

export const useExportFeedData = <TData = Awaited<ReturnType<typeof exportFeedData>>, TError = unknown>(
  params: ExportFeedDataParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof exportFeedData>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExportFeedDataQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const restoreFeedData = (
  restoreFeedDataBody: RestoreFeedDataBody,
  params: RestoreFeedDataParams,
  options?: SecondParameter<typeof request>
) => {
  const formData = new FormData();
  formData.append('backup', restoreFeedDataBody.backup);

  return request<number>(
    {
      url: `/utils/backup`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      params
    },
    options
  );
};

export const getRestoreFeedDataMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreFeedData>>,
    TError,
    { data: RestoreFeedDataBody; params: RestoreFeedDataParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof restoreFeedData>>,
  TError,
  { data: RestoreFeedDataBody; params: RestoreFeedDataParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof restoreFeedData>>,
    { data: RestoreFeedDataBody; params: RestoreFeedDataParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return restoreFeedData(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RestoreFeedDataMutationResult = NonNullable<Awaited<ReturnType<typeof restoreFeedData>>>;
export type RestoreFeedDataMutationBody = RestoreFeedDataBody;
export type RestoreFeedDataMutationError = unknown;

export const useRestoreFeedData = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof restoreFeedData>>,
    TError,
    { data: RestoreFeedDataBody; params: RestoreFeedDataParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getRestoreFeedDataMutationOptions(options);

  return useMutation(mutationOptions);
};

export const submitBnd = (submitBndBody: SubmitBndBody, options?: SecondParameter<typeof request>) => {
  const formData = new FormData();
  formData.append('bnd', submitBndBody.bnd);

  return request<SubmitBndResponse>(
    { url: `/utils/submit/bnd`, method: 'post', headers: { 'Content-Type': 'multipart/form-data' }, data: formData },
    options
  );
};

export const getSubmitBndMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof submitBnd>>, TError, { data: SubmitBndBody }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof submitBnd>>, TError, { data: SubmitBndBody }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitBnd>>, { data: SubmitBndBody }> = (props) => {
    const { data } = props ?? {};

    return submitBnd(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubmitBndMutationResult = NonNullable<Awaited<ReturnType<typeof submitBnd>>>;
export type SubmitBndMutationBody = SubmitBndBody;
export type SubmitBndMutationError = unknown;

export const useSubmitBnd = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof submitBnd>>, TError, { data: SubmitBndBody }, TContext>;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getSubmitBndMutationOptions(options);

  return useMutation(mutationOptions);
};

export const submitCertifier = (
  submitCertifierBody: SubmitCertifierBody,
  options?: SecondParameter<typeof request>
) => {
  const formData = new FormData();
  formData.append('certifier', submitCertifierBody.certifier);

  return request<SubmitCertifierResponse>(
    {
      url: `/utils/submit/certifier`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    },
    options
  );
};

export const getSubmitCertifierMutationOptions = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitCertifier>>,
    TError,
    { data: SubmitCertifierBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitCertifier>>,
  TError,
  { data: SubmitCertifierBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitCertifier>>, { data: SubmitCertifierBody }> = (
    props
  ) => {
    const { data } = props ?? {};

    return submitCertifier(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubmitCertifierMutationResult = NonNullable<Awaited<ReturnType<typeof submitCertifier>>>;
export type SubmitCertifierMutationBody = SubmitCertifierBody;
export type SubmitCertifierMutationError = HttpError;

export const useSubmitCertifier = <TError = HttpError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitCertifier>>,
    TError,
    { data: SubmitCertifierBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}) => {
  const mutationOptions = getSubmitCertifierMutationOptions(options);

  return useMutation(mutationOptions);
};

export const batchDetails = (
  params: BatchDetailsParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<BatchDetailsResponse>({ url: `/view/batch-details`, method: 'get', params, signal }, options);
};

export const getBatchDetailsQueryKey = (params: BatchDetailsParams) => {
  return [`/view/batch-details`, ...(params ? [params] : [])] as const;
};

export const getBatchDetailsInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof batchDetails>>, TError = unknown>(
  params: BatchDetailsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchDetails>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchDetailsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchDetails>>> = ({ signal, pageParam }) =>
    batchDetails({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof batchDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchDetailsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof batchDetails>>>;
export type BatchDetailsInfiniteQueryError = unknown;

export const useBatchDetailsInfinite = <TData = Awaited<ReturnType<typeof batchDetails>>, TError = unknown>(
  params: BatchDetailsParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof batchDetails>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchDetailsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBatchDetailsQueryOptions = <TData = Awaited<ReturnType<typeof batchDetails>>, TError = unknown>(
  params: BatchDetailsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof batchDetails>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBatchDetailsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof batchDetails>>> = ({ signal }) =>
    batchDetails(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof batchDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BatchDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof batchDetails>>>;
export type BatchDetailsQueryError = unknown;

export const useBatchDetails = <TData = Awaited<ReturnType<typeof batchDetails>>, TError = unknown>(
  params: BatchDetailsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof batchDetails>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBatchDetailsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const beatView = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<BeatViewResponse>({ url: `/view/beat`, method: 'get', signal }, options);
};

export const getBeatViewQueryKey = () => {
  return [`/view/beat`] as const;
};

export const getBeatViewInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof beatView>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof beatView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBeatViewQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beatView>>> = ({ signal }) => beatView(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof beatView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BeatViewInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof beatView>>>;
export type BeatViewInfiniteQueryError = unknown;

export const useBeatViewInfinite = <TData = Awaited<ReturnType<typeof beatView>>, TError = unknown>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof beatView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeatViewInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBeatViewQueryOptions = <TData = Awaited<ReturnType<typeof beatView>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof beatView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBeatViewQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beatView>>> = ({ signal }) => beatView(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beatView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BeatViewQueryResult = NonNullable<Awaited<ReturnType<typeof beatView>>>;
export type BeatViewQueryError = unknown;

export const useBeatView = <TData = Awaited<ReturnType<typeof beatView>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof beatView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeatViewQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const dietsView = (
  params?: DietsViewParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<DietsViewResponse>({ url: `/view/diet`, method: 'get', params, signal }, options);
};

export const getDietsViewQueryKey = (params?: DietsViewParams) => {
  return [`/view/diet`, ...(params ? [params] : [])] as const;
};

export const getDietsViewInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof dietsView>>, TError = unknown>(
  params?: DietsViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof dietsView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDietsViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dietsView>>> = ({ signal, pageParam }) =>
    dietsView({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof dietsView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DietsViewInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof dietsView>>>;
export type DietsViewInfiniteQueryError = unknown;

export const useDietsViewInfinite = <TData = Awaited<ReturnType<typeof dietsView>>, TError = unknown>(
  params?: DietsViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof dietsView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDietsViewInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDietsViewQueryOptions = <TData = Awaited<ReturnType<typeof dietsView>>, TError = unknown>(
  params?: DietsViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof dietsView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDietsViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dietsView>>> = ({ signal }) =>
    dietsView(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof dietsView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DietsViewQueryResult = NonNullable<Awaited<ReturnType<typeof dietsView>>>;
export type DietsViewQueryError = unknown;

export const useDietsView = <TData = Awaited<ReturnType<typeof dietsView>>, TError = unknown>(
  params?: DietsViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof dietsView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDietsViewQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const dietTransitionListView = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<DietTransitionListViewResponse>({ url: `/view/diet-transition`, method: 'get', signal }, options);
};

export const getDietTransitionListViewQueryKey = () => {
  return [`/view/diet-transition`] as const;
};

export const getDietTransitionListViewInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof dietTransitionListView>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof dietTransitionListView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDietTransitionListViewQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dietTransitionListView>>> = ({ signal }) =>
    dietTransitionListView(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof dietTransitionListView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DietTransitionListViewInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof dietTransitionListView>>>;
export type DietTransitionListViewInfiniteQueryError = unknown;

export const useDietTransitionListViewInfinite = <
  TData = Awaited<ReturnType<typeof dietTransitionListView>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof dietTransitionListView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDietTransitionListViewInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDietTransitionListViewQueryOptions = <
  TData = Awaited<ReturnType<typeof dietTransitionListView>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof dietTransitionListView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDietTransitionListViewQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dietTransitionListView>>> = ({ signal }) =>
    dietTransitionListView(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof dietTransitionListView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DietTransitionListViewQueryResult = NonNullable<Awaited<ReturnType<typeof dietTransitionListView>>>;
export type DietTransitionListViewQueryError = unknown;

export const useDietTransitionListView = <
  TData = Awaited<ReturnType<typeof dietTransitionListView>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof dietTransitionListView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDietTransitionListViewQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const entryManagementView = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<EntryManagementViewResponse>({ url: `/view/entry-management`, method: 'get', signal }, options);
};

export const getEntryManagementViewQueryKey = () => {
  return [`/view/entry-management`] as const;
};

export const getEntryManagementViewInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof entryManagementView>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof entryManagementView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getEntryManagementViewQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof entryManagementView>>> = ({ signal }) =>
    entryManagementView(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof entryManagementView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type EntryManagementViewInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof entryManagementView>>>;
export type EntryManagementViewInfiniteQueryError = unknown;

export const useEntryManagementViewInfinite = <
  TData = Awaited<ReturnType<typeof entryManagementView>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof entryManagementView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getEntryManagementViewInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getEntryManagementViewQueryOptions = <
  TData = Awaited<ReturnType<typeof entryManagementView>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof entryManagementView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getEntryManagementViewQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof entryManagementView>>> = ({ signal }) =>
    entryManagementView(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof entryManagementView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type EntryManagementViewQueryResult = NonNullable<Awaited<ReturnType<typeof entryManagementView>>>;
export type EntryManagementViewQueryError = unknown;

export const useEntryManagementView = <
  TData = Awaited<ReturnType<typeof entryManagementView>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof entryManagementView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getEntryManagementViewQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const exitOxenView = (
  params: ExitOxenViewParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ExitOxenViewResponse>({ url: `/view/exit-oxen`, method: 'get', params, signal }, options);
};

export const getExitOxenViewQueryKey = (params: ExitOxenViewParams) => {
  return [`/view/exit-oxen`, ...(params ? [params] : [])] as const;
};

export const getExitOxenViewInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof exitOxenView>>, TError = unknown>(
  params: ExitOxenViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof exitOxenView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExitOxenViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof exitOxenView>>> = ({ signal, pageParam }) =>
    exitOxenView({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof exitOxenView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ExitOxenViewInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof exitOxenView>>>;
export type ExitOxenViewInfiniteQueryError = unknown;

export const useExitOxenViewInfinite = <TData = Awaited<ReturnType<typeof exitOxenView>>, TError = unknown>(
  params: ExitOxenViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof exitOxenView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExitOxenViewInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getExitOxenViewQueryOptions = <TData = Awaited<ReturnType<typeof exitOxenView>>, TError = unknown>(
  params: ExitOxenViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof exitOxenView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExitOxenViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof exitOxenView>>> = ({ signal }) =>
    exitOxenView(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof exitOxenView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ExitOxenViewQueryResult = NonNullable<Awaited<ReturnType<typeof exitOxenView>>>;
export type ExitOxenViewQueryError = unknown;

export const useExitOxenView = <TData = Awaited<ReturnType<typeof exitOxenView>>, TError = unknown>(
  params: ExitOxenViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof exitOxenView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExitOxenViewQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const findOx = (params?: FindOxParams, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<FindOxResponse>({ url: `/view/find-ox`, method: 'get', params, signal }, options);
};

export const getFindOxQueryKey = (params?: FindOxParams) => {
  return [`/view/find-ox`, ...(params ? [params] : [])] as const;
};

export const getFindOxInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof findOx>>, TError = unknown>(
  params?: FindOxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof findOx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFindOxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findOx>>> = ({ signal, pageParam }) =>
    findOx({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof findOx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FindOxInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof findOx>>>;
export type FindOxInfiniteQueryError = unknown;

export const useFindOxInfinite = <TData = Awaited<ReturnType<typeof findOx>>, TError = unknown>(
  params?: FindOxParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof findOx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFindOxInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFindOxQueryOptions = <TData = Awaited<ReturnType<typeof findOx>>, TError = unknown>(
  params?: FindOxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof findOx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFindOxQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findOx>>> = ({ signal }) =>
    findOx(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof findOx>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FindOxQueryResult = NonNullable<Awaited<ReturnType<typeof findOx>>>;
export type FindOxQueryError = unknown;

export const useFindOx = <TData = Awaited<ReturnType<typeof findOx>>, TError = unknown>(
  params?: FindOxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof findOx>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFindOxQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const leftoverView = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<LeftoverViewResponse>({ url: `/view/leftover`, method: 'get', signal }, options);
};

export const getLeftoverViewQueryKey = () => {
  return [`/view/leftover`] as const;
};

export const getLeftoverViewInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof leftoverView>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof leftoverView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLeftoverViewQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof leftoverView>>> = ({ signal }) =>
    leftoverView(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof leftoverView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LeftoverViewInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof leftoverView>>>;
export type LeftoverViewInfiniteQueryError = unknown;

export const useLeftoverViewInfinite = <TData = Awaited<ReturnType<typeof leftoverView>>, TError = unknown>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof leftoverView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLeftoverViewInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getLeftoverViewQueryOptions = <
  TData = Awaited<ReturnType<typeof leftoverView>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof leftoverView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLeftoverViewQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof leftoverView>>> = ({ signal }) =>
    leftoverView(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof leftoverView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type LeftoverViewQueryResult = NonNullable<Awaited<ReturnType<typeof leftoverView>>>;
export type LeftoverViewQueryError = unknown;

export const useLeftoverView = <TData = Awaited<ReturnType<typeof leftoverView>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof leftoverView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getLeftoverViewQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const nmUpdatesHistory = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<NmUpdatesHistoryResponse>({ url: `/view/nm-update-history`, method: 'get', signal }, options);
};

export const getNmUpdatesHistoryQueryKey = () => {
  return [`/view/nm-update-history`] as const;
};

export const getNmUpdatesHistoryInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof nmUpdatesHistory>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nmUpdatesHistory>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNmUpdatesHistoryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nmUpdatesHistory>>> = ({ signal }) =>
    nmUpdatesHistory(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof nmUpdatesHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NmUpdatesHistoryInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof nmUpdatesHistory>>>;
export type NmUpdatesHistoryInfiniteQueryError = unknown;

export const useNmUpdatesHistoryInfinite = <
  TData = Awaited<ReturnType<typeof nmUpdatesHistory>>,
  TError = unknown
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nmUpdatesHistory>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNmUpdatesHistoryInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNmUpdatesHistoryQueryOptions = <
  TData = Awaited<ReturnType<typeof nmUpdatesHistory>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof nmUpdatesHistory>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNmUpdatesHistoryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nmUpdatesHistory>>> = ({ signal }) =>
    nmUpdatesHistory(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof nmUpdatesHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NmUpdatesHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof nmUpdatesHistory>>>;
export type NmUpdatesHistoryQueryError = unknown;

export const useNmUpdatesHistory = <TData = Awaited<ReturnType<typeof nmUpdatesHistory>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof nmUpdatesHistory>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNmUpdatesHistoryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const recentBeatsView = (
  params?: RecentBeatsViewParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<RecentBeatsViewResponse>({ url: `/view/recent-beats`, method: 'get', params, signal }, options);
};

export const getRecentBeatsViewQueryKey = (params?: RecentBeatsViewParams) => {
  return [`/view/recent-beats`, ...(params ? [params] : [])] as const;
};

export const getRecentBeatsViewInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof recentBeatsView>>,
  TError = unknown
>(
  params?: RecentBeatsViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof recentBeatsView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRecentBeatsViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof recentBeatsView>>> = ({ signal, pageParam }) =>
    recentBeatsView({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof recentBeatsView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RecentBeatsViewInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof recentBeatsView>>>;
export type RecentBeatsViewInfiniteQueryError = unknown;

export const useRecentBeatsViewInfinite = <TData = Awaited<ReturnType<typeof recentBeatsView>>, TError = unknown>(
  params?: RecentBeatsViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof recentBeatsView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRecentBeatsViewInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getRecentBeatsViewQueryOptions = <TData = Awaited<ReturnType<typeof recentBeatsView>>, TError = unknown>(
  params?: RecentBeatsViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof recentBeatsView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRecentBeatsViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof recentBeatsView>>> = ({ signal }) =>
    recentBeatsView(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof recentBeatsView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RecentBeatsViewQueryResult = NonNullable<Awaited<ReturnType<typeof recentBeatsView>>>;
export type RecentBeatsViewQueryError = unknown;

export const useRecentBeatsView = <TData = Awaited<ReturnType<typeof recentBeatsView>>, TError = unknown>(
  params?: RecentBeatsViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof recentBeatsView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRecentBeatsViewQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const recentTreatsView = (
  params?: RecentTreatsViewParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<RecentTreatsViewResponse>({ url: `/view/recent-treats`, method: 'get', params, signal }, options);
};

export const getRecentTreatsViewQueryKey = (params?: RecentTreatsViewParams) => {
  return [`/view/recent-treats`, ...(params ? [params] : [])] as const;
};

export const getRecentTreatsViewInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof recentTreatsView>>,
  TError = unknown
>(
  params?: RecentTreatsViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof recentTreatsView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRecentTreatsViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof recentTreatsView>>> = ({ signal, pageParam }) =>
    recentTreatsView({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof recentTreatsView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RecentTreatsViewInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof recentTreatsView>>>;
export type RecentTreatsViewInfiniteQueryError = unknown;

export const useRecentTreatsViewInfinite = <TData = Awaited<ReturnType<typeof recentTreatsView>>, TError = unknown>(
  params?: RecentTreatsViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof recentTreatsView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRecentTreatsViewInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getRecentTreatsViewQueryOptions = <TData = Awaited<ReturnType<typeof recentTreatsView>>, TError = unknown>(
  params?: RecentTreatsViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof recentTreatsView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRecentTreatsViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof recentTreatsView>>> = ({ signal }) =>
    recentTreatsView(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof recentTreatsView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type RecentTreatsViewQueryResult = NonNullable<Awaited<ReturnType<typeof recentTreatsView>>>;
export type RecentTreatsViewQueryError = unknown;

export const useRecentTreatsView = <TData = Awaited<ReturnType<typeof recentTreatsView>>, TError = unknown>(
  params?: RecentTreatsViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof recentTreatsView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRecentTreatsViewQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const sideBarFactoryView = (
  params: SideBarFactoryViewParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<SideBarFactoryViewResponse>({ url: `/view/side-bar-factory`, method: 'get', params, signal }, options);
};

export const getSideBarFactoryViewQueryKey = (params: SideBarFactoryViewParams) => {
  return [`/view/side-bar-factory`, ...(params ? [params] : [])] as const;
};

export const getSideBarFactoryViewInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof sideBarFactoryView>>,
  TError = unknown
>(
  params: SideBarFactoryViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sideBarFactoryView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSideBarFactoryViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sideBarFactoryView>>> = ({ signal, pageParam }) =>
    sideBarFactoryView({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sideBarFactoryView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SideBarFactoryViewInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof sideBarFactoryView>>>;
export type SideBarFactoryViewInfiniteQueryError = unknown;

export const useSideBarFactoryViewInfinite = <TData = Awaited<ReturnType<typeof sideBarFactoryView>>, TError = unknown>(
  params: SideBarFactoryViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sideBarFactoryView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSideBarFactoryViewInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getSideBarFactoryViewQueryOptions = <
  TData = Awaited<ReturnType<typeof sideBarFactoryView>>,
  TError = unknown
>(
  params: SideBarFactoryViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sideBarFactoryView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSideBarFactoryViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sideBarFactoryView>>> = ({ signal }) =>
    sideBarFactoryView(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sideBarFactoryView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SideBarFactoryViewQueryResult = NonNullable<Awaited<ReturnType<typeof sideBarFactoryView>>>;
export type SideBarFactoryViewQueryError = unknown;

export const useSideBarFactoryView = <TData = Awaited<ReturnType<typeof sideBarFactoryView>>, TError = unknown>(
  params: SideBarFactoryViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sideBarFactoryView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSideBarFactoryViewQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const sideBarView = (
  params: SideBarViewParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<SideBarViewResponse>({ url: `/view/sider-bar`, method: 'get', params, signal }, options);
};

export const getSideBarViewQueryKey = (params: SideBarViewParams) => {
  return [`/view/sider-bar`, ...(params ? [params] : [])] as const;
};

export const getSideBarViewInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof sideBarView>>, TError = unknown>(
  params: SideBarViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sideBarView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSideBarViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sideBarView>>> = ({ signal, pageParam }) =>
    sideBarView({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof sideBarView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SideBarViewInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof sideBarView>>>;
export type SideBarViewInfiniteQueryError = unknown;

export const useSideBarViewInfinite = <TData = Awaited<ReturnType<typeof sideBarView>>, TError = unknown>(
  params: SideBarViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sideBarView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSideBarViewInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getSideBarViewQueryOptions = <TData = Awaited<ReturnType<typeof sideBarView>>, TError = unknown>(
  params: SideBarViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sideBarView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSideBarViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sideBarView>>> = ({ signal }) =>
    sideBarView(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof sideBarView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SideBarViewQueryResult = NonNullable<Awaited<ReturnType<typeof sideBarView>>>;
export type SideBarViewQueryError = unknown;

export const useSideBarView = <TData = Awaited<ReturnType<typeof sideBarView>>, TError = unknown>(
  params: SideBarViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sideBarView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSideBarViewQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const transferView = (
  params: TransferViewParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<TransferViewResponse>({ url: `/view/transfer`, method: 'get', params, signal }, options);
};

export const getTransferViewQueryKey = (params: TransferViewParams) => {
  return [`/view/transfer`, ...(params ? [params] : [])] as const;
};

export const getTransferViewInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof transferView>>,
  TError = HttpError
>(
  params: TransferViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof transferView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTransferViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof transferView>>> = ({ signal, pageParam }) =>
    transferView({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof transferView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TransferViewInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof transferView>>>;
export type TransferViewInfiniteQueryError = HttpError;

export const useTransferViewInfinite = <TData = Awaited<ReturnType<typeof transferView>>, TError = HttpError>(
  params: TransferViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof transferView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTransferViewInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getTransferViewQueryOptions = <TData = Awaited<ReturnType<typeof transferView>>, TError = HttpError>(
  params: TransferViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof transferView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTransferViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof transferView>>> = ({ signal }) =>
    transferView(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof transferView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TransferViewQueryResult = NonNullable<Awaited<ReturnType<typeof transferView>>>;
export type TransferViewQueryError = HttpError;

export const useTransferView = <TData = Awaited<ReturnType<typeof transferView>>, TError = HttpError>(
  params: TransferViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof transferView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTransferViewQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const viewTreat = (
  params?: ViewTreatParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<ViewTreatResponse>({ url: `/view/treat`, method: 'get', params, signal }, options);
};

export const getViewTreatQueryKey = (params?: ViewTreatParams) => {
  return [`/view/treat`, ...(params ? [params] : [])] as const;
};

export const getViewTreatInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof viewTreat>>, TError = HttpError>(
  params?: ViewTreatParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof viewTreat>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getViewTreatQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof viewTreat>>> = ({ signal, pageParam }) =>
    viewTreat({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof viewTreat>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ViewTreatInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof viewTreat>>>;
export type ViewTreatInfiniteQueryError = HttpError;

export const useViewTreatInfinite = <TData = Awaited<ReturnType<typeof viewTreat>>, TError = HttpError>(
  params?: ViewTreatParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof viewTreat>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getViewTreatInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getViewTreatQueryOptions = <TData = Awaited<ReturnType<typeof viewTreat>>, TError = HttpError>(
  params?: ViewTreatParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof viewTreat>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getViewTreatQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof viewTreat>>> = ({ signal }) =>
    viewTreat(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof viewTreat>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ViewTreatQueryResult = NonNullable<Awaited<ReturnType<typeof viewTreat>>>;
export type ViewTreatQueryError = HttpError;

export const useViewTreat = <TData = Awaited<ReturnType<typeof viewTreat>>, TError = HttpError>(
  params?: ViewTreatParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof viewTreat>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getViewTreatQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const troughManagementView = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<TroughManagementViewResponse>({ url: `/view/trough-management`, method: 'get', signal }, options);
};

export const getTroughManagementViewQueryKey = () => {
  return [`/view/trough-management`] as const;
};

export const getTroughManagementViewInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof troughManagementView>>,
  TError = HttpError
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof troughManagementView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTroughManagementViewQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof troughManagementView>>> = ({ signal }) =>
    troughManagementView(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof troughManagementView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TroughManagementViewInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof troughManagementView>>>;
export type TroughManagementViewInfiniteQueryError = HttpError;

export const useTroughManagementViewInfinite = <
  TData = Awaited<ReturnType<typeof troughManagementView>>,
  TError = HttpError
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof troughManagementView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTroughManagementViewInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getTroughManagementViewQueryOptions = <
  TData = Awaited<ReturnType<typeof troughManagementView>>,
  TError = HttpError
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof troughManagementView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTroughManagementViewQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof troughManagementView>>> = ({ signal }) =>
    troughManagementView(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof troughManagementView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TroughManagementViewQueryResult = NonNullable<Awaited<ReturnType<typeof troughManagementView>>>;
export type TroughManagementViewQueryError = HttpError;

export const useTroughManagementView = <
  TData = Awaited<ReturnType<typeof troughManagementView>>,
  TError = HttpError
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof troughManagementView>>, TError, TData>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTroughManagementViewQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const troughManagementGraphView = (
  params?: TroughManagementGraphViewParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal
) => {
  return request<TroughManagementGraphViewResponse>(
    { url: `/view/trough-management-graph`, method: 'get', params, signal },
    options
  );
};

export const getTroughManagementGraphViewQueryKey = (params?: TroughManagementGraphViewParams) => {
  return [`/view/trough-management-graph`, ...(params ? [params] : [])] as const;
};

export const getTroughManagementGraphViewInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof troughManagementGraphView>>,
  TError = unknown
>(
  params?: TroughManagementGraphViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof troughManagementGraphView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTroughManagementGraphViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof troughManagementGraphView>>> = ({ signal, pageParam }) =>
    troughManagementGraphView({ page: pageParam, ...params }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof troughManagementGraphView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TroughManagementGraphViewInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof troughManagementGraphView>>
>;
export type TroughManagementGraphViewInfiniteQueryError = unknown;

export const useTroughManagementGraphViewInfinite = <
  TData = Awaited<ReturnType<typeof troughManagementGraphView>>,
  TError = unknown
>(
  params?: TroughManagementGraphViewParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof troughManagementGraphView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTroughManagementGraphViewInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getTroughManagementGraphViewQueryOptions = <
  TData = Awaited<ReturnType<typeof troughManagementGraphView>>,
  TError = unknown
>(
  params?: TroughManagementGraphViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof troughManagementGraphView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTroughManagementGraphViewQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof troughManagementGraphView>>> = ({ signal }) =>
    troughManagementGraphView(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof troughManagementGraphView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TroughManagementGraphViewQueryResult = NonNullable<Awaited<ReturnType<typeof troughManagementGraphView>>>;
export type TroughManagementGraphViewQueryError = unknown;

export const useTroughManagementGraphView = <
  TData = Awaited<ReturnType<typeof troughManagementGraphView>>,
  TError = unknown
>(
  params?: TroughManagementGraphViewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof troughManagementGraphView>>, TError, TData>;
    request?: SecondParameter<typeof request>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getTroughManagementGraphViewQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
