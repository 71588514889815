import CircularProgress from '@mui/material/CircularProgress';
import { ReactNode } from 'react';
import { ButtonContainer } from './styles';

type Props = {
  children: ReactNode;
  variant?: 'primary' | 'secondary';
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  fullWidth?: boolean;
};

export function Button({
  children,
  loading = false,
  type = 'button',
  disabled = false,
  onClick,
  variant = 'primary',
  className,
  fullWidth = false
}: Props) {
  return (
    <ButtonContainer
      type={type}
      disabled={loading || disabled}
      onClick={onClick}
      variant={variant}
      className={className}
      fullWidth={fullWidth}
    >
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px'
          }}
        />
      )}

      {children}
    </ButtonContainer>
  );
}
