// import { Dashboard } from '../../_layouts/dashboard';
import { Button } from '../button';
import { Error } from '../error';

export function FallBack() {
  return (
    // <Dashboard>
    <Error text="Um erro inesperado aconteceu, tente recarregar a página">
      <Button onClick={() => window.location.reload()}>Recarregar</Button>
    </Error>
    // </Dashboard>
  );
}
