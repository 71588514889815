import {
  Breadcrumbs,
  Dedupe,
  GlobalHandlers,
  HttpContext,
  init,
  LinkedErrors,
  makeBrowserOfflineTransport,
  makeFetchTransport,
  Replay,
  TryCatch
} from '@sentry/browser';
import { VITE_FDM_ENV, VITE_FDM_VERSION, VITE_SENTRY_DSN } from './constants';

init({
  dsn: VITE_SENTRY_DSN,
  environment: VITE_FDM_ENV,
  release: VITE_FDM_VERSION,
  enabled: VITE_FDM_ENV !== 'local',
  sampleRate: 1,
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 1,
  enableTracing: true,
  // Esses erros são pegos pelo sentry do backend.
  ignoreErrors: [/AxiosError/i, /Request failed with status code/i],
  integrations: [
    new Replay(),
    new HttpContext(),
    new TryCatch(),
    new Breadcrumbs(),
    new GlobalHandlers(),
    new LinkedErrors(),
    new Dedupe()
  ],
  transport: makeBrowserOfflineTransport(makeFetchTransport)
});
