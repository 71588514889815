import styled from 'styled-components';

const Container = styled.div`
  width: 38px;
  height: 38px;
`;

const Button = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export { Container, Button, Image };
