import { Stack, Typography } from '@mui/material';

export function NotFound() {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" height="100vh">
      <Typography variant="h1" fontSize={24} borderRight="1px solid rgba(0,0,0,.3)" p={2}>
        404
      </Typography>

      <Typography variant="h2" fontSize={14} p={2}>
        Página não encontrada
      </Typography>
    </Stack>
  );
}
