import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Languages
import ENUS from './translations/en-US.json';
import PTBR from './translations/pt-BR.json';

i18n //
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'pt-BR',

    defaultNS: 'common',

    resources: {
      'pt-BR': { common: PTBR },
      'en-US': { common: ENUS },
    },

    interpolation: {
      escapeValue: false,
    },
  });

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'resources';

    resources: {
      'pt-BR': typeof PTBR;
      'en-US': typeof ENUS;
    };
  }
}
