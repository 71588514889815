import styled from 'styled-components';

const Container = styled.div`
  width: auto;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  color: ${(props) => props.theme.colors.primary};
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  border: 1px solid ${(props) => props.theme.colors.primary};

  &:hover {
    background: ${(props) => props.theme.colors.bgActive};
  }
`;

export { Container, Button };
