export function getUTCDate(date: string | null) {
  if (!date) {
    return 'N/A';
  }

  const inputDate = new Date(date);

  const day = inputDate.getUTCDate();
  const month = inputDate.getUTCMonth() + 1;
  const year = inputDate.getUTCFullYear();

  return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
}

export function formatDate(date: string | Date | null | undefined) {
  if (date?.toString().length === 0) {
    return 'N/A';
  }

  if (date === null || date === undefined) {
    return 'N/A';
  }

  return (date instanceof Date ? date : new Date(date)).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });
}

export function formatDateUTC(date: string | Date | null | undefined) {
  if (date?.toString().length === 0) {
    return 'N/A';
  }

  if (date === null || date === undefined) {
    return 'N/A';
  }

  return (date instanceof Date ? date : new Date(date)).toLocaleDateString('pt-BR', { timeZone: 'UTC' });
}

export function formatDateToSubmit(date: string) {
  const newDate = date.split('/');
  return new Date(`${newDate[2]}-${newDate[1]}-${newDate[0]}`);
}

export function parsedDate(date: string | undefined) {
  if (!date) return 'N/A';

  return new Date(date).toLocaleString('pt-BR', {
    dateStyle: 'short',
    timeZone: 'UTC'
  });
}

export function getLongDate(date: string | Date) {
  const data = date instanceof Date ? date : new Date(date);

  const day = data.getUTCDate();
  const month = data.getUTCMonth() + 1;
  const year = data.getUTCFullYear();

  const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

  return `${day} ${months[month - 1]} ${year}`;
}

export function adjustToUserTimezone(utcISOString: any) {
  const utcDate = new Date(utcISOString);
  const userTimezoneOffset = new Date().getTimezoneOffset();
  const userOffsetDate = new Date(utcDate.getTime() + userTimezoneOffset * 60 * 1000);

  return userOffsetDate;
}

export function getDiffDays(date1: string | undefined, date2: string | undefined) {
  if (!date1 || !date2) return 0;

  const date1UTC = new Date(date1);
  const date2UTC = new Date(date2);

  const diffTime = Math.abs(date1UTC.getTime() - date2UTC.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
}
