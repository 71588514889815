export function useSession() {
  const currentSession = '';
  const currentNavigation = '';
  const currentAccordion = '';

  const toggleSession = '';
  const toggleNavigation = '';

  return {
    currentSession,
    currentNavigation,
    currentAccordion,

    toggleSession,
    toggleNavigation
  };
}
