import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { createContext } from 'use-context-selector';

import Dark from '../themes/dark';
import Light from '../themes/light';

import { createTheme } from '@mui/material/styles';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: '#008350',
      light: '#008350',
      dark: '#008350',
      contrastText: '#fff',
      '100': 'rgb(0 131 80 / 90%)'
    }
  }
});

const STORAGE_THEME = 'theme';

export enum ITheme {
  LIGHT = 'light',
  DARK = 'dark'
}

type ContextData = {
  theme: string;
  toggleTheme(): void;
};

export const ThemeContext = createContext({} as ContextData);

export const ThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<ITheme>(ITheme.LIGHT);

  const currentTheme = localStorage.getItem(STORAGE_THEME);
  const themeMode = theme === ITheme.LIGHT ? Light : Dark;

  const toggleTheme = useCallback(() => {
    if (currentTheme) {
      const newTheme = currentTheme === ITheme.LIGHT ? ITheme.DARK : ITheme.LIGHT;

      setTheme(newTheme);
      localStorage.setItem(STORAGE_THEME, newTheme);
    } else {
      setTheme(ITheme.DARK);
      localStorage.setItem(STORAGE_THEME, ITheme.DARK);
    }
  }, [theme]);

  const data: ContextData = useMemo(
    () => ({
      theme,
      toggleTheme
    }),
    [theme]
  );

  useEffect(() => {
    if (currentTheme && currentTheme === ITheme.DARK) {
      setTheme(ITheme.DARK);
    }
  }, []);

  return (
    <MuiThemeProvider theme={MuiTheme}>
      <StyledThemeProvider theme={themeMode}>
        <ThemeContext.Provider value={data}>{children}</ThemeContext.Provider>
      </StyledThemeProvider>
    </MuiThemeProvider>
  );
};
