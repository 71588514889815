import styled from 'styled-components';

export const Container = styled.div`
  width: fit-content;
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;

  thead tr {
    border-top: 1px solid #ccc;
  }

  thead td {
    padding: 8px;
  }

  tbody tr {
    border-top: 1px solid #ccc;
  }

  tbody td {
    padding: 8px;
  }
`;

const ContainerFlex = styled.div`
  width: 100%;
  display: flex;
`;

const ContainerHeader = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 20% 80%;
  padding: 16px;
`;

const ColumnHeader = styled.div`
  display: grid;
  grid-template-rows: 45% 10% 45%;
`;

// const Container = styled.div`
//   padding: 8px;
//   border: 2px solid ${(props) => props.theme.colors.primary};
//   border-radius: 8px;
// `;

const ContainerTitle = styled.div`
  text-align: center;
  font-weight: bold;
`;

const ColumnInformation = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 50% 50%;
`;

const RowInformation = styled.div`
  display: grid;
  gap: 0.7rem;
  grid-template-rows: auto;
  align-content: end;
`;

const HeaderFilter = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: inherit;
  padding: 16px;
`;

const SummaryTitle = styled.div`
  text-align: center;
  font-weight: bold;
  padding: 8px;
  border: 2px solid ${(props) => props.theme.colors.primary};
  border-radius: 8px;
`;

const TableContainer = styled.div`
  width: 100%;
  /* padding: 16px 0; */
  /* border-bottom: 1px solid ${(props) => props.theme.colors.primary}; */
`;

const TableTitle = styled.div`
  padding: 16px 8px;
  font-weight: bold;
  height: 50px;
`;

export {
  ColumnHeader,
  ColumnInformation,
  ContainerFlex,
  ContainerHeader,
  // Container,
  ContainerTitle,
  HeaderFilter,
  RowInformation,
  SummaryTitle,
  Table,
  // Content,
  TableContainer,
  TableTitle
};
