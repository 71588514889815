import styled from 'styled-components';

type TitleContainerProps = {
  noMargin: boolean;
};

const TitleContainer = styled.div<TitleContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .text {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      font-size: 20px;
      font-weight: 500;
      color: ${(props) => props.theme.colors.text100};
    }

    .description {
      font-size: 14px;
      font-weight: 500;
      color: ${(props) => props.theme.colors.text90};
    }
  }

  .btn {
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-weight: 500;
    background: fixed;
    color: ${(pros) => pros.theme.colors.primary};
    border-radius: 8px;
    border: ${(pros) => pros.theme.border.borderPrimary};
    height: 48px;
    cursor: pointer;
  }
`;

export { TitleContainer };
