import styled from 'styled-components';

const Container = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  list-style: none;
  margin-bottom: 16px;
`;
const BoxItem = styled.li`
  background-color: ${(props) => props.theme.colors.bg90};
  color: ${(props) => props.theme.colors.text100};
  box-shadow: ${(props) => props.theme.boxShadow.shadow100};
  border: ${(props) => props.theme.border.border100};
  border-radius: 8px;
  border-left: 5px solid ${(props) => props.theme.colors.primary};

  .header {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    height: 52px;
    align-items: center;

    .text {
      font-size: 18px;
      font-weight: 500;
    }

    .icon {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background 300ms linear;

      :hover {
        background: ${(props) => props.theme.colors.hover};
      }

      svg {
        fill: ${(props) => props.theme.colors.text90};
      }
    }
  }

  .body {
    width: 100%;
    padding: 0 16px;
    margin-bottom: 16px;

    .cards {
      width: 100%;
      display: grid;

      grid-template-areas:
        '. .'
        '. .';

      grid-template-columns: auto;
      grid-template-rows: auto;
      gap: 16px;
    }

    .card-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .description {
      font-size: 18px;
      font-weight: 500;
    }

    .title {
      font-size: 12px;
      font-weight: 500;
      padding-bottom: 8px;
      color: ${(props) => props.theme.colors.text90};
    }

    .tags {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      margin-bottom: 16px;

      .tag {
        background: ${(props) => props.theme.tags.bgDefault};
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 500;
        color: ${(props) => props.theme.tags.textDefault};
      }

      .red {
        background: ${(props) => props.theme.tags.bgRed};
        color: ${(props) => props.theme.tags.textRed};
      }

      .green {
        background: ${(props) => props.theme.tags.bgGreen};
        color: ${(props) => props.theme.tags.textGreen};
      }

      .blue {
        background: ${(props) => props.theme.tags.bgBlue};
        color: ${(props) => props.theme.tags.textBlue};
      }

      .yellow {
        background: ${(props) => props.theme.tags.bgYellow};
        color: ${(props) => props.theme.tags.textYellow};
      }
    }
  }
`;

export { Container, BoxItem };
