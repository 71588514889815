import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const Column = styled.div`
  width: 100%;
  border-right: ${(props) => props.theme.border.border100};

  div:last-child() {
    background: #ccc;
  }
`;

const Column2 = styled.div`
  width: 100%;
  display: flex;

  & > div:nth-child(1) {
    border-right: ${(props) => props.theme.border.border100};
  }

  .MuiFormControl-root {
    border: none !important;
  }
`;

const Row = styled.div`
  width: 100%;
  border-bottom: ${(props) => props.theme.border.border100};
  padding: 8px;
`;

const RowBody = styled.div`
  width: 100%;
  height: 57px;
  border-bottom: ${(props) => props.theme.border.border100};
  padding: 8px;
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  color: ${(props) => props.theme.colors.text90};
  font-size: 14px;
  font-weight: 500;
  border: none !important;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.text100};
  font-weight: 500;
  border: none !important;
  width: 100%;
`;

export {
  Container, Column, Row, Column2, Description, Title, RowBody,
};
