function parse<T>(env: any, parse: (a: any) => T, def?: T): T {
  // @ts-expect-error
  return env ? parse(env) : def;
}

export const API_BASE_URL = parse(import.meta.env.VITE_API_BASE_URL, String, 'http://localhost:23193/api');
export const IS_PROD = parse(import.meta.env.PROD, Boolean, false);
export const GMAPS_API_KEY = parse(
  import.meta.env.VITE_GMAPS_API_KEY,
  String,
  'AIzaSyA_ffOOZg7w13P6pj9_6UU-RsdzJcB7O04'
);

export const FARM_DAY = 'farm-day';

export const VITE_SENTRY_DSN = parse(import.meta.env.VITE_SENTRY_DSN, String);
export const VITE_FDM_ENV = parse(import.meta.env.VITE_FDM_ENV, String, 'local');
export const VITE_FDM_VERSION = parse(import.meta.env.VITE_FDM_VERSION, String, 'latest');

export const FARM_DAY_KEY = 'feed-farm-day';
export const AUTH_KEY = 'feed-auth';

export const FEED_SESSION = 'FEED:current-session';

export enum Sessions {
  admin = 'Painel',
  reports = 'Relatórios',
  management = 'Manejo',
  tract = 'Trato',
  diet = 'Formulação',
  financial = 'Financeiro',
  traceability = 'Rastreabilidade',
  farm = 'Fazenda',
  medicalServices = 'Sanidade',
  logout = 'Sair',
  batches = 'Lotes avulsos'
}
