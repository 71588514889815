import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SkeletonContent = styled.div`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid;
  border-color: ${(props) => props.theme.border.color};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  .MuiSkeleton-root {
    background-color: ${(props) => props.theme.border.color};
  }
`;

export { Container, SkeletonContent };
