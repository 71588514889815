import styled from 'styled-components';

interface ItemBodyContainerProps {
  showAll: boolean;
}

interface ItemProps {
  clickable: boolean;
  horizontal: boolean;
}

interface BodyProps {
  horizontal: boolean;
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const Body = styled.div<BodyProps>`
  width: 100%;
  display: ${(props) => (props.horizontal ? 'flex' : 'grid')};
  overflow-x: auto;
  gap: 16px;
  padding-bottom: 16px;
  grid-template-areas: '. . . .';
`;

const Item = styled.div<ItemProps>`
  width: ${(props) => (props.horizontal ? '45%' : '100%')};
  flex: ${(props) => (props.horizontal ? 'none' : '1')};
  background-color: ${(props) => props.theme.colors.bg90};
  color: ${(props) => props.theme.colors.text100};
  box-shadow: ${(props) => props.theme.boxShadow.shadow100};
  border: ${(props) => props.theme.border.border100};
  border-radius: 8px;
  border-left: 5px solid ${(props) => props.theme.colors.primary};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`;

const ItemHeader = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding-left: 16px;

  span {
    flex: 1;
  }
`;

const MoreButton = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 300ms linear;

  :hover {
    background: ${(props) => props.theme.colors.hover};
  }

  svg {
    fill: ${(props) => props.theme.colors.text90};
  }
`;

const ItemBodyContainer = styled.div<ItemBodyContainerProps>`
  width: 100%;
  display: grid;
  grid-template-areas: '. .';
  grid-template-columns: 50%;
  grid-template-rows: auto;
  gap: 16px;
  padding-bottom: 16px;

  div:nth-child(odd) {
    padding-left: 16px;
  }

  div:nth-child(even) {
    padding-right: 16px;
  }

  div:nth-child(n + 3):nth-child(n) {
    display: ${(props) => (props.showAll ? 'inherit' : 'none')};
  }
`;

const ItemBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  span:nth-child(odd) {
    font-size: 12px;
  }
`;

const ShowAllButton = styled.button`
  width: 100%;
  cursor: pointer;
  background: transparent;
  padding: 8px 0;
  color: ${(props) => props.theme.colors.text90};
  font-weight: 500;
  font-size: 12px;
`;

export {
  Container, Header, Body, Item, ItemHeader, MoreButton, ItemBodyContainer, ItemBody, ShowAllButton,
};
