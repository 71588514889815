import styled from 'styled-components';
import type { HighlightedStatusProps } from './types';

type IHighlightedStatus = {
  status: HighlightedStatusProps;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SkeletonContent = styled.div`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid;
  border-color: ${(props) => props.theme.border.color};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  .MuiSkeleton-root {
    background-color: ${(props) => props.theme.border.color};
  }
`;

const Highlighted = styled.div<IHighlightedStatus>`
  background: ${({ status, theme }) => (status === 'PENDING' ? theme.colors.orange : status === 'RECEIVED' ? theme.colors.blue : theme.colors.primary)};
  color: #fff;
  width: fit-content;
  padding: 8px 16px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
`;

export { Container, SkeletonContent, Highlighted };
