import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.text90};
  font-weight: 500;
`;

export { Container, Title };
